import React, { useState, useEffect } from 'react'
import market from '../../assets/images/pdfs/Market.png';
import frustration from '../../assets/images/pdfs/howork.png';
import profile from '../../assets/images/pdfs/keypoint.png';

export default function MarketRsearch(props) {
    const [data, setData] = useState(null);

    useEffect(() => {
        if (props.user) {
            // console.log('SWOT Analysis -> user: ', props.user);
            setData(props.user.companies[0].jsonContent.find(item => item.Title === "Market size and Trends"));
        }
    })

    return (<div style={{fontFamily:'sans-serif',display:'none'}}><div style={{fontFamily:'sans-serif', width: `400px`, padding: '10px 20px', marginLeft: 20 }} id='market'>
        <div style={{fontFamily:'sans-serif', display: 'flex', alignItems: 'center' }}>
            <div style={{fontFamily:'sans-serif', width: 8, height: 8, marginRight: 8, marginTop: 17, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 3, color: '#26B35A' }}>
                <div style={{fontFamily:'sans-serif', width: 12 }}>
                    <img src={market} />
                </div>
            </div>
            <div style={{fontFamily:'sans-serif', fontSize: 12, color: '#26B35A' }}>Market Research</div>
        </div>
        <div style={{fontFamily:'sans-serif', fontSize: 16, fontWeight:"bold" }}>
        Market Size & Trends
        </div>
        <div style={{fontFamily:'sans-serif', paddingTop: 20, paddingBottom: 20, textWrap: 'wrap' }}>
            <p style={{fontFamily:'sans-serif', fontSize: 8 }}>{data?.Summary}</p>
        </div>
        <div>
        <div style={{fontFamily:'sans-serif', textWrap: 'wrap' }}>
            {
                data?.Content.map(item => (<div style={{fontFamily:'sans-serif', border: '1px solid #666', borderRadius: 5, padding: '5px 0px', marginBottom: 5 }}>
                    <div style={{fontFamily:'sans-serif', padding: '5px', marginBottom: 10 }}>
                        <div style={{fontFamily:'sans-serif', fontSize: 10, fontWeight: 'bold', marginTop: -5 }}>{item?.SubTitle}</div>
                        <div>
                            <p style={{fontFamily:'sans-serif', fontSize: 8 }}>{item?.Description?.Overview}</p>
                        </div>
                    </div>
                    <div style={{fontFamily:'sans-serif', display: 'flex', height: 1, backgroundColor: '#666' }}></div>
                    <div style={{fontFamily:'sans-serif', display: 'flex', width: '100%', flexWrap: 'wrap', marginBottom:10 }}>
                        <div style={{fontFamily:'sans-serif', width: '50%', padding: 5 }}>
                            <div style={{fontFamily:'sans-serif', display: 'flex', alignItems: 'center', marginRight: 5 }}>
                                <img width={8} height={8} src={profile} style={{fontFamily:'sans-serif', marginTop: 5 }} />
                                <span style={{fontFamily:'sans-serif', marginLeft: 5, marginBottom: 5, fontWeight: 'bold', fontSize: 10 }}>Key Points</span>
                            </div>
                            <ol style={{fontFamily:'sans-serif', padding: 5 }}>
                                {
                                    item?.Description['Key Points']?.map((item, index) => (<li style={{fontFamily:'sans-serif', fontSize: 8 }}>
                                        {`${item.Description}`}
                                    </li>))
                                }
                            </ol>
                        </div>
                        <div style={{fontFamily:'sans-serif', width: '50%', padding: 5 }}>
                            <div style={{fontFamily:'sans-serif', display: 'flex', alignItems: 'center', marginRight: 5 }}>
                                <img width={8} height={8} src={frustration} style={{fontFamily:'sans-serif', marginTop: 5 }} />
                                <span style={{fontFamily:'sans-serif', marginLeft: 5, marginBottom: 5, fontWeight: 'bold', fontSize: 10 }}>How You Can Leverage</span>
                            </div>
                            <ol style={{fontFamily:'sans-serif', padding: 5 }}>
                                {
                                    item?.Description['How You Can Leverage']?.map((item, index) => (<li style={{fontFamily:'sans-serif', fontSize: 8 }}>
                                        {`${item.Description}`}
                                    </li>))
                                }
                            </ol>
                        </div>
                    </div>
                </div>))
            }
            </div>
            {/* <div>
                <div style={{fontFamily:'sans-serif',fontSize: 16, fontWeight: 'bold'}}>
                    { data?.Description?.SubTitle }
                </div>
                <div>
                    <p>{ data?.Description?.Description }</p>
                </div>
            </div> */}
        </div>
        </div>
    </div>)
}