import React, { useState, useRef, useEffect } from "react";

import axios from "axios";
import { Row, Col, Typography, Space, Switch } from "antd";
import * as formats from "../../utils/configure";
import { URL } from '../../utils/const';
import { genEachContent } from "../../utils/service";
import { useSelector, useDispatch } from "react-redux";
import { updateHtmlContent } from "../../Redux/features/UserSlice";
import { addContent } from "../../Redux/features/pagesSlice";
import Edit from "utils/edit";
import Admin from "utils/admin";
import { setRegenerate } from "../../Redux/features/UserSlice";
import Limit from "components/Limit";
import Generating from "components/Generating";
import { addUser } from "../../Redux/features/UserSlice";
import Upgrade from "components/Upgrade";
const { Text } = Typography;

const MarketingIdeas = () => {

  const [oldprompt, setOldPrompt] = useState([]);
  const [flag, setFlag] = useState(false);
  const [generateFlag, setGenerateFlag] = useState(false);

  const [renderData, setRenderData] = useState(null);
  const [headerData, setHeaderData] = useState(null);

  const [content, setContent] = useState([]);
  const [paid, setPaid] = useState();
  const dispatch = useDispatch();
  const { email, curID, companies, role, prompt } = useSelector(
    (state) => state.userSlice
  );
  const user = useSelector((state) => {
    return state.userSlice;
  });
  const pageContents = useSelector((state) => {
    return state.pagesSlice;
  });

  const category = 'Marketing Ideas'
  const page_title = user.companies[0].content.find((item) => item.title === "Sales and Marketing").list.find((item) => item.origin===category).shown
  const auth = role


  useEffect(() => {
    setFlag(false);
    dispatch(setRegenerate(false))
    if (email == "") window.location.href = "/sign-in";
  }, []);

  useEffect(() => {
    axios.post(URL + "/api/paid/get", { data: "Marketing Ideas" })
        .then(res => {
            console.log("res", res.data.prompt);
            if (res.data.prompt == 'paid')
                setPaid(true)
            else setPaid(false)
        })
        .catch(err => console.log("err", err))
}, [])

const handleChangeCheck = () => {
    if (paid == true)
        setPaid(false)
    else setPaid(true)

    axios.post(URL + "/api/paid/add", { data: "Marketing Ideas" })
    .then(res=>{
        console.log("paid has changed", res)
    })
    .catch(err=>console.log("failed to change the paid method", err))
}

  useEffect(() => {
    const currentPrompt = prompt.filter((item) => item.title == category);
    setOldPrompt(currentPrompt.prompt);
  }, []);

  const savePrompt = (newPrompt) => {
    console.log("saveNewPrompt", newPrompt);
  };

  const generateContent = (newPrompt) => {
    setFlag(true);
    setGenerateFlag(true);
    let formatData;
    Object.keys(formats).map((key) => {
      if (category == formats[key].Title) {
        console.log(formats[key].Title, formats[key], formats[key]);
        formatData = { title: formats[key].Title, data: formats[key] };
      }
    });

    genEachContent(user.companies[0], newPrompt, formatData.data, user.gpt.modelName)
      .then((res) => {
        if (Array.isArray(pageContents.contents)) {
          const newContents = pageContents.contents.map((item, idx) => {
            if (item.Title === category) {
              return res;
            }
            return item;
          });
          let temp = newContents;
          dispatch(addContent({ content: temp }));
          dispatch(updateHtmlContent({ itemTitle: category, newhtmlContent: {} }));
          dispatch(setRegenerate(false))
          setFlag(false);
          axios.post(URL + "/api/users/result/save", { email: user.email, companyName: user.companies[0].companyName, content: newContents })
            .then(res => {
              console.log("---------------", res)
              dispatch(addUser({
                email: user.email,
                name: 'StartupSphere',
                companies: res.data,
                curID: user.curID,
                role: user.role,
                prompt: user.prompt,
                upgrade: user.upgrade,
                gpt: user.gpt
              }))
            }).catch(err => console.log(err))
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    let middleData = [];

    if (Array.isArray(pageContents.contents)) {
      middleData = pageContents.contents.filter((item) => {
        return item.Title === category;
      });

    } else if (Array.isArray(pageContents.contents.contents)) {
      middleData = pageContents.contents.contents.filter((item) => {
        return item.Title === category;
      });
    }
    console.log("middledata", pageContents);
    setRenderData(middleData);
    console.log("middleData", middleData)
  }, [pageContents]);

  useEffect(() => {
    let htmlContent = null;
    companies[0].htmlContent.forEach((item) => {
      if (item.Title === category) {
        htmlContent = item.htmlcontent;
      }
    });
    if (htmlContent != null && !generateFlag) {
      setHeaderData(htmlContent.headerData);
      setContent(htmlContent.content);
    } else if (renderData != null && renderData[0]) {
      const result = `<h5>${renderData[0]?.["Summary"]}</h5>`;
      setHeaderData(result);
      let result1 = [], temp = "";
      for (let i = 0; i < renderData[0]?.["Content"].length; i++) {
        temp += `<h6>${renderData[0]?.["Content"]?.[i]?.["Title"]
          }` + `<h5>${renderData[0]?.["Content"]?.[i]?.["Description"]}</h5></h6>`;
        result1.push(`<h6>${renderData[0]?.["Content"]?.[i]?.["Title"]
          }` + `<h5>${renderData[0]?.["Content"]?.[i]?.["Description"]}</h5></h6>`);
      }
      result1.map(index => {
        console.log(index)
      })
      setContent(result1);

    }
  }, [renderData]);

  const handleChange = (html, clippedTxt, id) => {
    let tempContent;

    const data = {
      headerData: headerData,
      content: content,
    };
    if (id.includes("content")) {
      const number = parseInt(id.split("_")[1]);
      tempContent = content;
      renderData[0]?.["Content"].map((item, idx) => {
        if (idx == number) {
          tempContent[idx] = html;
        }
      })
      data["content"] = tempContent;
      setContent(tempContent);
    }

    if (id == "headerData") {
      data[id] = html;
      setHeaderData(html);
    }

  };

  useEffect(() => {
    if (headerData != null) {
      const data = {
        headerData: headerData,
        content: content,
      }
      const sendData = { email: user.email, company: user.companies[0].companyName, title: category, sendData: data }
      axios.post(URL + "/api/users/changeHtml", {
        data: sendData
      }).then(res => {
        console.log('updated and saved')
        dispatch(updateHtmlContent({ itemTitle: category, newhtmlContent: data }));
      }).catch(err => console.log('saving updated content'))
    }
  }, [content, headerData]);

  const chageHtml = (html, id) => {
    if (id == "headerData") setHeaderData(html);

    if (id.includes("content")) {
      const number = parseInt(id.split("_")[1]);
      let tempContent = content;
      tempContent[number] = html;
      setContent(tempContent);
    }

  };

  useEffect(() => {
    if (user.regenerateFlag == true) {
      let currentPrompt = ''
      console.log("regnerating")
      user.prompt.map(item => {
        if (item.title == category) {
          currentPrompt = item.prompt
        }
      })
      generateContent(currentPrompt)
    } else {
      setFlag(false)
    }
  }, [user.regenerateFlag]);


  const handleDragStart = (event, index) => {
    event.dataTransfer.setData('index', index);
  };

  const handleDragEnter = () => {
  };

  const handleDragLeave = () => {
  };

  const handleDragOver = event => {
    event.preventDefault();
  };

  const handleDrop = event => {
    const sourceIndex = event.dataTransfer.getData('index');
    const targetIndex = event.currentTarget.getAttribute('data-index');
    const newElements = [...content];
    const [removed] = newElements.splice(sourceIndex, 1);
    newElements.splice(targetIndex, 0, removed);
    setContent(newElements);
  };

  const deleteItem = (idx) => {
    const result = content.filter((item, index) => idx != index)
    setContent(result)
  }

  const addItem = (idx) => {
    let newContent = [...content]
    newContent.splice(idx + 1, 0, content[idx])
    setContent(newContent)
  }

  return (
    <div className="layout-content mx-auto max-w-4xl">
      {(user.upgrade == "unpaid" && auth != "admin") && <Col xs={24} md={24} lg={24}><Limit /></Col>}
      <Row className="p-6 ">
        <Col
          xs={24}
          md={24}
          lg={24}
          style={{ display: "flex", alignItems: "center" }}
          className="flex items-center justify-between mt-4 mb-9"
        >
          <div><h6 class="text-md font-normal flex items-center leading-none capitalize w-full text-green-500"><div class="w-6 h-6 mr-2 justify-center flex rounded-sm text-green-500 bg-green-50"><div class="w-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-full"><polyline points="22 7 13.5 15.5 8.5 10.5 2 17"></polyline><polyline points="16 7 22 7 22 13"></polyline></svg></div></div><span class="text-base font-normal">Sales &amp; Marketing</span></h6><h3 class="text-2xl sm:text-3xl font-medium leading-none tracking-tight capitalize w-full mt-4 mb-3"> {page_title}</h3></div>
          {auth=='admin'&&<Switch className="bg-[#3367D6]" checkedChildren="FREE" unCheckedChildren="PAID" checked={paid} onChange={handleChangeCheck} />}
        </Col>

        {auth == "admin" && (
          <Admin
            currentPrompt={oldprompt}
            category={category}
            flag={flag}
            generateContent={generateContent}
            savePrompt={savePrompt}
          ></Admin>
        )}
        <Row>
          {/* <Edit
            content={headerData}
            id={"headerData"}
            changeContent={handleChange}
            changeHtml={chageHtml}
          ></Edit> */}
          <div className="flex flex-col">
            {((content&&user.upgrade != "unpaid")||(content&&auth == 'admin')||(content&&paid))&&content.map((index, idx) => (
              <div
                key={idx}
                draggable
                onDragStart={event => handleDragStart(event, idx)}
                onDragEnter={() => handleDragEnter(idx)}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                data-index={idx}
                className="block py-2 px-6 pl-8 pr-8 border border-solid mt-2 rounded-lg">
                <div className="relative mb-[-15px] flex w-[100px] hover:opacity-100 opacity-0 group-hover:opacity-100 transition-opacity rounded-md bg-white shadow-[0_0_0_1px_rgba(0,0,0,0.1),0_5px_15px_-10px_rgba(0,0,0,0.3)] overflow-hidden ">
                  <button className="text-[15px] font-normal ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent active:bg-black/10 w-8 h-8 text-neutral-400 hover:text-neutral-900 flex items-center rounded-none justify-center cursor-grab" data-drag-handle="true" data-state="closed">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="w-4">
                      <circle cx="9" cy="12" r="1"></circle>
                      <circle cx="9" cy="5" r="1"></circle><circle cx="9" cy="19" r="1"></circle>
                      <circle cx="15" cy="12" r="1"></circle><circle cx="15" cy="5" r="1"></circle>
                      <circle cx="15" cy="19" r="1"></circle></svg>
                  </button>
                  <button onClick={() => deleteItem(idx)} className="text-[15px] font-normal ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent active:bg-black/10 w-8 h-8 text-neutral-400 hover:text-neutral-900 flex items-center rounded-none justify-center" data-state="closed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="w-4"><path d="M3 6h18"></path><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path></svg></button>
                  <button onClick={() => addItem(idx)} className="text-[15px] font-normal ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent active:bg-black/10 w-8 h-8 text-neutral-400 hover:text-neutral-900 flex items-center rounded-none justify-center" data-state="closed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="w-4"><rect width="14" height="14" x="8" y="8" rx="2" ry="2"></rect><path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2"></path></svg></button>
                </div>
                <Edit
                  content={index}
                  id={"content_" + idx}
                  changeContent={handleChange}
                  changeHtml={chageHtml}
                ></Edit>
              </div>

            ))}
            {
              !content&&<Generating></Generating>
            }
            
          </div>
          
        </Row>
        {
        auth != "admin" && user.upgrade == "unpaid" &&!paid &&<Upgrade></Upgrade>
      }
      </Row>
    </div>
  );
};

export default MarketingIdeas;
