import { Input, Select } from 'antd'
import React, { useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from 'react-router-dom'

const Index = () => {
    const navigator = useNavigate()
    const [type, setType] = useState('');
    const [tabStatus, setTabStatus] = useState(true);

    const handleType = (value) => {
        // console.log(`selected ${value}`);
        setType(value)
    };
    const user = useSelector(state => state.userSlice)
    const statusClick = () => {
        // console.log(`selected ${value}`);
        setTabStatus(!tabStatus)
    };
    
    return (
        <div className=''>
            <div className='flex justify-between items-center w-full border p-2 rounded-lg' >
                <div>
                    <h4 style={{fontWeight: 400}} className='text-[16px]' >{user.companies[0].companyName} Business Plan</h4>
                    <span className='text-[14px]'>Angeles Template</span>
                </div>
                <button className='flex hover:bg-gray-200 px-2 h-[40px] items-center rounded-lg' onClick={()=>{navigator('/pdfs')}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 mr-2"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" x2="12" y1="15" y2="3"></line></svg>
                    Export to PDF
                </button>
            </div>
            <div className='flex h-[40px] p-[4px] bg-gray-200 rounded-md mt-[16px]'>
                <button onClick={statusClick} className={`w-[227px]  text-black h-[32px] rounded-md px-[12px] py-[6px] ${tabStatus ? 'bg-white' : 'bg-gray-200'}`}>Theme</button>
                <button onClick={statusClick} className={`w-[227px]  text-black h-[32px] rounded-md px-[12px] py-[6px] ${tabStatus ? 'bg-gray-200' : 'bg-white'}`}>Document</button>
            </div>
            {tabStatus ? (<div className></div>) : (<><div className='grid grid-cols-2 gap-2 mt-[20px]'>
                <div className="flex flex-col">
                    <label className='text-[14px]' style={{fontWeight: 500}}>Company Name</label>
                    <input type="text" className='border rounded-md w-[190px] h-[40px] py-[8px] px-[12px] text-[12px]'></input>
                </div>
                <div className="flex flex-col">
                    <label className='text-[14px]' style={{fontWeight: 500}}>Website</label>
                    <input type="text" className='border rounded-md w-[190px] h-[40px] py-[8px] px-[12px] text-[12px]'></input>
                </div>
                <div className="flex flex-col">
                    <label className='text-[14px]' style={{fontWeight: 500}}>Contact Name</label>
                    <input type="text" className='border rounded-md w-[190px] h-[40px] py-[8px] px-[12px] text-[12px]'></input>
                </div>
                <div className="flex flex-col">
                    <label className='text-[14px]' style={{fontWeight: 500}}>Contact Email</label>
                    <input type="text" className='border rounded-md w-[190px] h-[40px] py-[8px] px-[12px] text-[12px]'></input>
                </div>
                <div className="flex flex-col">
                    <label className='text-[14px]' style={{fontWeight: 500}}>Contact Phone</label>
                    <input type="text" className='border rounded-md w-[190px] h-[40px] py-[8px] px-[12px] text-[12px]'></input>
                </div>

                <div className="flex flex-col">
                    <label className='text-[14px]' style={{fontWeight: 500}}>Contact Address</label>
                    <input type="text" className='border rounded-md w-[190px] h-[40px] py-[8px] px-[12px] text-[12px]'></input>
                </div>
                
            </div>
                <button className='bg-black rounded-md text-white w-full h-[36px] mt-[16px] font-medium'>Save</button>
            </>)}
        </div>
    )
}

export default Index