import { OPENAI_API_KEY } from './const';

export async function fetchOpenAIResponse(info) {
  console.log("inog",info.modelName)
  console.log("summaries", info.summaries)
  const companyName = info.companyName
  const companyDescription = info.companyDescription
  // const category = info.category
  const title = info.title
  const prompt = info.prompt
  const output_format = JSON.stringify(info.output_format);

  const userMessage = `Your company name is ${companyName}, and here is the description about the company --- '${companyDescription}'
    you have summaries that are already generated  to make companies infomation----${info.summaries} 
    You should generate content with some information. 
      Title covered is ${title}.
      For this title, here is the detailed prompt: ${prompt}.
      you have to follow the prompt. we will provide what we want on prompt
      
      Your output format is json object as follows:
      ${output_format}.
      in the output format, you should fill 'Summary' field within 5 sentences, the 'Value' field should be existed and the 'Value1' field should be random number from 70 to 100.
      don't change "Title" value.Title is already made, you don't need to make Title.
    `
  const response = await fetch("https://api.openai.com/v1/chat/completions", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${OPENAI_API_KEY}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      model: info.modelName,
      messages: [{ role: "user", content: userMessage }],
      temperature: 0.7,
      response_format: { type: "json_object" },
      // max_tokens: 25,
    }),
  });

  if (!response.ok) {
    throw new Error(
      `OpenAI API request failed with status ${response.status}`
    );
  }
  const data = await response.json();
  console.log("tokens used: ", data.usage.total_tokens);
  let result = JSON.parse(data.choices[0].message.content.trim())
  result.companyName = companyName
  return result;
}

export async function generateIdeas(info) {
  const companyName = info.companyName
  const companyDescription = info.companyDescription
  const specifics = info.specifics

  const userMessage = `Your company name is ${companyName}, and here is the description about the company - '${companyDescription}'
      Here is the answer of "what does make your company unique"- "${specifics}"  
      You should generate 10 compony's ideas. You have to write each idea within 4 words. don't contain number!
      Your output format is json object as follows:
      const output_format = {"ideas":[]}.
    `
  const response = await fetch("https://api.openai.com/v1/chat/completions", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${OPENAI_API_KEY}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      model: "gpt-3.5-turbo-1106",
      messages: [{ role: "user", content: userMessage }],
      temperature: 0.7,
      response_format: { type: "json_object" },
      // max_tokens: 25,
    }),
  });

  if (!response.ok) {
    throw new Error(
      `OpenAI API request failed with status ${response.status}`
    );
  }
  const data = await response.json();
  return data.choices[0].message.content.trim();
}



export async function continueWriting(info, text, title,modelName) {
  const companyName = info.companyName
  const companyDescription = info.companyDescription
  // console.log(info,text,title)

  const userMessage = `Your company name is ${companyName}, here is the description about the company - '${companyDescription}' 
      you are writing '${title}' to tell about the company  
    you have to complete a last sentence of "${text}" within a few sentences. Should not reply several sentences.
    Complete uncompleted sentences or add one sentence if the given sentence is completed. 
      for example. written text is "we are goint to sell", then In order to complete the sentence, you have response like "old computers for our living" 
    `
  // console.log(userMessage);
  const response = await fetch("https://api.openai.com/v1/chat/completions", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${OPENAI_API_KEY}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      model: modelName,

      messages: [{ role: "user", content: userMessage }],
      temperature: 0.7,
      // response_format: ,
      // max_tokens: 25,
    }),
  });
  if (!response.ok) {
    throw new Error(
      `OpenAI API request failed with status ${response.status}`
    );
  }
  const data = await response.json();
  return data.choices[0].message.content.trim();
}

export async function writContent(title,modelName) {

  // console.log(info,text,title)

  const userMessage = `you are writing '${title}' to tell about the company  
    you have to complete a last sentence  within a few sentences. Should not reply several sentences.
    Complete uncompleted sentences or add one sentence if the given sentence is completed. 
      for example. written text is "we are goint to sell", then In order to complete the sentence, you have response like "old computers for our living" 
    `
  // console.log(userMessage);
  const response = await fetch("https://api.openai.com/v1/chat/completions", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${OPENAI_API_KEY}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      model: modelName,
      messages: [{ role: "user", content: userMessage }],
      temperature: 0.7,
      // response_format: ,
      // max_tokens: 25,
    }),
  });
  if (!response.ok) {
    throw new Error(
      `OpenAI API request failed with status ${response.status}`
    );
  }
  const data = await response.json();
  return data.choices[0].message.content.trim();
}

export async function writQA(title) {

  // console.log(info,text,title)

  const userMessage = `you are writing '${title}' to tell about the company  
    you have to complete a last sentence  within a few sentences. Should not reply several sentences.
    Complete uncompleted sentences or add one sentence if the given sentence is completed. 
      for example. written text is "we are goint to sell", then In order to complete the sentence, you have response like "old computers for our living" 
    `
  // console.log(userMessage);
  const response = await fetch("https://api.openai.com/v1/chat/completions", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${OPENAI_API_KEY}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      model: "gpt-3.5-turbo-1106",
      messages: [{ role: "user", content: userMessage }],
      temperature: 0.7,
      // response_format: ,
      // max_tokens: 25,
    }),
  });
  if (!response.ok) {
    throw new Error(
      `OpenAI API request failed with status ${response.status}`
    );
  }
  const data = await response.json();
  return data.choices[0].message.content.trim();
}
