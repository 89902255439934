import React, { useState, useEffect, useRef } from "react";
import { updatePrompt } from "./service";

import {
  Button,
  Input,
  Col,
  Row,
  notification,
  Spin,
  Flex 
} from "antd";
import { PoweroffOutlined } from '@ant-design/icons';

import { connect, useSelector, useDispatch } from "react-redux";

import { addUser } from "Redux/features/UserSlice";


export default function Admin({ generateContent, savePrompt, flag,currentPrompt, category }) {

  const dispatch = useDispatch();

  const user = useSelector(state => {
    return state.userSlice
  })

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, data) => {
    console.log("here")
    api[type]({
      message: "Notification",
      description: data,
    });
  };

  ///////////////////////////// State /////////////////////////

  const [prompt, setPrompt] = useState("");
  useEffect(() => {
    
    user.prompt.map(item=>{
      if(item.title == category){
        setPrompt(item.prompt)
      }
    })
  }, [])
  const changePrompt = (e) => {
    setPrompt(e.target.value)
  }

//  const [flag, setFlag] = useState(false)

  const generate = () => {
 
    generateContent(prompt)
    // setFlag(true)
  }

  const save = () => {
    console.log("saveprompt")
    savePrompt(prompt)
    updatePrompt(category, prompt).then(res => {
      dispatch(addUser({
        email: user.email,
        name: 'StartupSphere',
        companies: user.companies,
        curID: user.curID,
        role: user.role,
        prompt: res.data.result,
        gpt:user.gpt,
      }))
      openNotificationWithIcon("success", "You updated Prompt successfully");
    })
  }

  /////////////////////// EDIT //////////////////////////

  //////////////////////////////////////////////////////////////////


  return (
    <Row style={{ width: '100%' }} className="mb-4">
      {contextHolder}
      <Col span={24}>
        <Input.TextArea value={prompt} onChange={changePrompt} defaultValue="click here" />
      </Col>
      <Col span={24} className="text-center mt-4">
        {flag?
          <Button style={{ backgroundColor: '#3367D6' }} onClick={generate} className="mr-4" type="primary" loading>Generate</Button>
        :
        <Button style={{ backgroundColor: '#3367D6' }} onClick={generate} className="mr-4" type="primary">Generate</Button>
        }
        <Button style={{ backgroundColor: '#3367D6' }} onClick={save} type="primary">Save</Button>
      </Col>
    </Row>
  );
}
