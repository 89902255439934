import React from 'react'
import { Select } from 'antd'
import * as Data from '../../../utils/FinancialData'

const index = () => {
    return (
        <div className='flex justify-between'>
            <Select
                options={Data.monthData}
                placeholder="Month"
                size='large'
                style={{
                    width: '48%',
                }}
            />
            <Select
                options={Data.yearData}
                placeholder="Year"
                size='large'
                style={{
                    width: '48%',
                }}
            />
        </div>
    )
}

export default index