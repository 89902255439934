// import React, {forwardRef} from 'react';
// import classNames from 'classnames';

// import {Handle, Remove} from '../Item';

// import styles from './Container.module.css';

// export interface Props {
//   children: React.ReactNode;
//   columns?: number;
//   label?: string;
//   style?: React.CSSProperties;
//   horizontal?: boolean;
//   hover?: boolean;
//   handleProps?: React.HTMLAttributes<any>;
//   scrollable?: boolean;
//   shadow?: boolean;
//   placeholder?: boolean;
//   unstyled?: boolean;
//   onClick?(): void;
//   onRemove?(): void;
// }

// export const Container = forwardRef(
//   (
//     {
//       children,
//       columns = 1,
//       handleProps,
//       horizontal,
//       hover,
//       onClick,
//       onRemove,
//       label,
//       placeholder,
//       style,
//       scrollable,
//       shadow,
//       unstyled,
//       ...props
//     },
//     ref
//   ) => {
//     const Component = onClick ? 'button' : 'div';

//     return (
//       <Component
//         {...props}
//         ref={ref}
//         style={
//           {
//             ...style,
//             '--columns': columns,
//           }
//         }
//         className={classNames(
//           styles.Container,
//           unstyled && styles.unstyled,
//           horizontal && styles.horizontal,
//           hover && styles.hover,
//           placeholder && styles.placeholder,
//           scrollable && styles.scrollable,
//           shadow && styles.shadow
//         )}
//         onClick={onClick}
//         tabIndex={onClick ? 0 : undefined}
//       >
//         {label ? (
//           <div className={styles.Header}>
//             {label}
//             <div className={styles.Actions}>
//               {onRemove ? <Remove onClick={onRemove} /> : undefined}
//               <Handle {...handleProps} />
//             </div>
//           </div>
//         ) : null}
//         {placeholder ? children : <ul>{children}</ul>}
//       </Component>
//     );
//   }
// );
import React, { forwardRef } from 'react';
import classNames from 'classnames';

import { Handle, Remove } from '../Item';

import styles from './Container.module.css';
import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  CheckOutlined, CloseOutlined
} from "@ant-design/icons";

export const Container = forwardRef(
  (
    {
      children,
      columns = 1,
      handleProps,
      horizontal,
      hover,
      onClick,
      onRemove,
      label,
      placeholder,
      style,
      scrollable,
      shadow,
      unstyled,
      onChangeStatus,
      changeItems,
      compareName,
      changedValue,
      handleChangeMiddle,
      handleStopChange,
      changeMiddle,
      ...props
    },
    ref
  ) => {
    const Component = onClick ? 'button' : 'div';

    return (
      React.createElement(
        Component,
        Object.assign({}, props, {
          ref: ref,
          style: Object.assign({}, style, { '--columns': columns }),
          className: classNames(
            styles.Container,
            unstyled && styles.unstyled,
            horizontal && styles.horizontal,
            hover && styles.hover,
            placeholder && styles.placeholder,
            scrollable && styles.scrollable,
            shadow && styles.shadow
          ),
          onClick: onClick,
          tabIndex: onClick ? 0 : undefined
        }),
        label=="Overview" ? (
          React.createElement(
            'div',
            { className: styles.Header },
            <b className='flex items-center text-[16px]'><span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="mr-2"><path d="m2 7 4.41-4.41A2 2 0 0 1 7.83 2h8.34a2 2 0 0 1 1.42.59L22 7"></path><path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path><path d="M15 22v-4a2 2 0 0 0-2-2h-2a2 2 0 0 0-2 2v4"></path><path d="M2 7h20"></path><path d="M22 7v3a2 2 0 0 1-2 2v0a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 16 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 12 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 8 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 4 12v0a2 2 0 0 1-2-2V7"></path></svg></span>{label}</b>,
            React.createElement(
              'div',
              { className: styles.Actions },
              onRemove ? React.createElement(Remove, { onClick: onRemove }) : undefined,
              React.createElement(Handle, handleProps),

            )
          )
        ) : 
        label=="Products & Services" ? (
          React.createElement(
            'div',
            { className: styles.Header },
            <b className='flex items-center text-[16px]'><span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className='mr-2'><path d="m7.5 4.27 9 5.15"></path><path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z"></path><path d="m3.3 7 8.7 5 8.7-5"></path><path d="M12 22V12"></path></svg></span>{label}</b>,
            React.createElement(
              'div',
              { className: styles.Actions },
              onRemove ? React.createElement(Remove, { onClick: onRemove }) : undefined,
              React.createElement(Handle, handleProps),

            )
          )
        ) : 
        label=="Market Research" ? (
          React.createElement(
            'div',
            { className: styles.Header },
            <b className='flex items-center text-[16px]'><span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className='mr-2'><path d="M3 3v18h18"></path><rect width="4" height="7" x="7" y="10" rx="1"></rect><rect width="4" height="12" x="15" y="5" rx="1"></rect></svg></span>{label}</b>,
            React.createElement(
              'div',
              { className: styles.Actions },
              onRemove ? React.createElement(Remove, { onClick: onRemove }) : undefined,
              React.createElement(Handle, handleProps),

            )
          )
        ) : 
        label=="Sales and Marketing" ? (
          React.createElement(
            'div',
            { className: styles.Header },
            <b className='flex items-center text-[16px]'><span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className='mr-2'><polyline points="22 7 13.5 15.5 8.5 10.5 2 17"></polyline><polyline points="16 7 22 7 22 13"></polyline></svg></span>{label}</b>,
            React.createElement(
              'div',
              { className: styles.Actions },
              onRemove ? React.createElement(Remove, { onClick: onRemove }) : undefined,
              React.createElement(Handle, handleProps),

            )
          )
        ) : 
        label=="Operations" ? (
          React.createElement(
            'div',
            { className: styles.Header },
            <b className='flex items-center text-[16px]'><span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className='mr-2'><rect width="18" height="18" x="3" y="3" rx="2"></rect><path d="M8 7v7"></path><path d="M12 7v4"></path><path d="M16 7v9"></path></svg></span>{label}</b>,
            React.createElement(
              'div',
              { className: styles.Actions },
              onRemove ? React.createElement(Remove, { onClick: onRemove }) : undefined,
              React.createElement(Handle, handleProps),

            )
          )
        ) : 
        label=="Financials" ? (
          React.createElement(
            'div',
            { className: styles.Header },
            <b className='flex items-center text-[16px]'><span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className='mr-2'><line x1="12" x2="12" y1="2" y2="22"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg></span>{label}</b>,
            React.createElement(
              'div',
              { className: styles.Actions },
              onRemove ? React.createElement(Remove, { onClick: onRemove }) : undefined,
              React.createElement(Handle, handleProps),

            )
          )
        ) : 
        label=="Implementations Plan" ? (
          React.createElement(
            'div',
            { className: styles.Header },
            <b className='flex items-center text-[16px]'><span><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className='mr-2'><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg></span>{label}</b>,
            React.createElement(
              'div',
              { className: styles.Actions },
              onRemove ? React.createElement(Remove, { onClick: onRemove }) : undefined,
              React.createElement(Handle, handleProps),

            )
          )
        ) : null,
        // (
        //   React.createElement(
        //     React.createElement(
        //       'div',
        //       { className: 'flex justify-between items-center' },
        //       React.createElement('input', { id: "value + index", className: 'border rounded py-1 px-4', value: "middle", onChange: (e) => console.log(e) }),
        //       React.createElement(CheckOutlined, { }),
        //       React.createElement(CloseOutlined, { })
        //     )
        //   )
        // ),
        // React.createElement(
        //   'div',
        //   { className: 'flex justify-between items-center' },
        //   React.createElement('input', { id: label, className: 'border rounded py-1 px-4', value: changedValue, onChange: (e) => changeMiddle(e.target.value) }), // Assuming changeMiddle is a function defined elsewhere
        //   React.createElement(CheckOutlined, { onClick: handleChangeMiddle }),
        //   React.createElement(CloseOutlined, { onClick: handleStopChange })
        // ),
        placeholder ? children : React.createElement('ul', null, children)
      )
    );
  }
);
