import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Row,
  Col,
  Switch
} from "antd";
import {
  TransactionOutlined,
  TrophyOutlined,
  CodepenOutlined,
  WomanOutlined,
  FrownOutlined,
  GlobalOutlined,
  DeploymentUnitOutlined,
  EnvironmentOutlined,
  HeartOutlined
} from "@ant-design/icons";
import { connect, useSelector, useDispatch } from "react-redux";
import Admin from "utils/admin";
import Edit from "utils/edit";
import axios from "axios";
import { URL } from "utils/const";
import *as formats from '../../utils/configure'
import { genEachContent } from "../../utils/service";
import { updateHtmlContent } from '../../Redux/features/UserSlice';
import { addContent } from "../../Redux/features/pagesSlice";
import { setRegenerate } from "../../Redux/features/UserSlice";
import Limit from "components/Limit";
import { addUser } from "../../Redux/features/UserSlice";
import Generating from "components/Generating";
import Upgrade from "components/Upgrade";
export default function TargetAudience() {

  const dispatch = useDispatch();

  const { email, curID, companies, role, prompt } = useSelector(state => state.userSlice)

  const user = useSelector(state => {
    return state.userSlice
  })

  const pageContents = useSelector(state => {
    return state.pagesSlice
  })

  const category = "Target Audience"
  const page_title = user.companies[0].content.find((item) => item.title === "Market Research").list.find((item) => item.origin===category).shown

  const auth = role

  const [oldprompt, setOldPrompt] = useState([]);
  const [flag, setFlag] = useState(false);
  const [generateFlag, setGenerateFlag] = useState(false);
  const [renderData, setRenderData] = useState(null);
  const [contentOverview, setContentOverview] = useState('')
  const [subTitle1, setSubtitle1] = useState('')
  const [subOverview1, setSubOverview1] = useState('')
  const [execution1, setExecution1] = useState('')
  const [challenges1, setChallenges1] = useState('')
  const [key1, setKey1] = useState('')
  const [promotion1, setPromotion1] = useState('')
  const [pricing1, setPricing1] = useState('')
  const [target1, setTarget1] = useState('')
  const [subTitle2, setSubtitle2] = useState('')
  const [subOverview2, setSubOverview2] = useState('')
  const [execution2, setExecution2] = useState('')
  const [challenges2, setChallenges2] = useState('')
  const [key2, setKey2] = useState('')
  const [promotion2, setPromotion2] = useState('')
  const [pricing2, setPricing2] = useState('')
  const [target2, setTarget2] = useState('')
  const [paid, setPaid] = useState();

  useEffect(() => {
    setFlag(false);
    dispatch(setRegenerate(false))
    if (email == '')
      window.location.href = '/sign-in';
  }, []);

  useEffect(() => {
    axios.post(URL + "/api/paid/get", { data: "Target Audience" })
        .then(res => {
            console.log("res", res.data.prompt);
            if (res.data.prompt == 'paid')
                setPaid(true)
            else setPaid(false)
        })
        .catch(err => console.log("err", err))
}, [])

const handleChangeCheck = () => {
    if (paid == true)
        setPaid(false)
    else setPaid(true)

    axios.post(URL + "/api/paid/add", { data: "Target Audience" })
    .then(res=>{
        console.log("paid has changed", res)
    })
    .catch(err=>console.log("failed to change the paid method", err))
}

  useEffect(() => {
    const currentPrompt = prompt.filter((item) => item.title == category)
    console.log("currentPrompts", currentPrompt)
    setOldPrompt(currentPrompt.prompt)
  }, [])

  useEffect(() => {
    let middleData = [];

    if (Array.isArray(pageContents.contents)) {
      middleData = pageContents.contents.filter((item) => {
        return item.Title === category;
      });
    } else if (Array.isArray(pageContents.contents.contents)) {
      middleData = pageContents.contents.contents.filter((item) => {
        return item.Title === category;
      });
    }
    setRenderData(middleData);
    console.log("middledata", middleData)
  }, [pageContents]);


  const savePrompt = (newPrompt) => {
    console.log("saveNewPrompt", newPrompt)
  }

  const generateContent = (newPrompt) => {
    setFlag(true);
    setGenerateFlag(true)
    let formatData;
    Object.keys(formats).map(key => {
      if (category == formats[key].Title) {
        console.log(formats[key].Title, formats[key], formats[key])
        formatData = { title: formats[key].Title, data: formats[key] }
      }
    })

    genEachContent(user.companies[0], newPrompt, formatData.data,user.gpt.modelName)
      .then((res) => {
        if (Array.isArray(pageContents.contents)) {
          const newContents = pageContents.contents.map((item, idx) => {
            if (item.Title === category) {
              return res
            }
            return item;
          });
          let temp = newContents;
          dispatch(addContent({ content: temp }));
          dispatch(updateHtmlContent({ itemTitle: category, newhtmlContent: {} }));
          dispatch(setRegenerate(false))
          setFlag(false);
          axios.post(URL+"/api/users/result/save", { email: user.email, companyName: user.companies[0].companyName, content: newContents })
            .then(res => {
              console.log("---------------",res)
              dispatch(addUser({
                email: user.email,
                name: 'StartupSphere',
                companies: res.data,
                curID: user.curID,
                role: user.role,
                prompt: user.prompt,
                upgrade:user.upgrade,
                gpt:user.gpt
              }))
            }).catch(err => console.log(err))
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleChange = (html, clippedTxt, id) => {

    if (id == "contentOverview")
      setContentOverview(html)
    if (id == "subTitle1")
      setSubtitle1(html)
    if (id == "subOverview1")
      setSubOverview1(html)
    if (id == "execution1")
      setExecution1(html)
    if (id == "challenges1")
      setChallenges1(html)
    if (id == "key1")
      setKey1(html)
    if (id == "promotion1")
      setPromotion1(html)
    if (id == "pricing1")
      setPricing1(html)
    if (id == "target1")
      setTarget1(html)
    if (id == "subTitle2")
      setSubtitle2(html)
    if (id == "subOverview2")
      setSubOverview2(html)
    if (id == "execution2")
      setExecution2(html)
    if (id == "challenges2")
      setChallenges2(html)
    if (id == "key2")
      setKey2(html)
    if (id == "promotion2")
      setPromotion2(html)
    if (id == "pricing2")
      setPricing2(html)
    if (id == "target2")
      setTarget2(html)
  }

  useEffect(() => {
    if(contentOverview!=""|| subTitle1!=""|| subOverview1!=""|| execution1!=""|| challenges1!=""|| key1!=""|| promotion1!=""|| pricing1!=""|| target1!=""|| subTitle2!=""|| subOverview2!=""|| execution2!=""|| challenges2!=""|| key2!=""|| promotion2!=""|| pricing2!=""|| target2!=""){
    const data = {
      contentOverview: contentOverview,
      subTitle1: subTitle1,
      subOverview1: subOverview1,
      execution1: execution1,
      challenges1: challenges1,
      key1: key1,
      promotion1: promotion1,
      pricing1: pricing1,
      target1: target1,
      subTitle2: subTitle2,
      subOverview2: subOverview2,
      execution2: execution2,
      challenges2: challenges2,
      key2: key2,
      promotion2: promotion2,
      pricing2: pricing2,
      target2: target2,
    }
    // console.log("problem----",problem)
    const sendData = { email: user.email, company: user.companies[0].companyName, title: category, sendData: data }
    axios.post(URL+"/api/users/changeHtml", {
      data: sendData
    }).then(res => {
      console.log('updated and saved')
      // console.log(sendData)
      dispatch(updateHtmlContent({ itemTitle: category, newhtmlContent: data }));
    }).catch(err => console.log('saving updated content'))
  }
  }, [contentOverview, subTitle1, subOverview1, execution1, challenges1, key1, promotion1, pricing1, target1, subTitle2, subOverview2, execution2, challenges2, key2, promotion2, pricing2, target2]);

  const chageHtml = (html, id) => {
    console.log("content overview")
    if (id == "contentOverview")
      setContentOverview(html)
    if (id == "subTitle1")
      setSubtitle1(html)
    if (id == "subOverview1")
      setSubOverview1(html)
    if (id == "execution1")
      setExecution1(html)
    if (id == "challenges1")
      setChallenges1(html)
    if (id == "key1")
      setKey1(html)
    if (id == "promotion1")
      setPromotion1(html)
    if (id == "pricing1")
      setPricing1(html)
    if (id == "target1")
      setTarget1(html)
    if (id == "subTitle2")
      setSubtitle2(html)
    if (id == "subOverview2")
      setSubOverview2(html)
    if (id == "execution2")
      setExecution2(html)
    if (id == "challenges2")
      setChallenges2(html)
    if (id == "key2")
      setKey2(html)
    if (id == "promotion2")
      setPromotion2(html)
    if (id == "pricing2")
      setPricing2(html)
    if (id == "target2")
      setTarget2(html)
  }

  useEffect(() => {
    if (user.regenerateFlag == true) {
      let currentPrompt = ''
      console.log("regnerating")
      user.prompt.map(item => {
        if (item.title == category) {
          currentPrompt = item.prompt
        }
      })
      generateContent(currentPrompt)
    }else {
      setFlag(false)
  }
  }, [user.regenerateFlag]);

  useEffect(() => {

    let htmlContent = null;
    companies[0].htmlContent.forEach((item) => {
      if (item.Title === category) {
        htmlContent = item.htmlcontent;
      }
    });
    if (htmlContent != null && !generateFlag) {
      setContentOverview(htmlContent.contentOverview)
      setSubtitle1(htmlContent.subTitle1)
      setSubOverview1(htmlContent.subOverview1)
      setExecution1(htmlContent.execution1)
      setChallenges1(htmlContent.challenges1)
      setKey1(htmlContent.key1)
      setPromotion1(htmlContent.promotion1)
      setPricing1(htmlContent.pricing1)
      setTarget1(htmlContent.target1)
      setSubtitle2(htmlContent.subTitle2)
      setSubOverview2(htmlContent.subOverview2)
      setExecution2(htmlContent.execution2)
      setChallenges2(htmlContent.challenges2)
      setKey2(htmlContent.key2)
      setPromotion2(htmlContent.promotion2)
      setPricing2(htmlContent.pricing2)
      setTarget2(htmlContent.target2)
    } else if (renderData != null && renderData[0]) {
      setContentOverview('<h3>' + renderData[0]?.["Summary"] + '</h3>')
      if(renderData[0]?.["Content"]?.[1]){
      setSubtitle1('<h4>' + renderData[0]?.["Content"]?.[1]?.["SubTitle"] + '</h4>')
      setSubOverview1('<h3>' + renderData[0]?.["Content"]?.[1]?.["Description"]?.["Overview"] + '</h3>')

      const rlt = renderData[0]?.["Content"]?.[1]?.["Description"]?.["Behavioural Considerations"]
      if (rlt) {
        let length = rlt.length
        let result = '';
        for (let i = 0; i < length; i++) {
          result = result + `<ol><li>${rlt[i]?.["Description"]}</li></ol>`;
        }
        setExecution1(result);
      }

      const rlt1 = renderData[0]?.["Content"]?.[1]?.["Description"]?.["Frustrations"]
      if (rlt1) {
        let length1 = rlt1.length
        let result1 = '';
        for (let i = 0; i < length1; i++) {
          result1 = result1 + `<ol><li>${rlt1[i]?.["Description"]}</li></ol>`;
        }
        setChallenges1(result1);
      }

      const rlt2 = renderData[0]?.["Content"]?.[1]?.["Description"]?.["Goals"]
      if (rlt2) {
        let length2 = rlt2.length
        let result2 = '';
        for (let i = 0; i < length2; i++) {
          result2 = result2 + `<ol><li>${rlt2[i]?.["Description"]}</li></ol>`;
        }
        setKey1(result2);
      }

      const rlt3 = renderData[0]?.["Content"]?.[1]?.["Description"]?.["Preferences"]
      if (rlt3) {
        let length3 = rlt3.length
        let result3 = '';
        for (let i = 0; i < length3; i++) {
          result3 = result3 + `<ol><li>${rlt3[i]?.["Description"]}</li></ol>`;
        }
        setPromotion1(result3);
      }

      const rlt4 = renderData[0]?.["Content"]?.[1]?.["Description"]?.["Profile"]
      if (rlt4) {
        let length4 = rlt4.length
        let result4 = '';
        for (let i = 0; i < length4; i++) {
          result4 = result4 + `<ol><li>${rlt4[i]?.["Description"]}</li></ol>`;
        }
        setPricing1(result4);
      }

      const rlt5 = renderData[0]?.["Content"]?.[1]?.["Description"]?.["Where To Find"]
      if (rlt5) {
        let length5 = rlt5.length
        let result5 = '';
        for (let i = 0; i < length5; i++) {
          result5 = result5 + `<ol><li>${rlt5[i]?.["Description"]}</li></ol>`;
        }
        setTarget1(result5);
      }
    }
    if(renderData[0]?.["Content"]?.[0]){

      setSubtitle2('<h4>' + renderData[0]?.["Content"]?.[0]?.["SubTitle"] + '</h4>')
      setSubOverview2('<h3>' + renderData[0]?.["Content"]?.[0]?.["Description"]?.["Overview"] + '</h3>')

      const rlt6 = renderData[0]?.["Content"]?.[0]?.["Description"]?.["Behavioural Considerations"]
      let length6 = rlt6.length
      let result6 = '';
      for (let i = 0; i < length6; i++) {
        result6 = result6 + `<ol><li>${rlt6[i]?.["Description"]}</li></ol>`;
      }
      setExecution2(result6);

      const rlt7 = renderData[0]?.["Content"]?.[0]?.["Description"]?.["Frustrations"]
      if (rlt7) {
        let length7 = rlt7.length
        let result7 = '';
        for (let i = 0; i < length7; i++) {
          result7 = result7 + `<ol><li>${rlt7[i]?.["Description"]}</li></ol>`;
        }
        setChallenges2(result7);
      }

      const rlt8 = renderData[0]?.["Content"]?.[0]?.["Description"]?.["Goals"]
      if (rlt8) {
        let length8 = rlt8.length
        let result8 = '';
        for (let i = 0; i < length8; i++) {
          result8 = result8 + `<ol><li>${rlt8[i]?.["Description"]}</li></ol>`;
        }
        setKey2(result8);
      }

      const rlt9 = renderData[0]?.["Content"]?.[0]?.["Description"]?.["Preferences"]
      if (rlt9) {
        let length9 = rlt9.length
        let result9 = '';
        for (let i = 0; i < length9; i++) {
          result9 = result9 + `<ol><li>${rlt9[i]?.["Description"]}</li></ol>`;
        }
        setPromotion2(result9);
      }

      const rlt10 = renderData[0]?.["Content"]?.[0]?.["Description"]?.["Profile"]
      if (rlt10) {
        let length10 = rlt10.length
        let result10 = '';
        for (let i = 0; i < length10; i++) {
          result10 = result10 + `<ol><li>${rlt10[i]?.["Description"]}</li></ol>`;
        }
        setPricing2(result10);
      }

      const rlt11 = renderData[0]?.["Content"]?.[0]?.["Description"]?.["Where To Find"]
      if (rlt11) {
        let length11 = rlt11.length
        let result11 = '';
        for (let i = 0; i < length11; i++) {
          result11 = result11 + `<ol><li>${rlt11[i]?.["Description"]}</li></ol>`;
        }
        setTarget2(result11);
      }
    }

    }

  }, [renderData])


  return (
    <div className="layout-content mx-auto max-w-4xl">
      {(user.upgrade == "unpaid" && auth != "admin") &&<Col xs={24} md={24} lg={24}><Limit /></Col>}
      <Row className="p-6">
        <Col>
        <h6 class="text-md font-normal flex items-center leading-none capitalize w-full text-green-500">
              <div class="w-6 h-6 mr-2 justify-center flex rounded-sm text-green-500 bg-green-50"><div class="w-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-full"><path d="M3 3v18h18"></path><rect width="4" height="7" x="7" y="10" rx="1"></rect><rect width="4" height="12" x="15" y="5" rx="1"></rect></svg></div></div>
              <span class="text-base font-normal">Market Research</span>
            </h6>
        </Col>
        <Col
          xs={24}
          md={24}
          lg={24}
          className="flex items-center justify-between mt-4 mb-9"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div>
            
            <h3 class="text-2xl sm:text-3xl font-medium leading-none tracking-tight capitalize w-full">{page_title}</h3>
          </div>
          {auth=='admin'&&<Switch className="bg-[#3367D6]" checkedChildren="FREE" unCheckedChildren="PAID" checked={paid} onChange={handleChangeCheck} />}
        </Col>
        {
          auth == 'admin' &&
          <Admin currentPrompt={oldprompt} category={category} flag={flag} generateContent={generateContent} savePrompt={savePrompt}></Admin>
        }
        {((contentOverview != '' && user.upgrade != "unpaid") || (contentOverview != '' && auth == 'admin') || (contentOverview != '' && paid)) && 
          <div>
            <Row>
              <Col xs={24} md={24} lg={24}>
                <div className="dashboard_title">
                  <div className="mt-4">
                    <Edit content={contentOverview} id={"contentOverview"} changeContent={handleChange} changeHtml={chageHtml} ></Edit>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-6 bg-[white] border rounded-md" style={{ width: "100%" }}>
              <div className="p-4 bg-[#FAFAFA]" style={{ width: "100%" }}>
                <Edit content={subTitle1} id={"subTitle1"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                <Edit content={subOverview1} id={"subOverview1"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
              </div>
              <Row className="p-4" style={{ width: "100%" }}>
                <Col xs={24} md={12} lg={12} className="pr-2">
                  <div className="dashboard_title">
                    <p className="text-xl font-semibold flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><circle cx="12" cy="12" r="10"></circle><circle cx="12" cy="10" r="3"></circle><path d="M7 20.662V19a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v1.662"></path></svg></div>
                      Profile
                    </p>
                    <Edit content={pricing1} id={"pricing1"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12} className="pl-2">
                  <div className="dashboard_title">
                    <p className="text-xl font-semibold flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><circle cx="12" cy="12" r="10"></circle><path d="M16 16s-1.5-2-4-2-4 2-4 2"></path><path d="M7.5 8 10 9"></path><path d="m14 9 2.5-1"></path><path d="M9 10h0"></path><path d="M15 10h0"></path></svg></div>
                      Frustrations
                    </p>
                    <Edit content={challenges1} id={"challenges1"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
              </Row>
              <Row className="p-4" style={{ width: "100%" }}>
                <Col xs={24} md={12} lg={12} className="pl-2">
                  <div className="dashboard_title">
                    <p className="text-xl font-semibold flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><path d="m21.44 11.05-9.19 9.19a6 6 0 0 1-8.49-8.49l8.57-8.57A4 4 0 1 1 18 8.84l-8.59 8.57a2 2 0 0 1-2.83-2.83l8.49-8.48"></path></svg></div>
                      Goals
                    </p>
                    <Edit content={key1} id={"key1"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12} className="pr-2">
                  <div className="dashboard_title">
                    <p className="text-xl font-semibold flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><path d="m21.44 11.05-9.19 9.19a6 6 0 0 1-8.49-8.49l8.57-8.57A4 4 0 1 1 18 8.84l-8.59 8.57a2 2 0 0 1-2.83-2.83l8.49-8.48"></path></svg></div>
                      Preferences
                    </p>
                    <Edit content={promotion1} id={"promotion1"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
              </Row>
              <Row className="p-4" style={{ width: "100%" }}>
                <Col xs={24} md={12} lg={12} className="pr-2">
                  <div className="dashboard_title">
                    <p className="text-xl font-semibold flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><path d="M12 6v12"></path><path d="M17.196 9 6.804 15"></path><path d="m6.804 9 10.392 6"></path></svg></div>
                      Behavioural Considerations
                    </p>
                    <Edit content={execution1} id={"execution1"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12} className="pl-2">
                  <div className="dashboard_title">
                    <p className="text-xl font-semibold flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z"></path><circle cx="12" cy="10" r="3"></circle></svg></div>
                      Where To Find
                    </p>
                    <Edit content={target1} id={"target1"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
              </Row>
            </Row>

            <Row className="mt-6 bg-[white] border rounded-md" style={{ width: "100%" }}>
              <div className="p-4 bg-[#FAFAFA]" style={{ width: "100%" }}>
                <Edit content={subTitle2} id={"subTitle2"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                <Edit content={subOverview2} id={"subOverview2"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
              </div>
              <Row className="p-4" style={{ width: "100%" }}>
                <Col xs={24} md={12} lg={12} className="pr-2">
                  <div className="dashboard_title">
                    <p className="text-xl font-semibold flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><circle cx="12" cy="12" r="10"></circle><circle cx="12" cy="10" r="3"></circle><path d="M7 20.662V19a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v1.662"></path></svg></div>
                      Profile
                    </p>
                    <Edit content={pricing2} id={"pricing2"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12} className="pl-2">
                  <div className="dashboard_title">
                    <p className="text-xl font-semibold flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><circle cx="12" cy="12" r="10"></circle><path d="M16 16s-1.5-2-4-2-4 2-4 2"></path><path d="M7.5 8 10 9"></path><path d="m14 9 2.5-1"></path><path d="M9 10h0"></path><path d="M15 10h0"></path></svg></div>
                      Frustrations
                    </p>
                    <Edit content={challenges2} id={"challenges2"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
              </Row>
              <Row className="p-4" style={{ width: "100%" }}>
                <Col xs={24} md={12} lg={12} className="pl-2">
                  <div className="dashboard_title">
                    <p className="text-xl font-semibold flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><path d="m21.44 11.05-9.19 9.19a6 6 0 0 1-8.49-8.49l8.57-8.57A4 4 0 1 1 18 8.84l-8.59 8.57a2 2 0 0 1-2.83-2.83l8.49-8.48"></path></svg></div>
                      Goals
                    </p>
                    <Edit content={key2} id={"key2"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12} className="pr-2">
                  <div className="dashboard_title">
                    <p className="text-xl font-semibold flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><path d="m21.44 11.05-9.19 9.19a6 6 0 0 1-8.49-8.49l8.57-8.57A4 4 0 1 1 18 8.84l-8.59 8.57a2 2 0 0 1-2.83-2.83l8.49-8.48"></path></svg></div>
                      Preferences
                    </p>
                    <Edit content={promotion2} id={"promotion2"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
              </Row>
              <Row className="p-4" style={{ width: "100%" }}>
                <Col xs={24} md={12} lg={12} className="pr-2">
                  <div className="dashboard_title">
                    <p className="text-xl font-semibold flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><path d="M12 6v12"></path><path d="M17.196 9 6.804 15"></path><path d="m6.804 9 10.392 6"></path></svg></div>
                      Behavioural Considerations
                    </p>
                    <Edit content={execution2} id={"execution2"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12} className="pl-2">
                  <div className="dashboard_title">
                    <p className="text-xl font-semibold flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z"></path><circle cx="12" cy="10" r="3"></circle></svg></div>
                      Where To Find
                    </p>
                    <Edit content={target2} id={"target2"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
              </Row>
            </Row>
          </div>}

          {contentOverview == '' && <Generating></Generating>}

          {
        auth != "admin" && user.upgrade == "unpaid" &&!paid &&<Upgrade></Upgrade>
      }

        {/* <Row className="mt-8 bg-[white] border rounded-md" style={{ width: "100%" }}>
        <Row className="p-8 bg-[#FAFAFA]" style={{ width: "100%" }}>
          <div className="text-sm flex text-[#1D4ED8] p-[1px] pl-[4px] pr-[4px] bg-[#DCFCE7] rounded-md">
            Media And Entertainment
          </div>
          <div className="mt-4">
            <h2 className="text-xl font-semibold">Podcast Creators</h2>
            <p className="text-lg mt-4">
              Podcast creators are individuals or teams who produce audio content to entertain and educate their audience. Legends Speak empowers podcast creators by providing them with a unique and easy-to-use platform to generate engaging episodes featuring historical and contemporary figures.
            </p>
          </div>
        </Row>
        <Row className="p-8" style={{ width: "100%" }}>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl font-semibold flex items-center">
                <WomanOutlined className="pr-2" />
                Profile
              </p>
              <ul className="pl-4" style={{ listStyle: "disc" }}>
                <li className="mt-3 text-md">
                  Recurring revenue stream from monthly or annual subscriptions
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                   ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                   equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl font-semibold flex items-center">
                <FrownOutlined className='pr-2' />
                Frustrations
              </p>
              <ul className="pl-4" style={{ listStyle: "disc" }}>
                <li className="mt-3 text-md">
                  Designing and curating walking routes that
                  showcase the historical and cultural highlights of
                  the city
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                  ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                  equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="p-8" style={{ width: "100%" }}>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl font-semibold flex items-center">
                <GlobalOutlined className="pr-2" />
                Goals
              </p>
              <ul className="pl-4" style={{ listStyle: "disc" }}>
                <li className="mt-3 text-md">
                  Recurring revenue stream from monthly or annual subscriptions
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                   ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                   equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl font-semibold flex items-center">
                <HeartOutlined className="pr-2" />
                Preferences
              </p>
              <ul className="pl-4" style={{ listStyle: "disc" }}>
                <li className="mt-3 text-md">
                  Designing and curating walking routes that
                  showcase the historical and cultural highlights of
                  the city
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                  ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                  equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="p-8" style={{ width: "100%" }}>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl font-semibold flex items-center">
                <DeploymentUnitOutlined className="pr-2" />
                Behavioural Considerations
              </p>
              <ul className="pl-4" style={{ listStyle: "disc" }}>
                <li className="mt-3 text-md">
                  Recurring revenue stream from monthly or annual subscriptions
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                   ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                   equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl font-semibold flex items-center">
                <EnvironmentOutlined className="pr-2" />
                Where To Find
              </p>
              <ul className="pl-4" style={{ listStyle: "disc" }}>
                <li className="mt-3 text-md">
                  Designing and curating walking routes that
                  showcase the historical and cultural highlights of
                  the city
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                  ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                  equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Row>
      <Row className="mt-8 bg-[white] border rounded-md" style={{ width: "100%" }}>
        <Row className="p-8 bg-[#FAFAFA]" style={{ width: "100%" }}>
          <div className="text-sm flex text-[#1D4ED8] p-[1px] pl-[4px] pr-[4px] bg-[#DBEAFE] rounded-md">
            Education
          </div>
          <div className="mt-4">
            <h2 className="text-xl font-semibold">Educators and Teachers</h2>
            <p className="text-lg mt-4">
              Educators and teachers are always looking for innovative and engaging teaching tools to captivate their students' attention. Legends Speak provides them with a unique educational platform to bring historical figures to life and create interactive and immersive learning experiences.
            </p>
          </div>
        </Row>
        <Row className="p-8" style={{ width: "100%" }}>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl font-semibold flex items-center">
                <WomanOutlined className="pr-2" />
                Profile
              </p>
              <ul className="pl-4" style={{ listStyle: "disc" }}>
                <li className="mt-3 text-md">
                  Recurring revenue stream from monthly or annual subscriptions
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                   ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                   equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl font-semibold flex items-center">
                <FrownOutlined className='pr-2' />
                Frustrations
              </p>
              <ul className="pl-4" style={{ listStyle: "disc" }}>
                <li className="mt-3 text-md">
                  Designing and curating walking routes that
                  showcase the historical and cultural highlights of
                  the city
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                  ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                  equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="p-8" style={{ width: "100%" }}>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl font-semibold flex items-center">
                <GlobalOutlined className="pr-2" />
                Goals
              </p>
              <ul className="pl-4" style={{ listStyle: "disc" }}>
                <li className="mt-3 text-md">
                  Recurring revenue stream from monthly or annual subscriptions
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                   ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                   equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl font-semibold flex items-center">
                <HeartOutlined className="pr-2" />
                Preferences
              </p>
              <ul className="pl-4" style={{ listStyle: "disc" }}>
                <li className="mt-3 text-md">
                  Designing and curating walking routes that
                  showcase the historical and cultural highlights of
                  the city
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                  ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                  equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="p-8" style={{ width: "100%" }}>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl font-semibold flex items-center">
                <DeploymentUnitOutlined className="pr-2" />
                Behavioural Considerations
              </p>
              <ul className="pl-4" style={{ listStyle: "disc" }}>
                <li className="mt-3 text-md">
                  Recurring revenue stream from monthly or annual subscriptions
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                   ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                   equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl font-semibold flex items-center">
                <EnvironmentOutlined className="pr-2" />
                Where To Find
              </p>
              <ul className="pl-4" style={{ listStyle: "disc" }}>
                <li className="mt-3 text-md">
                  Designing and curating walking routes that
                  showcase the historical and cultural highlights of
                  the city
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                  ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                  equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Row>

      <Row className="mt-8 bg-[white] border rounded-md" style={{ width: "100%" }}>
        <Row className="p-8 bg-[#FAFAFA]" style={{ width: "100%" }}>
          <div className="text-sm flex text-[#1D4ED8] p-[1px] pl-[4px] pr-[4px] bg-[#DCFCE7] rounded-md">
            Media And Entertainment
          </div>
          <div className="mt-4">
            <h2 className="text-xl font-semibold">Content Consumers</h2>
            <p className="text-lg mt-4">
              Content consumers are individuals who enjoy consuming educational and entertaining content related to historical events and figures. Legends Speak offers them a captivating and immersive experience to learn and engage with historical and contemporary figures.              </p>
          </div>
        </Row>
        <Row className="p-8" style={{ width: "100%" }}>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl font-semibold flex items-center">
                <WomanOutlined className="pr-2" />
                Profile
              </p>
              <ul className="pl-4" style={{ listStyle: "disc" }}>
                <li className="mt-3 text-md">
                  Recurring revenue stream from monthly or annual subscriptions
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                   ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                   equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl font-semibold flex items-center">
                <FrownOutlined className='pr-2' />
                Frustrations
              </p>
              <ul className="pl-4" style={{ listStyle: "disc" }}>
                <li className="mt-3 text-md">
                  Designing and curating walking routes that
                  showcase the historical and cultural highlights of
                  the city
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                  ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                  equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="p-8" style={{ width: "100%" }}>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl font-semibold flex items-center">
                <GlobalOutlined className="pr-2" />
                Goals
              </p>
              <ul className="pl-4" style={{ listStyle: "disc" }}>
                <li className="mt-3 text-md">
                  Recurring revenue stream from monthly or annual subscriptions
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                   ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                   equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl font-semibold flex items-center">
                <HeartOutlined className="pr-2" />
                Preferences
              </p>
              <ul className="pl-4" style={{ listStyle: "disc" }}>
                <li className="mt-3 text-md">
                  Designing and curating walking routes that
                  showcase the historical and cultural highlights of
                  the city
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                  ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                  equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="p-8" style={{ width: "100%" }}>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl font-semibold flex items-center">
                <DeploymentUnitOutlined className="pr-2" />
                Behavioural Considerations
              </p>
              <ul className="pl-4" style={{ listStyle: "disc" }}>
                <li className="mt-3 text-md">
                  Recurring revenue stream from monthly or annual subscriptions
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                   ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                   equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl font-semibold flex items-center">
                <EnvironmentOutlined className="pr-2" />
                Where To Find
              </p>
              <ul className="pl-4" style={{ listStyle: "disc" }}>
                <li className="mt-3 text-md">
                  Designing and curating walking routes that
                  showcase the historical and cultural highlights of
                  the city
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                  ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                  equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Row>

      <Row className="mt-8 bg-[white] border rounded-md" style={{ width: "100%" }}>
        <Row className="p-8 bg-[#FAFAFA]" style={{ width: "100%" }}>
          <div className="text-sm flex text-[#B91C1C] p-[1px] pl-[4px] pr-[4px] bg-[#FEE2E2] rounded-md">
            Creative Services
          </div>
          <div className="mt-4">
            <h2 className="text-xl font-semibold">Impersonators and Voice Actors</h2>
            <p className="text-lg mt-4">
              Impersonators and voice actors are professionals who specialize in imitating the voices of historical and contemporary figures. Legends Speak offers them a unique opportunity to showcase their talents and provide their services to podcast creators and content consumers.
            </p>
          </div>
        </Row>
        <Row className="p-8" style={{ width: "100%" }}>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl font-semibold flex items-center">
                <WomanOutlined className="pr-2" />
                Profile
              </p>
              <ul className="pl-4" style={{ listStyle: "disc" }}>
                <li className="mt-3 text-md">
                  Recurring revenue stream from monthly or annual subscriptions
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                   ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                   equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl font-semibold flex items-center">
                <FrownOutlined className='pr-2' />
                Frustrations
              </p>
              <ul className="pl-4" style={{ listStyle: "disc" }}>
                <li className="mt-3 text-md">
                  Designing and curating walking routes that
                  showcase the historical and cultural highlights of
                  the city
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                  ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                  equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="p-8" style={{ width: "100%" }}>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl font-semibold flex items-center">
                <GlobalOutlined className="pr-2" />
                Goals
              </p>
              <ul className="pl-4" style={{ listStyle: "disc" }}>
                <li className="mt-3 text-md">
                  Recurring revenue stream from monthly or annual subscriptions
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                   ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                   equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl font-semibold flex items-center">
                <HeartOutlined className="pr-2" />
                Preferences
              </p>
              <ul className="pl-4" style={{ listStyle: "disc" }}>
                <li className="mt-3 text-md">
                  Designing and curating walking routes that
                  showcase the historical and cultural highlights of
                  the city
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                  ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                  equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="p-8" style={{ width: "100%" }}>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl font-semibold flex items-center">
                <DeploymentUnitOutlined className="pr-2" />
                Behavioural Considerations
              </p>
              <ul className="pl-4" style={{ listStyle: "disc" }}>
                <li className="mt-3 text-md">
                  Recurring revenue stream from monthly or annual subscriptions
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                   ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                   equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl font-semibold flex items-center">
                <EnvironmentOutlined className="pr-2" />
                Where To Find
              </p>
              <ul className="pl-4" style={{ listStyle: "disc" }}>
                <li className="mt-3 text-md">
                  Designing and curating walking routes that
                  showcase the historical and cultural highlights of
                  the city
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                  ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                  equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Row> */}
      </Row>
    </div>
  );
}
