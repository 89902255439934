import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Row,
  ColorPicker,
  Switch
} from "antd";

import Edit from "utils/edit";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { URL } from "utils/const";
import Admin from "utils/admin";
import *as formats from '../../utils/configure'
import { genEachContent } from "../../utils/service";
import { updateHtmlContent } from '../../Redux/features/UserSlice';
import { addContent } from "../../Redux/features/pagesSlice";
import { setRegenerate } from "../../Redux/features/UserSlice";
import Limit from "components/Limit";
import Generating from "components/Generating";
import { addUser } from "../../Redux/features/UserSlice";
import Upgrade from "components/Upgrade";
const BrandingIdentity = () => {

  const dispatch = useDispatch();

  const { email, companies, role, prompt } = useSelector(state => state.userSlice)

  const user = useSelector(state => {
    return state.userSlice
  })

  const pageContents = useSelector(state => {
    return state.pagesSlice
  })

  const category = "Branding & Identity"
  const page_title = user.companies[0].content.find((item) => item.title === "Sales and Marketing").list.find((item) => item.origin===category).shown

  const auth = role

  const [oldprompt, setOldPrompt] = useState([]);
  const [flag, setFlag] = useState(false);
  const [generateFlag, setGenerateFlag] = useState(false);
  const [description, setDescription] = useState('')
  const [LogoIdea, setLogoIdea] = useState('')
  const [Typograpy, setTypograpy] = useState('')
  const [Imagery, setImagery] = useState('')
  const [BrandVoice, setBrandVoice] = useState('')
  const [BrandValues, setBrandValues] = useState('')
  const [BrandPersonality, setBrandPersonality] = useState('')
  const [CustomerPromise, setCustomerPromise] = useState('')
  const [renderData, setRenderData] = useState(null);
  const [paid, setPaid] = useState();


  useEffect(() => {
    setFlag(false);
    dispatch(setRegenerate(false))
    if (email == '')
      window.location.href = '/sign-in';
  }, []);

  useEffect(() => {
    axios.post(URL + "/api/paid/get", { data: "Branding & Identity" })
        .then(res => {
            console.log("res", res.data.prompt);
            if (res.data.prompt == 'paid')
                setPaid(true)
            else setPaid(false)
        })
        .catch(err => console.log("err", err))
}, [])

const handleChangeCheck = () => {
    if (paid == true)
        setPaid(false)
    else setPaid(true)

    axios.post(URL + "/api/paid/add", { data: "Branding & Identity" })
    .then(res=>{
        console.log("paid has changed", res)
    })
    .catch(err=>console.log("failed to change the paid method", err))
}

  /////////////////////////////////////////////////////////////////


  useEffect(() => {
    const currentPrompt = prompt.filter((item) => item.title == category)
    setOldPrompt(currentPrompt.prompt)
  }, [])

  const savePrompt = (newPrompt) => {
    console.log("saveNewPrompt", newPrompt)
  }

  const generateContent = (newPrompt) => {
    setFlag(true);
    setGenerateFlag(true)
    let formatData;
    Object.keys(formats).map(key => {
      if (category == formats[key].Title) {
        console.log(formats[key].Title, formats[key], formats[key])
        formatData = { title: formats[key].Title, data: formats[key] }
      }
    })


    genEachContent(user.companies[0], newPrompt, formatData.data, user.gpt.modelName)
      .then((res) => {
        if (Array.isArray(pageContents.contents)) {
          const newContents = pageContents.contents.map((item, idx) => {
            if (item.Title === category) {
              return res
            }
            return item;
          });
          let temp = newContents;
          dispatch(addContent({ content: temp }));
          dispatch(updateHtmlContent({ itemTitle: category, newhtmlContent: {} }));
          dispatch(setRegenerate(false))
          setFlag(false);
          axios.post(URL+"/api/users/result/save", { email: user.email, companyName: user.companies[0].companyName, content: newContents })
            .then(res => {
              console.log("---------------",res)
              dispatch(addUser({
                email: user.email,
                name: 'StartupSphere',
                companies: res.data,
                curID: user.curID,
                role: user.role,
                prompt: user.prompt,
                upgrade:user.upgrade,
                gpt:user.gpt
              }))
            }).catch(err => console.log(err))
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }


  //////////////////////////////////////////////////////////////////

  const handleChange = (html, clippedTxt, id) => {

    if (id == "LogoIdea")
      setLogoIdea(html)
    if (id == "Typograpy")
      setTypograpy(html)
    if (id == "Imagery")
      setImagery(html)
    if (id == "BrandVoice")
      setBrandVoice(html)
    if (id == "BrandValues")
      setBrandValues(html)
    if (id == "BrandPersonality")
      setBrandPersonality(html)
    if (id == "CustomerPromise")
      setCustomerPromise(html)

  }

  useEffect(() => {
    if (LogoIdea != "" || Typograpy != "" || Imagery != "" || BrandVoice != "" || BrandValues != "" || BrandPersonality != "" || CustomerPromise != "") {
      const data = {
        LogoIdea: LogoIdea,
        Typograpy: Typograpy,
        Imagery: Imagery,
        BrandVoice: BrandVoice,
        BrandValues: BrandValues,
        BrandPersonality: BrandPersonality,
        CustomerPromise: CustomerPromise,

      }
      // console.log("problem----",problem)
      const sendData = { email: user.email, company: user.companies[0].companyName, title: category, sendData: data }
      axios.post(URL+"/api/users/changeHtml", {
        data: sendData
      }).then(res => {
        console.log('updated and saved')
        // console.log(sendData)
        dispatch(updateHtmlContent({ itemTitle: category, newhtmlContent: data }));
      }).catch(err => console.log('saving updated content'))
    }
  }, [LogoIdea, Typograpy, Imagery, BrandVoice, BrandValues, BrandPersonality, CustomerPromise]);


  useEffect(() => {
    let middleData = [];

    if (Array.isArray(pageContents.contents)) {
      middleData = pageContents.contents.filter((item) => {
        return item.Title === category;
      });
    } else if (Array.isArray(pageContents.contents.contents)) {
      middleData = pageContents.contents.contents.filter((item) => {
        return item.Title === category;
      });
    }
    setRenderData(middleData);
    console.log("middledata", middleData)
  }, [pageContents]);



  const chageHtml = (html, id) => {
    console.log("content overview")
    if (id == "LogoIdea")
      setLogoIdea(html)
    if (id == "Typograpy")
      setTypograpy(html)
    if (id == "Imagery")
      setImagery(html)
    if (id == "BrandVoice")
      setBrandVoice(html)
    if (id == "BrandValues")
      setBrandValues(html)
    if (id == "BrandPersonality")
      setBrandPersonality(html)
    if (id == "CustomerPromise")
      setCustomerPromise(html)

  }

  useEffect(() => {
    if (user.regenerateFlag == true) {
      let currentPrompt = ''
      console.log("regnerating")
      user.prompt.map(item => {
        if (item.title == category) {
          currentPrompt = item.prompt
        }
      })
      generateContent(currentPrompt)
    } else {
      setFlag(false)
    }
  }, [user.regenerateFlag]);

  useEffect(() => {

    let htmlContent = null;
    companies[0].htmlContent.forEach((item) => {
      if (item.Title === category) {
        htmlContent = item.htmlcontent;
      }
    });
    if (htmlContent != null && !generateFlag) {
      setLogoIdea(htmlContent.LogoIdea)
      setTypograpy(htmlContent.Typograpy)
      setImagery(htmlContent.Imagery)
      setBrandVoice(htmlContent.BrandVoice)
      setBrandValues(htmlContent.BrandValues)
      setBrandPersonality(htmlContent.BrandPersonality)
      setCustomerPromise(htmlContent.CustomerPromise)

    } else if (renderData != null && renderData[0]) {
      setLogoIdea(`<h2>${renderData[0]?.["Content"]?.[0]?.["SubTitle"]}</h2><h3>${renderData[0]?.["Content"]?.[0]?.["Description"]}</h3>`)
      setBrandPersonality(`<h2>${renderData[0]?.["Content"]?.[5]?.["SubTitle"]}</h2><h3>${renderData[0]?.["Content"]?.[5]?.["Description"]}</h3>`)
      setTypograpy(`<h2>${renderData[0]?.["Content"]?.[1]?.["SubTitle"]}</h2><h3>${renderData[0]?.["Content"]?.[1]?.["Description"]}</h3>`)
      setCustomerPromise(`<h2>${renderData[0]?.["Content"]?.[6]?.["SubTitle"]}</h2><h3>${renderData[0]?.["Content"]?.[6]?.["Description"]}</h3>`)
      setImagery(`<h2>${renderData[0]?.["Content"]?.[2]?.["SubTitle"]}</h2><h3>${renderData[0]?.["Content"]?.[2]?.["Description"]}</h3>`)

      setBrandVoice(`<h2>${renderData[0]?.["Content"]?.[3]?.["SubTitle"]}</h2><h3>${renderData[0]?.["Content"]?.[3]?.["Description"]}</h3>`);

      setBrandValues(`<h2>${renderData[0]?.["Content"]?.[4]?.["SubTitle"]}</h2><h3>${renderData[0]?.["Content"]?.[4]?.["Description"]}</h3>`);

    }

  }, [renderData])


  const [color1, setColor1] = useState('#1677ff');
  const [color2, setColor2] = useState('#ff7f00');
  const [color3, setColor3] = useState('#79944b');
  const [color4, setColor4] = useState('#507687');
  const [color5, setColor5] = useState('#1677ff');

  return (
    <div className="layout-content mx-auto max-w-4xl">
      {(user.upgrade == "unpaid" && auth != "admin") && <Col xs={24} md={24} lg={24}><Limit /></Col>}
      <Row className="p-6">
        <Col
          xs={24}
          md={24}
          lg={24}
          style={{ display: "flex", alignItems: "center" }}
          className="flex items-center justify-between mt-4 mb-9"
        >
          <div><h6 class="text-md font-normal flex items-center leading-none capitalize w-full text-green-500"><div class="w-6 h-6 mr-2 justify-center flex rounded-sm text-green-500 bg-green-50"><div class="w-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-full"><polyline points="22 7 13.5 15.5 8.5 10.5 2 17"></polyline><polyline points="16 7 22 7 22 13"></polyline></svg></div></div><span class="text-base font-normal">Sales &amp; Marketing</span></h6><h3 class="text-2xl sm:text-3xl font-medium leading-none tracking-tight capitalize w-full mt-4 mb-3">{page_title}</h3></div>
          {auth=='admin'&&<Switch className="bg-[#3367D6]" checkedChildren="FREE" unCheckedChildren="PAID" checked={paid} onChange={handleChangeCheck} />}
        </Col>
        {
          auth == 'admin' &&
          <Admin currentPrompt={oldprompt} category={category} flag={flag} generateContent={generateContent} savePrompt={savePrompt}></Admin>
        }
        {((LogoIdea != ''&&user.upgrade != "unpaid")||(LogoIdea != ''&&auth == 'admin')||(LogoIdea != ''&&paid)) &&<div><Row className="bg-[white] w-full">
          <div className="dashboard_title">
            <Edit content={LogoIdea} id={"LogoIdea"} changeContent={handleChange} changeHtml={chageHtml} ></Edit>
          </div>
        </Row>
        <Row className="bg-[white] w-full">
          <div className="dashboard_title">
            <Edit content={"<h2>Color Scheme</h2>"} id={"COlorScheme"} changeContent={handleChange} changeHtml={chageHtml} ></Edit>
          </div>
          <Row className="bg-[white] w-full mt-6 mb-6">
            <ColorPicker className="w-1/5 border-none" value={color1} onChange={setColor1} />
            <ColorPicker className="w-1/5 border-none" value={color2} onChange={setColor2} />
            <ColorPicker className="w-1/5 border-none" value={color3} onChange={setColor3} />
            <ColorPicker className="w-1/5 border-none" value={color4} onChange={setColor4} />
            <ColorPicker className="w-1/5 border-none" value={color5} onChange={setColor5} />
          </Row>
        </Row>
        <Row className="bg-[white] w-full mt-6">
          <div className="dashboard_title">
            <Edit content={Typograpy} id={"Typograpy"} changeContent={handleChange} changeHtml={chageHtml} ></Edit>
          </div>
        </Row>
        <Row className="bg-[white] w-full">
          <div className="dashboard_title">
            <Edit content={Imagery} id={"Imagery"} changeContent={handleChange} changeHtml={chageHtml} ></Edit>
          </div>
        </Row>
        <Row className="bg-[white] w-full">
          <div className="dashboard_title">
            <Edit content={BrandVoice} id={"BrandVoice"} changeContent={handleChange} changeHtml={chageHtml} ></Edit>
          </div>
        </Row>
        <Row className="bg-[white] w-full">
          <div className="dashboard_title">
            <Edit content={BrandValues} id={"BrandValues"} changeContent={handleChange} changeHtml={chageHtml} ></Edit>
          </div>
        </Row>
        <Row className="bg-[white] w-full">
          <div className="dashboard_title">
            <Edit content={BrandPersonality} id={"BrandPersonality"} changeContent={handleChange} changeHtml={chageHtml} ></Edit>
          </div>
        </Row>
        <Row className="bg-[white] w-full">
          <div className="dashboard_title">
            <Edit content={CustomerPromise} id={"CustomerPromise"} changeContent={handleChange} changeHtml={chageHtml} ></Edit>
          </div>
        </Row></div>}
        {
          LogoIdea==''&&<Generating></Generating>
        }
        {
        auth != "admin" && user.upgrade == "unpaid" &&!paid &&<Upgrade></Upgrade>
      }
      </Row>
    </div>
  )
}

export default BrandingIdentity