import React, { useEffect, useState, useRef } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import logo from "../../assets/images/StartUp Sphere Logo-02.png";
import { dashboard } from "utils/SideNavData";
import MenuItem from "../../utils/SideNavData";
import { Button, Space, Dropdown, Menu, notification, Modal, Form, Input, Avatar, Segmented, Tabs, Rate } from "antd";
import { GooglePlusOutlined, UpOutlined, LogoutOutlined, DeleteOutlined, PlusCircleOutlined, EditOutlined, ThunderboltOutlined, TeamOutlined, QuestionCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { deleteUser, setRegenerate1, setRegenerate, setRegenerate2 } from "../../Redux/features/UserSlice";
import { addUser, removeHtmlContent } from "../../Redux/features/UserSlice";
import { addContent } from "../../Redux/features/pagesSlice";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { URL } from "utils/const";
import { items } from "utils/SignInUpData";
import { useParams } from 'react-router-dom'
// import { emit } from "../../../backend/models/userModel";
import { updateHtmlContent } from "../../Redux/features/UserSlice";

const SideNav = ({ color, onClickedChange, newCompanyAdded, changeNewCompanyState }) => {

  const [editCompModal, setEditCompModal] = useState(false)
  const [comp, setComp] = useState('')
  const [eidtItem, setEditItem] = useState('');
  const navigator = useNavigate()
  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");

  const dispatch = useDispatch()
  // const { companies, companyName, email } = useSelector(state => state.userSlice)
  const user = useSelector(state => state.userSlice)
  const { email, curID, companies, role, prompt } = useSelector(state => state.userSlice)
  const auth = role;
  // const curID = JSON.parse(localStorage.getItem('curID'));
  const [company, setCompanies] = useState(companies)
  const [companiesOnSection, setCompaniesOnSection] = useState(companies)
  const [selectedCompany, setSelectedCompany] = useState('')
  const [textValue, setTextValue] = useState('');
  const [companyName, setcompanyName] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const pageContents = useSelector(state => {
    return state.pagesSlice
  })
  const handleTextareaChange = (event) => {
    setTextValue(event.target.value);
    // console.log("event.target", event.target.value)
  };

  const style1 = { overflowY: "auto", overflowX: 'hidden', height: '85vh', marginLeft: 10 };
  const style2 = { overflowY: "auto", overflowX: 'hidden', height: '92vh', marginLeft: 10 };



  useEffect(() => {
    // console.log("subheader description", user.companies[0])
    try {
      if (user.companies[0].companyDescription != undefined) {
        setcompanyName(user.companies[0].companyName)
        setTextValue(user.companies[0].companyDescription)
        setcompanyName(user.companies[0].companyName)
      }
    } catch (err) {
      console.log(err)
    }
  }, [user])

  const openNotificationWithIcon = (type, data) => {

    api[type]({
      message: "Notification",
      description: data,
    });
  };
  const onEvent = () => {
    onClickedChange();

  }

  const handleLogout = () => {
    dispatch(deleteUser())
    localStorage.removeItem('StartupSphere_user');
    localStorage.removeItem('StartupSphere_content');
    // deleteUser()
    navigator('/sign-in')
  }
  useEffect(() => {
    let newCompanies = [];
    companiesOnSection.map(company => {
      if (user.companies.filter(comp => comp.companyName == company.companyName).length > 0) {
        newCompanies.push(company)
      }
    })
    user.companies.map(company => {
      if (newCompanies.filter(comp => comp.companyName == company.companyName).length == 0) {
        newCompanies.push(company)
      }
    })
    // console.log(user.companies)
    setCompaniesOnSection(newCompanies)
    if (user.email != '') {
      // console.log(selectedCompany)
      // if (selectedCompany == '') {
      setSelectedCompany(user.companies[0].companyName)
      // }

      // setTimeout(() => {
      //   onSelectCompany(selectedCompany)  
      // }, 2000);

    }

  }, [user])

  useEffect(() => {
    if (newCompanyAdded) {
      onSelectCompany(user.companies[user.companies.length - 1].companyName);
      changeNewCompanyState();
    }

  }, [newCompanyAdded])

  const handleRemoveCompany = (name) => {
    // console.log('removing company', companyName, email)

    const data = { companyName: name, email: email }
    // console.log(user.companies)
    axios.post(URL + "/api/users/removeCompany", data)
      .then(response => {
        // console.log("hhh", response.data);
        const data = response.data;
        // console.log(data.prompt)
        openNotificationWithIcon("success", "Deleted");
        dispatch(addUser({
          email: data.user.email,
          name: 'StartupSphere',
          companies: data.user.company,
          curID: data.user.curID,
          role: data.user.role,
          prompt: data.prompt,
          gpt: data.gpt
        }))
      })
      .catch(error => {
        console.error(error);
        openNotificationWithIcon("error", "failed");
      })
  }

  const handleChangeComp = (e) => {
    setComp(e.target.value)
  }



  // const resetDescription = (e) => {
  //   axios.post(URL+"/api/users/resetdescription", { email: user.email, companyName: user.companies[0].companyName, newDescription: textValue }).then(res => {
  //     dispatch(addUser({
  //       email: user.email,
  //       name: 'StartupSphere',
  //       companies: res.data,
  //       curID: user.curID,
  //       role: user.role,
  //       prompt: user.prompt,
  //       upgrade: user.upgrade,
  //       gpt: user.gpt,
  //     }))
  //   }).catch(err => {
  //     console.log(err);
  //   })

  // }

  const handleRegenerate = () => {
    dispatch(setRegenerate1(true))
    dispatch(setRegenerate(false))
    dispatch(setRegenerate2(false))
  }

  const handleSaveComp = (e) => {
    setEditCompModal(false)
    // resetDescription()
    axios.post(URL + "/api/users/rename", { email: user.email, oldComp: user.companies[0].companyName, newComp: companyName, newDescription: textValue }).then(res => {
      // console.log(res.data)
      const updatedTemp = user.companies.map((item, index) => {
        if (index == 0) {
          return { ...item, companyName: companyName, companyDescription: textValue };
        }
        return item;
      });

      dispatch(addUser({
        email: user.email,
        name: 'StartupSphere',
        companies: updatedTemp,
        curID: user.curID,
        role: user.role,
        prompt: user.prompt,
        gpt: user.gpt
      }))

      handleRegenerate();
    })

    // dispatch(setRegenerate(true))
  }

  const [isHovered, setIsHovered] = React.useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const onDeleteCompany = (e, name) => {
    handleRemoveCompany(name)

  }

  const [paymentloadings, setPaymentLoadings] = useState(false);
  const [isProPlan, setIsProPlan] = useState(true);
  const [isMonthlyPlan, setIsMonthlyPlan] = useState(false);
  const [pricePerMonth, setPricePerMonth] = useState(20);
  const { status } = useParams()
  useEffect(() => {
    if (isMonthlyPlan && isProPlan) {
      setPricePerMonth(19)
    }
    if (!isMonthlyPlan && isProPlan) {
      setPricePerMonth(11)
    }
    if (isMonthlyPlan && !isProPlan) {
      setPricePerMonth(39)
    }
    if (!isMonthlyPlan && !isProPlan) {
      setPricePerMonth(19)
    }
  }, [isMonthlyPlan, isProPlan])
  useEffect(() => {
    if (status == "success") {
      openNotificationWithIcon("success", "Upgraded Successfully");
    }

    if (status == "cancel") {
      openNotificationWithIcon("error", "Failed, Try again later");
    }
  }, [status])

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const connectToStripe = async () => {
    // setPaymentLoadings(!paymentloadings)
    try {
      const response = await axios.post(URL + `/api/payment/process`, { price: pricePerMonth * 100, userId: user.email, isMonthly: isMonthlyPlan });
      console.log(response.data)
      window.location.href = response.data.url;
    } catch (err) {
      console.log('error!')
    }
  }




  const onSelectCompany = (name) => {
    setSelectedCompany(name)
    axios.post(URL + "/api/users/changeCompany", { email: user.email })
      .then(response => {

        navigator('/dashboard')
        console.log("response.data.user", response.data.user);
        // console.log("name",name);
        response.data.user.company.map(itm => {

          if (itm.companyName == name) {
            dispatch(addContent({ content: itm.jsonContent }))

          }
        })


        let newCompanies = [];
        let temp;
        let tempArray = [];
        companies.map((item, idx) => {
          if (item.companyName != name) {
            tempArray.push(item)
          } else {
            temp = item


          }
        })
        newCompanies.push(temp)
        newCompanies = newCompanies.concat(tempArray)
        console.log(newCompanies)
        // window.alert("111")
        dispatch(addUser({
          email: user.email,
          name: 'StartupSphere',
          companies: newCompanies,
          curID: user.curID,
          role: user.role,
          prompt: user.prompt,
          gpt: user.gpt,

        }))

        // dispatch(removeHtmlContent({}));


      })
      .catch(error => {
        console.log(error.response)
      })





  }

  const menu = (
    <Menu style={{ position: "fixed", bottom: "65px", width: "246px" }} className="no-print">
      <p className="pl-4 pt-2 pb-2">Your Companies</p>
      <div className="h-[120px] overflow-y-scroll border-b-2 border-t-2">
        {companies &&
          companiesOnSection.map((item, index) => {

            return (
              <div
                style={{ paddingLeft: 20 }}
                key={index}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className="no-print company mainItem pl-8 pr-4 mt-2 flex justify-between hover:cursor">
                <div className="name-section" onClick={() => { onSelectCompany(item.companyName) }} style={{ display: "flex", width: '67%' }}>
                  <div style={{ width: "25px" }}>
                    {
                      selectedCompany == item.companyName ? <CheckOutlined></CheckOutlined> : ""
                    }
                  </div>
                  <p>{item.companyName}</p>
                </div>

                {companies.length > 1 ?
                  selectedCompany == item.companyName ?
                    <div className="hiddenItem">
                      <EditOutlined key={`${index} +1`} className="pl-4 pr-[5px]" type="text" onClick={() => { setEditCompModal(true); setEditItem(item.companyName); setComp(item.companyName) }} />
                      <DeleteOutlined key={`${index} +2`} type="text" onClick={(e) => onDeleteCompany(e, item.companyName)} />
                    </div>
                    :
                    <div className="hiddenItem">
                      <DeleteOutlined key={`${index} +3`} type="text" onClick={(e) => onDeleteCompany(e, item.companyName)} />
                    </div>
                  : selectedCompany == item.companyName && <EditOutlined key={`${index} +1`} className="pl-4 pr-4" type="text" onClick={() => { setEditCompModal(true); setEditItem(item.companyName); setComp(item.companyName) }} />
                }
              </div>
            )
          })
        }

      </div>
      <Menu.Item key="company">
        <div onClick={onEvent} className="flex items-center pt-2">
          <PlusCircleOutlined className="mr-2" /> New Company
        </div>
      </Menu.Item>
      <Menu.Item key="account">
        <Link className="flex items-center" to="/account">
          <TeamOutlined className="mr-2" />Account
        </Link>
      </Menu.Item>
      {/* <Menu.Item key="support">
        <a className="flex items-center" to="/account">
          <QuestionCircleOutlined className="mr-2" />Support
        </a>
      </Menu.Item>
      <Menu.Item key="feedback">
        <a className="flex items-center" to="/account">
          <BulbOutlined className="mr-2" />Feedback
        </a>
      </Menu.Item> */}
      <Menu.Item key="logout">
        <a className="flex items-center" to="/account" onClick={handleLogout}>
          <LogoutOutlined className="mr-2" />Log Out
        </a>
      </Menu.Item>
    </Menu >
  );

  return (
    <>
      {contextHolder}
      <Modal width={700} className='p-0' open={isModalOpen} closable={false} footer={false} onCancel={handleCancel} >
        <div className='w-full p-[20px] h-[75vh] overflow-scroll'>
          <div className='text-right'>
            <CloseOutlined onClick={handleCancel} />
          </div>
          <h3 className="text-2xl text-center font-normal my-4">Upgrade to Pro</h3>
          <div style={{ backgroundColor: "rgb(237 233 254)" }} className='rounded-[10px] bg-grey-500 p-[15px] flex items-center justify-center'>
            <span className='font-bold'>
              <ThunderboltOutlined className='font-bold' />
            </span>
            <p>&nbsp;Flash Sale Save 40% on a yearly subscription</p>
          </div>
          <div className='w-full flex pt-4'>
            <div className='w-[43%] rounded-[10px] border p-[20px]' >
              <Segmented
                centered='true'
                defaultValue="year"
                style={{
                  marginBottom: 8,
                  padding: '5px'
                }}
                onChange={(value) => {
                  if (value == 'month') { setIsMonthlyPlan(true) } else {
                    setIsMonthlyPlan(false)
                  }
                }}
                options={[
                  {
                    label: (<h3 className='font-normal'>
                      Yearly <span className='rounded-full p-1 bg-[#e7e1ff] text-[#5330db] text-[12px]'>Saved 40%</span>
                    </h3>),
                    value: 'year'
                  },
                  {
                    label: (<h3 className='font-normal'>
                      Monthly
                    </h3>),
                    value: 'month'
                  }
                ]}
              />
              {
                !isMonthlyPlan ?
                  <div>
                    <div className='flex relative'>
                      <p className='font-normal text-[30px]'>${pricePerMonth}</p><span className='relative ml-2 flex items-end pb-[10px] text-[14px]'>/mo</span>
                    </div>
                    <p className='text-[#5330db]'>Flash sale. Saving 40%</p>
                  </div>
                  :
                  <div>
                    <div className='flex'>
                      <p className='font-normal text-[30px]'>${pricePerMonth}</p><span className='relative ml-2 flex items-end pb-[10px] text-[14px]'>/mo</span>
                    </div>
                    <p className=''>Billed monthly</p>
                  </div>
              }

              {
                isProPlan ?
                  <div>
                    <Button className='upgradedEffect' type="primary" loading={paymentloadings} onClick={connectToStripe} style={{ backgroundColor: "black", width: "100%", marginTop: "15px", padding: 10, height: 'auto', fontSize: 16, fontWeight: 'normal' }}>
                      {paymentloadings ? "" : "Upgrade to Pro"}
                    </Button>
                    <div className='flex mt-3'>
                      <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                      <p>Unlock full business plan</p>
                    </div>
                    <div className='flex mt-3'>
                      <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                      <p>Financials & budgeting</p>
                    </div>
                    <div className='flex mt-3'>
                      <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                      <p>PDF export</p>
                    </div>
                    <div className='flex mt-3'>
                      <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                      <p>AI business plan editor</p>
                    </div>
                    <div className='flex mt-3'>
                      <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                      <p>3 full business plans a month</p>
                    </div>
                    <div className='flex mt-3'>
                      <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                      <p>Chat AI consultant</p>
                    </div>
                    <div className='flex mt-3'>
                      <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                      <p>Priority email support</p>
                    </div>
                    <div className='flex mt-3'>
                      <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                      <p>Cancel anytime</p>
                    </div></div>
                  : <div>
                    <Button type="primary" loading={paymentloadings} onClick={connectToStripe} style={{ backgroundColor: "black", width: "100%", marginTop: "15px" }}>
                      {paymentloadings ? "" : "Upgrade to Agency"}
                    </Button>
                    <div className='flex mt-2'>
                      <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                      <p>20 full business plans a month</p>
                    </div>
                    <div className='flex mt-2'>
                      <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                      <p>Unlock full business plan</p>
                    </div>
                    <div className='flex mt-2'>
                      <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                      <p>Financials & budgeting</p>
                    </div>
                    <div className='flex mt-2'>
                      <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                      <p>PDF export</p>
                    </div>
                    <div className='flex mt-2'>
                      <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                      <p>AI business plan editor</p>
                    </div>
                    <div className='flex mt-2'>
                      <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                      <p>Chat AI consultant</p>
                    </div>
                    <div className='flex mt-2'>
                      <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                      <p>Priority email support</p>
                    </div>
                    <div className='flex mt-2'>
                      <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                      <p>Cancel anytime</p>
                    </div>
                  </div>
              }
            </div>
            <div className='w-[60%]'>
              <div className='flex justify-center text-sm mt-5'>
                <div className='border-r-[1px] border-gray-300 pr-[20px]'>
                  <Rate className='text-[14px] text-[#ffb204]' disabled={true} defaultValue={5} />
                  <div className='text-center text-normal text-md'>Rated 4.9/5</div>
                </div>
                <div className='flex flex-col justify-center pl-4'>
                  <div className='flex justify-center w-full'>
                    <div className="inline-block w-2 h-2 mt-1 mr-2">
                      <div className="w-2 h-2 bg-green-500 rounded-full mr-1 absolute z-10"></div>
                      <div className="w-2 h-2 bg-green-400 rounded-full mr-1 animate-ping-slow absolute"></div>
                    </div>
                    <div className='text-center text-md text-black font-light'>4,903 people</div>
                  </div>
                  <div className='w-full text-center text-md'>upgraded last week</div>
                </div>
              </div>
              <p className='p-[20px]'>
                "StartupSphere has been a game changer. I was feeling a little overwhelmed with starting my business but now I have everything laid out in front of me. <br /><br />
                I probably launched three months quicker and the business plan still gives me direction to this day."
              </p>
              <div className='p-[20px]'>
                <div className='flex items-center'>
                  <div className='w-[32px] h-[32px] rounded-full'>
                    <img src='https://www.venturekit.ai/jason.webp' className='w-full h-full rounded-full' />
                  </div>
                  <div className='ml-3'>
                    <div className='font-normal'>Jason Malowaski</div>
                    <div>Mallow's Landscaping</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* {
                        isProPlan ?
                            <div className='mt-2 mb-4 pl-8'>
                                Get more with the&nbsp;&nbsp;<a style={{ color: "rgb(22 163 74)" }} className='hover:underline' onClick={() => { setIsProPlan(!isProPlan) }}>Agency plan</a>
                            </div>
                            :
                            <div className='mt-2 mb-4 pl-8'>
                                Back to the&nbsp;&nbsp;<a style={{ color: "rgb(22 163 74)" }} className='hover:underline' onClick={() => { setIsProPlan(!isProPlan) }}>Pro plan</a>
                            </div>
                    } */}

        </div>
      </Modal>
      <div style={(user.upgrade == "unpaid" && role != "admin") ? { overflowY: "auto", overflowX: 'hidden', height: '85vh', marginLeft: 10 } : { overflowY: "auto", overflowX: 'hidden', height: '92vh', marginLeft: 10 }}>
        <Link to="/">
          <div className="brand">
            <img width="80%" src={logo} alt="" />
          </div>
        </Link>
        {email != '' && <MenuItem pathname={pathname} color={color} />}
      </div>
      <div className="aside-footer">
        {(user.upgrade == "unpaid" && role != "admin") && <button onClick={showModal} className="ml-[2px] mb-3 text-center w-full bg-[#3367D6] text-white rounded-md text-[15px] font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary mt-2 sm:mt-0 text-primary-foreground hover:bg-primary/90 active:bg-primary-darker h-9 px-2">
          <div className="flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 h-4 text-white mr-1"><path d="m2 4 3 12h14l3-12-6 7-4-7-4 7-6-7zm3 16h14"></path></svg>Upgrade
          </div>
        </button>}

        <Dropdown overlay={menu} trigger={['click']} placement="top" destroyPopupOnHide={true}>
          <Button type="dashed" style={{ width: '100%', height: 50, marginLeft: '2px' }}>
            {
              selectedCompany == '' ?
                <Space className="justify-between flex pl-1 pr-1">
                  <p className="flex items-center">
                    <Avatar className="bg-[#BFDBFE] text-[#7c3aed] font-bold">A</Avatar>&nbsp; Account
                  </p>
                  <p>
                    <UpOutlined />
                  </p>
                </Space>
                :
                <Space className="justify-between flex p-2">
                  <p className="flex items-center">
                    <Avatar className="bg-[#BFDBFE] text-[#7c3aed] font-bold">{selectedCompany[0]}</Avatar>&nbsp; {selectedCompany}
                  </p>
                  <p>
                    <UpOutlined />
                  </p>
                </Space>
            }

          </Button>
        </Dropdown>
      </div>
      {/* <Modal
        title="Edit Company Name"
        visible={editCompModal}
        onOk={() => setEditCompModal(false)}
        onCancel={() => setEditCompModal(false)}
        footer={null}
      >
        <Input className="mt-6" name="edit" onChange={handleChangeComp} value={comp} />
        <div className="w-full flex justify-end">
          <Button className="mt-4" onClick={handleSaveComp}>Save</Button>
        </div>

      </Modal> */}
      <Modal
        title="Edit Company"
        centered
        open={editCompModal}
        onOk={() => setEditCompModal(false)}
        onCancel={() => setEditCompModal(false)}
        footer={[
          <Button className="border-none hover:bg-gray-100 text-black" key="cancel" onClick={() => setEditCompModal(false)}>
            Cancel
          </Button>,
          <Button style={{}} className="bg-black hover:bg-black" key="submit" type="primary" onClick={handleSaveComp}>
            Save
          </Button>,
        ]}
      >
        <p className="mt-4 mb-[5px]">Comapnay Name:</p>
        <textarea value={companyName} onChange={(e) => { setcompanyName(e.target.value) }} className="w-full h-[40px] p-2 mt-0 border border-solid rounded-lg text-[#8c8c8c]"></textarea>
        <p className="mt-[15px] mb-[5px]">Description:</p>
        <textarea value={textValue} onChange={handleTextareaChange} className="w-full h-[80px] p-2 mt-0 border border-solid rounded-lg text-[#8c8c8c] "></textarea>

      </Modal>
    </>
  );
}
export default SideNav;
