import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal, unstable_batchedUpdates } from 'react-dom';
import {
  CancelDrop,
  closestCenter,
  pointerWithin,
  rectIntersection,
  CollisionDetection,
  DndContext,
  DragOverlay,
  DropAnimation,
  getFirstCollision,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  Modifiers,
  useDroppable,
  UniqueIdentifier,
  useSensors,
  useSensor,
  MeasuringStrategy,
  KeyboardCoordinateGetter,
  defaultDropAnimationSideEffects,
} from '@dnd-kit/core';
import {
  AnimateLayoutChanges,
  SortableContext,
  useSortable,
  arrayMove,
  defaultAnimateLayoutChanges,
  verticalListSortingStrategy,
  SortingStrategy,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { URL } from "../../utils/const";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { coordinateGetter as multipleContainersCoordinateGetter } from './multipleContainersKeyboardCoordinates';

import { Item, Container, ContainerProps } from './DndComponent';
import { Input, Button } from 'antd';

import { createRange } from './utilities';
import { connect, useSelector, useDispatch } from "react-redux";
import { addUser } from 'Redux/features/UserSlice';
import axios from "axios";
import { setSelectionRange } from '@testing-library/user-event/dist/utils';
export default {
  title: 'Presets/Sortable/Multiple Containers',
};

const animateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

function DroppableContainer({
  children,
  columns = 1,
  disabled,
  id,
  items,
  style,
  ...props
}) {
  const {
    active,
    attributes,
    isDragging,
    listeners,
    over,
    setNodeRef,
    transition,
    transform,
  } = useSortable({
    id,
    data: {
      type: 'container',
      children: items,
    },
    animateLayoutChanges,
  });
  const include_overId = items.filter(item => item.shown === over?.id)
  const isOverContainer =
    over &&
    ((id === over.id && active?.data.current?.type !== 'container') ||
      include_overId.length > 0);

  return (
    <Container
      ref={disabled ? undefined : setNodeRef}
      style={{
        ...style,
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.5 : undefined,
      }}
      hover={isOverContainer}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      columns={columns}
      {...props}
    >
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-full"><path d="m2 7 4.41-4.41A2 2 0 0 1 7.83 2h8.34a2 2 0 0 1 1.42.59L22 7"></path><path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path><path d="M15 22v-4a2 2 0 0 0-2-2h-2a2 2 0 0 0-2 2v4"></path><path d="M2 7h20"></path><path d="M22 7v3a2 2 0 0 1-2 2v0a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 16 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 12 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 8 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 4 12v0a2 2 0 0 1-2-2V7"></path></svg> */}
      {children}
    </Container>
  );
}

const dropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
};

const TRASH_ID = 'void';
const PLACEHOLDER_ID = 'placeholder';
const empty = [];
const aa = "wwwe"

export function MultipleContainers({
  adjustScale = false,
  itemCount = 3,
  cancelDrop,
  columns,
  handle = false,
  items: initialItems,
  containerStyle,
  coordinateGetter = multipleContainersCoordinateGetter,
  getItemStyles = () => ({}),
  wrapperStyle = () => ({}),
  minimal = false,
  modifiers,
  renderItem,
  strategy = verticalListSortingStrategy,
  trashable = false,
  vertical = false,
  scrollable,
  onMiddleClose
}) {
  const dispatch = useDispatch();
  const user = useSelector(state => {
    return state.userSlice
  })
  const tee = useSelector(state => {
    return state.pagesSlice
  })
  // const company = user.companies[0].content;
  const [company, setCompany] = useState(user.companies[0].content)

  const createItemsFromDataSource = (source) => {
    const items = {};

    source.forEach((category) => {
      items[category.title] = createRange(category.list.length, (index) => category.list[index]);
    });

    return items;
  };
  const [items, setItems] = useState(

    initialItems || createItemsFromDataSource(company)
  );
  const changeItems = (value) => {
    const updatedCompany = company.map(item => ({
      ...item,
      list: item.list.filter(subItem => subItem.shown != value)
    }));
    setCompany(updatedCompany)
    setItems(createItemsFromDataSource(updatedCompany))
    setContainers(Object.keys(createItemsFromDataSource(updatedCompany)))
  }

  const [flag, setFlag] = useState('')
  const [middle, setMiddle] = useState('')
  const [saveFlag, setSaveFlag] = useState(false)
  const showChangeModal = (value) => {
    console.log("value", value);
    setFlag(value);
    setMiddle(value);
  }

  const changeMiddle = (e) => {
    setMiddle(e.target.value)
  }

  const [finalData, setFinalData] = useState([]);
  const [middleOrderMenu, setMiddleOrderMenu] = useState([]);

  const searchOrigin = () => {

  }
  const handleChangeMiddle = (origin) => {
    console.log("flag", flag)
    console.log("middle", middle);

    const updatedCompany = company.map(item => ({
      ...item,
      list: item.list.map(subItem => {
        if (subItem.origin === origin) {
          return {
            ...subItem,
            shown: middle,
          };
        }
        return subItem;
      })
    }));
    setCompany(updatedCompany)
    setFinalData(updatedCompany)
    setItems(createItemsFromDataSource(updatedCompany))
    setContainers(Object.keys(createItemsFromDataSource(updatedCompany)))
    setFlag('');
    setSaveFlag(true);
  }

  const handleStopChange = () => {
    console.log("flag", flag)
    setFlag('')
  }

  // useEffect(()=>{setCompany(user.companies[0].content)},[user])
  // useEffect(()=>{setItems(createItemsFromDataSource(company))},[company])
  // useEffect(()=>{console.log("itemscontainter", items, containers)})

  const [containers, setContainers] = useState(
    Object.keys(items)
  );
  const [activeId, setActiveId] = useState(null);
  const lastOverId = useRef(null);
  const recentlyMovedToNewContainer = useRef(false);
  const isSortingContainer = activeId ? containers.includes(activeId) : false;
  // console.log("user.update", user.companies[0])
  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });


  }, [items, containers]);

  // useEffect(()=>{setContainers(Object.keys(items))},[items])

  const collisionDetectionStrategy = useCallback(
    (args) => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => container.id in items
          ),
        });
      }

      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, 'id');

      if (overId != null) {
        if (overId === TRASH_ID) {
          return intersections;
        }

        if (overId in items) {
          const containerItems = items[overId];
          if (containerItems.length > 0) {
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>{
                  const include_container = containerItems.filter(item => item.shown === container.id)
                  return container.id !== overId &&
                  include_container.length > 0
                }
                  
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, items]
  );
  const [clonedItems, setClonedItems] = useState(null);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  );
  const findContainer = (id) => {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) => {
      const result = items[key].filter(item => {
        return item.shown === id;
      });
      return result.length > 0;
    })
  };

  const getIndex = (id) => {
    const container = findContainer(id);

    if (!container) {
      return -1;
    }

    let index = -1;
    items[container].forEach((item, i) => {
      if(item.shown === id){
        index = i
      }  
    })
    return index;
  };

  const onDragCancel = () => {
    if (clonedItems) {
      setItems(clonedItems);
    }

    setActiveId(null);
    setClonedItems(null);
  };



  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={({ active }) => {
        setActiveId(active.id);
        setClonedItems(items);
      }}
      onDragOver={({ active, over }) => {
        const overId = over?.id;

        if (overId == null || overId === TRASH_ID || active.id in items) {
          return;
        }

        const overContainer = findContainer(overId);
        const activeContainer = findContainer(active.id);

        if (!overContainer || !activeContainer) {
          return;
        }

        if (activeContainer !== overContainer) {
          setItems((items) => {
            const activeItems = items[activeContainer];
            const overItems = items[overContainer];
            let overIndex = -1;
            overItems.forEach((item, i) => {
              if(item.shown === overId){
                overIndex = i
              }  
            })
            let activeIndex = -1;
            activeItems.forEach((item, i) => {
              if(item.shown === activeId){
                activeIndex = i
              }  
            })
            let newIndex;

            if (overId in items) {
              newIndex = overItems.length + 1;
            } else {
              const isBelowOverItem =
                over &&
                active.rect.current.translated &&
                active.rect.current.translated.top >
                over.rect.top + over.rect.height;

              const modifier = isBelowOverItem ? 1 : 0;

              newIndex =
                overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
            }

            recentlyMovedToNewContainer.current = true;

            return {
              ...items,
              [activeContainer]: items[activeContainer].filter(
                (item) => item.shown !== active.id
              ),
              [overContainer]: [
                ...items[overContainer].slice(0, newIndex),
                items[activeContainer][activeIndex],
                ...items[overContainer].slice(
                  newIndex,
                  items[overContainer].length
                ),
              ],
            };
          });
        }
      }}
      onDragEnd={({ active, over }) => {
        if (active.id in items && over?.id) {
          setContainers((containers) => {
            const activeIndex = containers.indexOf(active.id);
            const overIndex = containers.indexOf(over.id);

            return arrayMove(containers, activeIndex, overIndex);
          });
        }

        const activeContainer = findContainer(active.id);

        if (!activeContainer) {
          setActiveId(null);
          return;
        }

        const overId = over?.id;

        if (overId == null) {
          setActiveId(null);
          return;
        }

        if (overId === TRASH_ID) {
          setItems((items) => ({
            ...items,
            [activeContainer]: items[activeContainer].filter(
              (id) => id.shown !== activeId
            ),
          }));
          setActiveId(null);
          return;
        }

        if (overId === PLACEHOLDER_ID) {
          const newContainerId = getNextContainerId();

          unstable_batchedUpdates(() => {
            setContainers((containers) => [...containers, newContainerId]);
            setItems((items) => ({
              ...items,
              [activeContainer]: items[activeContainer].filter(
                (id) => id.shown !== activeId
              ),
              [newContainerId]: [active.id],
            }));
            setActiveId(null);
          });
          return;
        }

        const overContainer = findContainer(overId);

        if (overContainer) {
          let activeIndex = -1;
          let overIndex = -1;
          items[activeContainer].forEach((item, i) => {
            if(item.shown === active.id){
              activeIndex = i
            }
          })
          items[overContainer].forEach((item, i) => {
            if(item.shown === overId){
              overIndex = i
            }
          })
          if (activeIndex !== overIndex) {
            setItems((items) => ({
              ...items,
              [overContainer]: arrayMove(
                items[overContainer],
                activeIndex,
                overIndex
              ),
            }));
          }
        }

        setActiveId(null);
      }}
      cancelDrop={cancelDrop}
      onDragCancel={onDragCancel}
      modifiers={modifiers}
    >
      <div className='fixed bottom-[-30px] bg-white h-[100px] items-center w-[335px] flex justify-center mb-2' style={{ zIndex: '99' }}>
        <Button
        className='w-1/2 mr-4'
          onClick={e => {
            setCompany(user.companies[0].content)
            setItems(createItemsFromDataSource(user.companies[0].content))
            setContainers(Object.keys(createItemsFromDataSource(user.companies[0].content)))
            onMiddleClose();
          }}>
          <p>Cancel</p></Button>

        <Button 
        className='w-1/2 bg-black text-white'
        onClick={e => {
          let orderedMenu = []

          for (const menu of containers) {
            let temp = { title: "", list: [] }
            Object.keys(items).forEach(key => {
              if (key == menu) {
                temp.title = menu;
                items[key].map((itm) => {
                  let middleItem = ''
                  company.map((item) => { 
                    item.list.map((subItem) => { 
                      if (subItem.shown == itm.shown) { 
                        middleItem = subItem.origin 
                      } 
                    }) 
                  })
                  temp.list.push({ shown: itm.shown, origin: middleItem })
                })
              }
            })
            orderedMenu.push(temp)
          }

          const newCompanies = user.companies.map((item, idx) => {
            if (idx === 0) {
              return {
                ...item,
                content: orderedMenu
              }
            }
            return item
          })

          console.log("orderMenu", user.companies[0],orderedMenu)

          axios.post(URL + "/api/users/savemenu", { data: orderedMenu, email:user.email, companyName: user.companies[0].companyName })
          .then(res => {
            dispatch(addUser({
              email: user.email,
              name: 'StartupSphere',
              companies: newCompanies,
              curID: user.curID,
              role: user.role,
              prompt: user.prompt,
              upgrade: user.upgrade,
              gpt: user.gpt,
            }))
            console.log("success!!!")
          })  
          .catch(error => {
              console.error(error);
            })

          onMiddleClose();
          // }

        }
        }><p>Save</p></Button>

      </div>

      <div
        style={{
          display: 'inline-grid',
          boxSizing: 'border-box',
          paddingLeft: 20,
          marginBottom: 80,
          paddingRight: 20,
          gridAutoFlow: vertical ? 'row' : 'column',
        }}
      >
        <SortableContext
          items={[...containers, PLACEHOLDER_ID]}
          strategy={
            vertical
              ? verticalListSortingStrategy
              : horizontalListSortingStrategy
          }
        >
          {containers.map((containerId) => (
            <DroppableContainer
              key={containerId}
              id={containerId}
              label={minimal ? undefined : `${containerId}`}
              columns={columns}
              items={items[containerId]}
              // scrollable={scrollable}
              style={containerStyle}
              unstyled={minimal}
            // onRemove={() => handleRemove(containerId)}
            >
              <SortableContext items={items[containerId]} strategy={strategy}>
                {items[containerId].map((value, index) => {
            
                  return (
                    flag != value.shown ? <SortableItem
                      disabled={isSortingContainer}
                      key={`item-${index}`}
                      id={value.shown}
                      index={index}
                      handle={handle}
                      style={getItemStyles}
                      wrapperStyle={wrapperStyle}
                      renderItem={renderItem}
                      containerId={containerId}
                      getIndex={getIndex}
                    /> :
                      <div className='flex justify-between items-center'>
                        <input id={value.shown + index} className='border rounded py-1 px-4' value={middle} onChange={(e) => changeMiddle(e)}></input>
                        <CheckOutlined onClick={(e) => {
                          handleChangeMiddle(value.origin)
                        }} />
                        <CloseOutlined onClick={handleStopChange} />
                      </div>
                  );
                })}
                <p
                  className='hover:cursor-pointer pl-2 text-[#22A067]'
                  id={containerId}
                  disabled={isSortingContainer}
                  items={empty}
                  onClick={() => handleAddMiniColumn(containerId)}
                  placeholder
                >
                  + Add Section
                </p>
              </SortableContext>
            </DroppableContainer>
          ))}
          {/* {minimal ? undefined : (
            <Button
              style={{ marginTop: '20px', fontSize: '20px' }}
              id={PLACEHOLDER_ID}
              disabled={isSortingContainer}
              items={empty}
              onClick={handleAddColumn}
              placeholder
            >
              + Add Section
            </Button>

          )} */}
        </SortableContext>
      </div>
      {createPortal(
        <DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
          {activeId
            ? containers.includes(activeId)
              ? renderContainerDragOverlay(activeId)
              : renderSortableItemDragOverlay(activeId)
            : null}
        </DragOverlay>,
        document.body
      )}
      {trashable && activeId && !containers.includes(activeId) ? (
        <Trash id={TRASH_ID} />
      ) : null}

    </DndContext>
  );

  function renderSortableItemDragOverlay(id) {
    return (
      React.createElement(Item, {
        value: id,
        handle: handle,
        style: getItemStyles({
          containerId: findContainer(id),
          overIndex: -1,
          index: getIndex(id),
          value: id,
          isSorting: true,
          isDragging: true,
          isDragOverlay: true,
        }),
        color: getColor(id),
        wrapperStyle: wrapperStyle({ index: 0 }),
        renderItem: renderItem,
        dragOverlay: true
      })
    );
  }


  function renderContainerDragOverlay(containerId) {
    return (
      React.createElement(Container, {
        label: `${containerId}`,
        columns: columns,
        style: {
          height: '100%',
        },
        shadow: true,
        unstyled: false
      }, items[containerId].map((item, index) => (
        React.createElement(Item, {
          key: item,
          value: item.shown,
          handle: handle,
          style: getItemStyles({
            containerId: containerId,
            overIndex: -1,
            index: getIndex(item),
            value: item.shown,
            isDragging: false,
            isSorting: false,
            isDragOverlay: false,
          }),
          color: getColor(item),
          wrapperStyle: wrapperStyle({ index }),
          renderItem: renderItem
        })
      )))
    );
  }

  function handleRemove(containerID) {
    setContainers(containers =>
      containers.filter(id => id !== containerID)
    );
  }

  function handleAddColumn() {
    const newContainerId = getNextContainerId();

    unstable_batchedUpdates(() => {
      setContainers(containers => [...containers, newContainerId]);
      setItems(items => ({
        ...items,
        [newContainerId]: [],
      }));
    });
  }

  function handleAddMiniColumn(id) {
    console.log("id", id)
    setItems(items => ({
      ...items,
      [id]: [...items[id], {origin: "newItem" + items[id].length, shown: "newItem" + items[id].length}]
    }));

    const updatedCompany = company.map((item, index) => {
      if (item.title === id) {
        return {
          ...item,
          list: [...item.list, { shown: "newItem" + item.list.length, origin: "newItem" + item.list.length }]
        };
      }
      return item;
    });

    setCompany(updatedCompany)

  }

  function getNextContainerId() {
    const containerIds = Object.keys(items);
    const lastContainerId = containerIds[containerIds.length - 1];

    // return String.fromCharCode(lastContainerId.charCodeAt(0) + 1);
    return 'New Section' + containerIds.length;
  }

  function getColor(id) {
    switch (String(id)[0]) {
      case 'A':
        return '#7193f1';
      case 'B':
        return '#ffda6c';
      case 'C':
        return '#00bcd4';
      case 'D':
        return '#ef769f';
    }

    return undefined;
  }

  function Trash({ id }) {
    const { setNodeRef, isOver } = useDroppable({
      id,
    });

    return (
      React.createElement("div", {
        ref: setNodeRef,
        style: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'fixed',
          left: '50%',
          marginLeft: -150,
          bottom: 20,
          width: 300,
          height: 60,
          borderRadius: 5,
          border: '1px solid',
          borderColor: isOver ? 'red' : '#DDD',
        }
      }, 'Drop here to delete')
    );
  }

  // interface SortableItemProps {
  //   containerId: UniqueIdentifier;
  //   id: UniqueIdentifier;
  //   index: number;
  //   handle: boolean;
  //   disabled?: boolean;
  //   style(args: any): React.CSSProperties;
  //   getIndex(id: UniqueIdentifier): number;
  //   renderItem(): React.ReactElement;
  //   wrapperStyle({ index }: { index: number }): React.CSSProperties;
  // }


  function SortableItem({
    disabled,
    id,
    index,
    handle,
    renderItem,
    style,
    containerId,
    getIndex,
    wrapperStyle,
  }) {
    const {
      setNodeRef,
      setActivatorNodeRef,
      listeners,
      isDragging,
      isSorting,
      over,
      overIndex,
      transform,
      transition,
    } = useSortable({
      id,
    });
    const mounted = useMountStatus();
    const mountedWhileDragging = isDragging && !mounted;

    return (
      <Item
        ref={disabled ? undefined : setNodeRef}
        value={id}
        dragging={isDragging}
        sorting={isSorting}
        handle={handle}
        handleProps={handle ? { ref: setActivatorNodeRef } : undefined}
        index={index}
        wrapperStyle={wrapperStyle({ index })}
        changeItems={changeItems}
        onChangeStatus={showChangeModal}
        style={style({
          index,
          value: id,
          isDragging,
          isSorting,
          overIndex: over ? getIndex(over.id) : overIndex,
          containerId,
        })}
        // color={getColor(id)}
        onRemove={handleRemove}
        transition={transition}
        transform={transform}
        fadeIn={mountedWhileDragging}
        listeners={listeners}
        renderItem={renderItem}
      />
    );
  }

  function useMountStatus() {
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
      const timeout = setTimeout(() => setIsMounted(true), 500);

      return () => clearTimeout(timeout);
    }, []);

    return isMounted;
  }
}