import React, { useEffect, useRef, useState } from 'react'
import { Layout, Button, Row, Col, Rate } from 'antd';
import logo from '../assets/images/StartUp Sphere Logo-02.png'
import avart1 from '../assets/images/avatar1.jpg'
import avart2 from '../assets/images/avatar2.jpg'
import avart3 from '../assets/images/avatar3.jpg'
import avart4 from '../assets/images/Priya S.jpg'
import avart6 from '../assets/images/Emily W.jpg'
import avart5 from '../assets/images/Kofi A.jpg'
import market from '../assets/images/Marketing.jpg'
import operation from '../assets/images/Operations.jpg'
import product from '../assets/images/Products.jpg'
import strategy from '../assets/images/Strategy.jpg'
import financial from '../assets/images/Financials.jpg'
import insight from '../assets/images/Insights.jpg'
import { Flex, Input } from 'antd';
import Card from '../components/Review'
import Accordion from '../components/Accordion';
import { EditOutlined, MessageOutlined, ContainerOutlined, HomeOutlined, ShopOutlined, ArrowRightOutlined, LeftOutlined, RightOutlined, MenuFoldOutlined } from '@ant-design/icons'
import TabContent from '../components/TabContent';
import { useNavigate,Link } from "react-router-dom";
import { OPENAI_API_KEY } from 'utils/const';
import Carousel from 'react-multi-carousel';

import './landing.css'


const { Header, Content } = Layout;

const { TextArea } = Input;


const headerStyle = {
    textAlign: 'center',
    color: 'black',
    height: 100,
    // paddingInline: 48,
    padding: 0,
    lineHeight: '64px',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
};

const contentStyle = {
    textAlign: 'center',

    height: 'auto',
    color: 'black',
    backgroundColor: 'white',
};

const contentStyle1 = {
    textAlign: 'left',

    height: 'auto',
    color: 'black',
    backgroundColor: 'white',
    // paddingTop: '70px'
};

let data = [
    {
        Name: 'india',
        mark: "https://tailwindui.com/img/logos/tuple-logo-gray-900.svg",
        review: 'I dreamed of turning my street food stall into a franchise, and StartupSphere.ai made it a reality, brilliant, amazing and much useful. The platform understood the heart and soul of my business and helped me craft a perfect and brilliant personla plan that spoke to investors and customers alike. Their operational guidance was invaluable for scaling up. Gracias, StartupSphere!',
        avatar: avart3,
        name: "Carlos G",
        role: "Small Business Owner"
    },
    {
        Name: 'australia',
        mark: "https://tailwindui.com/img/logos/reform-logo-gray-900.svg",
        review: 'As a first-time founder, I was swimming in ideas but struggling to anchor them into a solid business plan. StartupSphere.ai was my lighthouse! It helped me navigate through uncharted waters, I am confidently steering my eco-friendly fashion startup towards success. The AI even suggested a unique branding strategy that is got my first line sold out!',
        avatar: avart1,
        name: "Emma L.",
        role: "Entrepreneurial Enthusiast"
    },
    {
        Name: 'japan',
        mark: "https://tailwindui.com/img/logos/tuple-logo-gray-900.svg",
        review: 'I have been in tech for a decade, but starting my own SaaS company seemed daunting. With StartupSphere.ai, I created a business plan that is as innovative as the AI software I am developing. Their insights on market penetration for tech startups were spot on. Thanks to them, I am now pitching to investors with a perfect and brilliant personla plan that is as bulletproof as my code.',
        avatar: avart2,
        name: "Alex Q.",
        role: "Tech Innovator"
    },
    {
        Name: 'USA',
        mark: "https://tailwindui.com/img/logos/reform-logo-gray-900.svg",
        review: 'I dreamed of turning my street food stall into a franchise, and StartupSphere.ai made it a reality, brilliant, amazing and much useful. The platform understood the heart and soul of my business and helped me craft a perfect and brilliant personla plan that spoke to investors and customers alike. Their operational guidance was invaluable for scaling up. Gracias, StartupSphere!',
        avatar: avart4,
        name: "Priya S.",
        role: "Creative Freelancer"
    },
    {
        Name: 'Nepal',
        mark: "https://tailwindui.com/img/logos/tuple-logo-gray-900.svg",
        review: "My passion for social change needed a structured approach to attract the right support. StartupSphere.ai highlighted my social ventures impact in ways I hadnot even considered. The AIs recommendations for social engagement and community impact have brought my project to life and to the attention of major NGOs.",
        avatar: avart5,
        name: "Kofi A",
        role: "Social Entrepreneur"
    },
    {
        Name: 'Chile',
        mark: "https://tailwindui.com/img/logos/reform-logo-gray-900.svg",
        review: 'Transitioning from a brick-and-mortar shop to an online store was challenging. With StartupSphere.ai, I developed a business plan that catered to the digital marketplace is nuances. Their customer profiling and e-commerce strategies were invaluable, boosting our online sales by 150% in just six months!',
        avatar: avart6,
        name: "Emily W.",
        role: "Retail E-commerce Manager"
    },
    {
        Name: 'Germany',
        mark: "https://tailwindui.com/img/logos/tuple-logo-gray-900.svg",
        review: 'I dreamed of turning my street food stall into a franchise, and StartupSphere.ai made it a reality, brilliant, amazing and much useful. The platform understood the heart and soul of my business and helped me craft a perfect and brilliant personla plan that spoke to investors and customers alike. Their operational guidance was invaluable for scaling up. Gracias, StartupSphere!',
        avatar: avart1,
        name: "Carlos G.",
        role: "Small Business Owner"
    },
    {
        Name: 'Italy',
        mark: "https://tailwindui.com/img/logos/reform-logo-gray-900.svg",
        review: 'As a first-time founder, I was swimming in ideas but struggling to anchor them into a solid business plan. StartupSphere.ai was my lighthouse! It helped me navigate through uncharted waters, confidently steering my eco-friendly fashion startup towards success. The AI even suggested a unique branding strategy that is got my first line sold out!',
        avatar: avart2,
        name: "Emma L.",
        role: "Entrepreneurial Enthusiast"
    },
    // {
    //     Name: 'USA',
    //     mark: "https://tailwindui.com/img/logos/reform-logo-gray-900.svg",
    //     review: 'I dreamed of turning my street food stall into a franchise, and StartupSphere.ai made it a reality, brilliant, amazing and much useful. The platform understood the heart and soul of my business and helped me craft a perfect and brilliant personla plan that spoke to investors and customers alike. Their operational guidance was invaluable for scaling up. Gracias, StartupSphere!',
    //     avatar: avart4,
    //     name: "Priya S.",
    //     role: "Creative Freelancer"
    // },
    // {
    //     Name: 'Nepal',
    //     mark: "https://tailwindui.com/img/logos/tuple-logo-gray-900.svg",
    //     review: "My passion for social change needed a structured approach to attract the right support. StartupSphere.ai highlighted my social ventures impact in ways I hadnot even considered. The AIs recommendations for social engagement and community impact have brought my project to life and to the attention of major NGOs.",
    //     avatar: avart5,
    //     name: "Kofi A",
    //     role: "Social Entrepreneur"
    // },
    // {
    //     Name: 'Chile',
    //     mark: "https://tailwindui.com/img/logos/reform-logo-gray-900.svg",
    //     review: 'Transitioning from a brick-and-mortar shop to an online store was challenging. With StartupSphere.ai, I developed a business plan that catered to the digital marketplace is nuances. Their customer profiling and e-commerce strategies were invaluable, boosting our online sales by 150% in just six months!',
    //     avatar: avart6,
    //     name: "Emily W.",
    //     role: "Retail E-commerce Manager"
    // },
]
const Tabs = ({ color }) => {
    const [openTab, setOpenTab] = React.useState(1);
    return (
        <>
            <div className="w-full">
                <div className="w-full md:px-[110px] sm:px-[24px]">
                    <ul
                        className="flex w-full mb-1 list-none flex-wrap justify-between pt-3"
                        role="tablist"
                    >
                        <li className="last:mr-0 flex-auto text-center px-3 my-3 md:w-[128px] min-w-[100px] max-w-[230px]">
                            <a
                                className={
                                    "text-lg font-bold sentence py-2 shadow-md rounded-lg block leading-normal " +
                                    (openTab === 1
                                        ? "text-white bg-[#4382FE]"
                                        : "text-gray-500 bg-white")
                                }
                                onClick={e => {
                                    e.preventDefault();
                                    setOpenTab(1);
                                }}
                                data-toggle="tab"
                                href="#link1"
                                role="tablist"
                            >
                                Strategy
                            </a>
                        </li>
                        <li className="last:mr-0 flex-auto text-center px-3 md:w-[128px] my-3 min-w-[100px] max-w-[230px]">
                            <a
                                className={
                                    "text-lg font-bold sentence py-2 shadow-md rounded-lg block leading-normal " +
                                    (openTab === 2
                                        ? "text-white bg-[#4382FE]"
                                        : "text-gray-500 bg-white")
                                }
                                onClick={e => {
                                    e.preventDefault();
                                    setOpenTab(2);
                                }}
                                data-toggle="tab"
                                href="#link2"
                                role="tablist"
                            >
                                Products
                            </a>
                        </li>
                        <li className="last:mr-0 flex-auto text-center px-3 my-3 md:w-[128px]  min-w-[100px] max-w-[230px]">
                            <a
                                className={
                                    "text-lg font-bold sentence py-2 shadow-md rounded-lg block leading-normal " +
                                    (openTab === 3
                                        ? "text-white bg-[#4382FE]"
                                        : "text-gray-500 bg-white")
                                }
                                onClick={e => {
                                    e.preventDefault();
                                    setOpenTab(3);
                                }}
                                data-toggle="tab"
                                href="#link3"
                                role="tablist"
                            >
                                Marketing
                            </a>
                        </li>
                        <li className="last:mr-0 flex-auto text-center px-3 my-3 md:w-[128px]  min-w-[100px] max-w-[230px]">
                            <a
                                className={
                                    "text-lg font-bold sentence py-2 shadow-md rounded-lg block leading-normal " +
                                    (openTab === 4
                                        ? "text-white bg-[#4382FE]"
                                        : "text-gray-500 bg-white")
                                }
                                onClick={e => {
                                    e.preventDefault();
                                    setOpenTab(4);
                                }}
                                data-toggle="tab"
                                href="#link4"
                                role="tablist"
                            >
                                Operations
                            </a>
                        </li>
                        <li className="last:mr-0 flex-auto text-center px-3 my-3 md:w-[128px]  min-w-[100px] max-w-[230px]">
                            <a
                                className={
                                    "text-lg font-bold sentence py-2 shadow-md rounded-lg block leading-normal " +
                                    (openTab === 5
                                        ? "text-white bg-[#4382FE]"
                                        : "text-gray-500 bg-white")
                                }
                                onClick={e => {
                                    e.preventDefault();
                                    setOpenTab(5);
                                }}
                                data-toggle="tab"
                                href="#link5"
                                role="tablist"
                            >
                                Insights
                            </a>
                        </li>
                        <li className="-mb-px mr-2 last:mr-0 flex-auto text-center px-3 my-3 md:w-[128px]  min-w-[100px] max-w-[230px]">
                            <a
                                className={
                                    "text-lg font-bold sentence py-2 shadow-md rounded-lg block leading-normal " +
                                    (openTab === 6
                                        ? "text-white bg-[#4382FE]"
                                        : "text-gray-500 bg-white")
                                }
                                onClick={e => {
                                    e.preventDefault();
                                    setOpenTab(6);
                                }}
                                data-toggle="tab"
                                href="#link6"
                                role="tablist"
                            >
                                Finances
                            </a>
                        </li>
                    </ul>
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
                        <div className="sm:px-[100px] md:px-[200px] p-4 sm:py-[100px] flex-auto">
                            <div className="tab-content tab-space">
                                <div className={openTab === 1 ? "block" : "hidden"} id="link1">

                                    <TabContent
                                        // title="Get A Full SWOT Analysis of Any Business or Product In Seconds"
                                        content="We help you think of super cool and new ways to make your business grow fast! Like having a secret map to find treasure."
                                        btnName="Discover Your Strategy"
                                        path="/overview/mission-vision"
                                        img={strategy}
                                    />
                                </div>
                                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                                    <TabContent
                                        // title="Generate In-Depth Business & Product Research Reports"
                                        content="We show you the best things to sell or the best services to offer that people really want, so your business can be a big hit!"
                                        btnName="Discover Your Products"
                                        path="/products-services/core-offering"
                                        img={product}
                                    />
                                </div>
                                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                                    <TabContent
                                        // title="Uncover New Market Opportunities"
                                        content="We teach you how to tell lots of people about your business, using fun pictures and messages, so everyone will know how great it is!"
                                        btnName="Discover Your Marketing"
                                        path="/market-research/industry-overview"
                                        img={market}
                                    />
                                </div>
                                <div className={openTab === 4 ? "block" : "hidden"} id="link4">
                                    <TabContent
                                        // title="Get To Know Your Buyer Personas"
                                        content="We make running your business easy-peasy by showing you smart ways to do things, from making stuff to helping customers."
                                        btnName="Discover Your Operations"
                                        path="/operations/plan"
                                        img={operation}
                                    />
                                </div>
                                <div className={openTab === 5 ? "block" : "hidden"} id="link5">
                                    <TabContent
                                        // title="Sales Prospect Profiler"
                                        content="We give you special knowledge about what people like and what’s cool right now, so you can make better stuff for them."
                                        btnName="Discover Your Insights"
                                        path="/sales-marketing/online-presence"
                                        img={insight}
                                    />
                                </div>
                                <div className={openTab === 6 ? "block" : "hidden"} id="link6">
                                    <TabContent
                                        // title="Uncover New Market Opportunities"
                                        content="We help you understand money stuff, like how much you can earn and what you need to spend, so your business can have lots of allowance to grow."
                                        btnName="Discover Your Finances"
                                        path="/financials/revenue"
                                        img={financial}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const LandingPage = () => {
    const ref = useRef(null);

    const navigate = useNavigate();
    const [containerWidth, setWidth] = useState(100 + "%");
    const [animationState, setPlay] = useState("paused");
    const [flag, setflag] = useState(false);
    const [value, setValue] = useState('');

    const [currentIndex, setCurrentIndex] = useState(0);
    const intervalRef = useRef(null);
    const carouselRef = useRef(null);
    const totalItems = data.length;
    const itemWidth = 100 / totalItems;

    useEffect(() => {
        startAutoFlow();
        return () => stopAutoFlow();
    }, []);

    const startAutoFlow = () => {
        // carouselRef.current.style.transition = 'transform 1s linear';
        intervalRef.current = setInterval(goToNextSlide, 5000);
    };

    const stopAutoFlow = () => {
        clearInterval(intervalRef.current);
    };

    const goToPreviousSlide = () => {
        if (currentIndex === 0&& carouselRef.current.style) {
            // alert("sdfsdf")
            carouselRef.current.style.transition = 'transform 0.001s linear';
            setCurrentIndex(prevIndex => (prevIndex === 0 ? totalItems - 1 : prevIndex - 1));
            return
        }
        carouselRef.current.style.transition = 'transform 1s linear';
        setCurrentIndex(prevIndex => (prevIndex === 0 ? totalItems - 1 : prevIndex - 1));
    };

    const goToNextSlide = () => {
        // console.log(currentIndex)
        // if(currentIndex === totalItems - 1){
        //     alert("sdfsdf")
        //     carouselRef.current.style.transition = 'transform 0.001s linear';
        //     setCurrentIndex(prevIndex => (prevIndex === totalItems - 1 ? 0 : prevIndex + 1));
        //     return
        // }
        // carouselRef.current.style.transition = 'transform 1s linear';
        // setCurrentIndex(prevIndex => (prevIndex === totalItems - 1 ? 0 : prevIndex + 1));
        let flag = 0;
        setCurrentIndex(prevIndex => {
            if (prevIndex === totalItems - 1&&carouselRef.current.style) {
                carouselRef.current.style.transition = 'transform 0.001s linear';
                flag = 1;
                return 0
            } else {
                if(carouselRef.current.style) carouselRef.current.style.transition = 'transform 1s linear';
                return prevIndex + 1
            }
        })
    };

    const onChange = (e) => {
        console.log('Change:', e.target.value);
        setValue(e.target.value);
    };

    const goHome = ()=> {
        navigate('/dashboard', {state:{data:value}});
    }

    async function generatePrompt() {
        console.log("value", value)
        let temp = value
        console.log("------", temp)
        const userMessage = `Your website platform name is "Startup Shere", 
          this platform is for generating ideas and brainstorms for business guys.
          Here is the question: ${value}
          you will generate the respones for this question
          `
        setValue("");
        // console.log(userMessage);
        try {
            const response = await fetch("https://api.openai.com/v1/chat/completions", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${OPENAI_API_KEY}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    model: "gpt-3.5-turbo-1106",
                    messages: [{ role: "user", content: userMessage }],
                    temperature: 0.7,
                    // response_format: ,
                    stream: true
                }),
            });

            const reader = response.body.getReader();
            let result = '';
            while (1) {
                const { done, value } = await reader.read();
                if (done) {
                    // setMessage(temp)
                    // document.getElementById('chatContent').scrollTop = document.getElementById('chatContent').scrollHeight
                    break;
                }

                // Process the chunk as needed
                let chunk = new TextDecoder('utf-8').decode(value);
                const lines = chunk.split("\n\n");
                const parsedLines = lines
                    .map((line) => line.replace(/data: /, "").trim()) // Remove the "data: " prefix
                    .filter((line) => line !== "" && line !== "[DONE]") // Remove empty lines and "[DONE]"
                    .map((line) => JSON.parse(line)); // Parse the JSON string

                for (const parsedLine of parsedLines) {
                    const { choices } = parsedLine;
                    const { delta } = choices[0];
                    const { content } = delta;
                    // Update the UI with the new content
                    if (content) {
                        // console.log(content)

                        result += content
                        setValue(result)

                    }
                }

            }
        } catch (error) {
            console.log(error)
        }

    }

    const onChangeFlag = () => {
        if (flag == true) {
            setflag(false)
        }
        else {
            setflag(true)
        }
    }

    useEffect(() => {
        if (ref.current) {
            setWidth(ref.current.scrollWidth + "px");
            setPlay("running");
        }
    }, []);

    const renderCards = data.map((el, index) => {
        return <Card key={index} cardName={el.Name} mark={el.mark} review={el.review} avatar={el.avatar} name={el.name} role={el.role} />;
    });

    const [accordions, setAccordion] = useState([
        {
            key: 1,
            title: 'What is StartupSphere?',
            data: `StartupSphere.ai is a super smart computer helper that listens to your business ideas and helps you make a real plan to make them come true!`,
            isOpen: false
        },
        {
            key: 2,
            title: 'How does StartupSphere.ai work?',
            data: `Just tell us about your awesome business idea by answering some easy questions, and voilà! You get a perfect and brilliant personla plan that tells you how to start, what to sell, and even how to tell people about it!`,
            isOpen: false
        },
        {
            key: 3,
            title: 'Do I need to know a lot about business to use it?',
            data: `Nope! You just need an idea. We'll handle the tricky business stuff and explain everything so it's super easy to understand.`,
            isOpen: false
        },
        {
            key: 4,
            title: 'How long does it take to generate a business plan?',
            data: ` Super quick! Answer the questions, and you'll have a shiny new business plan faster than you can say "StartupSphere.ai!"`,
            isOpen: false
        },
        {
            key: 5,
            title: 'Is my business idea safe with you?',
            data: `Absolutely! We keep all your cool ideas just between us. Safety first!`,
            isOpen: false
        },
        {
            key: 6,
            title: 'Can I get help if I am stuck?',
            data: `Of course! We're here to help. Just click on the help button, and we'll guide you through any sticky spots`,
            isOpen: false
        },
        {
            key: 7,
            title: 'What if I need to change my plan?',
            data: `No problemo! With StartupSphere.ai, you can tweak and change your plan until it's just right.`,
            isOpen: false
        },
        {
            key: 8,
            title: 'How much does it cost?',
            data: `We have different plans for different needs. Some are even free! Check out our pricing section to find the perfect fit for you.`,
            isOpen: false
        },
        {
            key: 9,
            title: 'Can StartupSphere.ai help me get money for my business?',
            data: `While we don't give out cash, we do make your plan look so good that it might just help you impress people who can!`,
            isOpen: false
        },
        {
            key: 10,
            title: 'What if I have more questions?',
            data: `We love curious minds! Send us your questions anytime, and we'll get back to you with answers as fast as a shooting star!`,
            isOpen: false
        },
    ]);

    const toggleAccordion = (accordionkey) => {
        const updatedAccordions = accordions.map((accord) => {
            if (accord.key === accordionkey) {
                return { ...accord, isOpen: !accord.isOpen };
            } else {
                return { ...accord, isOpen: false };
            }
        });

        setAccordion(updatedAccordions);
    };


    return (

        <div style={{ backgroundColor: 'white' }}>
            <Row style={{ display: 'flex', justifyContent: 'center', fontFamily: 'fantasy' }}>
                <Col sm={20} xs={20} lg={19}>
                    <Header style={headerStyle}>
                        <Link to="/" >
                            <img src={logo} alt='logo' className='h-7 w-auto my-auto sm:h-10' />
                        </Link>
                        <div className='hidden sm:block'>
                            <Link to="/" className='text-lg mr-8' type='text' style={{ color: 'black' }}>Home</Link>
                            {/* <Link to="/blog" className='text-lg mr-8' type='text' style={{ color: 'black' }}>Blog</Link> */}
                            <Link to="/about" className='text-lg' type='text' style={{ color: 'black' }}>About</Link>
                        </div>
                        <div className='flex items-center'>
                            <Link cla to="/sign-in" className="mr-8 text-lg text-black hidden lg:block">
                                Login
                            </Link>
                            {/* <Button type='second' className='mr-4 text-md' style={{ backgroundColor: '#3367D6', color: 'white' }}>
                                <Link to="/dashboard" className="text-white text-lg">
                                    Get started
                                </Link>
                            </Button> */}
                            <Link to="/dashboard" className="text-white text-lg">
                                <button className="gradient-button shadow-xl flex" style={{ fontFamily: 'cursive', paddingRight: 20, paddingLeft: 20, fontSize: 16, borderRadius: "20px", paddingTop: "5px", paddingBottom: "5px", boxShadow: '-3px 4px #B7B7B7' }}>
                                    <span className='text-[12px] sm:text-[16px]'><b>Get started</b></span>
                                </button>
                            </Link>

                            {/* <Link className="text-white text-[12px] sm:text-[16px] sm:px-[30px] sm:py-[10px]" to="/dashboard">
                                        <button className="text-white gradient-button shadow-xl ml-4 sm:ml-0 mt-2 sm:mt-0 text-[16px]" style={{ borderRadius: "30px", boxShadow: '-3px 4px #B7B7B7' }}>
                                            <span><b>Generate Your Visionary Plan</b></span>
                                        </button>
                                    </Link> */}
                            <MenuFoldOutlined className='sm:hidden ml-2' onClick={onChangeFlag} />
                        </div>
                    </Header>
                </Col>
                {
                    flag &&
                    <div className='mt-20'>
                        <div className='block'>
                            <Link to="/" className='text-2xl ' type='text' style={{ color: 'black' }}>Home</Link>
                        </div>
                        {/* <div className='block mt-4'>
                            <Link to="/blog" className='text-2xl ' type='text' style={{ color: 'black' }}>Blog</Link>
                        </div> */}
                        <div className='block mt-4'>
                            <Link to="/about" className='text-2xl' type='text' style={{ color: 'black' }}>About</Link>
                        </div>
                        <div className='block mt-4'>
                            <Link cla to="/sign-in" className=" text-2xl text-black">
                                Login
                            </Link>
                        </div>
                    </div>
                }
                {
                    !flag && <>
                        <Col sm={20} xs={20} lg={19} style={{ boxShadow: '-16px 16px #383838', borderTop: "none" }} className=' sm:mt-4 border rounded-3xl w-full py-16'>
                            <Content className='flex flex-col justify-center mb-8' style={contentStyle}>
                                <div className='text-4xl tracking-tight md:text-7xl mx-auto' style={{ fontWeight: '600', color: "#464749" }}>
                                    Launch Your Company
                                </div><div className='text-2xl mt-2 tracking-tight sm:mb-2 md:text-7xl mx-auto' style={{ fontWeight: '400', color: "#464749" }}>
                                    in One Click
                                </div>
                                <div className='text-md mb-2 sm:mb-4 block md:text-[40px]' style={{ fontWeight: '200', color: "#464749" }}>
                                    Generate Your Business Plan
                                </div>
                                <div className='flex justify-center'>
                                    <TextArea
                                        // showCount
                                        // maxLength={100}
                                        id='last_answer'
                                        onChange={onChange}
                                        value={value}
                                        className='rounded-full sm:mt-0 w-[45%] min-w-[300px] min-h-[200px]'
                                        placeholder="Briefly describe your business..."
                                        style={{
                                            // width: '54%',
                                            resize: 'none',
                                            height: 120,
                                            // marginLeft: "23%",
                                            borderRadius: '30px',
                                            padding: '10px 15px'
                                        }}
                                    />
                                </div>
                                <div className='md:flex justify-center items-center pt-4'>
                                    <div className='sm: px-[30px] flex justify-center items-center'>
                                        <div>
                                            <Rate className='text-[0.7rem]' defaultValue={5} />
                                            <div className='text-[0.7rem]'>Rated 4.9/5</div>

                                        </div>
                                        <div className='text-[0.7rem]'>
                                            <div className=" inline-block w-2 h-2 ml-4 mt-2 mr-2">
                                                <div className="w-2 h-2 bg-green-500 rounded-full mr-1 absolute z-10"></div>
                                                <div className="w-2 h-2 bg-green-400 rounded-full mr-1 animate-ping-slow absolute"></div>
                                            </div>
                                            4,903 people
                                            <div className='pl-4'>signed up last week</div>
                                        </div>
                                    </div>

                                    <div
                                        className="text-white text-[12px] sm:text-[16px] sm:px-[30px] sm:py-[10px]"
                                        // to={{
                                        //     pathname: "/dashboard",
                                        //     state: { data: "value", data1: "here" }
                                        // }}
                                    >
                                        <button onClick={goHome} className="text-white gradient-button shadow-xl ml-4 sm:ml-0 mt-2 sm:mt-0 " style={{ borderRadius: "30px", boxShadow: '-3px 4px #B7B7B7' }}>
                                            <span className='text-[12px] sm:text-[16px]'><b>Generate Your Visionary Plan</b></span>
                                        </button>
                                    </div>

                                </div>

                            </Content>
                        </Col>
                        <Col sm={19} xs={19} lg={21} className='sm:mb-4'>
                            <Content style={contentStyle}>

                                <div className='hidden sm:block md:mt-20 md:mb-20'>

                                    <div className="Review" style={{ margin: "30px" }}>
                                        <div
                                            style={{ borderRadius: "17px" }}
                                            className='md:flex border rounded-lg py-0 px-0 border-opacity-20 border-[#4382FE]'
                                        >
                                            <div className="carousel-container md:pl-[40px] md:pr-[40px] md:pt-[30px] md:pb-[30px]" >
                                                <div>
                                                    <LeftOutlined className="carousel-arrow left" onClick={goToPreviousSlide} />
                                                </div>
                                                <div className="carousel" style={{ marginLeft: "0px", marginRight: "00px" }}>
                                                    <div className="carousel-inner" ref={carouselRef} style={{ transform: `translateX(-${currentIndex * itemWidth * 2.67}%)` }}>
                                                        {data.map((item, index) => (
                                                            <Card
                                                                key={index}
                                                                cardName={item.Name}
                                                                mark={item.mark}
                                                                review={item.review}
                                                                avatar={item.avatar}
                                                                name={item.name}
                                                                role={item.role}
                                                            />
                                                        ))}
                                                        {data.slice(0, 3).map((item, index) => (
                                                            <Card
                                                                key={index + data.length}
                                                                cardName={item.Name}
                                                                mark={item.mark}
                                                                review={item.review}
                                                                avatar={item.avatar}
                                                                name={item.name}
                                                                role={item.role}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                                <RightOutlined className="carousel-arrow right" onClick={goToNextSlide} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <Carousel
                                    swipeable={false}
                                    draggable={false}
                                    showDots={true}
                                    responsive={responsive}
                                    ssr={false} // means to render carousel on server-side.
                                    infinite={true}
                                    autoPlay={true}
                                    autoPlaySpeed={2000}
                                    keyBoardControl={true}
                                    customTransition="all .5"
                                    transitionDuration={500}
                                    containerClass="carousel-container"
                                    removeArrowOnDeviceType={["tablet", "mobile"]}
                                    deviceType={'tablet'}
                                    dotListClass="custom-dot-list-style"
                                    itemClass="carousel-item-padding-40-px"
                                    className='py-5'
                                >
                                    {data.map((item, index) => (
                                        <Card
                                            key={index}
                                            cardName={item.Name}
                                            mark={item.mark}
                                            review={item.review}
                                            avatar={item.avatar}
                                            name={item.name}
                                            role={item.role}
                                        />
                                    ))}
                                </Carousel> */}
                                <div className='mt-10 sm:mt-0'>
                                    <p className="text-center font-montserrat text-2xl sm:text-3xl font-extrabold leading-tight sm:mt-20" style={{ color: '#464749' }}>
                                        <b>Create Your Dream Business Plan</b>
                                    </p>
                                    <p className='sm:mb-16 font-montserrat sm:text-xl text-xl font-medium leading-tight mt-2 mb-6' style={{ color: '#464749' }}>
                                        🧭 We save you months of time and thousands of dollars<br />
                                        it costs to hire someone to create a business plan.
                                    </p>

                                    <Tabs color="#3367D6" />
                                </div>

                                <div className='mt-2 sm:mt-20 sm:px-5 md:px-[110px]'>
                                    <div className='relative isolate overflow-hidden px-[16px] py-12 text-center shadow-2xl sm:rounded-3xl sm:px-16' style={{ backgroundColor: '#E7E7E7' }}>
                                        <h2 className='mx-auto max-w-2xl text-3xl font-medium tracking-tight sm:text-3xl' style={{ color: '#464749' }}>
                                            <b>Get your AI-generated business plan</b>
                                        </h2>
                                        <p className='mx-auto mt-4 max-w-xl text-xl leading-8 text-black-500'>
                                            🚀 Just tell us a little about your idea.<br /> We make a
                                            super cool plan just for you, super quick!
                                        </p>
                                        <div className='mt-4 flex items-center justify-center gap-x-6'>
                                            <button className="text-white gradient-button shadow-xl flex ml-4 sm:ml-0 sm:px-[30px] mt-2 sm:mt-0 sm:py-[10px] text-[16px]" style={{ borderRadius: "30px", boxShadow: '-3px 4px #B7B7B7' }}>
                                                <Link to="/dashboard" className="text-white text-lg">
                                                    <span className='text-[12px] sm:text-[16px]'><b>Click to Start Your Adventure 🌟</b></span>
                                                </Link>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </Content>
                        </Col>
                        <Col sm={20} xs={20} lg={14} className='sm:mt-20'>
                            <Content style={contentStyle1}>
                                <h2 className='mx-auto max-w-2xl text-3xl p-8 sm:p-0 font-medium text-center sm:mb-16 tracking-tight sm:text-3xl' style={{ color: '#464749' }}>
                                    <b> Frequently asked questions</b>
                                </h2>
                                {/* <h2 className='text-3xl text-center font-medium leading-10 tracking-tight text-[#E7E7E7]-800 mb-5'>
                                    Frequently asked questions
                                </h2> */}
                                {accordions.map((accordion) => (
                                    <Accordion
                                        key={accordion.key}
                                        title={accordion.title}
                                        data={accordion.data}
                                        isOpen={accordion.isOpen}
                                        toggleAccordion={() => toggleAccordion(accordion.key)}
                                    />
                                ))}
                            </Content>
                        </Col>
                        <Col sm={20} xs={20} lg={12}>
                            <Content style={contentStyle}>
                                <div className='px-6 py-10 sm:py-24 lg:px-8'>
                                    <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                        <a href='/#' className='items-center justify-center rounded-md text-[15px] font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground active:bg-black/10 h-10 px-4 hidden sm:flex'>
                                            Home
                                        </a>
                                        <Link to='/pricing' className='items-center justify-center rounded-md text-[15px] font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground active:bg-black/10 h-10 px-4 hidden sm:flex'>
                                            Pricing
                                        </Link>
                                        <a href='/about' className='items-center justify-center rounded-md text-[15px] font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground active:bg-black/10 h-10 px-4 hidden sm:flex'>
                                            About
                                        </a>
                                        <a href='/#' className='items-center justify-center rounded-md text-[15px] font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground active:bg-black/10 h-10 px-4 hidden sm:flex'>
                                            Support
                                        </a>
                                        <a href='/#' className='items-center justify-center rounded-md text-[15px] font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground active:bg-black/10 h-10 px-4 hidden sm:flex'>
                                            Teams
                                        </a>
                                        <a href='/#' className='items-center justify-center rounded-md text-[15px] font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground active:bg-black/10 h-10 px-4 hidden sm:flex'>
                                            Privacy
                                        </a>
                                    </div>
                                    <p className='mt-4 sm:mt-10 text-center text-xs leading-5 text-gray-500'>
                                        © StartupSphere. All rights reserved.
                                    </p>
                                </div>
                            </Content>
                        </Col>
                    </>
                }
            </Row>

        </div>
    )
}

export default LandingPage