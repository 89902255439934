import React, { useState, useEffect } from 'react'
import Sales_Marketing from '../../../assets/images/pdfs/sales_marketing.png'

const OnlinePresence = (props) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        if (props.user) {
            // console.log('Online Presence -> user: ', props.user);
            setData(props.user.companies[0].jsonContent.find(item => item.Title === "Online Presence"));
        }
    })
    return (
        <div style={{fontFamily:'sans-serif', width: `400px`, padding: '10px 20px', marginLeft: 20 }}>
            <div style={{fontFamily:'sans-serif', display: 'flex', alignItems: 'center' }}>
                <div style={{fontFamily:'sans-serif', width: 13, height: 13, marginRight: 8, marginTop: 17, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 3, color: '#26B35A' }}>
                    <div style={{fontFamily:'sans-serif', width: 13 }}>
                        <img src={Sales_Marketing} />
                    </div>
                </div>
                <div style={{fontFamily:'sans-serif', fontSize: 14, color: '#26B35A' }}>Sales & Marketing</div>
            </div>
            <div style={{fontFamily:'sans-serif', fontSize: 20, fontWeight: 'bold' }}>
                Online Presence
            </div>
            <div style={{fontFamily:'sans-serif', width: '100%' }}>
                <div style={{fontFamily:'sans-serif', marginBottom: 10 }}>
                    <div style={{fontFamily:'sans-serif', marginBottom: 5, fontSize: 14, fontWeight: 'bold' }}>
                        {data?.Title}:
                    </div>
                    <div style={{fontFamily:'sans-serif', textWrap: 'wrap', fontSize: 12 }}>
                        <div>
                            {data?.Summary}
                        </div>
                    </div>
                </div>
                <div style={{fontFamily:'sans-serif',}}>
                    <ul style={{fontFamily:'sans-serif', listStyleType: 'disc', listStylePosition: 'outside', textWrap: 'wrap' }}>
                        {data?.Content[0]?.Description?.Description?.map((item, index) => {
                            return (
                                <li key={index} style={{fontFamily:'sans-serif', paddingLeft: 10, fontSize: 12 }}>
                                    {item.Description}
                                </li>
                            )
                        })}
                    </ul>

                </div>
            </div>
        </div>
    )
}

export default OnlinePresence