import React, { useState, useRef, useEffect } from "react";

import Upgrade from "components/Upgrade";
import axios from "axios";
import { Row, Col, Typography, Space, Switch } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import * as formats from "../../utils/configure";
import { URL } from '../../utils/const';
import { genEachContent } from "../../utils/service";
import { useSelector, useDispatch } from "react-redux";
import { updateHtmlContent } from "../../Redux/features/UserSlice";
import { addContent } from "../../Redux/features/pagesSlice";
import Edit from "utils/edit";
import Admin from "utils/admin";
import { setRegenerate } from "../../Redux/features/UserSlice";
import Limit from "components/Limit";
import Generating from "components/Generating";
import { addUser } from "../../Redux/features/UserSlice";
import Title from 'components/Financial/title';
const { Text } = Typography;

const FundingPlan = () => {

  const [oldprompt, setOldPrompt] = useState([]);
  const [flag, setFlag] = useState(false);
  const [generateFlag, setGenerateFlag] = useState(false);

  const [renderData, setRenderData] = useState(null);
  const [headerData, setHeaderData] = useState(null);
  const [content, setContent] = useState(null);
  const [paid, setPaid] = useState();

  const dispatch = useDispatch();
  const { email, curID, companies, role, prompt } = useSelector(
    (state) => state.userSlice
  );
  const user = useSelector((state) => {
    return state.userSlice;
  });
  const pageContents = useSelector((state) => {
    return state.pagesSlice;
  });

  const category = 'Funding Plan'
  const auth = role


  useEffect(() => {
    axios.post(URL + "/api/paid/get", { data: "Funding Plan" })
      .then(res => {
        console.log("res", res.data.prompt);
        if (res.data.prompt == 'paid')
          setPaid(true)
        else setPaid(false)
      })
      .catch(err => console.log("err", err))
  }, [])

  const handleChangeCheck = () => {
    if (paid == true)
      setPaid(false)
    else setPaid(true)

    axios.post(URL + "/api/paid/add", { data: "Funding Plan" })
      .then(res => {
        console.log("paid has changed", res)
      })
      .catch(err => console.log("failed to change the paid method", err))
  }

  useEffect(() => {
    setFlag(false);
    dispatch(setRegenerate(false))
    if (email == "") window.location.href = "/sign-in";
  }, []);

  useEffect(() => {
    const currentPrompt = prompt.filter((item) => item.title == category);
    setOldPrompt(currentPrompt.prompt);
  }, []);

  const savePrompt = (newPrompt) => {
    console.log("saveNewPrompt", newPrompt);
  };

  const generateContent = (newPrompt) => {
    setFlag(true);
    setGenerateFlag(true);
    let formatData;
    Object.keys(formats).map((key) => {
      if (category == formats[key].Title) {
        console.log(formats[key].Title, formats[key], formats[key]);
        formatData = { title: formats[key].Title, data: formats[key] };
      }
    });

    genEachContent(user.companies[0], newPrompt, formatData.data, user.gpt.modelName)
      .then((res) => {
        if (Array.isArray(pageContents.contents)) {
          const newContents = pageContents.contents.map((item, idx) => {
            if (item.Title === category) {
              return res;
            }
            return item;
          });
          let temp = newContents;
          dispatch(addContent({ content: temp }));
          dispatch(updateHtmlContent({ itemTitle: category, newhtmlContent: {} }));
          dispatch(setRegenerate(false))
          setFlag(false);
          axios.post(URL+"/api/users/result/save", { email: user.email, companyName: user.companies[0].companyName, content: newContents })
            .then(res => {
              console.log("---------------",res)
              dispatch(addUser({
                email: user.email,
                name: 'StartupSphere',
                companies: res.data,
                curID: user.curID,
                role: user.role,
                prompt: user.prompt,
                upgrade:user.upgrade,
                gpt:user.gpt
              }))
              console.log('saved new content')
            }).catch(err => console.log(err))
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    let middleData = [];

    if (Array.isArray(pageContents.contents)) {
      middleData = pageContents.contents.filter((item) => {
        return item.Title === category;
      });

    } else if (Array.isArray(pageContents.contents.contents)) {
      middleData = pageContents.contents.contents.filter((item) => {
        return item.Title === category;
      });
    }
    console.log("middledata", middleData);
    setRenderData(middleData);
  }, [pageContents]);

  useEffect(() => {
    let htmlContent = null;
    companies[0].htmlContent.forEach((item) => {
      if (item.Title === category) {
        htmlContent = item.htmlcontent;
      }
    });
    if (htmlContent != null && !generateFlag) {
      setHeaderData(htmlContent.headerData);
      setContent(htmlContent.content);
    } else if (renderData != null && renderData[0]) {
      const result = `<h5>${renderData[0]?.["Summary"]}</h5>`;
      setHeaderData(result);
      let temp = "";
      for (let i = 0; i < renderData[0]?.["Content"].length; i++) {
        temp += `<h6>${renderData[0]?.["Content"]?.[i]?.["Title"]
          } </h6>` + `<h5>${renderData[0]?.["Content"]?.[i]?.["Summary"]}</h5><ol>`;

        for (let j = 0; j < renderData[0]?.["Content"]?.[i]?.["Content"].length; j++) {
          temp += `<li><b>${renderData[0]?.["Content"]?.[i]?.["Content"]?.[j]?.["SubTitle"]
            }</b> :` + `${renderData[0]?.["Content"]?.[i]?.["Content"]?.[j]?.["Description"]} </li>`;
        }
        temp += `<h5>${renderData[0]?.["Content"]?.[i]?.["Conclusion"]}</h5></ol>`;
      }
      temp += `<h5>${renderData[0]?.["Conclusion"]}</h5>`
      setContent(temp);
    }
  }, [renderData]);

  const handleChange = (html, clippedTxt, id) => {
    const data = {
      headerData: headerData,
      content: content,
    };
    data[id] = html;
    if (id == "headerData") {

      setHeaderData(html);
    }
    if (id == "content") {
      setContent(html);
    }
  };

  useEffect(() => {
    if (content != null || headerData != null) {
      const data = {
        headerData: headerData,
        content: content,
      }
      // console.log("content----",content)
      const sendData = { email: user.email, company: user.companies[0].companyName, title: category, sendData: data }
      axios.post(URL+"/api/users/changeHtml", {
        data: sendData
      }).then(res => {
        console.log('updated and saved')
        // console.log(sendData)
        dispatch(updateHtmlContent({ itemTitle: category, newhtmlContent: data }));
      }).catch(err => console.log('saving updated content'))
    }
  }, [content, headerData]);

  const chageHtml = (html, id) => {
    if (id == "headerData") setHeaderData(html);
    if (id == "content") setContent(html);
  };

  useEffect(() => {
    if (user.regenerateFlag == true) {
      let currentPrompt = ''
      console.log("regnerating")
      user.prompt.map(item => {
        if (item.title == category) {
          currentPrompt = item.prompt
        }
      })
      generateContent(currentPrompt)
    } else {
      setFlag(false)
    }
  }, [user.regenerateFlag]);

  return (
    <div className="layout-content mx-auto max-w-4xl">
      {(user.upgrade == "unpaid" && auth != "admin") && <Col xs={24} md={24} lg={24}><Limit /></Col>}

       <div className='flex w-full items-center justify-between'>
       <Title title={user.companies[0].content.find((item) => item.title === "Financials").list.find((item) => item.origin==="Funding Plan").shown} />
        {auth=='admin'&&<Switch className="bg-[#3367D6]" checkedChildren="FREE" unCheckedChildren="PAID" checked={paid} onChange={handleChangeCheck} />}
      </div>
      <Row className="mx-auto max-w-4xl p-3 sm:p-6 pt-0 sm:pt-0 flex items-center ">

        {auth == "admin" && (
          <Admin
            currentPrompt={oldprompt}
            category={category}
            flag={flag}
            generateContent={generateContent}
            savePrompt={savePrompt}
          ></Admin>
        )}
        {((headerData != '' && user.upgrade != "unpaid") || (headerData != '' && auth == 'admin') || (headerData != '' && paid)) && <Row>
          <Edit
            content={headerData}
            id={"headerData"}
            changeContent={handleChange}
            changeHtml={chageHtml}
          ></Edit>
          <Edit
            content={content}
            id={"content"}
            changeContent={handleChange}
            changeHtml={chageHtml}
          ></Edit>
        </Row>}
        {headerData == ''&& <Generating></Generating>}
        {
                    auth != "admin" && user.upgrade == "unpaid" && !paid && <Upgrade></Upgrade>
                }
      </Row>
    </div>
  );
};

export default FundingPlan;
