
import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Signup from "pages/signUp";
import SignIn from "pages/signIn";
import { Main } from "components/Layout";
import Home from "pages/Home";
import Chat from "pages/Chat";
import CreateContent from "pages/CreateContent";
import Content from "pages/ExistingContent";
import Mission from "pages/overview/Mission";
import Viability from 'pages/overview/Viability';
import Business from "pages/overview/Business";
import Swot from "pages/overview/Swot";
import Team from "pages/operations/Team&Roles";
import OperationsPlan from "pages/operations/OperationsPlan";
import Regulatory from "pages/operations/Regulatory";
import RiskAnalysis from "pages/operations/RiskAnalysis";

import PreLaunch from "pages/implementation/PreLaunch";
import PostLaunch from "pages/implementation/PostLaunch";
import FiveYear from "pages/implementation/FiveYear";

import Admin from "pages/admin/admin";
import Overview from "pages/admin/overview";
import LandingPage from "pages/LandingPage";

import Secondary from "pages/products/SecondaryOfferings";
import CustomerService from "pages/products/CustomerService";
import CompetitorAnalysis from "pages/market/CompetitorAnalysis";
import OnlinePresence from "pages/sales/OnlinePresence";
import ForgotPassword from "pages/ForgotPassword";
import About from "pages/About";
import Account from "pages/Account";
import CoreOfferings from "pages/products/CoreOfferings";
import ExpansionOpportunities from "pages/products/ExpansionOpportunities";
import IndustryOverview from "pages/market/IndustryOverview";
import MarketSizeTrend from "pages/market/MarketSizeTrends";
import TargetAudience from "pages/market/TargetAudience";
import BrandingIdentity from "pages/sales/BrandingIdentity";
import MarketingIdeas from "pages/sales/MarketingIdeas";
import CustomerRetention from "pages/sales/CustomerRetention";
import Revenue from "pages/financials/Revenue";
import Expenses from "pages/financials/Expenses";
import Financing from "pages/financials/Financing";
import Dividends from "pages/financials/Dividens";
import Taxes from "pages/financials/Taxes";
import ProfitLoss from "pages/financials/ProfitLoss";
import BalanceSheet from "pages/financials/BalanceSheet";
import CostBreakdown from "pages/financials/CostBreakdown";
import FundingPlan from "pages/financials/FundingPlan";
import AuthProvider from "pages/AuthProvider";
import NewComponent from "utils/newComponent";
import PdfView from "pages/pdfView";
import Pricing from "pages/Pricing";

export default function App() {



  return (
    <AuthProvider>
      <Routes>
        <Route path="/sign-up" element={<Signup />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="/about" element={<About />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/blog" element={<About />} />
        <Route path="/" element={<LandingPage />} />
        <Route path="/" element={<Navigate to="/dashboard" />} />
        {/* <Route path="/" element={<Navigate to="/dashboard" />} /> */}
        <Route path="/" element={<Main />}>
          <Route path="/new/:id" element={<NewComponent />}></Route>
          <Route path="/pdfs" element={<PdfView />} />
          <Route path="operations">
            <Route path="team-roles" element={<Team />} />
            <Route path="plan" element={<OperationsPlan />} />
            <Route path="regulatory-compliance" element={<Regulatory />} />
            <Route path="risk-analysis" element={<RiskAnalysis />} />
          </Route>
          <Route path="overview">
            <Route path="mission-vision" element={<Mission />} />
            <Route path="viability-analysis" element={<Viability />} />
            <Route path="business-models" element={<Business />} />
            <Route path="swot-analysis" element={<Swot />} />
          </Route>

          <Route path="products-services">
            <Route path="secondary-offering" element={<Secondary />} />
            <Route path="customer-service" element={<CustomerService />} />
            <Route path="core-offering" element={<CoreOfferings />} />
            <Route path="expansion-opportunities" element={<ExpansionOpportunities />} />

          </Route>

          <Route path="market-research">

            <Route path="competitor-analysis" element={<CompetitorAnalysis />} />
            <Route path="industry-overview" element={<IndustryOverview />} />
            <Route path="target-audience" element={<TargetAudience />} />
            <Route path="market-size" element={<MarketSizeTrend />} />

          </Route>

          <Route path="sales-marketing">

            <Route path="online-presence" element={<OnlinePresence />} />
            <Route path="branding-identity" element={<BrandingIdentity />} />
            <Route path="marketing-ideas" element={<MarketingIdeas />} />
            <Route path="customer-retention" element={<CustomerRetention />} />

          </Route>
          <Route path="financials">
            <Route path="revenue" element={<Revenue />} />
            <Route path="expenses" element={<Expenses />} />

            <Route path="financing" element={<Financing />} />
            <Route path="dividends" element={<Dividends />} />

            <Route path="taxes" element={<Taxes />} />
            <Route path="profit-loss" element={<ProfitLoss />} />
            <Route path="balance-sheet" element={<BalanceSheet />} />
            <Route path="costs-breakdown" element={<CostBreakdown />} />


            <Route path="funding-plan" element={<FundingPlan />} />
            <Route path="funding-plan" element={<FundingPlan />} />
          </Route>

          <Route
            path="implementation-plan/pre-launch"
            element={<PreLaunch />}
          />
          <Route
            path="implementation-plan/post-launch"
            element={<PostLaunch />}
          />
          <Route
            path="implementation-plan/five-year-plan"
            element={<FiveYear />}
          />

          <Route path="/dashboard" element={<Home />} />
          <Route path="chat" element={<Chat />} />
          <Route path="createContent" element={<CreateContent />} />
          <Route path="content" element={<Content />} />
          <Route path="account" element={<Account />} />
          <Route path="account/:status" element={<Account />} />

          {/* <Route path="profile" element={<Profile />} /> */}

          <Route path="admin" element={<Admin />} />
          <Route path="admin/overview" element={<Overview />} />

        </Route>
      </Routes>
    </AuthProvider>
  );
}
