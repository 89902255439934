import {
    Input,
    Row,
    Col,
    Tabs,
    Card,
    Button,
    Drawer
} from "antd";
import {
    NotificationOutlined,
    CopyOutlined,
    CloseOutlined,
    ArrowRightOutlined
} from '@ant-design/icons';
import React, { useState, useRef } from "react";

const { TextArea } = Input;

export default function CreateContent() {

    const tabNames = ['All', 'Blog', 'Website', 'E-Commerce', 'Business', 'Social', 'Email', 'Ads', 'SEO', 'Sales', 'Customer Support', 'Video']
    const tabDatas = {
        All: [
            { title: 'Blank Page', content: 'Start with blank page', key: 'all_blankpage' },
            { title: 'Blog Post Ideas', content: 'Get a list of blog post ideas', key: 'all_blankpage' },
            { title: 'Blog Post', content: 'Write a blog post', key: 'all_blankpage' },
            { title: 'How-To Guide', content: 'Write a how-to guide style blog post', key: 'all_blankpage' },
            { title: 'Listicle', content: 'Write a listicle style blog post', key: 'all_blankpage' },
            { title: 'Interview', content: 'Write an interview style blog post', key: 'all_blankpage' },
            { title: 'Product Review', content: 'Write a product review style blog post', key: 'all_blankpage' },
            { title: 'Opinion Piece', content: 'Write an opinion piece style blog post', key: 'all_blankpage' },
            { title: 'Industry News Update', content: 'Write a new update style blog post', key: 'all_blankpage' },
            { title: 'Landing Page', content: 'Write a full landing page', key: 'all_blankpage' },
        ],

        Blog: [
            { title: 'Blog Post Ideas', content: 'Get a list of blog post ideas', key: 'all_blankpage' },
            { title: 'Blog Post', content: 'Write a blog post', key: 'all_blankpage' },
            { title: 'How-to Guide', content: 'Write a how-to guide style blog post', key: 'all_blankpage' },
            { title: 'Listicle', content: 'Write a listicle style blog post', key: 'all_blankpage' },
            { title: 'Interview', content: 'Write an interview style blog post', key: 'all_blankpage' },
            { title: 'Product Review', content: 'Write a product review style blog post', key: 'all_blankpage' },
            { title: 'Opinion Piece', content: 'Write an opinion piece style blog post', key: 'all_blankpage' },
            { title: 'Industry News Update', content: 'Write a new update style blog post', key: 'all_blankpage' },
        ],

        Website: [
            { title: 'Landing Page', content: 'Write a full landing page', key: 'all_blankpage' },
            { title: 'About Page', content: 'Write an about-us page', key: 'all_blankpage' },
            { title: 'Services Section', content: 'Write a services section', key: 'all_blankpage' },
            { title: 'FAQs', content: 'Write a FAQs section', key: 'all_blankpage' },
            { title: 'Testimonials', content: 'Write a testimonials section', key: 'all_blankpage' },
        ],

        'E-Commerce': [
            { title: 'Product Description', content: 'Write a product description', key: 'all_blankpage' },
            { title: 'Category Description', content: 'Write a category description', key: 'all_blankpage' },
            { title: 'Buyer Guide', content: 'Write a buyer guide', key: 'all_blankpage' },
        ],

        Business: [
            { title: 'Company Description', content: 'Write a company description', key: 'all_blankpage' },
            { title: 'Mission and Vision', content: 'Write a mission and vision statement', key: 'all_blankpage' },
            { title: 'Job Description', content: 'Write a job description', key: 'all_blankpage' },
            { title: 'Hiring Ad', content: 'Write a hiring ad', key: 'all_blankpage' },
            { title: 'Rejection Letter', content: 'Write a rejection letter', key: 'all_blankpage' },
        ],

        Social: [
            { title: 'Social Media Post', content: 'Write a social media post', key: 'all_blankpage' },
            { title: 'Engaging Question', content: 'Write an engaging question', key: 'all_blankpage' },
            { title: 'Product Post', content: 'Write a product post', key: 'all_blankpage' },
            { title: 'Event Announcement', content: 'Write an event announcement', key: 'all_blankpage' },
            { title: 'Contests/Giveaway', content: 'Write a contest/giveaway post', key: 'all_blankpage' },
            { title: 'Polls/Question', content: 'Write a poll/question post', key: 'all_blankpage' },
            { title: 'Inspirational/Motivational Quote', content: 'Write an inspirational/motivational quote', key: 'all_blankpage' },
            { title: 'Customer Testimonials & Review', content: 'Write a customer testimonials & review', key: 'all_blankpage' },
        ],

        Email: [
            { title: 'Cold Email', content: 'Write a cold email', key: 'all_blankpage' },
            { title: 'Marketing Email', content: 'Write a marketing email', key: 'all_blankpage' },
            { title: 'Welcome Email', content: 'Write a welcome email', key: 'all_blankpage' },
            { title: 'Promotional/Discount Offer', content: 'Write a promotional/discount offer', key: 'all_blankpage' },
            { title: 'Newsletter', content: 'Write a newsletter', key: 'all_blankpage' },
            { title: 'Cart Abandonment Reminder', content: 'Write a cart abandonment reminder', key: 'all_blankpage' },
            { title: 'Product Launch Announcement', content: 'Write a product launch announcement', key: 'all_blankpage' },
            { title: 'Survey/Feedback Request', content: 'Write a survey/feedback request', key: 'all_blankpage' },
        ],

        Ads: [
            { title: 'Advertisement', content: 'Write an advertisement', key: 'all_blankpage' },
            { title: 'Google Ad', content: 'Write a google ad', key: 'all_blankpage' },
            { title: 'Facebook/Instagram Ad', content: 'Write a facebook/instagram ad', key: 'all_blankpage' },
            { title: 'Banner Ad', content: 'Write a banner ad', key: 'all_blankpage' },
            { title: 'Sponsored Content', content: 'Write a piece of sponsored content', key: 'all_blankpage' },
            { title: 'Video Script Ad', content: 'Write a video script ad', key: 'all_blankpage' },
        ],

        SEO: [
            { title: 'Meta Description', content: 'Write a meta description', key: 'all_blankpage' },
            { title: 'Title Tag', content: 'Write a title tag', key: 'all_blankpage' },
        ],

        Sales: [
            { title: 'Sales Letter', content: 'Write a sales letter', key: 'all_blankpage' },
            { title: 'Proposal', content: 'Write a proposal', key: 'all_blankpage' },
            { title: 'Elevator Pitch', content: 'Write an elevator pitch', key: 'all_blankpage' },
            { title: 'Presentation Script', content: 'Write a presentation script', key: 'all_blankpage' },
        ],

        'Customer Support': [
            { title: 'Response Templates', content: 'Write a response template', key: 'all_blankpage' },
            { title: 'Trouble Ticket Responses', content: 'Write a trouble ticket response', key: 'all_blankpage' },
            { title: 'Feedback Responses', content: 'Write a feedback response', key: 'all_blankpage' },
        ],

        Video: [
            { title: 'Explainer', content: 'Write an explainer video script', key: 'all_blankpage' },
            { title: 'Product Demo', content: 'Write a product demo video script', key: 'all_blankpage' },
            { title: 'Tutorial', content: 'Write a tutorial video script', key: 'all_blankpage' },
            { title: 'Brand Story', content: 'Write a brand story video script', key: 'all_blankpage' },
        ]
    }

    const [detailData, setDetailData] = useState(null)
    const [openDrawer, setOpenDrawer] = useState(false)

    const handleContentAI = (data) => {
        setDetailData(data)
        setOpenDrawer(true)
    }

    const onCloseDrawPanel = () => {
        setOpenDrawer(false)
    }

    return (
        <div>
            <div className="layout-content">
                <Row justify="start">
                    <Col span={24}>
                        <div className="flex items-center text-violet-700 mb-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" className="w-4 mr-2">
                                <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z"></path>
                                <path d="M5 3v4"></path><path d="M19 17v4"></path><path d="M3 5h4"></path><path d="M17 19h4"></path>
                            </svg>
                            <span className="">Instant Magic</span>
                        </div>
                    </Col>
                </Row>
            </div>
            <div>
                <h1 className="text-2xl font-bold">Create Content</h1>
            </div>
            <Tabs>
                {
                    tabNames.map((item, index) => {
                        const tabContent = tabDatas[item].map((card, index) => (<Col xs={12} sm={12} md={6} key={`${card.title}_${index}`}>
                            <Card bodyStyle={{ paddingBottom: 2, height: '150px', position: 'relative' }} className="w-full h-[150px]" hoverable={true} onClick={() => handleContentAI(card)}>
                                <h1 className="text-xl pb-2 font-bold">{card.title}</h1>
                                <p className="text-md">{card.content}</p>
                                <Button type="link" className="flex items-center absolute bottom-2" style={{padding: 0}}>
                                    <span className="mr-3">Go</span>
                                    <ArrowRightOutlined />
                                </Button>
                            </Card>
                        </Col>))
                        return (<Tabs.TabPane tab={item} key={`item-${item}-${index}`}>
                            <Row gutter={[22, 22]} key={`row-${item}-col`}>
                                {tabContent}
                            </Row>
                        </Tabs.TabPane>)
                    })
                }
            </Tabs>
            <Drawer
                placement="right"
                width={500}
                onClose={onCloseDrawPanel}
                closable={false}
                visible={openDrawer}
            >
                <div className="flex justify-end">
                    <Button type="text" onClick={onCloseDrawPanel}>
                        <CloseOutlined />
                    </Button>
                </div>
                {detailData && <div><h2 className="text-xl font-bold mb-1">{detailData.title}</h2></div>}
                {detailData && <div><h5 className="text-gray-500 mb-3">{detailData.content}</h5></div>}
                {detailData && <div>
                    <TextArea rows={4} placeholder="Add any additional detail" className="rounded-lg" />
                </div>}
                {detailData && <div className="flex">
                    <Button type="text" className="flex items-center">
                        <NotificationOutlined className="flex items-center" />
                        <span className="flex items-center">Tone of voice</span>
                    </Button>
                    <Button type="text" className="flex items-center">
                        <CopyOutlined className="flex items-center" />
                        <span className="flex items-center">Company Brief</span>
                    </Button>
                </div>}
                {detailData && <div className="mt-3">
                    <Button className="flex items-center px-4 text-purple-400">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.8" strokeLinecap="round" strokeLinejoin="round" className="w-4 mr-2">
                            <path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z"></path>
                            <path d="M5 3v4"></path><path d="M19 17v4"></path><path d="M3 5h4"></path><path d="M17 19h4"></path>
                        </svg>
                        <span className="text-lg px-3">Generate</span>
                    </Button>
                </div>}
            </Drawer>
        </div>
    );
}
