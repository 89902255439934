import React, { useState, useRef } from "react";
import {
  Row, Col
} from "antd";
import Edit from "utils/edit";
import {useParams} from 'react-router-dom'

export default function NewComponent() {

  const {id} = useParams();

  const handleChange = () => {
    console.log("here")
  }

  const chageHtml = () =>{
    console.log("here1")
  }

  return (
    <div>
      <Row>
        <Col xs={24} md={24} lg={24} className="text-center">
          <div className="text-center">
            <h1 className="text-4xl mt-5">{id}</h1>
          </div>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col xs={24} md={24} lg={24}>
          <Row className="bg-[white] w-1/2 ml-24">
            <div className="text-center">
              <Edit content={"Please edit this new page"} id={"newItem"} changeContent={handleChange} changeHtml={chageHtml} ></Edit>
            </div>
          </Row>
        </Col>
      </Row>
    </div>
  )
}