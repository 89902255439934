import React from 'react'

const PopContent = () => {
    return (
        <div className='w-64'>
            Assets that can be converted to cash within one year.
            Examples include cash, accounts receivable, and inventory.
        </div>
    )
}

export default PopContent