import React, { useState } from 'react'
import { Footer } from 'components/Layout'
import { Row, Col, Typography, Layout, Button } from 'antd'
import { useNavigate, Link } from "react-router-dom";
import { MenuFoldOutlined } from '@ant-design/icons'
import logo from '../assets/images/StartUp Sphere Logo-02.png'
const { Header } = Layout;

const headerStyle = {
    textAlign: 'center',
    color: 'black',
    height: 100,
    // paddingInline: 48,
    padding: 0,
    lineHeight: '64px',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
};

const About = () => {


    const [flag, setflag] = useState(false);

    const onChangeFlag = () => {
        if (flag == true) {
            setflag(false)
        }
        else {
            setflag(true)
        }
    }

    return (
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
            {/* <Header /> */}
            <Col sm={20} xs={20} lg={19} style={{}}>
                <Header style={headerStyle}>
                    <Link to="/" >
                        <img src={logo} alt='logo' className='h-7 w-auto my-auto sm:h-10' />
                    </Link>
                    <div className='hidden sm:block'>
                        <Link to="/" className='text-lg mr-8' type='text' style={{ color: 'black' }}>Home</Link>
                        {/* <Link to="/blog" className='text-lg mr-8' type='text' style={{ color: 'black' }}>Blog</Link> */}
                        <Link to="/about" className='text-lg' type='text' style={{ color: 'black' }}>About</Link>
                    </div>
                    <div className='flex items-center'>
                        <Link cla to="/sign-in" className="mr-8 text-lg text-black hidden lg:block">
                            Login
                        </Link>
                        <Link to="/dashboard" className="text-white text-lg">
                            <button className="gradient-button shadow-xl flex" style={{ fontFamily: 'cursive', paddingRight: 20, paddingLeft: 20, fontSize: 16, borderRadius: "20px", paddingTop: "5px", paddingBottom: "5px", boxShadow: '-3px 4px #B7B7B7' }}>
                                <span className='text-[12px] sm:text-[16px]'><b>Get started</b></span>
                            </button>
                        </Link>
                        <MenuFoldOutlined className='sm:hidden' onClick={onChangeFlag} />
                    </div>
                </Header>
            </Col>
            {
                !flag && <>
                    <div className='h-[500px] w-full flex items-center justify-center'>
                        <Col sm={24} xs={24} lg={9} className='p-4'>
                            <Typography.Title level={2}>
                                Start your business on the right foot.
                            </Typography.Title>
                            <p className='text-xl'>
                                We're on a mission to make it easy for anyone to start a business. With our AI-powered business plan generator, you can get a comprehensive business plan in minutes. Then, with tools like Chat and Content Creator, we're the secret weapon for thousands of entrepreneurs.
                            </p>
                        </Col>
                        <Col lg={9} />

                    </div>
                    <Col lg={18} className='w-full p-4'>
                        <div className='sm:flex justify-center mb-20'>
                            <Col sm={24} xs={24} lg={8}>
                                <Typography.Title level={2}>
                                    Get in touch
                                </Typography.Title>
                                <p className='text-lg'>
                                    We'd love to hear from you! Send us a message using the contact details here and we'll get back to you as soon as possible.
                                </p>
                            </Col>
                            <Col lg={1}></Col>
                            <Col sm={24} xs={24} lg={15}>
                                <div className='rounded-2xl bg-gray-50 p-10'>
                                    <h3 className='text-base font-semibold leading-7 text-gray-900'>
                                        Dawson Whitfield, Founder
                                    </h3>
                                    <div className='mt-3 space-y-1 text-md leading-6'>
                                        <div style={{ color: 'rgb(0, 184, 119)' }}>dawson@StartupSphere.ai</div>
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </Col>
                    <Col className='w-full p-4' lg={18}>
                        <div className='sm:flex justify-center sm:mb-20'>
                            <Col sm={24} xs={24} lg={8}>
                                <Typography.Title level={2}>
                                    Locations
                                </Typography.Title>
                                <p className='text-lg'>
                                    Located in downtown Toronto.
                                </p>
                            </Col>
                            <Col lg={1}></Col>
                            <Col sm={24} xs={24} lg={15}>
                                <div className='rounded-2xl bg-gray-50 p-10'>
                                    <h3 className='text-base font-semibold leading-7 text-gray-900'>
                                        Toronto
                                    </h3>
                                    <div className='mt-3 space-y-1 text-md leading-6'>
                                        <div>400-116 Spadina Avenue</div>
                                        <div>Toronto, ON M5V 2K6</div>
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </Col>
                    <Col className='w-full p-4' lg={18}>
                        <div className='px-6 py-10 sm:py-24 lg:px-8'>
                            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                <a href='/#' className='items-center justify-center rounded-md text-[15px] font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground active:bg-black/10 h-10 px-4 hidden sm:flex'>
                                    Home
                                </a>
                                <Link to='/pricing' className='items-center justify-center rounded-md text-[15px] font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground active:bg-black/10 h-10 px-4 hidden sm:flex'>
                                    Pricing
                                </Link>
                                <a href='/about' className='items-center justify-center rounded-md text-[15px] font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground active:bg-black/10 h-10 px-4 hidden sm:flex'>
                                    About
                                </a>
                                <a href='/#' className='items-center justify-center rounded-md text-[15px] font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground active:bg-black/10 h-10 px-4 hidden sm:flex'>
                                    Support
                                </a>
                                <a href='/#' className='items-center justify-center rounded-md text-[15px] font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground active:bg-black/10 h-10 px-4 hidden sm:flex'>
                                    Teams
                                </a>
                                <a href='/#' className='items-center justify-center rounded-md text-[15px] font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground active:bg-black/10 h-10 px-4 hidden sm:flex'>
                                    Privacy
                                </a>
                            </div>
                            <p className='mt-10 text-center text-xs leading-5 text-gray-500'>
                                © StartupSphere. All rights reserved.
                            </p>
                        </div>
                    </Col>
                </>}
            {
                flag &&
                <div className='mt-20 text-center'>
                    <div className='block'>
                        <Link to="/" className='text-2xl ' type='text' style={{ color: 'black' }}>Home</Link>
                    </div>
                    {/* <div className='block mt-4'>
                <Link to="/blog" className='text-2xl ' type='text' style={{ color: 'black' }}>Blog</Link>
              </div> */}
                    <div className='block mt-4'>
                        <Link to="/about" className='text-2xl' type='text' style={{ color: 'black' }}>About</Link>
                    </div>
                    <div className='block mt-4'>
                        <Link cla to="/sign-in" className=" text-2xl text-black">
                            Login
                        </Link>
                    </div>
                </div>
            }

        </Row>
    )
}

export default About