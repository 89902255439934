import React, { useState } from 'react'
import { Footer } from 'components/Layout'
import { Row, Col, Typography, Layout, Button } from 'antd'
import { useNavigate, Link } from "react-router-dom";
import { CheckOutlined } from '@ant-design/icons'
import { MenuFoldOutlined } from '@ant-design/icons'
import logo from '../assets/images/StartUp Sphere Logo-02.png'
const { Header } = Layout;

const headerStyle = {
    textAlign: 'center',
    color: 'black',
    height: 100,
    // paddingInline: 48,
    padding: 0,
    lineHeight: '64px',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
};

const Pricing = () => {

    const price = 19;

    return (
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
            {/* <Header /> */}
            <Col sm={20} xs={20} lg={19} style={{}}>
                <Header style={headerStyle}>
                    <Link to="/" >
                        <img src={logo} alt='logo' className='h-7 w-auto my-auto sm:h-10' />
                    </Link>
                    <div className='hidden sm:block'>
                        <Link to="/" className='text-lg mr-8' type='text' style={{ color: 'black' }}>Home</Link>
                        {/* <Link to="/blog" className='text-lg mr-8' type='text' style={{ color: 'black' }}>Blog</Link> */}
                        <Link to="/about" className='text-lg' type='text' style={{ color: 'black' }}>About</Link>
                    </div>
                    <div className='flex items-center'>
                        <Link cla to="/sign-in" className="mr-8 text-lg text-black hidden lg:block">
                            Login
                        </Link>
                        <button className="gradient-button shadow-xl flex " style={{ paddingRight: 20, paddingLeft: 20, fontSize: 16, borderRadius: "20px", paddingTop: "5px", paddingBottom: "5px", boxShadow: '-3px 4px #B7B7B7' }}>
                            <Link to="/dashboard" className="text-white text-lg">
                                Get started
                            </Link>
                        </button>
                        {/* <MenuFoldOutlined className='sm:hidden' onClick={onChangeFlag} /> */}
                    </div>
                </Header>
            </Col>
            <div className='flex flex-col justify-center'>
                <div className='my-[64px]'>
                    <h1 className='text-center text-2xl sm:text-4xl text-black'>Simple pricing</h1>
                    <h6 className='text-[lg] mt-[32px] text-center'>Simple pricing designed to return 10x the value of your investment.</h6>
                </div>
                <div className='flex items-center my-5'>
                    <div className='rounded-2xl border border-gray-300 p-[32px] w-[450px] shadow-2xl'>
                        <h6 className='text-green-500'>Pro</h6>
                        <h1 className='text-[44px] font-normal text-gray-900'>
                            ${price}
                            <span className='ml-3 text-[18px]'>/mo</span>
                        </h1>
                        <div className='text-[18px] my-5'>For entrepreneurs and small teams.</div>
                        <div className='mt-[42px]'>
                            <div className='flex mt-3'>
                                <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                                <p className='ml-3'>20 full business plans a month</p>
                            </div>
                            <div className='flex mt-3'>
                                <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                                <p className='ml-3'>Unlock full business plan</p>
                            </div>
                            <div className='flex mt-3'>
                                <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                                <p className='ml-3'>Financials & budgeting</p>
                            </div>
                            <div className='flex mt-3'>
                                <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                                <p className='ml-3'>PDF export</p>
                            </div>
                            <div className='flex mt-3'>
                                <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                                <p className='ml-3'>AI business plan editor</p>
                            </div>
                            <div className='flex mt-3'>
                                <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                                <p className='ml-3'>Chat AI consultant</p>
                            </div>
                            <div className='flex mt-3'>
                                <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                                <p className='ml-3'>Priority email support</p>
                            </div>
                            <div className='flex mt-3'>
                                <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                                <p className='ml-3'>Cancel anytime</p>
                            </div>
                        </div>
                        <div className='flex justify-center mt-5'>
                            <Link to={`/dashboard`}>
                                <Button className='upgradedEffect' type="primary" style={{ backgroundColor: "black", width: "100%", marginTop: "15px", padding: 10, height: 'auto', fontSize: 16, fontWeight: 'normal' }}>
                                    Get started
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <div className='rounded-r-2xl border-r-2 border-t-2 border-b-2 border-gray-300 p-[32px] w-[450px] shadow-xl'>
                        <h6 className='text-green-500'>Basic</h6>
                        <h1 className='text-[48px] font-normal text-gray-900'>
                            Free
                        </h1>
                        <div className='text-[18px] my-5'>Designed to give you a taste of StartupSphere.</div>
                        <div className='mt-[42px]'>
                            <div className='flex mt-3'>
                                <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                                <p className='ml-3'>Generate one business plan preview</p>
                            </div>
                            <div className='flex mt-3'>
                                <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                                <p className='ml-3'>3 Chat messages</p>
                            </div>
                            <div className='flex mt-3'>
                                <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                                <p className='ml-3'>10 content generations</p>
                            </div>
                        </div>
                        <div className='flex justify-center mt-5'>
                            <Link to={`/dashboard`}>
                                <Button className='upgradedEffect' type="primary" style={{ backgroundColor: "#fff", border: '1px solid #aaa', width: "100%", marginTop: "15px", padding: 7, height: 'auto', fontSize: 14, fontWeight: 'normal', color: '#333' }}>
                                    Get started
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='flex justify-center'>
                    {/* <Footer /> */}
                    <div className='px-6 py-10 sm:py-24 lg:px-8'>
                        <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                            <a href='/#' className='items-center mr-[128px] justify-center rounded-md text-[15px] font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground active:bg-black/10 h-10 px-4 hidden sm:flex'>
                                Home
                            </a>
                            <Link to='/pricing' className='items-center mr-[128px] justify-center rounded-md text-[15px] font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground active:bg-black/10 h-10 px-4 hidden sm:flex'>
                                Pricing
                            </Link>
                            <a href='/about' className='items-center justify-center rounded-md text-[15px] font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground active:bg-black/10 h-10 px-4 hidden sm:flex'>
                                About
                            </a>
                            {/* <a href='/#' className='items-center justify-center rounded-md text-[15px] font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground active:bg-black/10 h-10 px-4 hidden sm:flex'>
                                            Support
                                        </a>
                                        <a href='/#' className='items-center justify-center rounded-md text-[15px] font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground active:bg-black/10 h-10 px-4 hidden sm:flex'>
                                            Teams
                                        </a>
                                        <a href='/#' className='items-center justify-center rounded-md text-[15px] font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground active:bg-black/10 h-10 px-4 hidden sm:flex'>
                                            Privacy
                                        </a> */}
                        </div>
                        <p className='mt-10 text-center text-xs leading-5 text-gray-500'>
                            © StartupSphere. All rights reserved.
                        </p>
                    </div>
                </div>
            </div>

        </Row>
    )
}

export default Pricing