import React, { useEffect, useState } from "react";
import business_title_img from "../../assets/images/pdfs/business_title.png";
import swot_1 from "../../assets/images/pdfs/swot_1.png";
import swot_2 from "../../assets/images/pdfs/swot_2.png";
import swot_3 from "../../assets/images/pdfs/swot_3.png";
import swot_4 from "../../assets/images/pdfs/swot_4.png";
import graph from "../../assets/images/pdfs/visibility_graph.png";
import { Descriptions } from "antd";

export default function Visibility(props) {
  const [data, setData] = useState(null);

  const colors = [
    { bg: "#f1f7ff", t_color: "rgb(59, 130, 246)", icon: swot_1 },
    { bg: "#fffbee", t_color: "rgb(234, 179, 8)", icon: swot_2 },
    { bg: "#ebfff2", t_color: "rgb(34, 197, 94)", icon: swot_3 },
    { bg: "#fff0f0", t_color: "rgb(239, 68, 68)", icon: swot_4 },
  ];

  useEffect(() => {
    if (props.user) {
      // console.log('Viability analysis -> user: ', props.user);
      // setData(
      //   props.user.companies[0].htmlContent.find(
      //     (item) => item.Title === "Viability analysis"
      //   )
      // );
      setData(props.user.companies[0].jsonContent.find(item => item.Title === "Viability analysis"));
    }
  });

  return (
    <div style={{fontFamily:'sans-serif',display:'none'}}>
    <div style={{fontFamily:'sans-serif', width: `400px`, padding: "10px 20px", marginLeft: 20 }} id="visibility">
      <div style={{fontFamily:'sans-serif', display: "flex", alignItems: "center" }}>
        <div
          style={{fontFamily:'sans-serif',
            width: 8,
            height: 8,
            marginRight: 8,
            marginTop: 15,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 3,
            color: "#26B35A",
          }}
        >
          <div style={{fontFamily:'sans-serif', width: 8 }}>
            <img src={business_title_img} />
          </div>
        </div>
        <div style={{fontFamily:'sans-serif', fontSize: 12, color: "#26B35A" }}>Overview</div>
      </div>
      <div style={{fontFamily:'sans-serif', textWrap: "wrap" }}>
        <div style={{fontFamily:'sans-serif', fontSize: 16, margin: "5px 0", fontWeight: "bold" }}>
          Viability Analysis
        </div>
        <div
          style={{fontFamily:'sans-serif',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <div style={{fontFamily:'sans-serif', display: "flex", width: 200, minWidth: 180 }}>
            <img src={graph} width={180} height={90} />
            <span style={{fontFamily:'sans-serif', fontWeight: "bold", fontSize: 26, marginTop: 8 }}>
              85/100
            </span>
          </div>
        </div>
        <div style={{fontFamily:'sans-serif', marginTop: 10 }}>
          <div
          style={{fontFamily:'sans-serif',fontSize:8, fontWeight:"normal"}}
          >
            {data?.Content[0]?.Description}
          </div>
          <div
          style={{fontFamily:'sans-serif',fontSize:8, fontWeight:"normal", marginTop:10, marginBottom:10}}
          >
             {data?.Content[1]?.Description?.map((subitem, idx) => (
                                <div style={{fontFamily:'sans-serif',fontSize: 8}}>{subitem.item + " : " + subitem.Description }</div>
                            ))}
          </div>
          <div
          style={{fontFamily:'sans-serif',fontSize:8, fontWeight:"normal"}}
          >
            {data?.Content[2]?.Description}
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}
