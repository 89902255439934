import React, { useState } from 'react'
import { EditOutlined } from '@ant-design/icons'
import { Drawer } from 'antd'
import { Vertical } from './VerticalDnD'
import { connect, useSelector, useDispatch } from "react-redux";

const DragDrop = (props) => {
  const [drawer, setDrawer] = useState(false)
  const user = useSelector(state => {
    return state.userSlice
  })
  const openDrawer = () => {
    setDrawer(true)
  }
  const closeDrawer = () => {
    setDrawer(false)
  }

  return (
    <div className='flex items-center justify-between mb-1 pl-3'>
      {/* {user.role == "admin" ? */}
      <>
        <span className='font-semibold'>{props.title}</span>
        <div>
          <button className='inline-flex items-center justify-center font-medium h-auto text-sm py-1 px-1.5 rounded-md hover:bg-gray-100 active:bg-gray-200' onClick={openDrawer}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.3" stroke-linecap="round" stroke-linejoin="round" class="w-3 mr-1.5"><path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z"></path><path d="m15 5 4 4"></path></svg>
            <span>Edit</span>
          </button>
        </div>
        <Drawer
          title="Edit Outline"
          onClose={closeDrawer}
          open={drawer}
          width={400}
        >
          <Vertical onClose={closeDrawer} />
        </Drawer>
      </>
      {/* :
        <span className='font-semibold'>{props.title}</span>
      } */}
    </div>
  )
}

export default DragDrop