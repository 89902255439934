import {
    Menu,
    Row,
    Col,
    Input,
    Space,
    Button,
    Dropdown,
} from "antd";
import {
    PlusOutlined,
    ReloadOutlined,
    SendOutlined,
    BulbOutlined,
    EditOutlined,
    LoadingOutlined,
} from '@ant-design/icons';
import { useSelector } from "react-redux";
import React, { useState, useRef, useEffect } from "react";
import { OPENAI_API_KEY } from '../utils/const';
import axios from "axios";
import { URL } from '../utils/const'

const Chat = () => {

    const user = useSelector(state => {
        return state.userSlice
    })
    const [messageTemp, setMsessageTemp] = useState([]);
    const [message, setMessage] = useState([]);
    const [data, setData] = useState([]);
    const [questionData, setQuestionData] = useState([]);

    let generatingflag = false;

    useEffect(() => {
        if (message.length != 0) {
            document.getElementById('chatContent').scrollTop = document.getElementById('chatContent').scrollHeight
        }
    })
    useEffect(() => {
        if (messageTemp.length != 0) {
            reGenerate(inputValue)
        }
    }, [messageTemp])

    const [inputValue, setInputValue] = useState('');
    const [showInputArray, setShowInputArray] = useState([]);
    const [showQuestionInputArray, setShowQestionInputArray] = useState([]);
    const [editIndex1, setEditIndex1] = useState([null, null]);
    const [editedValue, setEditedValue] = useState('');
    const [isGenerating, setGenerating] = useState(false);
    const [title, setTitle] = useState("");

    async function getInitialData() {
        try {
            const response = await axios.post(URL + `/api/users/chatdata/getall`, {});
            setData(response.data.result.promptsData)
            setQuestionData(response.data.result.questions)
            setShowInputArray(Array(response.data.result.promptsData.length).fill(false))
            setShowQestionInputArray(Array(response.data.result.questions.length).fill(false))
        } catch (err) {
            console.log('error!')
        }
    }
    useEffect(() => {
        getInitialData()
    }, [])

    useEffect(() => {
        generatingflag = isGenerating
    }, [isGenerating])

    const handleChangePromptValue = (e, listItem) => {
        setInputValue(listItem);
    };

    const handleChangeTitleValue = (e, index) => {
        setTitle(e.target.value);
    };

    const handleChangeValue = (e) => {
        setEditedValue(e.target.value);
    };

    const handleChangeTitle = (index) => {
        const newShowInputArray = [...showInputArray];
        newShowInputArray[index] = false;
        setShowInputArray(newShowInputArray);
    };
    const handleQuestionAddTitle = (e, index) => {
        e.stopPropagation();
        let middleData = questionData;
        middleData.map((item, id) => {
            if (id == index) {
                item.list.push(title);
            }
        })
        setQuestionData(middleData);
        const newShowInputArray = [...showQuestionInputArray];
        newShowInputArray[index] = false;
        setShowQestionInputArray(newShowInputArray);
        setEditedValue('');
        setEditIndex1([null, null]);
        try {
            axios.post(URL + "/api/users/chatdata/update", { promptsData: data, questions: middleData }).then(res => {
                console.log(res)
            })
        } catch (err) {
            console.log(err)
        }
    };

    const handleAddTitle = (e, index) => {
        e.stopPropagation();
        let middleData = data;
        middleData.map((item, id) => {
            if (id == index) {
                item.list.push(title);
            }
        })
        setData(middleData);
        const newShowInputArray = [...showInputArray];
        newShowInputArray[index] = false;
        setShowInputArray(newShowInputArray);
        setEditedValue('');
        setEditIndex1([null, null]);
        try {

            axios.post(URL + "/api/users/chatdata/update", { promptsData: middleData, questions: questionData }).then(res => {
                console.log(res)
            })
        } catch (err) {
            console.log(err)
        }
    };

    const handleChangeItemFlag = (e, index, listItemIndex, listItem) => {
        e.stopPropagation();
        setEditedValue(listItem)
        setEditIndex1([index, listItemIndex]);
    };
    async function generateAnswer() {
        if (inputValue == "") {
            return
        }
        setMessage(message.concat({ question: inputValue, answer: '' }))
        setMsessageTemp(message.concat({ question: inputValue, answer: '' }))

    }
    const handleRegen = () => {
        if (isGenerating == true) {
            setGenerating(false)
        }
        let temp = message
        temp[message.length - 1].answer = ""
        setMessage(temp);
        const text = message[message.length - 1].question
        reGenerate(text)
    }

    function formatText(text) {
        // Regular expression pattern to identify bold text within "**"
        
        // const texts = text.split('. ');
        

        // const formattedTexts = texts.map(item => item.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>'));

        // const formattedParagraph = formattedTexts.join('. ');
        // return formattedParagraph
        return text
      }

    async function reGenerate(text) {
        setGenerating(true)
        let temp = message
        const userMessage = `Your company name is ${user.companies[0].companyName}, and here is the description about the company --- '${user.companies[0].companyDescription}, 
          this platform is for generating ideas and brainstorms for business guys.
          Here is the question: ${text}
          you will generate the respones for this question. And convert title and subtitle into <b> tag
          `
        try {
            const response = await fetch("https://api.openai.com/v1/chat/completions", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${OPENAI_API_KEY}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    model: user.gpt.modelName,
                    messages: [{ role: "user", content: userMessage }],
                    temperature: 0.7,
                    stream: true
                }),
            });

            const reader = response.body.getReader();
            let result = '';
            console.log("isGenerating", isGenerating)
            while (1) {
                const { done, value } = await reader.read();
                if (done) {
                    console.log("done")
                    setGenerating(false)
                    console.log(message)
                    setMessage(temp)
                    break;
                }

                let chunk = new TextDecoder('utf-8').decode(value);
                const lines = chunk.split("\n\n");
                const parsedLines = lines
                    .map((line) => line.replace(/data: /, "").trim())
                    .filter((line) => line !== "" && line !== "[DONE]")
                    .map((line) => JSON.parse(line));
                for (const parsedLine of parsedLines) {
                    const { choices } = parsedLine;
                    const { delta } = choices[0];
                    const { content } = delta;
                    if (content) {
                        result += formatText(content)
                        temp[message.length - 1].answer = result;
                        document.getElementById('last_answer').innerHTML = result
                        document.getElementById('chatContent').scrollTop = document.getElementById('chatContent').scrollHeight
                    }
                }
            }
        } catch (error) {
            setGenerating(false)
        }
    }

    const handleChangeItem = (e) => {
        e.stopPropagation();
        let middleData = data;
        middleData.map((item, index) => {
            if (index === editIndex1[0]) {
                item.list.map((subItem, subIndex) => {
                    if (subIndex === editIndex1[1]) {
                        return item.list[subIndex] = editedValue;
                    }
                });
            }
        });
        setData(middleData);
        setEditIndex1([null, null]);
        try {
            axios.post(URL + "/api/users/chatdata/update", { promptsData: middleData, questions: questionData }).then(res => {
                console.log(res)
            })
        } catch (err) {
            console.log(err)
        }
    };

    const handleQuestionChangeItem = (e) => {
        e.stopPropagation();
        let middleData = questionData;
        middleData.map((item, index) => {
            if (index === editIndex1[0]) {
                item.list.map((subItem, subIndex) => {
                    if (subIndex === editIndex1[1]) {
                        return item.list[subIndex] = editedValue;
                    }
                });
            }
        });
        setQuestionData(middleData);
        setEditIndex1([null, null]);
        try {

            axios.post(URL + "/api/users/chatdata/update", { promptsData: data, questions: middleData }).then(res => {
                console.log(res)
            })
        } catch (err) {
            console.log(err)
        }


    };

    const handleChangeFlagTitle = (e, index) => {
        e.stopPropagation();
        const newShowInputArray = [...showInputArray];
        newShowInputArray[index] = true;
        setShowInputArray(newShowInputArray);
    };

    const handleChangeQuestionFlagTitle = (e, index) => {
        e.stopPropagation();
        const newShowInputArray = [...showQuestionInputArray];
        newShowInputArray[index] = true;
        setShowQestionInputArray(newShowInputArray);
    };

    const questionMenuItems = questionData.map((item, index) => (
        <Menu.ItemGroup key={index} title={item.title}>
            {item.list.map((listItem, listItemIndex) => (
                <Menu.Item key={listItemIndex + listItem}>
                    {editIndex1[0] === index && editIndex1[1] === listItemIndex ? (
                        <div className="flex" style={{ display: 'flex', alignItems: 'center' }}>
                            <Input onFocus={(e) => e.stopPropagation()}
                                onClick={(e) => e.stopPropagation()} value={editedValue} onChange={handleChangeValue} />
                            <Button onClick={(e) => handleQuestionChangeItem(e)}>Change</Button>
                        </div>
                    ) : (
                        <div onClick={(e) => handleChangePromptValue(e, listItem)} className="flex justify-between">
                            <p >{listItem}</p>
                            {user.role == "admin" ?
                                <div>
                                    <EditOutlined onClick={(e) => handleChangeItemFlag(e, index, listItemIndex, listItem)} style={{ marginLeft: '5px', cursor: 'pointer' }} />
                                </div>
                                :
                                ""
                            }
                        </div>
                    )}
                </Menu.Item>
            ))}
            {showQuestionInputArray[index] ? (
                <Menu.Item key={`input-${index}`} style={{ textAlign: 'center', display: 'inline-flex' }}>
                    <div className="flex">
                        <Input
                            onChange={(e) => handleChangeTitleValue(e, index)}
                            onPressEnter={() => handleChangeTitle(index)}
                            onFocus={(e) => e.stopPropagation()}
                            onClick={(e) => e.stopPropagation()}
                        />
                        <Button onClick={(e) => { handleQuestionAddTitle(e, index) }}>Add</Button>
                    </div>
                </Menu.Item>
            ) : (
                <Menu.Item key={index} style={{ textAlign: 'center' }}>
                    {user.role == "admin" ?
                        <p onClick={(e) => handleChangeQuestionFlagTitle(e, index)}><PlusOutlined />Add</p>
                        :
                        ""
                    }

                </Menu.Item>
            )}
        </Menu.ItemGroup>
    ));


    const menuItems = data.map((item, index) => (
        <Menu.ItemGroup key={index} title={item.title}>
            {item.list.map((listItem, listItemIndex) => (
                <Menu.Item key={listItemIndex + listItem}>
                    {editIndex1[0] === index && editIndex1[1] === listItemIndex ? (
                        <div className="flex" style={{ display: 'flex', alignItems: 'center' }}>
                            <Input onFocus={(e) => e.stopPropagation()}
                                onClick={(e) => e.stopPropagation()} value={editedValue} onChange={handleChangeValue} />
                            <Button onClick={(e) => handleChangeItem(e)}>Change</Button>
                        </div>
                    ) : (
                        <div onClick={(e) => handleChangePromptValue(e, listItem)} className="flex justify-between">
                            <p >{listItem}</p>
                            {user.role == "admin" ?
                                <div>
                                    <EditOutlined onClick={(e) => handleChangeItemFlag(e, index, listItemIndex, listItem)} style={{ marginLeft: '5px', cursor: 'pointer' }} />
                                </div>
                                :
                                ""
                            }
                        </div>
                    )}
                </Menu.Item>
            ))}
            {showInputArray[index] ? (
                <Menu.Item key={`input-${index}`} style={{ textAlign: 'center', display: 'inline-flex' }}>
                    <div className="flex">
                        <Input
                            onChange={(e) => handleChangeTitleValue(e, index)}
                            onPressEnter={() => handleChangeTitle(index)}
                            onFocus={(e) => e.stopPropagation()}
                            onClick={(e) => e.stopPropagation()}
                        />
                        <Button onClick={(e) => handleAddTitle(e, index)}>Add</Button>
                    </div>
                </Menu.Item>
            ) : (
                <Menu.Item key={index} style={{ textAlign: 'center' }}>
                    {user.role == "admin" ?
                        <p onClick={(e) => handleChangeFlagTitle(e, index)}><PlusOutlined />Add</p>
                        :
                        ""
                    }

                </Menu.Item>
            )}
        </Menu.ItemGroup>
    ));

    const menu = (
        <Menu>{menuItems}</Menu>
    );
    const questionMenu = (
        <Menu>{questionMenuItems}</Menu>
    );

    return (
        <div className="relative h-[90vh]">
            <div className="layout-content">
                <Row justify="start" align="middle">
                    <Col span={12}>
                        <div className="flex" style={{ justifyContent: 'flex-start', alignItems: 'center' }}>
                            <Button onClick={() => { setMessage([]) }} type="text" style={{ marginLeft: 10 }}>
                                <PlusOutlined />
                                New Chat
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
            {message.length != 0 ?
                <div id="chatContent" className="text-[#333] overflow-y-auto text-md mt-5 font-mono h-[70%]">
                    {message.map((item, index) => {
                        return (<div>
                            <div class="whitespace-pre-wrap py-6 px-2 rounded-xl group bg-neutral-50"><div class="mx-auto w-full max-w-3xl flex relative"><div class="w-8 h-8 bg-white text-neutral-800 border-neutral-300 border rounded-md flex items-center justify-center flex-shrink-0 "><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-5"><path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg></div><div class="pl-4"><article class="prose whitespace-normal">
                                <div className="whitespace-pre-wrap">{item.question}</div></article></div></div>
                            </div>
                            {
                                index != message.length - 1 ?
                                    <div class="whitespace-pre-wrap py-6 px-2 rounded-xl group false"><div class="mx-auto w-full max-w-3xl flex relative"><div class="w-8 h-8 bg-neutral-700 text-white rounded-md flex items-center justify-center flex-shrink-0 "><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-5"><path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z"></path><path d="M5 3v4"></path><path d="M19 17v4"></path><path d="M3 5h4"></path><path d="M17 19h4"></path></svg></div><div class="pl-4"><button class="inline-flex items-center justify-center rounded-md text-[15px] font-normal ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground active:bg-black/10 absolute right-0 w-7 h-7 text-neutral-400 opacity-0 group-hover:opacity-100 transition-opacity" data-state="closed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4"><rect width="8" height="4" x="8" y="2" rx="1" ry="1"></rect><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path></svg></button><article class="prose whitespace-normal">
                                        <div className="whitespace-pre-wrap" dangerouslySetInnerHTML={{__html: item.answer}}></div></article></div></div>
                                    </div>
                                    :
                                    ""
                            }

                        </div>)
                    })
                    }

                    <div class="whitespace-pre-wrap py-6 px-2 rounded-xl group false"><div class="mx-auto w-full max-w-3xl flex relative"><div class="w-8 h-8 bg-neutral-700 text-white rounded-md flex items-center justify-center flex-shrink-0 "><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-5"><path d="m12 3-1.912 5.813a2 2 0 0 1-1.275 1.275L3 12l5.813 1.912a2 2 0 0 1 1.275 1.275L12 21l1.912-5.813a2 2 0 0 1 1.275-1.275L21 12l-5.813-1.912a2 2 0 0 1-1.275-1.275L12 3Z"></path><path d="M5 3v4"></path><path d="M19 17v4"></path><path d="M3 5h4"></path><path d="M17 19h4"></path></svg></div><div class="pl-4"><button class="inline-flex items-center justify-center rounded-md text-[15px] font-normal ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground active:bg-black/10 absolute right-0 w-7 h-7 text-neutral-400 opacity-0 group-hover:opacity-100 transition-opacity" data-state="closed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4"><rect width="8" height="4" x="8" y="2" rx="1" ry="1"></rect><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path></svg></button><article class="prose whitespace-normal">
                        <div id="last_answer" className="whitespace-pre-wrap" dangerouslySetInnerHTML={{__html: message[message.length - 1].answer}}></div></article></div></div>
                    </div>

                </div>

                :
                <div className="flex h-[60vh] items-center">
                    <div className="mt-[5%] w-full h-full flex items-center justify-center "><div class="flex text-center justify-center flex-col"><div class="w-12 h-12 rounded-md mx-auto text-blue-500 bg-blue-50 flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round" class="w-8 h-8 "><path d="M14 9a2 2 0 0 1-2 2H6l-4 4V4c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2v5Z"></path><path d="M18 9h2a2 2 0 0 1 2 2v11l-4-4h-6a2 2 0 0 1-2-2v-1"></path></svg></div><h3 class="text-primary w-full text-2xl text-foreground py-4 font-medium ">Startup Sphere</h3><p class="w-full max-w-lg text-muted-foreground">Your AI consultant, powered by ChatGPT. Ask it anything - from generating content to getting business advice. Start by sending a message or selecting an idea below.</p><div style={{ color: "blue" }} class="flex justify-center w-full max-w-lg text-primary space-x-8 pt-4"><div class="flex items-center" data-state="closed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 mr-2 text-primary"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg><span style={{ color: "blue" }} class="">Your company brief</span></div><div style={{ color: "blue" }} class="flex items-center" data-state="closed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 mr-2 text-primary"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg><span style={{ color: "blue" }} class="">Your tone of voice</span></div></div></div></div>
                </div>
            }

            <div className="absolute bottom-1   w-full">
                <div className="flex justify-center mb-3 ">
                    {
                        message.length != 0 ?
                            <Button onClick={handleRegen} disabled={isGenerating} className="flex items-center bg-white">
                                {isGenerating != true ? <div><ReloadOutlined />Regenerate response</div> : <div><LoadingOutlined />Generating...</div>}
                            </Button>
                            :
                            ""
                    }

                </div>

                <div className="w-full">
                    <div className="flex mr-[15%] ml-[15%] md:mr-[25%] md:ml-[25%]">
                        <Input.TextArea
                            placeholder="Type a message..."
                            size="middle"
                            value={inputValue}
                            className="rounded-lg"
                            onChange={(e) => { setInputValue(e.target.value) }}
                        />
                    </div>

                    <div className="flex w-full p-2 items-center overflow-x-auto chatmenu justify-center"  >
                        <Dropdown overlay={menu} trigger={['click']} placement="top" className="promptsMenu" overlayStyle={{ maxHeight: '50vh' }}>
                            <Button type="text">
                                <Space align="center">
                                    <BulbOutlined className="flex items-center" />
                                    <span className="flex items-center text-md">Prompts</span>
                                </Space>
                            </Button>
                        </Dropdown>
                        <Dropdown overlay={questionMenu} trigger={['click']} placement="top" className="ml-4" overlayStyle={{ maxHeight: '50vh' }}>
                            <Button type="text">
                                <Space align="center">
                                    <BulbOutlined className="flex items-center" />
                                    <span className="flex items-center text-md">Questions</span>
                                </Space>
                            </Button>
                        </Dropdown>
                        <Button onClick={generateAnswer} type="text" className="ml-4 flex items-center justify-center text-center">
                            <SendOutlined />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Chat