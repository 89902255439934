import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Col,
  Row,
  Switch
} from "antd";
import {
  ProjectOutlined
} from "@ant-design/icons";

import Edit from "utils/edit";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { URL } from "utils/const";
import Admin from "utils/admin";
import *as formats from '../../utils/configure'
import { genEachContent } from "../../utils/service";
import { updateHtmlContent } from '../../Redux/features/UserSlice';
import { addContent } from "../../Redux/features/pagesSlice";
import { setRegenerate } from "../../Redux/features/UserSlice";
import Limit from "components/Limit";
import Upgrade from "components/Upgrade";
import Generating from "components/Generating";

const Regulatory = () => {

  const dispatch = useDispatch();

  const { email, curID, companies, role, prompt } = useSelector(state => state.userSlice)

  const user = useSelector(state => {
    return state.userSlice
  })

  const pageContents = useSelector(state => {
    return state.pagesSlice
  })

  const category = "Regulatory Compliance"
  const page_title = user.companies[0].content.find((item) => item.title === "Operations").list.find((item) => item.origin===category).shown

  const auth = role

  const [oldprompt, setOldPrompt] = useState([]);
  const [flag, setFlag] = useState(false);
  const [generateFlag, setGenerateFlag] = useState(false);
  const [description, setDescription] = useState('')
  const [Plan, setPlan] = useState('')
  const [Infrastructure, setInfrastructure] = useState('')
  const [Creation, setCreation] = useState('')
  const [Customer, setCustomer] = useState('')
  const [Revenue, setRevenue] = useState('')
  const [Conclusion, setConclusion] = useState('')
  const [renderData, setRenderData] = useState(null);
  const [paid, setPaid] = useState();

  useEffect(() => {
    setFlag(false);
    dispatch(setRegenerate(false))
    if (email == '')
      window.location.href = '/sign-in';
  }, []);

  useEffect(() => {
    axios.post(URL + "/api/paid/get", { data: "Regulatory Compliance" })
        .then(res => {
            console.log("res", res.data.prompt);
            if (res.data.prompt == 'paid')
                setPaid(true)
            else setPaid(false)
        })
        .catch(err => console.log("err", err))
}, [])

const handleChangeCheck = () => {
    if (paid == true)
        setPaid(false)
    else setPaid(true)

    axios.post(URL + "/api/paid/add", { data: "Regulatory Compliance" })
    .then(res=>{
        console.log("paid has changed", res)
    })
    .catch(err=>console.log("failed to change the paid method", err))
}

  /////////////////////////////////////////////////////////////////


  useEffect(() => {
    const currentPrompt = prompt.filter((item) => item.title == category)
    setOldPrompt(currentPrompt.prompt)
  }, [])

  const savePrompt = (newPrompt) => {
    console.log("saveNewPrompt", newPrompt)
  }

  const generateContent = (newPrompt) => {
    setFlag(true);
    setGenerateFlag(true)
    let formatData;
    Object.keys(formats).map(key => {
      if (category == formats[key].Title) {
        console.log(formats[key].Title, formats[key], formats[key])
        formatData = { title: formats[key].Title, data: formats[key] }
      }
    })

    genEachContent(user.companies[0], newPrompt, formatData.data,user.gpt.modelName)
      .then((res) => {
        if (Array.isArray(pageContents.contents)) {
          const newContents = pageContents.contents.map((item, idx) => {
            if (item.Title === category) {
              return res
            }
            return item;
          });
          let temp = newContents;
          dispatch(addContent({ content: temp }));
          dispatch(setRegenerate(false))
          setFlag(false);
          axios.post(URL+"/api/users/result/save", { email: user.email, companyName: user.companies[0].companyName, content: newContents })
            .then(res => {
              console.log('saved new content')
            }).catch(err => console.log(err))
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }


  //////////////////////////////////////////////////////////////////

  const handleChange = (html, clippedTxt, id) => {

    if (id == "Plan")
      setPlan(html)
    if (id == "Infrastructure")
      setInfrastructure(html)
    if (id == "Creation")
      setCreation(html)
    if (id == "Customer")
      setCustomer(html)
    if (id == "Revenue")
      setRevenue(html)
    if (id == "Conclusion")
      setConclusion(html)

  }

  useEffect(() => {
    if(Plan!=""|| Infrastructure!=""|| Creation!=""|| Customer!=""|| Revenue!=""|| Conclusion!=""){
    const data = {
      Plan: Plan,
      Infrastructure: Infrastructure,
      Creation: Creation,
      Customer: Customer,
      Revenue: Revenue,
      Conclusion: Conclusion,
    }
    // console.log("problem----",problem)
    const sendData = { email: user.email, company: user.companies[0].companyName, title: category, sendData: data }
    axios.post(URL+"/api/users/changeHtml", {
      data: sendData
    }).then(res => {
      console.log('updated and saved')
      // console.log(sendData)
      dispatch(updateHtmlContent({ itemTitle: category, newhtmlContent: data }));
    }).catch(err => console.log('saving updated content'))
  }
  }, [Plan, Infrastructure, Creation, Customer, Revenue, Conclusion]);


  useEffect(() => {
    let middleData = [];

    if (Array.isArray(pageContents.contents)) {
      middleData = pageContents.contents.filter((item) => {
        return item.Title === category;
      });
    } else if (Array.isArray(pageContents.contents.contents)) {
      middleData = pageContents.contents.contents.filter((item) => {
        return item.Title === category;
      });
    }
    setRenderData(middleData);
    console.log("middledata", middleData)
  }, [pageContents]);



  const chageHtml = (html, id) => {
    console.log("content overview")
    if (id == "Plan")
      setPlan(html)
    if (id == "Infrastructure")
      setInfrastructure(html)
    if (id == "Creation")
      setCreation(html)
    if (id == "Customer")
      setCustomer(html)
    if (id == "Revenue")
      setRevenue(html)
    if (id == "Conclusion")
      setConclusion(html)

  }

  useEffect(() => {
    if (user.regenerateFlag == true) {
      let currentPrompt = ''
      console.log("regnerating")
      user.prompt.map(item => {
        if (item.title == category) {
          currentPrompt = item.prompt
        }
      })
      generateContent(currentPrompt)
    }else {
      setFlag(false)
  }
  }, [user.regenerateFlag]);

  useEffect(() => {

    let htmlContent = null;
    companies[0].htmlContent.forEach((item) => {
      if (item.Title === category) {
        htmlContent = item.htmlcontent;
      }
    });
    if (htmlContent != null && !generateFlag) {
      setPlan(htmlContent.Plan)
      setInfrastructure(htmlContent.Infrastructure)
      setCreation(htmlContent.Creation)
      setCustomer(htmlContent.Customer)
      setRevenue(htmlContent.Revenue)
      setConclusion(htmlContent.Conclusion)

    } else if (renderData != null && renderData[0]) {
      setPlan(`<h3>${renderData[0]?.["Title"]}: ${renderData[0]?.["Summary"]}</h3>`)
      setConclusion(`<h3>${renderData[0]?.["Conclusion"]}</h3>`)
      const rlt = renderData[0]?.["Content"]

      let temp = '';
      for (let i = 0; i < renderData[0]?.['content'].length; i++) {
        temp += `<ol><li>${renderData[0]?.['content']?.[i]?.["SubTitle"]}: ${renderData[0]?.['content']?.[i]?.["Description"]}</li></ol>`;
      }
      temp += '';
      console.log('temp', temp);

      setInfrastructure(temp);
      // const rlt1 = renderData[0]?.["content"]?.[2]?.["Description"]?.["Description"]
      // if (rlt1) {
      //   let length1 = rlt1.length
      //   let result1 = '';
      //   for (let i = 0; i < length1; i++) {
      //     result1 = result1 + `<h3>${rlt1[i]?.["id"]}. ${rlt1[i]?.["item"]}: ${rlt1[i]?.["Description"]}</h3><br />`;
      //   }
      //   setCreation(`<h2>${renderData[0]?.["content"]?.[2]?.["SubTitle"]}</h2><br /><h3>${renderData[0]?.["content"]?.[2]?.["Description"]?.["Overview"]}</h3><br /><h3>${result1}</h3><h3>`)
      // }

      // const rlt2 = renderData[0]?.["content"]?.[3]?.["Description"]?.["Description"]
      // if (rlt2) {
      //   let length2 = rlt2.length
      //   let result2 = '';
      //   for (let i = 0; i < length2; i++) {
      //     result2 = result2 + `<h3>${rlt2[i]?.["id"]}. ${rlt2[i]?.["item"]}: ${rlt2[i]?.["Description"]}</h3><br />`;
      //   }
      //   setCustomer(`<h2>${renderData[0]?.["content"]?.[3]?.["SubTitle"]}</h2><br /><h3>${renderData[0]?.["content"]?.[3]?.["Description"]?.["Overview"]}</h3><br /><h3>${result2}</h3>`)
      // }

      // const rlt3 = renderData[0]?.["content"]?.[4]?.["Description"]?.["Description"]
      // if (rlt3) {
      //   let length3 = rlt3.length
      //   let result3 = '';
      //   for (let i = 0; i < length3; i++) {
      //     result3 = result3 + `<h3>${rlt3[i]?.["id"]}. ${rlt3[i]?.["item"]}: ${rlt3[i]?.["Description"]}</h3><br />`;
      //   }
      //   setRevenue(`<h2>${renderData[0]?.["content"]?.[4]?.["SubTitle"]}</h2><br /><h3>${renderData[0]?.["content"]?.[4]?.["Description"]?.["Overview"]}</h3><br /><h3>${result3}</h3>`)
      // }
    }

  }, [renderData])

  return (
    <div className="layout-content mx-auto max-w-4xl">
      {(user.upgrade == "unpaid" && auth != "admin") &&<Col xs={24} md={24} lg={24}><Limit /></Col>}
      <Row className="p-6">
        <Col
          xs={24}
          md={24}
          lg={24}
          style={{ display: "flex", alignItems: "center" }}
          className="flex items-center justify-between mt-4 mb-9"
        >
          <div><h6 class="text-md font-normal flex items-center leading-none capitalize w-full text-green-500"><div class="w-6 h-6 mr-2 justify-center flex rounded-sm text-green-500 bg-green-50"><div class="w-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-full"><rect width="18" height="18" x="3" y="3" rx="2"></rect><path d="M8 7v7"></path><path d="M12 7v4"></path><path d="M16 7v9"></path></svg></div></div><span class="text-base font-normal">Operations</span></h6><h3 class="text-2xl sm:text-3xl font-medium leading-none tracking-tight capitalize w-full mt-4 mb-3">{page_title}</h3></div>
          {auth=='admin'&&<Switch className="bg-[#3367D6]" checkedChildren="FREE" unCheckedChildren="PAID" checked={paid} onChange={handleChangeCheck} />}
        </Col>
        {
          auth == 'admin' &&
          <Admin currentPrompt={oldprompt} category={category} flag={flag} generateContent={generateContent} savePrompt={savePrompt}></Admin>
        }
        {((Plan != ''&&user.upgrade != "unpaid")||(Plan != ''&&auth == 'admin')||(Plan != ''&&paid)) &&
          <div>
            <Row className="mt-4 bg-[white] w-full">
              <div className="dashboard_title">
                <Edit content={Plan} id={"Plan"} changeContent={handleChange} changeHtml={chageHtml} ></Edit>
              </div>
            </Row>

            <Row className="bg-[white] w-full">
              <div className="dashboard_title">
                <Edit content={Infrastructure} id={"Infrastructure"} changeContent={handleChange} changeHtml={chageHtml} ></Edit>
              </div>
            </Row>

            <Row className="bg-[white] w-full">
              <div className="dashboard_title">
                <Edit content={Conclusion} id={"Conclusion"} changeContent={handleChange} changeHtml={chageHtml} ></Edit>
              </div>
            </Row>
          </div>
        }
        {Plan == '' && <Generating></Generating>}
        {
        auth != "admin" && user.upgrade == "unpaid" &&!paid &&<Upgrade></Upgrade>
      }
      </Row>
    </div>
  )
}

export default Regulatory