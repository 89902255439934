import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Row,
  Switch
} from "antd";
import {
  ProjectOutlined,
} from "@ant-design/icons";
import Edit from "utils/edit";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { URL } from "utils/const";
import Admin from "utils/admin";
import { genEachContent } from "../../utils/service";
import *as formats from '../../utils/configure'
import { updateHtmlContent } from '../../Redux/features/UserSlice';
import { addContent } from "../../Redux/features/pagesSlice";
import { setRegenerate } from "../../Redux/features/UserSlice";
import { Iconify } from "utils/Iconify";
import Limit from "components/Limit";
import Generating from "components/Generating";
import { addUser } from "../../Redux/features/UserSlice";
import Upgrade from "components/Upgrade";

export default function Swot() {

  const dispatch = useDispatch();

  const { email, curID, companies, role, prompt } = useSelector(state => state.userSlice)

  const user = useSelector(state => {
    return state.userSlice
  })

  const htmlContent = user.companies[0].htmlContent

  const pageContents = useSelector(state => {
    return state.pagesSlice
  })

  const category = "Swot Analysis"
  const page_title = user.companies[0].content.find((item) => item.title === "Overview").list.find((item) => item.origin === category).shown

  const auth = role

  const [oldprompt, setOldPrompt] = useState([]);
  const [flag, setFlag] = useState(false);
  const [generateFlag, setGenerateFlag] = useState(false);
  const [strengths, setStrengths] = useState('')
  const [weaknesses, setWeaknesses] = useState('')
  const [opportunities, setOpportunities] = useState('')
  const [threats, setThreats] = useState('')
  const [renderData, setRenderData] = useState(null);
  const [paid, setPaid] = useState();



  useEffect(() => {
    dispatch(setRegenerate(false));
    console.log("---flag")
    setFlag(false);
    setGenerateFlag(false)
    if (email == '')
      window.location.href = '/sign-in';
  }, []);

  useEffect(() => {
    axios.post(URL + "/api/paid/get", { data: "Swot Analysis" })
      .then(res => {
        console.log("res", res.data.prompt);
        if (res.data.prompt == 'paid')
          setPaid(true)
        else setPaid(false)
      })
      .catch(err => console.log("err", err))
  }, [])

  const handleChangeCheck = () => {
    if (paid == true)
      setPaid(false)
    else setPaid(true)

    axios.post(URL + "/api/paid/add", { data: "Swot Analysis" })
      .then(res => {
        console.log("paid has changed", res)
      })
      .catch(err => console.log("failed to change the paid method", err))
  }


  useEffect(() => {
    const currentPrompt = prompt.filter((item) => item.title == category)
    setOldPrompt(currentPrompt.prompt)
  }, [])

  const savePrompt = (newPrompt) => {
    console.log("saveNewPrompt", newPrompt)
  }

  const generateContent = (newPrompt) => {
    setFlag(true);
    setGenerateFlag(true)
    let formatData;
    Object.keys(formats).map(key => {
      if (category == formats[key].Title) {
        console.log(formats[key].Title, formats[key], formats[key])
        formatData = { title: formats[key].Title, data: formats[key] }
      }
    })



    genEachContent(user.companies[0], newPrompt, formatData.data, user.gpt.modelName)
      .then((res) => {
        if (Array.isArray(pageContents.contents)) {
          const newContents = pageContents.contents.map((item, idx) => {
            if (item.Title === category) {
              return res
            }
            return item;
          });
          let temp = newContents;
          dispatch(addContent({ content: temp }));
          dispatch(updateHtmlContent({ itemTitle: category, newhtmlContent: {} }));
          dispatch(setRegenerate(false))
          setFlag(false);
          axios.post(URL + "/api/users/result/save", { email: user.email, companyName: user.companies[0].companyName, content: newContents })
            .then(res => {
              console.log("---------------", res)
              dispatch(addUser({
                email: user.email,
                name: 'StartupSphere',
                companies: res.data,
                curID: user.curID,
                role: user.role,
                prompt: user.prompt,
                upgrade: user.upgrade,
                gpt: user.gpt
              }))
            }).catch(err => console.log(err))
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleChange = (html, clippedTxt, id) => {
    // console.log("handlechange", id, html)
    if (id == "strengths")
      setStrengths(html)
    if (id == "weaknesses")
      setWeaknesses(html)
    if (id == "opportunities")
      setOpportunities(html)
    if (id == "threats")
      setThreats(html)

  }

  const chageHtml = (html, id) => {
    if (id == "strengths")
      setStrengths(html)
    if (id == "weaknesses")
      setWeaknesses(html)
    if (id == "opportunities")
      setOpportunities(html)
    if (id == "threats")
      setThreats(html)
  }

  useEffect(() => {
    if (strengths != "" || weaknesses != "" || opportunities != "" || threats != "") {
      const data = {
        strengths: strengths,
        weaknesses: weaknesses,
        opportunities: opportunities,
        threats: threats,
      }
      const sendData = { email: user.email, company: user.companies[0].companyName, title: category, sendData: data }
      axios.post(URL + "/api/users/changeHtml", {
        data: sendData
      }).then(res => {
        console.log('updated and saved')
        dispatch(updateHtmlContent({ itemTitle: category, newhtmlContent: data }));
      }).catch(err => console.log('saving updated content'))
    }
  }, [strengths, weaknesses, opportunities, threats]);

  useEffect(() => {
    if (user.regenerateFlag == true) {
      let currentPrompt = ''
      console.log("regnerating")
      user.prompt.map(item => {
        if (item.title == category) {
          currentPrompt = item.prompt
        }
      })
      generateContent(currentPrompt)
    } else {
      setFlag(false)
    }
  }, [user.regenerateFlag]);

  useEffect(() => {
    let middleData = [];

    if (Array.isArray(pageContents.contents)) {
      middleData = pageContents.contents.filter((item) => {
        return item.Title === category;
      });
    } else if (Array.isArray(pageContents.contents.contents)) {
      middleData = pageContents.contents.contents.filter((item) => {
        return item.Title === category;
      });
    }
    console.log("midddddddddddddddddd", middleData)
    setRenderData(middleData);
  }, [pageContents]);

  useEffect(() => {

    let htmlContent = null;
    companies[0].htmlContent.forEach((item) => {
      if (item.Title === category) {
        htmlContent = item.htmlcontent;
      }
    });
    if (htmlContent != null && !generateFlag) {
      setStrengths(htmlContent.strengths);
      setWeaknesses(htmlContent.weaknesses);
      setThreats(htmlContent.threats);
      setOpportunities(htmlContent.opportunities);
    } else if (renderData != null && renderData[0]) {
      const rlt = renderData[0]?.["Content"]?.[0]?.["Description"]
      let length = rlt.length
      let result = '';
      for (let i = 0; i < length; i++) {
        result = result + `<ol><li>${rlt[i]?.["Description"]}</li></ol>`;
      }
      setStrengths(result);

      const rlt1 = renderData[0]?.["Content"]?.[1]?.["Description"]
      let length1 = rlt1.length
      let result1 = '';
      for (let i = 0; i < length1; i++) {
        result1 = result1 + `<ol><li>${rlt1[i]?.["Description"]}</li></ol>`;
      }
      setWeaknesses(result1);

      const rlt2 = renderData[0]?.["Content"]?.[2]?.["Description"]
      let length2 = rlt2.length
      let result2 = '';
      for (let i = 0; i < length2; i++) {
        result2 = result2 + `<ol><li>${rlt2[i]?.["Description"]}</li></ol>`;
      }
      setOpportunities(result2);

      const rlt3 = renderData[0]?.["Content"]?.[3]?.["Description"]
      let length3 = rlt3.length
      let result3 = '';
      for (let i = 0; i < length3; i++) {
        result3 = result3 + `<ol><li>${rlt3[i]?.["Description"]}</li></ol>`;
      }
      setThreats(result3);
    }
    // }

  }, [renderData])


  return (
    <div className="layout-content mx-auto max-w-4xl">
      {(user.upgrade == "unpaid" && auth != "admin") && <Col xs={24} md={24} lg={24}><Limit /></Col>}
      <div className="layout-content mx-auto max-w-4xl">
        <Row className="p-6">
          <Col
            xs={24}
            md={24}
            lg={24}
            className="flex items-center"
          >
            <div class="w-6 h-6 mr-2 justify-center flex rounded-sm text-green-500 bg-green-50"><div class="w-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-full"><path d="m2 7 4.41-4.41A2 2 0 0 1 7.83 2h8.34a2 2 0 0 1 1.42.59L22 7"></path><path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path><path d="M15 22v-4a2 2 0 0 0-2-2h-2a2 2 0 0 0-2 2v4"></path><path d="M2 7h20"></path><path d="M22 7v3a2 2 0 0 1-2 2v0a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 16 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 12 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 8 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 4 12v0a2 2 0 0 1-2-2V7"></path></svg></div></div>
            <div className="text-[#26B35A] text-lg">Overview</div>
          </Col>
          <Col xs={24} md={24} lg={24} className="flex items-center justify-between mt-4 mb-9">
            <div className="dashboard_title">
              <h1 className="text-3xl text-[#141414] font-[500]">{page_title}</h1>
            </div>
            {auth == 'admin' && <Switch className="bg-[#3367D6]" checkedChildren="FREE" unCheckedChildren="PAID" checked={paid} onChange={handleChangeCheck} />}
          </Col>
          {
            auth == 'admin' &&
            <Admin currentPrompt={oldprompt} category={category} flag={flag} generateContent={generateContent} savePrompt={savePrompt}></Admin>
          }
          {((strengths != '' && user.upgrade != "unpaid") || (strengths != '' && auth == 'admin') || (strengths != '' && paid)) && <div><Row className="w-full">
            <Col xs={24} md={12} lg={12}>
              <div className="dashboard_title ml-4 xs:mr-1 p-8 bg-[#f1f7ff]">
                <div class="flex w-full text-center justify-center items-center mb-8 rounded-t-md ">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" class="w-5 h-5 mr-3 ml-1 text-blue-500"><path d="m6.5 6.5 11 11"></path><path d="m21 21-1-1"></path><path d="m3 3 1 1"></path><path d="m18 22 4-4"></path><path d="m2 6 4-4"></path><path d="m3 10 7-7"></path><path d="m14 21 7-7"></path></svg>
                  <h3 class="flex m-0 p-0 text-xl  font-semibold text-blue-500 capitalize">
                    strengths
                  </h3>
                </div>

                <Edit content={strengths} id={"strengths"} changeContent={handleChange} changeHtml={chageHtml}></Edit>

              </div>
            </Col>
            <Col xs={24} md={12} lg={12} className="mt-2 xm:mt-0">
              <div className="dashboard_title ml-4 xs:mr-1 p-8 bg-[#fffbee]">
                <div class="flex w-full text-center justify-center items-center mb-8 rounded-t-md ">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" class="w-5 h-5 mr-3 ml-1 text-yellow-500"><path d="M19.7 14a6.9 6.9 0 0 0 .3-2V5l-8-3-3.2 1.2"></path><path d="m2 2 20 20"></path><path d="M4.7 4.7 4 5v7c0 6 8 10 8 10a20.3 20.3 0 0 0 5.62-4.38"></path></svg>
                  <h3 class="flex m-0 p-0 text-xl  font-semibold text-yellow-500 capitalize">weaknesses</h3>
                </div>
                <Edit content={weaknesses} id={"weaknesses"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
              </div>
            </Col>
          </Row>
            <Row className="w-full mt-2">
              <Col xs={24} md={12} lg={12}>
                <div className="dashboard_title ml-4 xs:mr-1 p-8 bg-[#ebfff2]">
                  <div class="flex w-full text-center justify-center items-center mb-6 rounded-t-md ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" class="w-5 h-5 mr-3 ml-1 text-green-500"><path d="M7 20h10"></path><path d="M10 20c5.5-2.5.8-6.4 3-10"></path><path d="M9.5 9.4c1.1.8 1.8 2.2 2.3 3.7-2 .4-3.5.4-4.8-.3-1.2-.6-2.3-1.9-3-4.2 2.8-.5 4.4 0 5.5.8z"></path><path d="M14.1 6a7 7 0 0 0-1.1 4c1.9-.1 3.3-.6 4.3-1.4 1-1 1.6-2.3 1.7-4.6-2.7.1-4 1-4.9 2z"></path></svg>
                    <h3 class="flex m-0 p-0 text-xl font-semibold text-green-500 capitalize">opportunities</h3>
                  </div>

                  <Edit content={opportunities} id={"opportunities"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                </div>
              </Col>
              <Col xs={24} md={12} lg={12} className="mt-2 xm:mt-0">
                <div className="dashboard_title ml-4 xs:mr-1 p-8 bg-[#fff0f0]">
                  <div class="flex w-full text-center justify-center items-center mb-6 rounded-t-md ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" class="w-5 h-5 mr-3 ml-1 text-red-500"><path d="M8.5 14.5A2.5 2.5 0 0 0 11 12c0-1.38-.5-2-1-3-1.072-2.143-.224-4.054 2-6 .5 2.5 2 4.9 4 6.5 2 1.6 3 3.5 3 5.5a7 7 0 1 1-14 0c0-1.153.433-2.294 1-3a2.5 2.5 0 0 0 2.5 2.5z"></path></svg>
                    <h3 class="flex m-0 p-0 text-xl font-semibold text-red-500 capitalize">threats</h3>
                  </div>
                  <Edit content={threats} id={"threats"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                </div>
              </Col>
            </Row>
          </div>}

          {strengths == '' && <Generating></Generating>}
          {
            auth != "admin" && user.upgrade == "unpaid" && !paid && <Upgrade></Upgrade>
          }
        </Row>
      </div>
    </div>
  );
}
