import { Col, Row } from 'antd'
import React from 'react'
import { Rate } from 'antd'

const Review = (props) => {
    return (
        <Col sm={24} xs={24} lg={8} >
            <div className='flex pt-8 h-[300px] overflow-hidden rounded-lg text-sm shadow-md shadow-md border p-2 mx-4'>
                <div className='w-3/5 text-left pl-[20px] text-[14px]' style={{color:'black', fontWeight:'300'}}>
                    {props.review}
                </div>
                <div className='w-2/5 text-left  pl-6 align-row flex-col justify-center'>
                    <img src={props.avatar} width="120px" height="120px" className='rounded-sm' alt='' />
                    <div style={{marginLeft:10, marginTop: 10}}><Rate className='text-[13px]' defaultValue={5} />
                    </div>
                    <div style={{ textAlign: "left", fontSize:'14px',paddingRight:'5px', fontWeight:'300' }}>{props.name} | {props.role}</div>
                    {/* <div style={{ textAlign: "left", fontSize:'14px' }}>{props.role}</div> */}
                </div>
            </div>
        </Col>
    )
}

export default Review