import {
  Card,
  Row,
  Col,
  Typography,
  Radio,
  Upload,
  message,
  Button,
  Spin,
  Avatar,
  Form,
  Input,
  notification,
  Progress,
} from "antd";
import { PlusOutlined, RetweetOutlined, LockOutlined, CrownOutlined } from "@ant-design/icons"
import { URL } from "../utils/const";
import { addUser } from "Redux/features/UserSlice";
import React, { useState, useEffect } from "react";
import { Iconify } from "utils/Iconify";
import AntCard from "../components/AntCard";
import card from "../assets/images/info-card-1.jpg";
import PieChartComp from "../components/charts/Piechart";
import Piechart1 from "components/charts/Piechart1";
import AntdCard from "components/AntCard";
import Modal from 'react-modal';
import PdfView from "./pdfView";
import axios from "axios";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import { addContent } from "../Redux/features/pagesSlice";
import { fetchOpenAIResponse, generateIdeas } from "../utils/openai";
import { generateAll, genEachContent } from "../utils/service";
import *as formats from '../utils/configure'
import lock from "../assets/images/lock.svg";
import generate from "../assets/images/generate.svg";
import { ArrowLeftOutlined } from '@ant-design/icons'
import { GoogleLogin } from '@react-oauth/google'
import BarChart from "../components/HomeChart/BarChart"
import AreaChart from "../components/HomeChart/AreaChart"
import { hasData } from "jquery";
import Limit from "components/Limit";
import { setRegenerate, setRegenerate2, setRegenerate1, setRegenerate3 } from "../Redux/features/UserSlice";
import { jsPDF } from 'jspdf';
import FinancialPieChart1 from "components/charts/FinancialPieChart1";
// import { PdfExport, useGeneratePdf } from '@garage-panda/react-pdf-export';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    textAlign: 'center',
    bottom: 'auto',
    marginRight: '-50%',
    borderRadius: '10px',
    overflowX: "scroll",
    overflowY: 'scroll',
    transform: 'translate(-50%, -50%)',
    // backgroundColor:' rgba(0, 0, 0, 0.1) !important'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 500
  }
};

const { TextArea } = Input;

const { Title, Paragraph, Text } = Typography;

export default function Home() {

  const location = useLocation();

  console.log("location", location)

  const { data } = location.state ? location.state : {};

  const params = window.history.state;

  const [areaYear, setAreaYear] = useState(2023);

  const dispatch = useDispatch();

  const user = useSelector(state => {
    return state.userSlice
  })

  const pageContents = useSelector(state => {
    return state.pagesSlice
  })

  useEffect(() => {
    console.log("params", params)
    setCompanyDescription(data)
    console.log("companyDes", data, companyDescription)
  }, [])

  const [BusinessData, setBusinessData] = useState([]);
  const [MissionData, setMissionData] = useState([]);
  const [ViabilityData, setViabilityData] = useState([]);
  const [SwotData, setSwotData] = useState([]);
  const [CoreData, setCoreData] = useState([]);
  const [SecondaryData, setSecondaryData] = useState([]);
  const [ExpansionData, setExpansionData] = useState([]);
  const [CustomerData, setCustomerData] = useState([]);
  const [IndustryData, setIndustryData] = useState([]);
  const [TargetData, setTargetData] = useState([]);
  const [MarketsizeData, setMarketsizeData] = useState([]);
  const [CompetitorData, setCompetitorData] = useState([]);
  const [BrandingData, setBrandingData] = useState([]);
  const [OnlineData, setOnlineData] = useState([]);
  const [MarketideaData, setMarketideaData] = useState([]);
  const [RetentionData, setRetentionData] = useState([]);
  const [OperationData, setOperationData] = useState([]);
  const [RiskData, setRiskData] = useState([]);
  const [RegulatoryData, setRegulatoryData] = useState([]);
  const [TeamData, setTeamData] = useState([]);
  const [PreLaunch, setPreLaunch] = useState([]);
  const [PostLaunch, setPostLaunch] = useState([]);
  const [CostsBreakDown, setCostsBreakDown] = useState([]);
  const [FundingPlan, setFundingPlan] = useState([]);
  const [FIveYearPlan, setFIveYearPlan] = useState([]);
  const [MarketingIdeas, setMarketingIdeas] = useState([]);
  const preProgress = 0;
  let subtitle;
  const company = user.companies;
  const auth = user.role;
  const email = user.email;
  const [companyName, setCompanyName] = useState('');
  const [ideas, setIdeas] = useState([]);
  const [companyDescription, setCompanyDescription] = useState('123');
  const [unique, setUnique] = useState('');
  const [numberToGenerate, setnumberToGenerate] = useState(0);
  const [numberOfMenu, setnumberOfMenu] = useState(0);
  const [companyUnique, setCompanyUnique] = useState('');
  const [modalIsOpen, setIsOpen] = React.useState(true);
  const [modalCompany, setModalCompany] = React.useState(true);
  const [modalUnique, setModalUnique] = React.useState(false);
  const [modalSignUp, setSignUp] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [flag, setFlag] = useState(false);
  const [numbertoGen, setnumbertoGen] = useState(0);
  const [percent, setPercent] = useState(70);
  const [comptetedCount, setcomptetedCount] = useState(0);
  const [companyInfoToGenerate, setcompanyInfoToGenerate] = useState({ name: "", description: "" });
  const navigator = useNavigate();
  const [api, contextHolder] = notification.useNotification();





  useEffect(() => {




    setMissionData(pageContents["contents"].filter((item) => { return item.Title == 'Mission and Vision' }));
    setSwotData(pageContents["contents"].filter((item) => { return item.Title == 'Swot Analysis' }));
    setBusinessData(pageContents["contents"].filter((item) => { return item.Title == 'Business Models' }));
    setViabilityData(pageContents["contents"].filter((item) => { return item.Title == 'Viability analysis' }));
    setCoreData(pageContents["contents"].filter((item) => { return item.Title == 'Core Offerings' }));
    setSecondaryData(pageContents["contents"].filter((item) => { return item.Title == 'Secondary Offering' }));
    setExpansionData(pageContents["contents"].filter((item) => { return item.Title == 'Expansion Opportunities' }));
    setCustomerData(pageContents["contents"].filter((item) => { return item.Title == 'Customer Service' }))
    setIndustryData(pageContents["contents"].filter((item) => { return item.Title == 'Industry Overview' }))
    setRetentionData(pageContents["contents"].filter((item) => { return item.Title == 'Customer Retention' }))
    setTargetData(pageContents["contents"].filter((item) => { return item.Title == 'Target Audience' }))
    setMarketsizeData(pageContents["contents"].filter((item) => { return item.Title == 'Market size and Trends' }))
    setCompetitorData(pageContents["contents"].filter((item) => { return item.Title == 'Competitor Analysis' }))
    setBrandingData(pageContents["contents"].filter((item) => { return item.Title == 'Branding & Identity' }))
    setOnlineData(pageContents["contents"].filter((item) => { return item.Title == 'Online Presence' }))
    setOperationData(pageContents["contents"].filter((item) => { return item.Title == 'Operations Plan' }))
    setTeamData(pageContents["contents"].filter((item) => { return item.Title == 'Team & Roles' }))
    setRiskData(pageContents["contents"].filter((item) => { return item.Title == 'Risk Analysis' }))
    setRegulatoryData(pageContents["contents"].filter((item) => { return item.Title == 'Regulatory Compliance' }))
    setPreLaunch(pageContents["contents"].filter((item) => { return item.Title == 'Pre-Launch' }))
    setPostLaunch(pageContents["contents"].filter((item) => { return item.Title == 'Post-Launch' }))
    setCostsBreakDown(pageContents["contents"].filter((item) => { return item.Title == 'Costs Breakdown' }))
    setFundingPlan(pageContents["contents"].filter((item) => { return item.Title == 'Funding Plan' }))
    setFIveYearPlan(pageContents["contents"].filter((item) => { return item.Title == '5 Year Plan' }))
    setMarketingIdeas(pageContents["contents"].filter((item) => { return item.Title == 'Marketing Ideas' }))
  }, [pageContents])

  async function generateIdea() {
    setIsLoading(false)
    let data = await generateIdeas({ companyName: companyName, companyDescription: companyDescription, modelName: user.gpt.modalName })
    const response = JSON.parse(data);
    setIdeas(response.ideas)
    setIsLoading(true)
  }

  function getcontents(generateList) {
    // console.log(user)
    let count = 0;
    console.log("genlist", generateList)
    console.log("count", count)
    let subtitles = []
    let pages = [];
    generateList.map(title => {
      Object.keys(formats).map(key => {
        if (title == formats[key].Title) {
          subtitles = subtitles.concat({ title: title, data: formats[key] })

        }
      })
    })
    setnumbertoGen(subtitles.length)
    console.log("subtitles-----25", subtitles)
    console.log("numTogen------", subtitles.length)
    // for (const menu of subtitles) {
    //   console.log("user.proompt", user.prompt)
    //   for (const prom of user.prompt) {
    //     if (prom.title == menu.title) {
    //       let newJsonContent = []
    //       prompt = prom.prompt
    //       count += 1
    //       if (count > 33) {
    //         // alert("failed")
    //         return
    //       }
    //       console.log("00000000000000000000000", menu)
    //       genEachContent(user.companies[0], prompt, menu.data, user.gpt.modelName).then(res => {

    //         let data = { email: user.email, companyName: user.companies[0].companyName, pageName: menu.title, content: res, isHtmlGenerated: 0 }
    //         pages.push(res)
    //         console.log("res", res)
    //         let temp = pageContents.contents.concat(pages)
    //         console.log("pageContents", temp)
    //         if (pageContents.contents.length == 0) {
    //           dispatch(addContent({ content: temp }))
    //         } else {
    //           if (pageContents.contents[0].companyName == temp[0].companyName) {
    //             dispatch(addContent({ content: temp }))
    //           }
    //         }

    //         axios.post(URL + "/api/users/jsonContent", { result: temp, email: user.email, company: user.companies[0].companyName }).then((res) => {
    //         })
    //           .catch(error => {
    //             console.error(error);
    //           })
    //       }).catch(err => {
    //         console.log("openAI", err)
    //       })
    //     }
    //   }

    // }

    function processMenusSequentially(user, subtitles, index = 0) {
      if (index >= subtitles.length) {
        return; // Exit if all subtitles are processed
      }

      const menu = subtitles[index];
      console.log("user.prompt", user.prompt);
      for (const prom of user.prompt) {
        if (prom.title === menu.title) {
          let newJsonContent = [];
          let prompt = prom.prompt;
          let count = 0;
          if (count > 33) {
            // alert("failed")
            return;
          }
          console.log("00000000000000000000000", menu);
          genEachContent(user.companies[0], prompt, menu.data, user.gpt.modelName).then(res => {
            let data = { email: user.email, companyName: user.companies[0].companyName, pageName: menu.title, content: res, isHtmlGenerated: 0 };
            pages.push(res);
            console.log("res", res);
            let temp = pageContents.contents.concat(pages);
            console.log("pageContents", temp);
            if (pageContents.contents.length == 0 || pageContents.contents[0].companyName == temp[0].companyName) {
              dispatch(addContent({ content: temp }));
            }
            axios.post(URL + "/api/users/jsonContent", { result: temp, email: user.email, company: user.companies[0].companyName }).then((res) => {
            })
              .catch(error => {
                console.error(error);
              })
            // Process the next menu recursively
            processMenusSequentially(user, subtitles, index + 1);
          }).catch(err => {
            console.log("openAI", err);
            // Process the next menu recursively even if an error occurs
            processMenusSequentially(user, subtitles, index + 1);
          });
        }
      }
    }

    // Call the function with the initial index 0
    processMenusSequentially(user, subtitles);


  }
  useEffect(() => {

    if (user.regenerateFlag == true || user.regenerateFlag1 == true || user.regenerateFlag2 == true) {
      dispatch(setRegenerate3(true));
      axios.post(URL + "/api/users/regenerateall", { email: user.email, companyName: user.companies[0].companyName })
        .then(response => {
          const updatedcompanies = user.companies.map((item, idx) => {
            if (idx == 0) {
              return { ...item, jsonContent: [], htmlContent: [] };
            }
            return item;
          });

          // companies[0].jsonContent = []
          // companies[0].htmlContent = []
          dispatch(addContent({ content: [] }))
          dispatch(setRegenerate(false))
          dispatch(setRegenerate1(false))
          dispatch(setRegenerate2(false))
          dispatch(addUser({
            email: user.email,
            name: 'StartupSphere',
            companies: updatedcompanies,
            curID: user.curID,
            role: user.role,
            prompt: user.prompt,
            upgrade: user.upgrade,
            gpt: user.gpt
          }))

        })
        .catch(error => {
          console.error(error);
        })
    }
  }, [user.regenerateFlag, user.regenerateFlag1, user.regenerateFlag2]);

  useEffect(() => {

    let menuCount = 0;
    let generateList = [];
    if (user.email != "") {
      for (const submenu of user.companies[0].content) {
        for (const item of submenu.list) {
          menuCount++;
          if (pageContents.contents.filter(menu => menu.Title == item.origin).length == 0) {
            generateList.push(item.origin)
          }
        }
      }

      setnumberOfMenu(menuCount);
      setnumberToGenerate(generateList.length);
      if (pageContents.contents.length < menuCount) {
        // console.log("new generate");
        getcontents(generateList)
      } else {

      }

    }
  }, [user.companies]);

  const openNotificationWithIcon = (type, data) => {
    api[type]({
      message: "Notification",
      description: data,
    });
  };


  const changeCompanyDescription = (e) => {
    setCompanyDescription(e.target.value)
  }

  const changeUnique = (item) => {
    let newUnique = ''


    if (unique == '') {
      newUnique = item;
    } else {
      newUnique = unique + ' , ' + item;
    }

    setUnique(newUnique)
    const updatedIdeas = ideas.filter((idea) => idea !== item);
    setIdeas(updatedIdeas);
  }

  function backCompany() {
    setModalCompany(true);
    setModalUnique(false);
    setSignUp(false);
  }

  function backUnique() {
    setModalCompany(false);
    setModalUnique(true);
    setSignUp(false);
  }


  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  const [reverse, setReverse] = useState(false);
  const [open, setOpen] = useState(false);
  const handleMenuClick = (e) => {
    if (e.key === '3') {
      setOpen(false);
    }
  };
  const handleOpenChange = (nextOpen, info) => {
    if (info.source === 'trigger' || nextOpen) {
      setOpen(nextOpen);
    }
  };
  const onChange = (e) => console.log(`radio checked:${e.target.value}`);
  const uploadProps = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed`);
      }
    },
  };

  const onFinishCompany = (values) => {
    setCompanyName(values.companyName);
    // setCompanyDescription(values.companyDescription)
    setModalCompany(false);
    setModalUnique(true);
  };

  const onFinishUnique = (values) => {

    if (params.usr) {
      const data = { email: params.usr, password: params.usr, companyName: companyName, companyDescription: companyDescription, companyUnique: values.companyUnique }
      console.log("data", data)
      axios.post(URL + "/api/users/register", data)
        .then(response => {
          const data = response.data;
          if (data.email) {
            openNotificationWithIcon("success", "You registered successfully");
            setTimeout(() => {
              navigator('/dashboard')
            }, 1000)
            dispatch(addContent({ content: data.company[0].jsonContent }))
            dispatch(addUser({
              email: data.email,
              name: 'StartupSphere',
              companies: data.company,
              curID: data.curID,
              role: data.role,
              prompt: data.prompt,
              upgrade: data.upgrade,
              gpt: data.gpt,
            }))
          }
          else {
            openNotificationWithIcon("error", "Register failed");
          }
        })
        .catch(error => {
          console.error(error);
        })
      setIsOpen(false);
    } else {
      const data = { email: email, companyName: companyName, companyDescription: companyDescription, companyUnique: unique }
      if (auth) {
        axios.post(URL + "/api/users/updateCompany", data)
          .then(response => {
            // console.log("hhh", response.data);
            const data = response.data;
            if (data.email) {
              openNotificationWithIcon("success", "You registered successfully");
              setTimeout(() => {
                navigator('/dashboard')
              }, 1000)
            }
            else {
              openNotificationWithIcon("error", "Register failed");
            }
          })
          .catch(error => {
            console.error(error);
          })
        setModalCompany(false);
        setModalUnique(false);
        setSignUp(false);
      }
      else {
        setModalCompany(false);
        setModalUnique(false);
        setSignUp(true);
      }
    }
  };

  const onFinishFailed = (errInfo) => {
    console.log("Error : ", errInfo);
  };

  const changeInputUnique = (e) => {

    const newUnique = e.target.value

    setUnique(newUnique)
  }
  ////////////////////// Google Login /////////////////////////

  const responseMessage = (response) => {
    console.log(response);
  };
  const errorMessage = (error) => {
    console.log(error);
  };

  //////////////////////////// Register ////////////////////////

  const onRegister = (values) => {

    const data = { email: values.email, password: values.password, companyName: companyName, companyDescription: companyDescription, companyUnique: values.companyUnique }
    axios.post(URL + "/api/users/register", data)
      .then(response => {


        const data = response.data;
        if (data.email) {
          openNotificationWithIcon("success", "You registered successfully");
          // if (data) {
          // openNotificationWithIcon("success", "You logined successfully");
          setTimeout(() => {
            navigator('/dashboard')
          }, 1000)
          dispatch(addContent({ content: data.company[0].jsonContent }))

          dispatch(addUser({
            email: data.email,
            name: 'StartupSphere',
            companies: data.company,
            curID: data.curID,
            role: data.role,
            prompt: data.prompt,
            upgrade: data.upgrade,
            gpt: data.gpt
          }))
        }
        else {
          openNotificationWithIcon("error", "Register failed");
        }
      })
      .catch(error => {
        console.error(error);
      })
    setIsOpen(false);
    // navigator('/sign-in')

  };
  const onRegisterFailed = (errInfo) => {
    console.log("Error : ", errInfo);
  };
  const validateEmail = (_, value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!value || emailRegex.test(value)) {
      return Promise.resolve();
    }

    return Promise.reject('Please enter a valid email address.');
  };

  const [paidItems, setPaidItems] = useState([])

  useEffect(() => {
    axios.get(URL + "/api/paid/getAll")
      .then(res => {
        console.log("res", res.data);
        setPaidItems(res.data);
        // if (res.data.prompt == 'paid')
        //   setPaid(true)
        // else setPaid(false)
      })
      .catch(err => console.log("err", err))
  }, [])


  const estimateFlag = (label) => {
    for (const item of paidItems) {
      if (item.title == label && item.prompt == "paid")
        return true;
    }

    return false;
  }



  return (
    <div id="my-component">

      {contextHolder}
      {

        email == '' ?
          <Modal
            maskClosable={false}
            visible={true}
            isOpen={modalIsOpen}
            keyboard={false}
            onCancel={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            {
              modalCompany &&
              <div>
                <div className="flex justify-end">
                  <p><Link to="/sign-in" className="text-blue-500">Login</Link></p>
                </div>
                <p style={{ fontSize: '1.5rem', paddingTop: '0.5rem', fontWeight: '600' }}>
                  Get Started with StartupSphere
                </p>
                <h2>
                  Tell us about your business and we'll tailor our AI report and content<br className="hidden sm:block" /> generator to fit your unique needs.
                </h2>

                <Form
                  onFinish={onFinishCompany}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <p style={{ textAlign: 'left', paddingTop: '2.5rem' }}>Company Name</p>
                  <Form.Item
                    className="username"
                    style={{ paddingTop: '0.5rem' }}
                    name="companyName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your company name!",
                      },
                    ]}
                  >
                    <Input placeholder="E.g. Happy Hounds" />
                  </Form.Item>

                  <p style={{ textAlign: 'left' }}>Company Description</p>
                  <div
                    className="username"
                    // name="companyDescription"
                    style={{ marginBottom: 0, paddingTop: '0.5rem' }}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please input your company description!",
                  //   },
                  // ]}
                  >
                    <Input.TextArea value={companyDescription} onChange={changeCompanyDescription} autoSize={{ minRows: 1, maxRows: 6 }} placeholder="E.g. dog walking business in Toronto" />
                  </div>

                  <Form.Item>
                    <Button
                      className="mt-5"
                      type="default"
                      htmlType="submit"
                      style={{ width: "100%", backgroundColor: '#3367D6' }}
                    >
                      <div className="text-lg text-white">Next</div>
                    </Button>
                  </Form.Item>

                </Form>

                <div className="flex justify-center">
                  <img width={15} className="mr-2" src={lock} alt="" />Private & Secure.
                </div>
              </div>
            }
            {
              modalUnique &&
              <div>
                <div className="flex justify-between">
                  <p onClick={backCompany} className="hover:cursor-pointer"> <ArrowLeftOutlined className="mr-1" />Back</p>
                  {params == null && <p><Link to="/sign-in" className="text-blue-500">Login</Link></p>}
                </div>
                <p style={{ fontSize: '1.5rem', paddingTop: '0.5rem', fontWeight: '600' }}>
                  Get Started with StartupSphere
                </p>
                <h2>
                  Tell us about your business and we'll tailor our AI report and content<br className="hidden sm:block" /> generator to fit your unique needs.
                </h2>
                <Form
                  onFinish={onFinishUnique}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <p style={{ textAlign: 'left', paddingTop: '2rem' }}>What makes <b>{companyName}</b> unique?</p>
                  <Input.TextArea autoSize={{ minRows: 1, maxRows: 6 }} className="mt-2" value={unique} onChange={(e) => changeInputUnique(e)} />
                  <Form.Item>
                    <Button
                      className="mt-5"
                      onClick={() => generateIdea()}
                      style={{ width: "100%", backgroundColor: 'white' }}
                    >
                      {
                        isLoading ?
                          <div className="text-lg text-[#A660F6] flex justify-center"> <img width={15} className="mr-2" src={generate} alt="" />Generate ideas</div>
                          :
                          <Spin className="relative" size="small" />
                      }
                    </Button>
                  </Form.Item>
                  {
                    ideas && ideas.map((item, key) => (<div onClick={() => changeUnique(item)} className="idea-text" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><p className="block text-left cursor-pointer hover:pointer " style={{ marginLeft: "10px" }} key={item}>{item}</p><PlusOutlined className="btn-plus" style={{ marginRight: '10px' }} /></div>)
                    )
                  }
                  <Form.Item>
                    <Button
                      className="mt-3"
                      type="default"
                      htmlType="submit"
                      style={{ width: "100%", backgroundColor: '#3367D6' }}
                    >
                      <div className="text-lg text-white"> Continue </div>
                    </Button>
                  </Form.Item>
                </Form>
                <div className="flex justify-center">
                  <img width={15} className="mr-2" src={lock} alt="" />Private & Secure
                </div>
              </div>
            }
            {
              !auth && modalSignUp &&
              <div className="ml-4 mr-4">
                <div className="flex justify-between">
                  <p onClick={backUnique} className="hover:cursor-pointer"><ArrowLeftOutlined className="mr-1" />Back</p>
                  <p><Link to="/sign-in" className="text-blue-500">Login</Link></p>
                </div>
                <p style={{ fontSize: '1.5rem', paddingTop: '0.7rem', fontWeight: '600' }}>
                  Sign up to access your report
                </p>
                <Form
                  onFinish={onRegister}
                  onFinishFailed={onRegisterFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Form.Item
                    className="username"
                    style={{ paddingTop: '0.5rem' }}
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter your email address.',
                      },
                      {
                        validator: validateEmail,
                      },
                    ]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>

                  <Form.Item
                    className="username"
                    name="password"
                    style={{ marginBottom: 0, paddingTop: '0.5rem' }}
                    rules={[
                      {
                        required: true,
                        message: "Password",
                      },
                    ]}
                  >
                    <Input placeholder="Password" type="password" />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      className="mt-5"
                      type="default"
                      htmlType="submit"
                      style={{ width: "100%", backgroundColor: '#3367D6' }}
                    >
                      <div className="text-lg text-white">Sign Up</div>
                    </Button>
                  </Form.Item>

                </Form>

                {/* <div>
                  <div className="relative mt-3">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                      <div className="w-full border-t border-neutral-200"></div>
                    </div>
                    <div className="relative flex justify-center text-sm font-normal leading-6">
                      <span className="bg-white px-6 text-muted-foreground">
                        Or
                      </span>
                    </div>
                  </div>
                  <div className="mt-3 flex gap-4 justify-center w-full">
                    <GoogleLogin className="w-full" text="Google" onSuccess={responseMessage} onError={errorMessage} />
                  </div>
                </div> */}
              </div>
            }
          </Modal>
          :
          null
      }
      {
        email != ''
          ?
          <div className="layout-content">
            <Row>
              <Col xs={24} md={24} lg={24}>
                <div className="dashboard_title">
                  <h1 className="text-3xl mt-4 font-bold">{user.companies[0].companyName} Business Plan</h1>
                </div>
              </Col>
              {(user.upgrade == "unpaid" && auth != "admin") &&
                <Col xs={24} md={24} lg={24}>
                  <Limit></Limit>
                </Col>}
              {
                numbertoGen > 0 ? <Progress className="pl-4" percent={Math.ceil((pageContents.contents.length) / numberOfMenu * 100 * 1.33)} /> : ""
              }

              {/* <div className="w-6 h-6 mr-3 justify-center flex rounded-sm bg-[#D9F8E4]">
                  <div className="w-4 text-[#26B35A]"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="w-full"><path d="m7.5 4.27 9 5.15"></path><path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z"></path><path d="m3.3 7 8.7 5 8.7-5"></path><path d="M12 22V12"></path></svg></div></div>
                <div className="text-[#26B35A] ml-2 text-xl">Product & Services</div> */}

              <Col xs={24} md={24} lg={24} className="flex items-center pt-4 pb-4 justify-between">
                <div className="flex items-center">
                  {/* <div className="text-lg text-[#9F6BF4] w-4 bg-[#EBE1FA]">
                    <Iconify icon="eva:gift-outline" />
                  </div>
                  <div className="text-[#9F6BF4] ml-2 text-xl">Overview</div> */}
                  <div class="w-6 h-6 mr-2 justify-center flex rounded-sm text-[#9F6BF4] bg-[#EBE1FA]"><div class="w-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-full"><path d="m2 7 4.41-4.41A2 2 0 0 1 7.83 2h8.34a2 2 0 0 1 1.42.59L22 7"></path><path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path><path d="M15 22v-4a2 2 0 0 0-2-2h-2a2 2 0 0 0-2 2v4"></path><path d="M2 7h20"></path><path d="M22 7v3a2 2 0 0 1-2 2v0a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 16 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 12 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 8 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 4 12v0a2 2 0 0 1-2-2V7"></path></svg></div></div>
                  <div className="text-[#9F6BF4] ml-2 text-xl">Overview</div>
                </div>
                <div>
                  <Button onClick={() => dispatch(setRegenerate(true))} style={{ color: "rgb(0 184 119)", fontSize: '15px' }} className="float-right">Regenerate All</Button>
                </div>

              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <AntCard xl={12}>
                <div className="flex space-between align-center">
                  <h2 className="text-2xl font-semibold mr-2">{user.companies[0].content.find((item) => item.title === "Overview").list.find((item) => item.origin === "Mission and Vision").shown}</h2>
                  <Link to="/overview/mission-vision" className="text-[#00b877] inline-flex items-center justify-center rounded-md text-[15px] font-medium hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto">
                    Open&nbsp;&nbsp;<Iconify icon="eva:arrow-forward-outline" />
                  </Link>
                </div>
                {(user.upgrade == "paid" || auth == "admin" || estimateFlag("Mission and Vision")) ?
                  MissionData.length > 0 ?
                    <div className="mt-4 font-default h-[300px] relative overflow-hidden">
                      <div className="absolute h-20 w-full bottom-0 left-0 z-10 bg-gradient-to-b from-[#ffffff]/0 to-[#ffffff]"></div>
                      <h4>Mission:</h4><span>{MissionData[0]["Content"][0]["Description"][0]["Description"]}</span>
                      <h4 className="mt-6">Vision:</h4><span>{MissionData[0]["Content"][0]["Description"][1]["Description"]}</span>
                      <h4 className="mt-6">Problem:</h4><span>{MissionData[0]["Content"][1]["Description"]}</span>
                    </div>
                    :
                    <Spin className="flex h-[300px] items-center justify-center" />
                  :
                  <div className="flex h-[200px] text-4xl justify-center items-center">
                    <CrownOutlined style={{ color: "#FFAE00" }} />
                  </div>
                }
              </AntCard>
              <AntCard xl={12}>
                <div className="flex space-between align-center">
				  <h2 className="text-2xl font-semibold mr-2">{user.companies[0].content.find((item) => item.title === "Overview").list.find((item) => item.origin === "Swot Analysis").shown}</h2>
                  <Link to="/overview/swot-analysis" className="text-[#00b877] inline-flex items-center justify-center rounded-md text-[15px] font-medium hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto">
                    Open&nbsp;&nbsp;<Iconify icon="eva:arrow-forward-outline" />
                  </Link>
                </div>
                {
                  (user.upgrade == "paid" || auth == "admin" ||estimateFlag("Swot Analysis")) ?
                  SwotData.length > 0 ?
                    <Row className="">
                      <Col xs={24} sm={24} md={12} lg={12} className="p-2">
                        <div className="w-full bg-[#f1f7ff] rounded-lg">
                          <div className="flex items-center justify-center pb-1 px-1 pt-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" className="w-4 h-4 mr-2 shrink-0 ml-1 text-blue-500"><path d="m6.5 6.5 11 11"></path><path d="m21 21-1-1"></path><path d="m3 3 1 1"></path><path d="m18 22 4-4"></path><path d="m2 6 4-4"></path><path d="m3 10 7-7"></path><path d="m14 21 7-7"></path></svg><span className="text-lg text-blue-500">Strengths</span>
                          </div>
                          <div className="px-2 pt-0 relative h-20 overflow-hidden">
                            <div className="text-md will-change-transform text-center transition-all h-full flex justify-center">{SwotData[0]["Content"][0]["Description"][0]["Description"]}</div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} className="p-2">
                        <div className="w-full bg-[#fffbee] rounded-lg">
                          <div className="flex items-center justify-center pb-1 px-1 pt-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" className="w-4 h-4 mr-2 shrink-0 ml-1 text-yellow-500"><path d="M19.7 14a6.9 6.9 0 0 0 .3-2V5l-8-3-3.2 1.2"></path><path d="m2 2 20 20"></path><path d="M4.7 4.7 4 5v7c0 6 8 10 8 10a20.3 20.3 0 0 0 5.62-4.38"></path></svg><span className="text-lg text-yellow-500">Weaknesses</span>
                          </div>
                          <div className="px-2 pt-0 relative h-20 overflow-hidden">
                            <div className="text-md will-change-transform text-center transition-all h-full flex justify-center">{SwotData[0]["Content"][1]["Description"][0]["Description"]}</div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} className="p-2">
                        <div className="w-full bg-[#ebfff2] rounded-lg">
                          <div className="flex items-center justify-center pb-1 px-1 pt-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" className="w-4 h-4 mr-2 shrink-0 ml-1 text-green-500"><path d="M7 20h10"></path><path d="M10 20c5.5-2.5.8-6.4 3-10"></path><path d="M9.5 9.4c1.1.8 1.8 2.2 2.3 3.7-2 .4-3.5.4-4.8-.3-1.2-.6-2.3-1.9-3-4.2 2.8-.5 4.4 0 5.5.8z"></path><path d="M14.1 6a7 7 0 0 0-1.1 4c1.9-.1 3.3-.6 4.3-1.4 1-1 1.6-2.3 1.7-4.6-2.7.1-4 1-4.9 2z"></path></svg><span className="text-lg text-green-500">Opportunities</span>
                          </div>
                          <div className="px-2 pt-0 relative h-20 overflow-hidden">
                            <div className="text-md will-change-transform text-center transition-all h-full flex justify-center">{SwotData[0]["Content"][2]["Description"][0]["Description"]}</div>
                          </div>
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={12} className="p-2">
                        <div className="w-full bg-[#fff0f0] rounded-lg">
                          <div className="flex items-center justify-center pb-1 px-1 pt-6">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" className="w-4 h-4 mr-2 shrink-0 ml-1 text-red-500"><path d="M8.5 14.5A2.5 2.5 0 0 0 11 12c0-1.38-.5-2-1-3-1.072-2.143-.224-4.054 2-6 .5 2.5 2 4.9 4 6.5 2 1.6 3 3.5 3 5.5a7 7 0 1 1-14 0c0-1.153.433-2.294 1-3a2.5 2.5 0 0 0 2.5 2.5z"></path></svg><span className="text-lg text-red-500">Threats</span>
                          </div>
                          <div className="px-2 pt-0 relative h-20 overflow-hidden">
                            <div className="text-md will-change-transform text-center transition-all h-full flex justify-center">{SwotData[0]["Content"][3]["Description"][0]["Description"]}</div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    :
                    <Spin className="flex h-[300px] items-center justify-center" />
                    :
                    <div className="flex h-[200px] text-4xl justify-center items-center">
                      <CrownOutlined style={{ color: "#FFAE00" }} />
                    </div>
                  }

              </AntCard>
            </Row>
            <Row gutter={[24, 0]}>
              <AntCard cardclassName="cardbody" xl={12} className="p-4">
                <div className="flex space-between align-center p-4">
                  <h2 className="text-xl font-semibold mr-2">{user.companies[0].content.find((item) => item.title === "Overview").list.find((item) => item.origin === "Business Models").shown}</h2>
                  <Link to="/overview/business-models" className="text-[#00b877] inline-flex items-center justify-center rounded-md text-[15px] font-medium hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto">
                    Open&nbsp;&nbsp;<Iconify icon="eva:arrow-forward-outline" />
                  </Link>
                </div>
                {(user.upgrade == "paid" || auth == "admin" ||estimateFlag("Business Models")) ?

                  BusinessData.length > 0 ?
                    <Row className="h-[200px] overflow-hidden">
                      {BusinessData[0].hasOwnProperty("Content") && BusinessData[0]["Content"].map((item, i) => (
                        item["Description"] && item["Description"]["Overview"] ?
                          <Col span={24} className="flex items-center p-4" key={i}>
                            <div>
                              <Avatar className="bg-[#ede9fe] text-[#7c3aed] font-bold">{item["SubTitle"][0]}</Avatar>
                            </div>

                            <div className="ml-4">
                              <h4>{item["SubTitle"]}</h4>
                              <span>{item["Description"]["Overview"]}</span>
                            </div>
                          </Col>
                          :
                          null
                      ))}

                    </Row>
                    :
                    <Spin className="flex items-center justify-center" />
                    :
                    <div className="flex h-[200px] text-4xl justify-center items-center">
                      <CrownOutlined style={{ color: "#FFAE00" }} />
                    </div>
                  }

              </AntCard>
              <AntCard xl={12}>

                <div className="flex space-between align-center p-4">
                  <h2 className="text-xl font-semibold mr-2">{user.companies[0].content.find((item) => item.title === "Overview").list.find((item) => item.origin === "Viability analysis").shown}</h2>
                  <Link to="/overview/viability-analysis" className="text-[#00b877] inline-flex items-center justify-center rounded-md text-[15px] font-medium hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto">
                    Open&nbsp;&nbsp;<Iconify icon="eva:arrow-forward-outline" />
                  </Link>
                </div>
                {(user.upgrade == "paid" || auth == "admin" ||estimateFlag("Viability analysis")) ?

                  ViabilityData.length > 0 ?
                    <Col span={24} className="h-[200px] overflow-hidden">
                      {/* <div className="flex items-start justify-center h-full min-h-[170px]">
                        <div className="flex w-[360px] h-[210px] absolute left-1/2 ml-[-105px] mt-[-60px]"> */}
                      <div className='w-full h-[250px] flex items-center border justify-center mt-[-30px]'>
                        <PieChartComp value="85" />
                        <p className='lg:text-6xl font-bold text-3xl' style={{ marginLeft: '-100px' }}>85/100</p>
                      </div>
                      {/* </div>
                      </div> */}
                    </Col>
                    :
                    <Spin className="flex items-center justify-center" />
                    :
                    <div className="flex h-[200px] text-4xl justify-center items-center">
                      <CrownOutlined style={{ color: "#FFAE00" }} />
                    </div>
                  }

              </AntCard>

              <Col xs={24} md={24} lg={24} className="flex items-center p-4">
                <div className="w-6 h-6 mr-3 justify-center flex rounded-sm bg-[#D9F8E4]">
                  <div className="w-4 text-[#26B35A]"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="w-full"><path d="m7.5 4.27 9 5.15"></path><path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z"></path><path d="m3.3 7 8.7 5 8.7-5"></path><path d="M12 22V12"></path></svg></div></div>
                <div className="text-[#26B35A] ml-2 text-xl">Product & Services</div>
              </Col>
            </Row>
            <Row gutter={[24, 0]}>
              <AntCard cardclassName="cardbody" xl={12} className="p-4">
                <div className="flex space-between align-center p-4">
                  <h2 className="text-xl font-bold mr-2">{user.companies[0].content.find((item) => item.title === "Products & Services").list.find((item) => item.origin === "Core Offerings").shown}</h2>
                  <Link to="/products-services/core-offering" className="text-[#00b877] inline-flex items-center justify-center rounded-md text-[15px] font-medium hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto">
                    Open&nbsp;&nbsp;<Iconify icon="eva:arrow-forward-outline" />
                  </Link>
                </div>
                {(user.upgrade == "paid" || auth == "admin" ||estimateFlag("Core Offerings")) ?

                  CoreData.length > 0 ?
                    <Row className="h-[200px] overflow-hidden">
                      {CoreData[0].hasOwnProperty("Content") ?
                        <Col span={24} className="flex items-center p-4">
                          <div>
                            <Avatar className="bg-[#ede9fe] text-[#7c3aed] font-bold">{CoreData[0]?.["Title"]?.[0]}</Avatar>
                          </div>

                          <div className="ml-4">
                            {/* <h4>{item["SubTitle"]}</h4> */}
                            <span>{CoreData[0]?.["Summary"]}</span>
                          </div>
                        </Col>
                        :
                        null
                      }

                    </Row>
                    :
                    <Spin className="flex items-center justify-center" />
                    :
                    <div className="flex h-[200px] text-4xl justify-center items-center">
                      <CrownOutlined style={{ color: "#FFAE00" }} />
                    </div>
                  }
              </AntCard>
              <AntCard xl={12} className="w-full h-full">
                <div className="flex space-between align-center p-4">
                  <h2 className="text-xl font-bold mr-2">{user.companies[0].content.find((item) => item.title === "Products & Services").list.find((item) => item.origin === "Secondary Offering").shown}</h2>
                  <Link to="/products-services/secondary-offering" className="text-[#00b877] inline-flex items-center justify-center rounded-md text-[15px] font-medium hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto">
                    Open&nbsp;&nbsp;<Iconify icon="eva:arrow-forward-outline" />
                  </Link>
                </div>
                {(user.upgrade == "paid" || auth == "admin" || estimateFlag("Secondary Offering")) ?

                  SecondaryData.length > 0 ?
                    <Row className="h-[200px] overflow-hidden">
                      {SecondaryData[0].hasOwnProperty("Content") ?
                        <Col span={24} className="flex items-center p-4">
                          <div>
                            <Avatar className="bg-[#ede9fe] text-[#7c3aed] font-bold">{SecondaryData[0]?.["Title"]?.[0]}</Avatar>
                          </div>

                          <div className="ml-4">
                            {/* <h4>{item["SubTitle"]}</h4> */}
                            <span>{SecondaryData[0]?.["Summary"]}</span>
                          </div>
                        </Col>
                        :
                        null
                      }

                    </Row>
                    :
                    <Spin className="flex items-center justify-center" />

                  :
                  <div className="flex h-[200px] text-4xl justify-center items-center">
                    <CrownOutlined style={{ color: "#FFAE00" }} />
                  </div>
                }

              </AntCard>
            </Row>
            <Row gutter={[24, 0]}>
              <AntCard cardclassName="cardbody" xl={12} className="p-4">
                <div className="flex space-between align-center p-4">
                  <h2 className="text-xl font-bold mr-2">{user.companies[0].content.find((item) => item.title === "Products & Services").list.find((item) => item.origin === "Expansion Opportunities").shown}</h2>
                  <Link to="/products-services/expansion-opportunities" className="text-[#00b877] inline-flex items-center justify-center rounded-md text-[15px] font-medium hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto">
                    Open&nbsp;&nbsp;<Iconify icon="eva:arrow-forward-outline" />
                  </Link>
                </div>
                {(user.upgrade == "paid" || auth == "admin" ||estimateFlag("Expansion Opportunities")) ?

                  ExpansionData.length > 0 ?
                    <Row className="h-[200px] overflow-hidden">
                      {ExpansionData[0].hasOwnProperty("Content") ?
                        <Col span={24} className="flex items-center p-4">
                          <div>
                            <Avatar className="bg-[#ede9fe] text-[#7c3aed] font-bold">{ExpansionData[0]?.["Title"]?.[0]}</Avatar>
                          </div>

                          <div className="ml-4">
                            {/* <h4>{item["SubTitle"]}</h4> */}
                            <span>{ExpansionData[0]?.["Summary"]}</span>
                          </div>
                        </Col>
                        :
                        null
                      }

                    </Row>
                    :
                    <Spin className="flex items-center justify-center" />
                    :
                    <div className="flex h-[200px] text-4xl justify-center items-center">
                      <CrownOutlined style={{ color: "#FFAE00" }} />
                    </div>
                }
              </AntCard>
              <AntCard xl={12} className="w-full h-full">
                <div className="flex space-between align-center p-4">
                  <h2 className="text-xl font-bold mr-2">{user.companies[0].content.find((item) => item.title === "Products & Services").list.find((item) => item.origin === "Customer Service").shown}</h2>
                  <Link to="/products-services/customer-service" className="text-[#00b877] inline-flex items-center justify-center rounded-md text-[15px] font-medium hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto">
                    Open&nbsp;&nbsp;<Iconify icon="eva:arrow-forward-outline" />
                  </Link>
                </div>
                {(user.upgrade == "paid" || auth == "admin"||estimateFlag("Customer Service")) ?
                  CustomerData.length > 0 ?
                    <Row className="h-[200px] overflow-hidden">
                      {CustomerData[0].hasOwnProperty("Content") && CustomerData[0]["Content"].map((item, i) => (
                        item["Description"] && item["Description"]["Overview"] ?
                          <Col span={24} className="flex items-center p-4" key={i}>
                            <div>
                              <Avatar className="bg-[#ede9fe] text-[#7c3aed] font-bold">{item["SubTitle"][0]}</Avatar>
                            </div>

                            <div className="ml-4">
                              <h4>{item["SubTitle"]}</h4>
                              <span>{item["Description"]["Overview"]}</span>
                            </div>
                          </Col>
                          :
                          null
                      ))}

                    </Row>
                    :
                    <Spin className="flex items-center justify-center" />
                  :
                  <div className="flex h-[200px] text-4xl justify-center items-center">
                    <CrownOutlined style={{ color: "#FFAE00" }} />
                  </div>
                }

              </AntCard>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={24} md={24} lg={24} className="flex items-center p-4">
                <div className="w-6 h-6 mr-3 justify-center flex rounded-sm bg-[#FAE1F8]">
                  <div className="w-4 text-[#9B2791]"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="w-full"><path d="M3 3v18h18"></path><rect width="4" height="7" x="7" y="10" rx="1"></rect><rect width="4" height="12" x="15" y="5" rx="1"></rect></svg></div></div>
                <div className="text-[#9b2791] ml-2 text-xl">Market Research</div>
              </Col>
              <AntCard cardclassName="cardbody" xl={12} className="p-4">
                <div className="flex space-between align-center p-4">
                  <h2 className="text-xl font-bold mr-2">{user.companies[0].content.find((item) => item.title === "Market Research").list.find((item) => item.origin === "Industry Overview").shown}</h2>
                  <Link to="/market-research/industry-overview" className="text-[#00b877] inline-flex items-center justify-center rounded-md text-[15px] font-medium hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto">
                    Open&nbsp;&nbsp;<Iconify icon="eva:arrow-forward-outline" />
                  </Link>
                </div>
                {(user.upgrade == "paid" || auth == "admin" ||estimateFlag("Industry Overview")) ?

                  IndustryData.length > 0 ?
                    <div className='overflow-hidden mt- h-[200px] text-muted-foreground text-xs border flex'>
                      {/* <div>
                    <FinancialPieChart1 year={2024} />
                  </div> */}
                      <div className="py-4 sm:py-8 px-4 flex flex-col gap-8 false">
                        <div className="w-full">
                          <div className="text-base w-full items-stretch flex">
                            <div className="w-1 mr-2 rounded-[1px] shrink-0" style={{ backgroundColor: "rgb(36, 99, 235)" }}>
                            </div><div className="flex flex-col w-full false">
                              <span className="w-full false" style={{ fontWeight: '500', color: 'black' }}>40% - Students and Educators</span>
                              <span className="text-sm text-muted-foreground w-full false">Educational institutions and teachers seeking innovative religious studies resources.</span>
                            </div>
                          </div>
                        </div>
                        <div className="w-full">
                          <div className="text-base w-full items-stretch flex">
                            <div className="w-1 mr-2 rounded-[1px] shrink-0" style={{ backgroundColor: "rgb(62, 121, 246)" }}></div>
                            <div className="flex flex-col w-full false">
                              <span className="w-full false" style={{ fontWeight: '500', color: 'black' }}>30% - Interfaith Organizations</span>
                              <span className="text-sm text-muted-foreground w-full false">Groups and individuals dedicated to promoting interfaith dialogue and understanding.</span>
                            </div></div></div>
                        <div className="w-full">
                          <div className="text-base w-full items-stretch flex">
                            <div className="w-1 mr-2 rounded-[1px] shrink-0" style={{ backgroundColor: "rgb(86, 138, 251)" }}></div>
                            <div className="flex flex-col w-full false"><span className="w-full false" style={{ fontWeight: '500', color: 'black' }}>30% - General Public</span>
                              <span className="text-sm text-muted-foreground w-full false">Curious individuals interested in exploring different religions and cultures.</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <Spin className="flex items-center justify-center" />
                    :
                    <div className="flex h-[200px] text-4xl justify-center items-center">
                      <CrownOutlined style={{ color: "#FFAE00" }} />
                    </div>
                }

              </AntCard>
              <AntCard cardclassName="cardbody" xl={12} className="p-4">
                <div className="flex space-between align-center p-4">
                  <h2 className="text-xl font-bold mr-2">{user.companies[0].content.find((item) => item.title === "Market Research").list.find((item) => item.origin === "Target Audience").shown}</h2>
                  <Link to="/market-research/target-audience" className="text-[#00b877] inline-flex items-center justify-center rounded-md text-[15px] font-medium hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto">
                    Open&nbsp;&nbsp;<Iconify icon="eva:arrow-forward-outline" />
                  </Link>
                </div>
                {(user.upgrade == "paid" || auth == "admin" ||estimateFlag("Target Audience")) ?

                  TargetData.length > 0 ?
                    <Row className="h-[200px] overflow-hidden">
                      {TargetData[0].hasOwnProperty("Content") ?
                        <Col span={24} className="flex items-center p-4">
                          <div>
                            <Avatar className="bg-[#ede9fe] text-[#7c3aed] font-bold">{TargetData[0]?.["Title"]?.[0]}</Avatar>
                          </div>

                          <div className="ml-4">
                            {/* <h4>{item["SubTitle"]}</h4> */}
                            <span>{TargetData[0]?.["Summary"]}</span>
                          </div>
                        </Col>
                        :
                        null
                      }

                    </Row>
                    :
                    <Spin className="flex items-center justify-center" />
                    :
                    <div className="flex h-[200px] text-4xl justify-center items-center">
                      <CrownOutlined style={{ color: "#FFAE00" }} />
                    </div>
                  }

              </AntCard>
              <AntCard cardclassName="cardbody" xl={12} className="p-4">
                <div className="flex space-between align-center p-4">
                  <h2 className="text-xl font-bold mr-2">{user.companies[0].content.find((item) => item.title === "Market Research").list.find((item) => item.origin === "Market size and Trends").shown}</h2>
                  <Link to="/market-research/market-size" className="text-[#00b877] inline-flex items-center justify-center rounded-md text-[15px] font-medium hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto">
                    Open&nbsp;&nbsp;<Iconify icon="eva:arrow-forward-outline" />
                  </Link>
                </div>
                {(user.upgrade == "paid" || auth == "admin" ||estimateFlag("Market size and Trends")) ?

                  MarketsizeData.length > 0 ?
                    <Row className="h-[200px] overflow-hidden">
                      {MarketsizeData[0].hasOwnProperty("Content") ?
                        <Col span={24} className="flex items-center p-4">
                          <div>
                            <Avatar className="bg-[#ede9fe] text-[#7c3aed] font-bold">{MarketsizeData[0]?.["Title"]?.[0]}</Avatar>
                          </div>

                          <div className="ml-4">
                            {/* <h4>{item["SubTitle"]}</h4> */}
                            <span>{MarketsizeData[0]?.["Summary"]}</span>
                          </div>
                        </Col>
                        :
                        null
                      }

                    </Row>
                    :
                    <Spin className="flex items-center justify-center" />
                    :
                    <div className="flex h-[200px] text-4xl justify-center items-center">
                      <CrownOutlined style={{ color: "#FFAE00" }} />
                    </div>
                }

              </AntCard>
              <AntCard xl={12} className="w-full h-full">
                <div className="flex space-between align-center p-4">
                  <h2 className="text-xl font-bold mr-2">{user.companies[0].content.find((item) => item.title === "Market Research").list.find((item) => item.origin === "Competitor Analysis").shown}</h2>
                  <Link to="/market-research/competitor-analysis" className="text-[#00b877] inline-flex items-center justify-center rounded-md text-[15px] font-medium hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto">
                    Open&nbsp;&nbsp;<Iconify icon="eva:arrow-forward-outline" />
                  </Link>
                </div>
                {(user.upgrade == "paid" || auth == "admin"||estimateFlag("Competitor Analysis")) ?
                  CompetitorData.length > 0 ?
                    <Row className="h-[200px] overflow-hidden">
                      {CompetitorData[0].hasOwnProperty("Content") ?
                        <Col span={24} className="flex items-center p-4">
                          <div>
                            <Avatar className="bg-[#ede9fe] text-[#7c3aed] font-bold">{CompetitorData[0]?.["Title"]?.[0]}</Avatar>
                          </div>

                          <div className="ml-4">
                            {/* <h4>{item["SubTitle"]}</h4> */}
                            <span>{CompetitorData[0]?.["Summary"]}</span>
                          </div>
                        </Col>
                        :
                        null
                      }

                    </Row>
                    :
                    <Spin className="flex items-center justify-center" />
                  :
                  <div className="flex h-[200px] text-4xl justify-center items-center">
                    <CrownOutlined style={{ color: "#FFAE00" }} />
                  </div>
                }

              </AntCard>
            </Row>
            <Row gutter={[24, 0]}>
              <Col xs={24} md={24} lg={24} className="flex items-center p-4">
                <div className="w-6 h-6 mr-3 justify-center flex rounded-sm bg-green-50">
                  <div className="w-4 text-green-500 bg-green-50"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-full"><polyline points="22 7 13.5 15.5 8.5 10.5 2 17"></polyline><polyline points="16 7 22 7 22 13"></polyline></svg></div></div>
                <div className="ml-2 text-xl text-green-500">Sales & Marketing</div>
              </Col>
              <AntCard xl={12} className="w-full h-full">
                <div className="flex space-between align-center p-4">
                  <h2 className="text-xl font-bold mr-2">{user.companies[0].content.find((item) => item.title === "Sales and Marketing").list.find((item) => item.origin === "Branding & Identity").shown}</h2>
                  <Link to="/sales-marketing/branding-identity" className="text-[#00b877] inline-flex items-center justify-center rounded-md text-[15px] font-medium hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto">
                    Open&nbsp;&nbsp;<Iconify icon="eva:arrow-forward-outline" />
                  </Link>
                </div>
                {(user.upgrade == "paid" || auth == "admin" ||estimateFlag("Branding & Identity")) ?

                  BrandingData.length > 0 ?
                    <Row className="h-[200px] overflow-hidden">
                      {BrandingData[0].hasOwnProperty("Content") && BrandingData[0]["Content"].map((item, i) => (
                        <Col span={24} className="flex items-center p-4" key={i}>
                          <div>
                            <Avatar className="bg-[#ede9fe] text-[#7c3aed] font-bold">{item["SubTitle"][0]}</Avatar>
                          </div>

                          <div className="ml-4">
                            <h4>{item["SubTitle"]}</h4>
                            <span>{item["Description"]}</span>
                          </div>
                        </Col>
                      ))}

                    </Row>
                    :
                    <Spin className="flex items-center justify-center" />
                    :
                    <div className="flex h-[200px] text-4xl justify-center items-center">
                      <CrownOutlined style={{ color: "#FFAE00" }} />
                    </div>
                  }

              </AntCard>

              <AntCard cardclassName="cardbody" xl={12} className="p-4">
                <div className="w-full max-w-7xl p-3 sm:p-6 pt-2 sm:pt-4 m-auto bg-white rounded-lg h-full flex flex-col group">
                  <div className="flex space-between align-center p-4 px-0 pb-0">
                    <h2 className="text-xl font-bold mr-2 mb-2">{user.companies[0].content.find((item) => item.title === "Sales and Marketing").list.find((item) => item.origin === "Marketing Ideas").shown}</h2>
                    <Link to="/sales-marketing/marketing-ideas" className="text-[#00b877] inline-flex items-center justify-center rounded-md text-[15px] font-medium hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto">
                      Open&nbsp;&nbsp;<Iconify icon="eva:arrow-forward-outline" />
                    </Link>
                  </div>
                  {(user.upgrade == "paid" || auth == "admin" ||estimateFlag("Marketing Ideas")) ?

                    MarketingIdeas.length > 0 ?
                      <Row className="h-[200px] overflow-hidden">
                        <span>{MarketingIdeas[0]["Summary"]}</span>
                        {MarketingIdeas[0].hasOwnProperty("Content") && MarketingIdeas[0]["Content"].map((item, i) => (
                          <div className="border rounded-lg p-2 mt-2 block">
                            <span className="text-gray text-[14px]">{item["Title"]}</span>
                            <span>{item["Description"]}</span>
                          </div>
                        ))}

                      </Row>
                      :
                      <Spin className="flex items-center justify-center" />
                      :
                      <div className="flex h-[200px] text-4xl justify-center items-center">
                        <CrownOutlined style={{ color: "#FFAE00" }} />
                      </div>
                    }

                </div>

              </AntCard>
              <AntCard cardclassName="cardbody" xl={12} className="p-4">
                <div className="flex space-between align-center p-4">
                  <h2 className="text-xl font-bold mr-2">{user.companies[0].content.find((item) => item.title === "Sales and Marketing").list.find((item) => item.origin === "Customer Retention").shown}</h2>
                  <Link to="/sales-marketing/customer-retention" className="text-[#00b877] inline-flex items-center justify-center rounded-md text-[15px] font-medium hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto">
                    Open&nbsp;&nbsp;<Iconify icon="eva:arrow-forward-outline" />
                  </Link>
                </div>
                {(user.upgrade == "paid" || auth == "admin" ||estimateFlag("Customer Retention")) ?

                  RetentionData.length > 0 ?
                    <Row className="h-[200px] overflow-hidden">
                      {RetentionData[0].hasOwnProperty("Content") && RetentionData[0]["Content"].map((item, i) => (
                        item["Description"] && item["Description"]["Overview"] ?
                          <Col span={24} className="flex items-center p-4" key={i}>
                            <div>
                              <Avatar className="bg-[#ede9fe] text-[#7c3aed] font-bold">B</Avatar>
                            </div>

                            <div className="ml-4">
                              <h4>{item["SubTitle"]}</h4>
                              <span>{item["Description"]["Overview"]}</span>
                            </div>
                          </Col>
                          :
                          null
                      ))}

                    </Row>
                    :
                    <Spin className="flex items-center justify-center" />
                    :
                  <div className="flex h-[200px] text-4xl justify-center items-center">
                    <CrownOutlined style={{ color: "#FFAE00" }} />
                  </div>
                }

              </AntCard>
              <AntCard xl={12} className="w-full h-full">
                <div className="flex space-between align-center p-4">
                  <h2 className="text-xl font-bold mr-2">{user.companies[0].content.find((item) => item.title === "Sales and Marketing").list.find((item) => item.origin === "Online Presence").shown}</h2>
                  <Link to="/sales-marketing/online-presence" className="text-[#00b877] inline-flex items-center justify-center rounded-md text-[15px] font-medium hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto">
                    Open&nbsp;&nbsp;<Iconify icon="eva:arrow-forward-outline" />
                  </Link>
                </div>
                {(user.upgrade == "paid" || auth == "admin"||estimateFlag("Online Presence")) ?
                  OnlineData.length > 0 ?
                    <Row className="h-[200px] overflow-hidden">
                      <Col span={24} className="flex items-center p-4">
                        <div className="ml-4">
                          <h4>{OnlineData[0]["Title"]}</h4>
                          <span>{OnlineData[0]["Summary"]}</span>
                        </div>
                      </Col>

                    </Row>
                    :
                    <Spin className="flex items-center justify-center" />
                  :
                  <div className="flex h-[200px] text-4xl justify-center items-center">
                    <CrownOutlined style={{ color: "#FFAE00" }} />
                  </div>
                }

              </AntCard>
            </Row>

            <Row gutter={[24, 0]}>
              <Col xs={24} md={24} lg={24} className="flex items-center p-4">
                <div className="w-6 h-6 mr-3 justify-center flex rounded-sm bg-[#D4E5F6]">
                  <div className="w-4 text-[#2A7EAF]"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="w-full"><rect width="18" height="18" x="3" y="3" rx="2"></rect><path d="M8 7v7"></path><path d="M12 7v4"></path><path d="M16 7v9"></path></svg></div></div>
                <div className="text-[#2A7EAF] ml-2 text-xl">Operations</div>
              </Col>
              <AntCard cardclassName="cardbody" xl={12} className="p-4">
                <div className="flex space-between align-center p-4">
                  <h2 className="text-xl font-bold mr-2">{user.companies[0].content.find((item) => item.title === "Operations").list.find((item) => item.origin === "Operations Plan").shown}</h2>
                  <Link to="/operations/plan" className="text-[#00b877] inline-flex items-center justify-center rounded-md text-[15px] font-medium hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto">
                    Open&nbsp;&nbsp;<Iconify icon="eva:arrow-forward-outline" />
                  </Link>
                </div>
                {(user.upgrade == "paid" || auth == "admin" ||estimateFlag("Operations Plan")) ?

                  OperationData.length > 0 && OperationData[0].hasOwnProperty("content") ?
                    <Row className="h-[200px] overflow-hidden">
                      <Col span={24} className="flex items-center p-4">
                        <div className="ml-4">
                          <h4>{OperationData[0]["content"][0]["SubTitle"]}</h4>
                          <span>{OperationData[0]["content"][0]["Description"]}</span>
                        </div>
                      </Col>

                    </Row>
                    :
                    <Spin className="flex items-center justify-center" />
                    :
                    <div className="flex h-[200px] text-4xl justify-center items-center">
                      <CrownOutlined style={{ color: "#FFAE00" }} />
                    </div>
                  }



              </AntCard>
              <AntdCard>
                <div className="flex space-between align-center p-4">
                  <h2 className="text-xl font-bold mr-2">{user.companies[0].content.find((item) => item.title === "Operations").list.find((item) => item.origin === "Team & Roles").shown}</h2>
                  <Link to="/operations/team-roles" className="text-[#00b877] inline-flex items-center justify-center rounded-md text-[15px] font-medium hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto">
                    Open&nbsp;&nbsp;<Iconify icon="eva:arrow-forward-outline" />
                  </Link>
                </div>
                {(user.upgrade == "paid" || auth == "admin"||estimateFlag("Team & Roles")) ?
                  TeamData.length > 0 ?
                    <Row className="h-[200px] overflow-hidden">
                      {TeamData[0].hasOwnProperty("Content") && TeamData[0]["Content"].map((item, i) => (
                        item["Description"] && item["Description"]["Overview"] ?
                          <Col span={24} className="flex items-center p-4" key={i}>
                            <div>
                              <Avatar className="bg-[#ede9fe] text-[#7c3aed] font-bold">B</Avatar>
                            </div>

                            <div className="ml-4">
                              <h4>{item["SubTitle"]}</h4>
                              <span>{item["Description"]["Overview"]}</span>
                            </div>
                          </Col>
                          :
                          null
                      ))}

                    </Row>
                    :
                    <Spin className="flex items-center justify-center" />
                  :
                  <div className="flex h-[200px] text-4xl justify-center items-center">
                    <CrownOutlined style={{ color: "#FFAE00" }} />
                  </div>
                }

              </AntdCard>
              <AntCard cardclassName="cardbody" xl={12} className="p-4">
                <div className="w-full max-w-7xl p-3 sm:p-6 pt-2 sm:pt-4 m-auto bg-white rounded-lg h-full flex flex-col group">
                  <div className="flex space-between align-center p-4">
                    <h2 className="text-xl font-bold mr-2">{user.companies[0].content.find((item) => item.title === "Operations").list.find((item) => item.origin === "Risk Analysis").shown}</h2>
                    <Link to="/operations/risk-analysis" className="text-[#00b877] inline-flex items-center justify-center rounded-md text-[15px] font-medium hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto">
                      Open&nbsp;&nbsp;<Iconify icon="eva:arrow-forward-outline" />
                    </Link>
                  </div>
                  {(user.upgrade == "paid" || auth == "admin"||estimateFlag("Risk Analysis")) ?
                    RiskData.length > 0 ?
                      <Row className="h-[200px] overflow-hidden">
                        {RiskData[0].hasOwnProperty("content") && RiskData[0]["content"].map((item, i) => (
                          <Col span={24} className="flex items-center p-4" key={i}>

                            <div className="ml-4">
                              <h4>{item["SubTitle"]}</h4>
                              <span>{item["Description"]}</span>
                            </div>
                          </Col>
                        ))}

                      </Row>
                      :
                      <Spin className="flex items-center justify-center" />
                    :
                    <div className="flex h-[200px] text-4xl justify-center items-center">
                      <CrownOutlined style={{ color: "#FFAE00" }} />
                    </div>
                  }

                </div>


              </AntCard>
              <AntCard cardclassName="cardbody" xl={12} className="p-4">
                <div className="w-full max-w-7xl p-3 sm:p-6 pt-2 sm:pt-4 m-auto bg-white rounded-lg h-full flex flex-col group">
                  <div className="flex space-between align-center p-4">
                    <h2 className="text-xl font-bold mr-2">{user.companies[0].content.find((item) => item.title === "Operations").list.find((item) => item.origin === "Regulatory Compliance").shown}</h2>
                    <Link to="/operations/regulatory-compliance" className="text-[#00b877] inline-flex items-center justify-center rounded-md text-[15px] font-medium hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto">
                      Open&nbsp;&nbsp;<Iconify icon="eva:arrow-forward-outline" />
                    </Link>
                  </div>
                  {(user.upgrade == "paid" || auth == "admin"||estimateFlag("Regulatory Compliance")) ?
                    RegulatoryData.length > 0 ?
                      <Row className="h-[200px] overflow-hidden">
                        <Col span={24} className="flex items-center p-4">
                          <div className="ml-4">
                            <h4>{RegulatoryData[0]["Title"]}</h4>
                            <span>{RegulatoryData[0]["Summary"]}</span>
                          </div>
                        </Col>

                      </Row>
                      :
                      <Spin className="flex items-center justify-center" />
                    :
                    <div className="flex h-[200px] text-4xl justify-center items-center">
                      <CrownOutlined style={{ color: "#FFAE00" }} />
                    </div>
                  }

                </div>


              </AntCard>
            </Row>
            {/* <Row gutter={[24, 0]}>
              <Col xs={24} md={24} lg={24} className="flex items-center p-4">
                <div className="w-6 h-6 mr-3 justify-center flex rounded-sm bg-[#D3F8EF]">
                  <div className="w-4 text-[#23AB8B]"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="w-full"><line x1="12" x2="12" y1="2" y2="22"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg></div></div>
                <div className="text-[#23AB8B] ml-2 text-xl">Financials</div>
              </Col>
              <AntCard cardclassName="cardbody" xl={12} className="p-4">
                <div className="w-full max-w-7xl p-3 sm:p-6 pt-2 sm:pt-4 m-auto bg-white rounded-lg h-full flex flex-col group">
                  <div className="flex space-between align-center p-4">
                    <h2 className="text-xl font-bold mr-2">{user.companies[0].content.find((item) => item.title === "Financials").list.find((item) => item.origin === "Revenue").shown}</h2>
                    <Link to="/financials/revenue" className="text-[#00b877] inline-flex items-center justify-center rounded-md text-[15px] font-medium hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto">
                      Open&nbsp;&nbsp;<Iconify icon="eva:arrow-forward-outline" />
                    </Link>
                  </div>
                </div>
                {(user.upgrade == "paid" || auth == "admin"||estimateFlag("Revenue")) ?
                  pageContents.contents.length >= 20 ?
                    <Row>
                      <Col span={24}>
                        <div className="flex items-start justify-center h-full min-h-[170px]">
                          <div className="lg:h-[200px] block w-full">
                            <BarChart />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    :
                    <Spin className="flex h-[200px] items-center justify-center" />
                    :
                  <div className="flex h-[200px] text-4xl justify-center items-center">
                    <CrownOutlined style={{ color: "#FFAE00" }} />
                  </div>
                }

              </AntCard>
              <AntCard cardclassName="cardbody" xl={12} className="p-4">
                <div className="w-full max-w-7xl p-3 sm:p-6 pt-2 sm:pt-4 m-auto bg-white rounded-lg h-full flex flex-col group">
                  <div className="flex space-between align-center p-4">
                    <h2 className="text-xl font-bold mr-2">{user.companies[0].content.find((item) => item.title === "Financials").list.find((item) => item.origin === "Expenses").shown}</h2>
                    <Link to="/financials/expenses" className="text-[#00b877] inline-flex items-center justify-center rounded-md text-[15px] font-medium hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto">
                      Open&nbsp;&nbsp;<Iconify icon="eva:arrow-forward-outline" />
                    </Link>
                  </div>

                </div>
                {(user.upgrade == "paid" || auth == "admin"||estimateFlag("Expenses")) ?
                  pageContents.contents.length >= 21 ?
                    <Row>
                      <Col span={24}>
                        <div className="flex items-start justify-center h-full min-h-[170px]">
                          <div className="lg:h-[200px] block w-full">
                            <BarChart />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    : <Spin className="flex h-[200px] items-center justify-center" />
                  :
                  <div className="flex h-[200px] text-4xl justify-center items-center">
                    <CrownOutlined style={{ color: "#FFAE00" }} />
                  </div>
                }



              </AntCard>
              <AntCard cardclassName="cardbody" xl={12} className="p-4">
                <div className="w-full max-w-7xl p-3 sm:p-6 pt-2 sm:pt-4 m-auto bg-white rounded-lg h-full flex flex-col group">
                  <div className="flex space-between align-center p-4">
                    <h2 className="text-xl font-bold mr-2">{user.companies[0].content.find((item) => item.title === "Financials").list.find((item) => item.origin === "Balance Sheet").shown}</h2>
                    <Link to="/financials/balance-sheet" className="text-[#00b877] inline-flex items-center justify-center rounded-md text-[15px] font-medium hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto">
                      Open&nbsp;&nbsp;<Iconify icon="eva:arrow-forward-outline" />
                    </Link>
                  </div>

                </div>
                {(user.upgrade == "paid" || auth == "admin"||estimateFlag("Financing")) ?
                  pageContents.contents.length >= 22 ?
                    <Row>
                      <Col span={24}>
                        <div className="flex items-start justify-center h-full min-h-[170px]">
                          <div className="lg:h-[200px] block w-full">
                            <AreaChart year={areaYear} />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    : <Spin className="flex h-[200px] items-center justify-center" />
                  :
                  <div className="flex h-[200px] text-4xl justify-center items-center">
                    <CrownOutlined style={{ color: "#FFAE00" }} />
                  </div>
                }

              </AntCard>
              <div xl={12} className="p-4">

              </div>

              <AntCard cardclassName="cardbody" xl={12} className="p-4">
                <div className="w-full max-w-7xl p-3 sm:p-6 pt-2 sm:pt-4 m-auto bg-white rounded-lg h-full flex flex-col group">
                  <div className="flex space-between align-center p-4 pb-0">
                    <h2 className="text-xl font-bold mr-2">{user.companies[0].content.find((item) => item.title === "Financials").list.find((item) => item.origin === "Costs Breakdown").shown}</h2>
                    <Link to="../financials/costs-breakdown" className="text-[#00b877] inline-flex items-center justify-center rounded-md text-[15px] font-medium hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto">
                      Open&nbsp;&nbsp;<Iconify icon="eva:arrow-forward-outline" />
                    </Link>
                  </div>
                  {(user.upgrade == "paid" || auth == "admin"||estimateFlag("Costs Breakdown")) ?
                    CostsBreakDown.length > 0 ?
                      <Row className="h-[200px] overflow-hidden">
                        <Col span={24} className="flex items-center p-2 pt-0">
                         
                          <div className="block px-4">
                            <p className="text-[16px] mb-1">{CostsBreakDown[0]["Title"]}</p>
                            <span className="text-gray">{CostsBreakDown[0]["Summary"]}</span>
                          </div>
                        </Col>

                      </Row>
                      :
                      <Spin className="flex items-center justify-center" />
                    :
                    <div className="flex h-[200px] text-4xl justify-center items-center">
                      <CrownOutlined style={{ color: "#FFAE00" }} />
                    </div>
                  }

                </div>
              </AntCard>
              <AntCard cardclassName="cardbody" xl={12} className="p-4">
                <div className="w-full max-w-7xl p-3 sm:p-6 pt-2 sm:pt-4 m-auto bg-white rounded-lg h-full flex flex-col group">
                  <div className="flex space-between align-center p-4 pb-0">
                    <div className="block">
                      <h2 className="text-xl font-bold mr-2 mb-2">{user.companies[0].content.find((item) => item.title === "Financials").list.find((item) => item.origin === "Funding Plan").shown}</h2>
                      <span className="text-[16px]">Funding Plan</span>
                    </div>

                    <Link to="../financials/funding-plan" className="text-[#00b877] inline-flex items-center justify-center rounded-md text-[15px] font-medium hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto">
                      Open&nbsp;&nbsp;<Iconify icon="eva:arrow-forward-outline" />
                    </Link>
                  </div>
                  {(user.upgrade == "paid" || auth == "admin"||estimateFlag("Funding Plan")) ?
                    FundingPlan.length > 0 ?
                      <Row className="h-[200px] overflow-hidden">
                        {FundingPlan[0].hasOwnProperty("Content") && FundingPlan[0]["Content"].map((item, i) => (
                          <Col span={24} className="flex items-center p-2" key={i}>

                            <ol className="ml-4">
                              {item["Content"].map((item1, j) => (
                                <div>
                                  <span className="text-gray"><span>{j + 1 + ". " + item1["SubTitle"] + ": "}</span>{item1["Description"]}</span>
                                  <br />
                                </div>

                              ))}
                            </ol>
                          </Col>
                        ))}

                      </Row>
                      :
                      <Spin className="flex items-center justify-center" />
                    :
                    <div className="flex h-[200px] text-4xl justify-center items-center">
                      <CrownOutlined style={{ color: "#FFAE00" }} />
                    </div>
                  }

                </div>

              </AntCard>
            </Row> */}
            <Row gutter={[24, 0]}>
              <Col xs={24} md={24} lg={24} className="flex items-center p-4">
                <div className="w-6 h-6 mr-3 justify-center flex rounded-sm bg-[#FBEDD6]">
                  <div className="w-4 text-[#E69B23]"><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="w-full"
                  >
                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                  </svg></div></div>
                <div className="text-[#E69B23] ml-2 text-xl">Implementation Plan</div>
              </Col>
              <AntCard cardclassName="cardbody" xl={12} className="p-4">
                <div className="w-full max-w-7xl p-3 sm:p-6 pt-2 sm:pt-4 m-auto bg-white rounded-lg h-full flex flex-col group">
                  <div className="flex space-between align-center p-4 pb-0">
                    <div className="block">
                      <h2 className="text-xl font-bold mr-2">{user.companies[0].content.find((item) => item.title === "Implementations Plan").list.find((item) => item.origin === "Pre-Launch").shown}</h2>
                      <span className="text-[16px]">Implementation Plan:</span>
                    </div>

                    <Link to='../implementation-plan/pre-launch' className="text-[#00b877] inline-flex items-center justify-center rounded-md text-[15px] font-medium hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto">
                      Open&nbsp;&nbsp;<Iconify icon="eva:arrow-forward-outline" />
                    </Link>
                  </div>
                  {(user.upgrade == "paid" || auth == "admin"||estimateFlag("Pre-Launch")) ?
                    PreLaunch.length > 0 ?
                      <Row className="h-[200px] overflow-hidden">
                        {PreLaunch[0].hasOwnProperty("Content") && PreLaunch[0]["Content"].map((item, i) => (
                          <Col span={24} className="flex items-center p-2" key={i}>
                            {/* <div>
                              <Avatar className="bg-[#ede9fe] text-[#7c3aed] font-bold">{item["SubTitle"][0]}</Avatar>
                            </div> */}

                            <div className="ml-4">
                              <span>{i + 1 + ". " + item["SubTitle"]}</span>
                              <ul style={{ listStyleType: "disc", color: "gray", marginLeft: '20px' }}>{item["Description"].map((item1, j) => (
                                <li>{item1["Description"]}</li>
                              ))}</ul>
                            </div>
                          </Col>
                        ))}

                      </Row>
                      :
                      <Spin className="flex items-center justify-center" />
                    :
                    <div className="flex h-[200px] text-4xl justify-center items-center">
                      <CrownOutlined style={{ color: "#FFAE00" }} />
                    </div>
                  }

                </div>


              </AntCard>
              <AntCard cardclassName="cardbody" xl={12} className="p-4">
                <div className="w-full max-w-7xl p-3 sm:p-6 pt-2 sm:pt-4 m-auto bg-white rounded-lg h-full flex flex-col group">
                  <div className="flex space-between align-center p-4 pb-0">
                    <h2 className="text-xl font-bold mr-2">{user.companies[0].content.find((item) => item.title === "Implementations Plan").list.find((item) => item.origin === "Post-Launch").shown}</h2>
                    <Link to="../implementation-plan/post-launch" className="text-[#00b877] inline-flex items-center justify-center rounded-md text-[15px] font-medium hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto">
                      Open&nbsp;&nbsp;<Iconify icon="eva:arrow-forward-outline" />
                    </Link>
                  </div>
                  {(user.upgrade == "paid" || auth == "admin"||estimateFlag("Post-Launch")) ?
                    PostLaunch.length > 0 ?
                      <Row className="h-[200px] overflow-hidden">
                        {PostLaunch[0].hasOwnProperty("Content") && PostLaunch[0]["Content"].map((item, i) => (
                          <Col span={24} className="flex items-center p-2 pt-0" key={i}>
                            {/* <div>
                              <Avatar className="bg-[#ede9fe] text-[#7c3aed] font-bold">{item["SubTitle"][0]}</Avatar>
                            </div> */}
                            <div className="block px-4">
                              <h4>{PostLaunch[0]["Title"]}</h4>
                              <br />
                              <span>{PostLaunch[0]["Summary"]}</span>
                            </div>
                          </Col>
                        ))}

                      </Row>
                      :
                      <Spin className="flex items-center justify-center" />
                    :
                    <div className="flex h-[200px] text-4xl justify-center items-center">
                      <CrownOutlined style={{ color: "#FFAE00" }} />
                    </div>
                  }

                </div>


              </AntCard>
              <AntCard cardclassName="cardbody" xl={12} className="p-4">
                <div className="w-full max-w-7xl p-3 sm:p-6 pt-2 sm:pt-4 m-auto bg-white rounded-lg h-full flex flex-col group">
                  <div className="flex space-between align-center p-4 pb-0">
                    <div className="block">
                      <h2 className="text-xl font-bold mr-2">{user.companies[0].content.find((item) => item.title === "Implementations Plan").list.find((item) => item.origin === "5 Year Plan").shown}</h2>
                      <span className="text-[16px]">Implementation Plan (Years 2-5)</span>
                    </div>

                    <Link to="../implementation-plan/five-year-plan" className="text-[#00b877] inline-flex items-center justify-center rounded-md text-[15px] font-medium hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto">
                      Open&nbsp;&nbsp;<Iconify icon="eva:arrow-forward-outline" />
                    </Link>
                  </div>
                  {(user.upgrade == "paid" || auth == "admin"||estimateFlag("5 Year Plan")) ?
                    FIveYearPlan.length > 0 ?
                      <Row className="h-[200px] overflow-hidden">
                        {FIveYearPlan[0].hasOwnProperty("Content") && FIveYearPlan[0]["Content"].map((item, i) => (
                          <Col span={24} className="flex items-center p-2" key={i}>
                            {/* <div>
                              <Avatar className="bg-[#ede9fe] text-[#7c3aed] font-bold">{item["SubTitle"][0]}</Avatar>
                            </div> */}

                            <div className="ml-4">
                              <ul style={{ listStyleType: "disc", color: "gray", marginLeft: '20px' }}>{item["Content"].map((item1, j) => (
                                <div>
                                  <span className="ml-[-25px]">{j + 1 + ". " + item1["SubTitle"]}</span>
                                  {item1["Description"].map((item2, j) => (
                                    <li>{item2["Description"]}</li>
                                  ))}
                                </div>
                              ))}</ul>
                            </div>
                          </Col>
                        ))}

                      </Row>
                      :
                      <Spin className="flex items-center justify-center" />
                    :
                    <div className="flex h-[200px] text-4xl justify-center items-center">
                      <CrownOutlined style={{ color: "#FFAE00" }} />
                    </div>
                  }

                </div>

              </AntCard>
            </Row>
          </div>
          :
          null
      }
    </div>
  );
}
