import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, Svg, Path, Image } from '@react-pdf/renderer';
import { connect, useSelector, useDispatch } from "react-redux";
import product from "../../assets/images/pdfs/product.png"
import business_title_img from '../../assets/images/pdfs/business_title.png';
import business_advantages from '../../assets/images/pdfs/business_advantages.png'
const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: 'rgb(150,200,150)',
        // paddingLeft:10,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    }
});

export default function Secondary(user) {
    // const user = useSelector(state => {
    //     return state.userSlice
    // })
    const [data, setData]    = useState(null);
    const [menu, setMenu] = useState([]);
    const [content, setContent] = useState({});
    const [contentData, setContentData] = useState({});
    useEffect(() => {
        if (user != {}) {
            // console.log(user)
            const userdata = user;
            setMenu(userdata.user.companies[0].content);
            let temp = {};
            userdata.user.companies[0].jsonContent.forEach(itm => {
                if (itm.Title == "Core Offerings") {
                    temp = itm
                }
            });
            setContent(temp);

            if (user.user) {

                setData(user.user?.companies[0]?.jsonContent.find(item => item.Title === "Core Offerings"));
            }
        }

    }, [])


    return (
        <div style={{fontFamily:'sans-serif',display:'none'}}>
        <div style={{fontFamily:'sans-serif', width: "400px", margin: "20px", paddingLeft: "10px" }} id='core'>
            <div style={{fontFamily:'sans-serif', display: 'flex', color: "#22C55E" }}>
                <div style={{fontFamily:'sans-serif', display: "flex", color: "#22C55E", justifyContent: "center" }}>
                    <img style={{fontFamily:'sans-serif', marginRight: '5px', marginTop: '6px', width:12 }} src={product} />
                    <span style={{fontFamily:'sans-serif',fontSize:12}} >Products &amp; Services</span>
                </div>
            </div>
            <p style={{fontFamily:'sans-serif', fontSize: "16px", fontWeight: "bold" }}>Core Offerings</p>
            <div style={{fontFamily:'sans-serif', marginTop: "20px" }}>
                <p style={{fontFamily:'sans-serif', fontSize: "8px" }}>{content.Summary}</p>
            </div>
            <div style={{fontFamily:'sans-serif', marginTop: "20px" }}>
                <div style={{fontFamily:'sans-serif', border: '1px solid #666', borderRadius: 5, padding: '5px 0px' }}>
                    <div style={{fontFamily:'sans-serif', padding: '5px', marginBottom: 5 }}>
                        <div style={{fontFamily:'sans-serif', fontSize: 10, fontWeight: 'bold', marginTop: -5 }}>{data?.Content[0]?.SubTitle}</div>
                        <div>
                            <p style={{fontFamily:'sans-serif',fontSize:8}}>{data?.Content[0]?.Description?.Overview}</p>
                        </div>
                    </div>
                    <div style={{fontFamily:'sans-serif', display: 'flex', height: 1, backgroundColor: '#666' }}></div>
                    <div style={{fontFamily:'sans-serif', display: 'flex', width: '100%' }}>
                        <div style={{fontFamily:'sans-serif', width: '50%', padding: 5 }}>
                            <div style={{fontFamily:'sans-serif', display: 'flex', alignItems: 'center', marginRight: 5 }}>
                                <img style={{fontFamily:'sans-serif', width: 12, paddingTop:6 }} src={business_advantages} />
                                <span style={{fontFamily:'sans-serif', marginLeft: 5, marginBottom: 5, fontWeight: 'bold', fontSize:10 }}>Advantages</span>
                            </div>
                            <div style={{fontFamily:'sans-serif', padding: 5 }}>
                                {
                                    data?.Content[0]?.Description?.Advantages.map((item, index) => (<div  style={{fontFamily:'sans-serif',fontSize:8}}>
                                        {`${index + 1}. ${item.Description}`}
                                    </div>))
                                }
                            </div>
                        </div>
                        <div style={{fontFamily:'sans-serif', width: '50%', padding: 5 }}>
                            <div style={{fontFamily:'sans-serif', display: 'flex', alignItems: 'center', marginRight: 5 }}>
                                <img style={{fontFamily:'sans-serif', width: 12, paddingTop:6 }} src={business_advantages} />
                                <span style={{fontFamily:'sans-serif', marginLeft: 5, marginBottom: 5, fontWeight: 'bold', fontSize:10 }}>Challenges</span>
                            </div>
                            <div style={{fontFamily:'sans-serif', padding: 5 }}>
                                {
                                    data?.Content[0]?.Description?.Challenges?.map((item, index) => (<div  style={{fontFamily:'sans-serif',fontSize:8}}>
                                        {`${index + 1}. ${item.Description}`}
                                    </div>))
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{fontFamily:'sans-serif', display: 'flex', width: '100%' }}>
                        <div style={{fontFamily:'sans-serif', width: '50%', padding: 5 }}>
                            <div style={{fontFamily:'sans-serif', display: 'flex', alignItems: 'center', marginRight: 5 }}>
                                <img style={{fontFamily:'sans-serif', width: 12, paddingTop:6 }} src={business_advantages} />
                                <span style={{fontFamily:'sans-serif', marginLeft: 5, marginBottom: 5, fontWeight: 'bold', fontSize:10 }}>Target Audience</span>
                            </div>
                            <div style={{fontFamily:'sans-serif', padding: 5 }}>
                                {
                                    data?.Content[0]?.Description?.["Target Audience"]?.map((item, index) => (<div  style={{fontFamily:'sans-serif',fontSize:8}}>
                                        {`${index + 1}. ${item.Description}`}
                                    </div>))
                                }
                            </div>
                        </div>
                        <div style={{fontFamily:'sans-serif', width: '50%', padding: 5 }}>
                            <div style={{fontFamily:'sans-serif', display: 'flex', alignItems: 'center', marginRight: 5 }}>
                                <img style={{fontFamily:'sans-serif', width: 12, paddingTop:6 }} src={business_advantages} />
                                <span style={{fontFamily:'sans-serif', marginLeft: 5, marginBottom: 5, fontWeight: 'bold', fontSize:10 }}>Pricing Strategy</span>
                            </div>
                            <div style={{fontFamily:'sans-serif', padding: 5 }}>
                                {
                                    data?.Content[0]?.Description?.["Pricing Strategy"]?.map((item, index) => (<div style={{fontFamily:'sans-serif',fontSize:8}}>
                                        {`${index + 1}. ${item.Description}`}
                                    </div>))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{fontFamily:'sans-serif', border: '1px solid #666', borderRadius: 5, padding: '10px 0px', marginTop: 5 }}>
                <div style={{fontFamily:'sans-serif', padding: '5px', marginBottom: 5 }}>
                    <div style={{fontFamily:'sans-serif', fontSize: 10, fontWeight: 'bold', marginTop: -10 }}>{data?.Content[1]?.SubTitle}</div>
                    <div>
                        <p style={{fontFamily:'sans-serif',fontSize:8}}>{data?.Content[1]?.Description?.Overview}</p>
                    </div>
                </div>
                <div style={{fontFamily:'sans-serif', display: 'flex', height: 1, backgroundColor: '#666' }}></div>
                <div style={{fontFamily:'sans-serif', display: 'flex', width: '100%' }}>
                    <div style={{fontFamily:'sans-serif', width: '50%', padding: 5 }}>
                        <div style={{fontFamily:'sans-serif', display: 'flex', alignItems: 'center', marginRight: 5 }}>
                            <img style={{fontFamily:'sans-serif', width: 12, paddingTop:6 }} src={business_advantages} />
                            <span style={{fontFamily:'sans-serif', marginLeft: 5, marginBottom: 5, fontWeight: 'bold', fontSize:10 }}>Advantages</span>
                        </div>
                        <div style={{fontFamily:'sans-serif', padding: 5 }}>
                            {
                                data?.Content[1]?.Description?.Advantages.map((item, index) => (<>
                                {
                                    typeof(item) === 'object' && <div style={{fontFamily:'sans-serif',fontSize:8}}>
                                    {`${index + 1}. ${item.Description}`}
                                </div>
                                }
                                </>))
                            }
                        </div>
                    </div>
                    <div style={{fontFamily:'sans-serif', width: '50%', padding: 5 }}>
                        <div style={{fontFamily:'sans-serif', display: 'flex', alignItems: 'center', marginRight: 5 }}>
                            <img style={{fontFamily:'sans-serif', width: 12, paddingTop:6 }} src={business_advantages} />
                            <span style={{fontFamily:'sans-serif', marginLeft: 5, marginBottom: 5, fontWeight: 'bold', fontSize:10 }}>Challenges</span>
                        </div>
                        <div style={{fontFamily:'sans-serif', padding: 5 }}>
                            {
                                data?.Content[1]?.Description?.Challenges?.map((item, index) => (<div style={{fontFamily:'sans-serif',fontSize:8}}>
                                    {`${index + 1}. ${item.Description}`}
                                </div>))
                            }
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
        </div>)
};
