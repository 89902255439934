import React, { PureComponent } from 'react';
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer } from 'recharts';

const data01 = [
  { name: 'Group A', value: 600 },
  { name: 'Group B', value: 578 },
  { name: 'Group C', value: 489 },
  // { name: 'Group D', value: 200 },
  // { name: 'Group E', value: 278 },
  // { name: 'Group F', value: 189 },
];


export default class Piechart1 extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/two-simple-pie-chart-otx9h';

  render() {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width="100%" height="100%">
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={data01}
            cx="50%"
            cy="50%"
            outerRadius={80}
            fill="#F38D0D"
            legendType='square'
          />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
