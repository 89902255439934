import React, { useState, useEffect } from 'react'
import Sales_Marketing from '../../../assets/images/pdfs/sales_marketing.png'
import Key_Points from '../../../assets/images/pdfs/key_points.png'
import Challenges from '../../../assets/images/pdfs/challenges.png'
import Opportunity from '../../../assets/images/pdfs/opportunity.png'
import Execution_Strategy from '../../../assets/images/pdfs/execution_strategy.png'


const CustomerRetention = (props) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        if (props.user) {
            // console.log('Customer Retention -> user: ', props.user);
            setData(props.user.companies[0].jsonContent.find(item => item.Title === "Customer Retention"));
        }
    })
    return (
        <div style={{fontFamily:'sans-serif',display:'none'}}>
        <div style={{fontFamily:'sans-serif', width: `400px`, padding: '10px 20px', marginLeft: 20 }} id='retention'>
            <div style={{fontFamily:'sans-serif', display: 'flex', alignItems: 'center' }}>
                <div style={{fontFamily:'sans-serif', width: 13, height: 13, marginRight: 8, marginTop: 17, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 3, color: '#26B35A' }}>
                    <div style={{fontFamily:'sans-serif', width: 12 }}>
                        <img style={{fontFamily:'sans-serif',marginTop:3}} src={Sales_Marketing} />
                    </div>
                </div>
                <div style={{fontFamily:'sans-serif', fontSize: 12, color: '#26B35A' }}>Sales & Marketing</div>
            </div>
            <div style={{fontFamily:'sans-serif', fontSize: 16, fontWeight: 'bold', marginBottom:20 }}>
                Customer Retention
            </div>
            <div style={{fontFamily:'sans-serif', marginBottom: 10, fontSize: 8 }}>
                {data?.Summary}
            </div>
            <div style={{fontFamily:'sans-serif', textWrap: 'wrap', marginTop:25 }}>
                <div style={{fontFamily:'sans-serif', border: '1px solid #666', borderRadius: 5, padding: '10px 0px', marginTop: 20 }}>
                    <div style={{fontFamily:'sans-serif', padding: '5px', marginBottom: 5 }}>
                        <div style={{fontFamily:'sans-serif', fontSize: 10, fontWeight: 'bold', marginTop: -5 }}>{data?.Content[1]?.SubTitle}</div>
                        <div style={{fontFamily:'sans-serif', fontSize: 8 }}>
                            {data?.Content[1]?.Description?.Overview}
                        </div>
                    </div>
                    <div style={{fontFamily:'sans-serif', display: 'flex', height: 1, backgroundColor: '#666' }}></div>
                    <div style={{fontFamily:'sans-serif', display: 'flex', width: '100%' }}>
                        <div style={{fontFamily:'sans-serif', width: '50%', padding: 5 }}>
                            <div style={{fontFamily:'sans-serif', display: 'flex', alignItems: 'center', marginRight: 5 }}>
                                <img width={12} style={{fontFamily:'sans-serif',marginTop:3}} src={Key_Points} />
                                <span style={{fontFamily:'sans-serif', marginLeft: 5, marginBottom: 5, fontWeight: 'bold', fontSize: 10 }}>
                                    Key Points
                                </span>
                            </div>
                            <div style={{fontFamily:'sans-serif', padding: 5 }}>
                                {
                                    data?.Content[1]?.Description?.["Key Points"]?.map((item, index) => (
                                        <div style={{fontFamily:'sans-serif', fontSize: 8 }}>
                                            {`${index + 1}. ${item.Description}`}
                                        </div>))
                                }
                            </div>
                        </div>
                        <div style={{fontFamily:'sans-serif', width: '50%', padding: 5 }}>
                            <div style={{fontFamily:'sans-serif', display: 'flex', alignItems: 'center', marginRight: 5 }}>
                                <img width={12} style={{fontFamily:'sans-serif',marginTop:3}} src={Challenges} />
                                <span style={{fontFamily:'sans-serif', marginLeft: 5, marginBottom: 5, fontWeight: 'bold', fontSize: 10 }}>Challenges</span>
                            </div>
                            <div style={{fontFamily:'sans-serif', padding: 5 }}>
                                {
                                    data?.Content[1]?.Description?.Challenges?.map((item, index) => (
                                        <div style={{fontFamily:'sans-serif', fontSize: 8 }}>
                                            {`${index + 1}. ${item.Description}`}
                                        </div>))
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{fontFamily:'sans-serif', display: 'flex', width: '100%' }}>
                        <div style={{fontFamily:'sans-serif', width: '50%', padding: 5 }}>
                            <div style={{fontFamily:'sans-serif', display: 'flex', alignItems: 'center', marginRight: 5 }}>
                                <img width={12} style={{fontFamily:'sans-serif',marginTop:3}} src={Opportunity} />
                                <span style={{fontFamily:'sans-serif', marginLeft: 5, marginBottom: 5, fontWeight: 'bold', fontSize: 10 }}>Opportunity</span>
                            </div>
                            <div style={{fontFamily:'sans-serif', padding: 5 }}>
                                {
                                    data?.Content[1]?.Description?.Opportunity?.map((item, index) => (
                                        <div style={{fontFamily:'sans-serif', fontSize: 8 }}>
                                            {`${index + 1}. ${item.Description}`}
                                        </div>))
                                }
                            </div>
                        </div>
                        <div style={{fontFamily:'sans-serif', width: '50%', padding: 5 }}>
                            <div style={{fontFamily:'sans-serif', display: 'flex', alignItems: 'center', marginRight: 5 }}>
                                <img width={12} style={{fontFamily:'sans-serif',marginTop:3}} src={Execution_Strategy} />
                                <span style={{fontFamily:'sans-serif', marginLeft: 5, marginBottom: 5, fontWeight: 'bold', fontSize: 10 }}>Execution Strategy</span>
                            </div>
                            <div style={{fontFamily:'sans-serif', padding: 5 }}>
                                {
                                    data?.Content[1]?.Description?.["Execution Strategy"]?.map((item, index) => (
                                        <div style={{fontFamily:'sans-serif', fontSize: 8 }}>
                                            {`${index + 1}. ${item.Description}`}
                                        </div>))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{fontFamily:'sans-serif', border: '1px solid #666', borderRadius: 5, padding: '10px 0px', marginTop: 20 }}>
                    <div style={{fontFamily:'sans-serif', padding: '5px', marginBottom: 5 }}>
                        <div style={{fontFamily:'sans-serif', fontSize: 10, fontWeight: 'bold', marginTop: -5 }}>{data?.Content[0]?.SubTitle}</div>
                        <div style={{fontFamily:'sans-serif', fontSize: 8 }}>
                            {data?.Content[0]?.Description?.Overview}
                        </div>
                    </div>
                    <div style={{fontFamily:'sans-serif', display: 'flex', height: 1, backgroundColor: '#666' }}></div>
                    <div style={{fontFamily:'sans-serif', display: 'flex', width: '100%' }}>
                        <div style={{fontFamily:'sans-serif', width: '50%', padding: 5 }}>
                            <div style={{fontFamily:'sans-serif', display: 'flex', alignItems: 'center', marginRight: 5 }}>
                                <img  width={12} style={{fontFamily:'sans-serif',marginTop:3}} src={Key_Points} />
                                <span style={{fontFamily:'sans-serif', marginLeft: 5, marginBottom: 5, fontWeight: 'bold', fontSize: 10 }}>
                                    Key Points
                                </span>
                            </div>
                            <div style={{fontFamily:'sans-serif', padding: 5 }}>
                                {
                                    data?.Content[0]?.Description?.["Key Points"]?.map((item, index) => (
                                        <div style={{fontFamily:'sans-serif', fontSize: 8 }}>
                                            {`${index + 1}. ${item.Description}`}
                                        </div>))
                                }
                            </div>
                        </div>
                        <div style={{fontFamily:'sans-serif', width: '50%', padding: 5 }}>
                            <div style={{fontFamily:'sans-serif', display: 'flex', alignItems: 'center', marginRight: 5 }}>
                                <img  width={12} style={{fontFamily:'sans-serif',marginTop:3}} src={Challenges} />
                                <span style={{fontFamily:'sans-serif', marginLeft: 5, marginBottom: 5, fontWeight: 'bold', fontSize: 10 }}>Challenges</span>
                            </div>
                            <div style={{fontFamily:'sans-serif', padding: 5 }}>
                                {
                                    data?.Content[0]?.Description?.Challenges?.map((item, index) => (
                                        <div style={{fontFamily:'sans-serif', fontSize: 8 }}>
                                            {`${index + 1}. ${item.Description}`}
                                        </div>))
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{fontFamily:'sans-serif', display: 'flex', width: '100%' }}>
                        <div style={{fontFamily:'sans-serif', width: '50%', padding: 5 }}>
                            <div style={{fontFamily:'sans-serif', display: 'flex', alignItems: 'center', marginRight: 5 }}>
                                <img  width={12} style={{fontFamily:'sans-serif',marginTop:3}} src={Opportunity} />
                                <span style={{fontFamily:'sans-serif', marginLeft: 5, marginBottom: 5, fontWeight: 'bold', fontSize: 10 }}>Opportunity</span>
                            </div>
                            <div style={{fontFamily:'sans-serif', padding: 5 }}>
                                {
                                    data?.Content[0]?.Description?.Opportunity?.map((item, index) => (
                                        <div style={{fontFamily:'sans-serif', fontSize: 8 }}>
                                            {`${index + 1}. ${item.Description}`}
                                        </div>))
                                }
                            </div>
                        </div>
                        <div style={{fontFamily:'sans-serif', width: '50%', padding: 5 }}>
                            <div style={{fontFamily:'sans-serif', display: 'flex', alignItems: 'center', marginRight: 5 }}>
                                <img  width={12} style={{fontFamily:'sans-serif',marginTop:3}} src={Execution_Strategy} />
                                <span style={{fontFamily:'sans-serif', marginLeft: 5, marginBottom: 5, fontWeight: 'bold', fontSize: 10 }}>Execution Strategy</span>
                            </div>
                            <div style={{fontFamily:'sans-serif', padding: 5 }}>
                                {
                                    data?.Content[0]?.Description?.["Execution Strategy"]?.map((item, index) => (
                                        <div style={{fontFamily:'sans-serif', fontSize: 8 }}>
                                            {`${index + 1}. ${item.Description}`}
                                        </div>))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default CustomerRetention