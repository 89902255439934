import React, { useEffect, useState } from "react";
import business_title_img from '../../assets/images/pdfs/business_title.png';
import swot_1 from '../../assets/images/pdfs/swot_1.png'
import swot_2 from '../../assets/images/pdfs/swot_2.png'
import swot_3 from '../../assets/images/pdfs/swot_3.png'
import swot_4 from '../../assets/images/pdfs/swot_4.png'

export default function SWOT(props) {
    const [data, setData] = useState(null);

    const colors = [
        {bg: '#f1f7ff', t_color: 'rgb(59, 130, 246)', icon: swot_1},
        {bg: '#fffbee', t_color: 'rgb(234, 179, 8)', icon: swot_2},
        {bg: '#ebfff2', t_color: 'rgb(34, 197, 94)', icon: swot_3},
        {bg: '#fff0f0', t_color: 'rgb(239, 68, 68)', icon: swot_4}
    ]

    useEffect(() => {
        if (props.user) {
            // console.log('SWOT Analysis -> user: ', props.user);
            setData(props.user.companies[0].jsonContent.find(item => item.Title === "Swot Analysis"));
        }
    })

    return (
    <div style={{fontFamily:'sans-serif',display:'none' }}><div style={{fontFamily:'sans-serif', width: `400px`, padding: '10px 20px', marginLeft: 20, }}  id="swot">
        <div style={{fontFamily:'sans-serif', display: 'flex', alignItems: 'center' }}>
            <div style={{fontFamily:'sans-serif', width: 8, height: 8, marginRight: 8, marginTop: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 3, color: '#26B35A' }}>
                <div style={{fontFamily:'sans-serif', width: 8 }}>
                    <img src={business_title_img} />
                </div>
            </div>
            <div style={{fontFamily:'sans-serif', fontSize: 12, color: '#26B35A' }}>Overview</div>
        </div>
        <div style={{fontFamily:'sans-serif',textWrap: 'wrap'}}>
            <div style={{fontFamily:'sans-serif', fontSize: 16, margin: '10px 0', fontWeight: 'bold' }}>
                {data?.Title}
            </div>
            <div style={{fontFamily:'sans-serif', display: 'flex', justifyContent: 'center' }}>
                <div style={{fontFamily:'sans-serif', display: 'flex', flexWrap: 'wrap' }}>
                    {
                        data?.Content?.map((item, index) => (index!=3&&<div style={{fontFamily:'sans-serif',width: '100%', margin: '10px 0', padding: 10, backgroundColor: colors[index].bg}}>
                            <div style={{fontFamily:'sans-serif', display: 'flex', alignItems: 'center', fontSize: 10, fontWeight: 'bold', color: colors[index].t_color }}>
                                <img style={{fontFamily:'sans-serif',marginTop: 14, marginRight: 5}} width={12} height={12} src={colors[index].icon} />
                                <span>{item.SubTitle}</span>
                            </div>
                            <div>
                                <ol>
                                {
                                    item.Description.map((content, index) => (<li><p style={{fontFamily:'sans-serif',fontSize: 8}}>
                                        {`${index + 1}. ${content.Description}`}
                                    </p></li>))
                                }
                                </ol>
                            </div>
                        </div>))
                    }
                </div>
            </div>
        </div>
    </div></div>)
}