import React from 'react'
import {
    DownOutlined, UpOutlined
} from '@ant-design/icons';

const Accordion = (props) => {
    return (
        <div className="sm:mx-10 mb-1 transition transition-all">
            <button
                className={`w-full p-2 sm:p-4 flex rounded-lg items-center text-left ${props.isOpen ?
                    'bg-white text-gray-600' : 'bg-gradient-to-b from-[#4382FE] to-[#314EF4] text-white'} focus:text-gray-600 focus:bg-white transition duration-300`}
                // className="gradient-button w-full p-4 flex items-center text-left bg-[#4382FE] text-white 
                //         //    focus:text-gray-600 focus:bg-white transition duration-300"
                onClick={props.toggleAccordion}
            >
                <span className={`text-xl float-left mr-4 ml-8 transform ${props.isOpen ?
                    'rotate-180 text-gray-600' : 'rotate-0 text-white'} transition-transform duration-300`}>
                    <UpOutlined />
                </span>
                <p className='sm:text-xl text-left'>{props.isOpen ? <b className='text-gray-600'>{props.title}</b> : props.title}</p>
            </button>
            {props.isOpen && (
                <div className="transition transition-all px-4 sm:py-4 sm:p-2 mx-16 sm:ml-20 sm:text-lg text-gray-600 bg-white text-left">
                    {props.data}
                </div>
            )}
        </div>
    )
}

export default Accordion