import React, { useState, useEffect } from 'react'
import Sales_Marketing from '../../../assets/images/pdfs/sales_marketing.png'

const BrandingIdentity = (props) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        if (props.user) {
            // console.log('branding and Identity -> user: ', props.user);
            setData(props.user.companies[0].jsonContent.find(item => item.Title === "Branding & Identity"));
        }
    })

    return (
        <div style={{fontFamily:'sans-serif',display:'none'}}>
        <div style={{fontFamily:'sans-serif', width: `400px`, padding: '10px 20px', marginLeft: 20 }} id='branding'>
            <div style={{fontFamily:'sans-serif', display: 'flex', alignItems: 'center' }}>
                <div style={{fontFamily:'sans-serif', width: 13, height: 13, marginRight: 8, marginTop: 17, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 3, color: '#26B35A' }}>
                    <div style={{fontFamily:'sans-serif', width: 12 }}>
                        <img src={Sales_Marketing} />
                    </div>
                </div>
                <div style={{fontFamily:'sans-serif', fontSize: 12, color: '#26B35A' }}>Sales & Marketing</div>
            </div>
            <div style={{fontFamily:'sans-serif', fontSize: 16, fontWeight: 'bold' }}>
                Branding & Identity
            </div>
            <div style={{fontFamily:'sans-serif', width: '100%' }}>
                <div style={{fontFamily:'sans-serif', marginBottom: 5, marginTop:20 }}>
                    <div style={{fontFamily:'sans-serif', marginBottom: 5, fontWeight: 'bold', fontSize: 10 }}>
                        {data?.Content[0]?.SubTitle}
                    </div>
                    <div style={{fontFamily:'sans-serif', textWrap: 'wrap', fontSize: 8 }}>
                            {data?.Content[0]?.Description}
                    </div>
                </div>

                <div style={{fontFamily:'sans-serif', marginBottom: 10 }}>
                    <div style={{fontFamily:'sans-serif', marginBottom: 15, fontSize: 10, fontWeight: 'bold' }}>
                        Color Theme
                    </div>
                    <div style={{fontFamily:'sans-serif', display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{fontFamily:'sans-serif', backgroundColor: 'rgb(22, 119, 255)', width: '15%', height: '50px', borderRadius: 5 }}></div>
                        <div style={{fontFamily:'sans-serif', backgroundColor: 'rgb(255, 127, 0)', width: '15%', height: '50px', borderRadius: 5 }}></div>
                        <div style={{fontFamily:'sans-serif', backgroundColor: 'rgb(121, 148, 75)', width: '15%', height: '50px', borderRadius: 5 }}></div>
                        <div style={{fontFamily:'sans-serif', backgroundColor: 'rgb(80, 118, 135)', width: '15%', height: '50px', borderRadius: 5 }}></div>
                        <div style={{fontFamily:'sans-serif', backgroundColor: 'rgb(22, 119, 255)', width: '15%', height: '50px', borderRadius: 5 }}></div>
                    </div>
                </div>

                <div style={{fontFamily:'sans-serif', marginBottom: 10 }}>
                    <div style={{fontFamily:'sans-serif', marginBottom: 5 }}>
                        <div style={{fontFamily:'sans-serif', marginBottom: 5, fontWeight: 'bold', fontSize: 10 }}>
                            {data?.Content[1]?.SubTitle}
                        </div>
                    </div>
                    <div style={{fontFamily:'sans-serif', textWrap: 'wrap', fontWeight: 'normal' }}>
                        <div style={{fontFamily:'sans-serif', fontSize: 8 }}>
                            {data?.Content[1]?.Description}
                        </div>
                    </div>
                </div>
                <div style={{fontFamily:'sans-serif', marginBottom: 10 }}>
                    <div style={{fontFamily:'sans-serif', marginBottom: 5 }}>
                        <div style={{fontFamily:'sans-serif', marginBottom: 5, fontWeight: 'bold', fontSize: 10 }}>
                            {data?.Content[2]?.SubTitle}
                        </div>
                    </div>
                    <div style={{fontFamily:'sans-serif', textWrap: 'wrap', fontWeight: 'normal' }}>
                        <div style={{fontFamily:'sans-serif', fontSize: 8 }}>
                            {data?.Content[2]?.Description}
                        </div>
                    </div>
                </div>
                <div style={{fontFamily:'sans-serif', marginBottom: 10 }}>
                    <div style={{fontFamily:'sans-serif', marginBottom: 5 }}>
                        <div style={{fontFamily:'sans-serif', marginBottom: 5, fontWeight: 'bold', fontSize: 10 }}>
                            {data?.Content[3]?.SubTitle}
                        </div>
                    </div>
                    <div style={{fontFamily:'sans-serif', textWrap: 'wrap', fontWeight: 'normal' }}>
                        <div style={{fontFamily:'sans-serif', fontSize: 8 }}>
                            {data?.Content[3]?.Description}
                        </div>
                    </div>
                </div>
                <div style={{fontFamily:'sans-serif', marginBottom: 10 }}>
                    <div style={{fontFamily:'sans-serif', marginBottom: 5 }}>
                        <div style={{fontFamily:'sans-serif', marginBottom: 5, fontWeight: 'bold', fontSize: 10 }}>
                            {data?.Content[4]?.SubTitle}
                        </div>
                    </div>
                    <div style={{fontFamily:'sans-serif', textWrap: 'wrap', fontWeight: 'normal' }}>
                        <div style={{fontFamily:'sans-serif', fontSize: 8 }}>
                            {data?.Content[4]?.Description}
                        </div>
                    </div>
                </div>
                <div style={{fontFamily:'sans-serif', marginBottom: 10 }}>
                    <div style={{fontFamily:'sans-serif', marginBottom: 5 }}>
                        <div style={{fontFamily:'sans-serif', marginBottom: 5, fontWeight: 'bold', fontSize: 10 }}>
                            {data?.Content[5]?.SubTitle}
                        </div>
                    </div>
                    <div style={{fontFamily:'sans-serif', textWrap: 'wrap', fontWeight: 'normal' }}>
                        <div style={{fontFamily:'sans-serif', fontSize: 8 }}>
                            {data?.Content[5]?.Description}
                        </div>
                    </div>
                </div>
                <div style={{fontFamily:'sans-serif', marginBottom: 10 }}>
                    <div style={{fontFamily:'sans-serif', marginBottom: 5 }}>
                        <div style={{fontFamily:'sans-serif', marginBottom: 5, fontWeight: 'bold', fontSize: 10 }}>
                            {data?.Content[6]?.SubTitle}
                        </div>
                    </div>
                    <div style={{fontFamily:'sans-serif', textWrap: 'wrap', fontWeight: 'normal' }}>
                        <div style={{fontFamily:'sans-serif', fontSize: 8 }}>
                            {data?.Content[6]?.Description}
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default BrandingIdentity