export const option1 = [
    {
        value: '1',
        label: 'First 12 months',
    },
    {
        value: '2',
        label: '2024',
    },
    {
        value: '3',
        label: '2025',
    },
    {
        value: '4',
        label: '2026',
    },
    {
        value: '5',
        label: '2027',
    },
    {
        value: '6',
        label: '2028',
    },
]

export const option2 = [
    {
        value: '1',
        label: '2024',
    },
    {
        value: '2',
        label: '2025',
    },
    {
        value: '3',
        label: '2026',
    },
    {
        value: '4',
        label: '2027',
    },
    {
        value: '5',
        label: '2028',
    },
]

export const monthData = [
    {
        value: 'jan',
        label: 'Jan',
    },
    {
        value: 'feb',
        label: 'Feb',
    },
    {
        value: 'mar',
        label: 'Mar',
    },
    {
        value: 'apr',
        label: 'Apr',
    },
    {
        value: 'may',
        label: 'May',
    },
    {
        value: 'jun',
        label: 'Jun',
    },
    {
        value: 'jul',
        label: 'Jul',
    },
    {
        value: 'aug',
        label: 'Aug',
    },
    {
        value: 'sep',
        label: 'Sep',
    },
    {
        value: 'oct',
        label: 'Oct',
    },
    {
        value: 'nov',
        label: 'Nov',
    },
    {
        value: 'dec',
        label: 'Dec',
    },
]

export const yearData = [
    {
        value: '2023',
        label: '2023',
    },
    {
        value: '2024',
        label: '2024',
    },
    {
        value: '2025',
        label: '2025',
    },
    {
        value: '2026',
        label: '2026',
    },
    {
        value: '2027',
        label: '2027',
    },
    {
        value: '2028',
        label: '2028',
    },
]

export const revenueType = [
    {
        value: 'unit_sales',
        label: 'Unit Sales',
    },
    {
        value: 'billable_hours',
        label: 'Billable Hours',
    },
    {
        value: 'recurring_charges',
        label: 'Recurring Charges',
    },
    {
        value: 'revenue_only',
        label: 'Revenue Only',
    },
]

export const areaData = [

    {
        year: 2023,
        data: [
            {
                "name": "Jan",
                "uv": 190,
            },
            {
                "name": "Feb",
                "uv": 285,
            },
            {
                "name": "Mar",
                "uv": 330,
            },
            {
                "name": "Apr",
                "uv": 373,
            },
            {
                "name": "May",
                "uv": 415,

            },
            {
                "name": "Jun",
                "uv": 456,
            },
            {
                "name": "Jul",
                "uv": 495,
            },
            {
                "name": "Aug",
                "uv": 533,
            },
            {
                "name": "Sept",
                "uv": 569,
            },
            {
                "name": "Oct",
                "uv": 605,
            },
            {
                "name": "Nov",
                "uv": 639,
            },
            {
                "name": "Dec",
                "uv": 672,
            }
        ]
    },
    {
        year: 2024,
        data: [
            {
                "name": "Jan",
                "uv": 190,
            },
            {
                "name": "Feb",
                "uv": 285,
            },
            {
                "name": "Mar",
                "uv": 330,
            },
            {
                "name": "Apr",
                "uv": 373,
            },
            {
                "name": "May",
                "uv": 415,

            },
            {
                "name": "Jun",
                "uv": 456,
            },
            {
                "name": "Jul",
                "uv": 495,
            },
            {
                "name": "Aug",
                "uv": 533,
            },
            {
                "name": "Sept",
                "uv": 569,
            },
            {
                "name": "Oct",
                "uv": 605,
            },
            {
                "name": "Nov",
                "uv": 639,
            },
            {
                "name": "Dec",
                "uv": 672,
            }
        ]
    },
    {
        year: 2025,
        data: [
            {
                "name": "Jan",
                "uv": 704,

            },
            {
                "name": "Feb",
                "uv": 735,

            },
            {
                "name": "Mar",
                "uv": 765,

            },
            {
                "name": "Apr",
                "uv": 795,

            },
            {
                "name": "May",
                "uv": 824,

            },
            {
                "name": "Jun",
                "uv": 851,

            },
            {
                "name": "Jul",
                "uv": 879,

            },
            {
                "name": "Aug",
                "uv": 905,
            },
            {
                "name": "Sept",
                "uv": 931,
            },
            {
                "name": "Oct",
                "uv": 956,
            },
            {
                "name": "Nov",
                "uv": 981,
            },
            {
                "name": "Dec",
                "uv": 1000,
            }
        ]
    },
    {
        year: 2026,
        data: [
            {
                "name": "Jan",
                "uv": 1000,

            },
            {
                "name": "Feb",
                "uv": 1100,

            },
            {
                "name": "Mar",
                "uv": 1100,

            },
            {
                "name": "Apr",
                "uv": 1100,

            },
            {
                "name": "May",
                "uv": 1100,

            },
            {
                "name": "Jun",
                "uv": 1100,

            },
            {
                "name": "Jul",
                "uv": 1200,

            },
            {
                "name": "Aug",
                "uv": 1200,
            },
            {
                "name": "Sept",
                "uv": 1200,
            },
            {
                "name": "Oct",
                "uv": 1200,
            },
            {
                "name": "Nov",
                "uv": 1200,
            },
            {
                "name": "Dec",
                "uv": 1300,
            }
        ]
    },
    {
        year: 2027,
        data: [
            {
                "name": "Jan",
                "uv": 1300,

            },
            {
                "name": "Feb",
                "uv": 1300,

            },
            {
                "name": "Mar",
                "uv": 1300,

            },
            {
                "name": "Apr",
                "uv": 1400,

            },
            {
                "name": "May",
                "uv": 1400,

            },
            {
                "name": "Jun",
                "uv": 1400,

            },
            {
                "name": "Jul",
                "uv": 1400,

            },
            {
                "name": "Aug",
                "uv": 1400,
            },
            {
                "name": "Sept",
                "uv": 1400,
            },
            {
                "name": "Oct",
                "uv": 1500,
            },
            {
                "name": "Nov",
                "uv": 1500,
            },
            {
                "name": "Dec",
                "uv": 1500,
            }
        ]
    },
    {
        year: 2028,
        data: [
            {
                "name": "Jan",
                "uv": 1500,

            },
            {
                "name": "Feb",
                "uv": 1500,

            },
            {
                "name": "Mar",
                "uv": 1600,

            },
            {
                "name": "Apr",
                "uv": 1600,

            },
            {
                "name": "May",
                "uv": 1600,

            },
            {
                "name": "Jun",
                "uv": 1600,

            },
            {
                "name": "Jul",
                "uv": 1600,

            },
            {
                "name": "Aug",
                "uv": 1700,
            },
            {
                "name": "Sept",
                "uv": 1700,
            },
            {
                "name": "Oct",
                "uv": 1700,
            },
            {
                "name": "Nov",
                "uv": 1700,
            },
            {
                "name": "Dec",
                "uv": 252,
            }
        ]
    }
]

export const barChart = [
    {
        "name": "2024",
        "uv": 5.6,
    },
    {
        "name": "2025",
        "uv": 10,
    },
    {
        "name": "2026",
        "uv": 14,
    },
    {
        "name": "2027",
        "uv": 17,
    },
    {
        "name": "2028",
        "uv": 18,
    },
]


export const pieChart1 = [
    {
        year: 2024,
        data: [
            {
                "name": 'Maintenance Contracts',
                "value": 30
            },
            {
                "name": 'Consulting Services',
                "value": 40
            },
            {
                "name": 'AI Software Licenses',
                "value": 30
            },
            // {
            //     "name": 'Data Analysis Services',
            //     "value": 1054
            // },
            // {
            //     "name": 'Training Problems',
            //     "value": 634
            // },
        ]
    },
]

export const pieChart = [
    {
        year: 2024,
        data: [
            {
                "name": 'Maintenance Contracts',
                "value": 1400
            },
            {
                "name": 'Consulting Services',
                "value": 1400
            },
            {
                "name": 'AI Software Licenses',
                "value": 1400
            },
            {
                "name": 'Data Analysis Services',
                "value": 1054
            },
            {
                "name": 'Training Problems',
                "value": 634
            },
        ]
    },
    {
        year: 2025,
        data: [
            {
                "name": 'Maintenance Contracts',
                "value": 8300
            },
            {
                "name": 'Consulting Services',
                "value": 256
            },
            {
                "name": 'AI Software Licenses',
                "value": 715
            },
            {
                "name": 'Data Analysis Services',
                "value": 286
            },
            {
                "name": 'Training Problems',
                "value": 715
            },
        ]
    },
    {
        year: 2026,
        data: [
            {
                "name": 'Maintenance Contracts',
                "value": 12000
            },
            {
                "name": 'Consulting Services',
                "value": 322
            },
            {
                "name": 'AI Software Licenses',
                "value": 805
            },
            {
                "name": 'Data Analysis Services',
                "value": 322
            },
            {
                "name": 'Training Problems',
                "value": 805
            },
        ]
    },
    {
        year: 2027,
        data: [
            {
                "name": 'Maintenance Contracts',
                "value": 14000
            },
            {
                "name": 'Consulting Services',
                "value": 363
            },
            {
                "name": 'AI Software Licenses',
                "value": 907
            },
            {
                "name": 'Data Analysis Services',
                "value": 363
            },
            {
                "name": 'Training Problems',
                "value": 907
            },
        ]
    },
    {
        year: 2028,
        data: [
            {
                "name": 'Maintenance Contracts',
                "value": 15000
            },
            {
                "name": 'Consulting Services',
                "value": 409
            },
            {
                "name": 'AI Software Licenses',
                "value": 1000
            },
            {
                "name": 'Data Analysis Services',
                "value": 409
            },
            {
                "name": 'Training Problems',
                "value": 1000
            },
        ]
    },

]



export const revenueData = [
    {
        key: 1,
        revenue_stream: 'Product Sales',
        four: '$115,668',
        five: '$141,495',
        six: '$159,440',
        seven: '$179,661',
        eight: '$202,446',
        children: [
            {
                key: 11,
                revenue_stream: 'Unit sales',
                four: 1157,
                five: 1415,
                six: 1594,
                seven: 1797,
                eight: 2024,
            },
            {
                key: 12,
                revenue_stream: 'Revenue per unit',
                four: '$100',
                five: '$100',
                six: '$100',
                seven: '$100',
                eight: '$100',
            }
        ]
    },
    {
        key: 2,
        revenue_stream: 'Consulting Services',
        four: '$34,701',
        five: '$42,448',
        six: '$47,832',
        seven: '$53,898',
        eight: '$60,734',
        children: [
            {
                key: 21,
                revenue_stream: 'Billable hours',
                four: 231,
                five: 283,
                six: 319,
                seven: 359,
                eight: 405,
            },
            {
                key: 22,
                revenue_stream: 'Hourly rate',
                four: '$150',
                five: '$150',
                six: '$150',
                seven: '$150',
                eight: '$150',
            }
        ]
    },
    {
        key: 3,
        revenue_stream: 'Subscription Fees',
        four: '$553,375',
        five: '$1,135,205',
        six: '$1,417,636',
        seven: '$1,636,532',
        eight: '$1,855,130',
        children: [
            {
                key: 31,
                revenue_stream: 'Signups',
                four: 2313,
                five: 2830,
                six: 3189,
                seven: 3593,
                eight: 4049,
            },
            {
                key: 32,
                revenue_stream: 'Customers at end',
                four: 1444,
                five: 2104,
                six: 2505,
                seven: 2861,
                eight: 3234,
            },
            {
                key: 33,
                revenue_stream: 'Monthly churn rate',
                four: '10%',
                five: '10%',
                six: '10%',
                seven: '10%',
                eight: '10%',
            },
            {
                key: 34,
                revenue_stream: 'Recurring charge',
                four: '$50',
                five: '$50',
                six: '$50',
                seven: '$50',
                eight: '$50',
            },
        ]
    },
    {
        key: 4,
        revenue_stream: 'Training Programs',
        four: '$23,134',
        five: '$28,299',
        six: '$31,888',
        seven: '$35,932',
        eight: '$40,489',
        children: [
            {
                key: 41,
                revenue_stream: 'Billable hours',
                four: 116,
                five: 141,
                six: 159,
                seven: 180,
                eight: 202,
            },
            {
                key: 42,
                revenue_stream: 'Hourly rate',
                four: '200$',
                five: '200$',
                six: '200$',
                seven: '200$',
                eight: '200$',
            },
        ]
    },
    {
        key: 5,
        revenue_stream: 'Advisory Services',
        four: '$17,350',
        five: '$21,224',
        six: '$23,916',
        seven: '$26,949',
        eight: '$30,367',
        children: [
            {
                key: 51,
                revenue_stream: 'Billable hours',
                four: 58,
                five: 71,
                six: 80,
                seven: 90,
                eight: 101,
            },
            {
                key: 52,
                revenue_stream: 'Hourly rate',
                four: '$300',
                five: '$300',
                six: '$300',
                seven: '$300',
                eight: '$300',
            }
        ]
    },
    {
        key: 6,
        revenue_stream: 'Total Revenue',
        four: '$744,228',
        five: '$1,368,672',
        six: '$1,680,711',
        seven: '$1,932,972',
        eight: '$2,189,166',

    }
]

const monthlyRevenue_old = [
    {
        key: 1,
        revenue_stream: 'Product Sales',

        children: [
            {
                key: 11,
                revenue_stream: 'Unit sales',
            },
            {
                key: 12,
                revenue_stream: 'Revenue per unit',
            }
        ]
    },
    {
        key: 2,
        revenue_stream: 'Consulting Services',

        children: [
            {
                key: 21,
                revenue_stream: 'Billable hours',
            },
            {
                key: 22,
                revenue_stream: 'Hourly rate',
            }
        ]
    },
    {
        key: 3,
        revenue_stream: 'Subscription Fees',

        children: [
            {
                key: 31,
                revenue_stream: 'Signups',
            },
            {
                key: 32,
                revenue_stream: 'Customers at end',
            },
            {
                key: 33,
                revenue_stream: 'Monthly churn rate',
            },
            {
                key: 34,
                revenue_stream: 'Recurring charge',
            },
        ]
    },
    {
        key: 4,
        revenue_stream: 'Training Programs',

        children: [
            {
                key: 41,
                revenue_stream: 'Billable hours',
            },
            {
                key: 42,
                revenue_stream: 'Hourly rate',
            },
        ]
    },
    {
        key: 5,
        revenue_stream: 'Advisory Services',
        children: [
            {
                key: 51,
                revenue_stream: 'Billable hours',
            },
            {
                key: 52,
                revenue_stream: 'Hourly rate',
            }
        ]
    },
    // {
    //     key: 6,
    //     revenue_stream: 'Total Revenue',
    //     // 1: 432432,
    //     // 2: 34234
    // }
]

let newss = monthlyRevenue_old.map(item => {
    if (item?.children && item?.children.length > 0) {
        const newdd = item.children?.map(child => {
            let newqq = {
                ...child
            }
            for (let i = 1; i <= 39; i++) {
                if (child?.revenue_stream === "Unit sales") {
                    newqq = {
                        ...newqq,
                        [`${i}`]: 100 + (i - 1)
                    }
                } else if (child.revenue_stream === "Revenue per unit") {
                    newqq = {
                        ...newqq,
                        [`${i}`]: `$100`
                    }
                } else if (child.revenue_stream === "Billable hours") {
                    newqq = {
                        ...newqq,
                        [`${i}`]: 40 + (i - 1)
                    }
                } else if (child.revenue_stream === "Hourly rate") {
                    newqq = {
                        ...newqq,
                        [`${i}`]: `$${40 + (i - 1)}`
                    }
                } else if (child.revenue_stream === "Monthly churn rate") {
                    newqq = {
                        ...newqq,
                        [`${i}`]: 5
                    }
                } else if (child.revenue_stream === "Recurring charge") {
                    newqq = {
                        ...newqq,
                        [`${i}`]: '$10'
                    }
                } else if (child.revenue_stream === "Signups") {
                    newqq = {
                        ...newqq,
                        [`${i}`]: 100 + (i - 1) * 5
                    }
                } else if (child.revenue_stream === "Customers at end") {
                    newqq = {
                        ...newqq,
                        [`${i}`]: Math.floor(Math.random() * 100 + 5)
                    }
                }
            }
            return newqq;
        })
        console.log(newdd)
        let newuu = {
            ...item,
            children: newdd
        }
        for (let i = 1; i <= 39; i++) {
            if (item.revenue_stream === 'Product Sales') {
                let vv = 1
                newdd.map(yy => {
                    if (typeof (yy[`${i}`]) === 'string') {
                        vv = vv * Number(yy[`${i}`].slice(1))
                    } else vv = vv * yy[`${i}`]
                })
                newuu = {
                    ...newuu,
                    [`${i}`]: `$${vv}`
                }
            } else if (item.revenue_stream === 'Consulting Services') {
                let vv = 1
                newdd.map(yy => {
                    if (typeof (yy[`${i}`]) === 'string') {
                        vv = vv * Number(yy[`${i}`].slice(1))
                    } else vv = vv * yy[`${i}`]
                })
                newuu = {
                    ...newuu,
                    [`${i}`]: `$${vv}`
                }
            } else if (item.revenue_stream === 'Subscription Fees') {
                let vv = 1
                newdd.map(yy => {
                    if (typeof (yy[`${i}`]) === 'string') {
                        vv = vv * Number(yy[`${i}`].slice(1))
                    } else vv = vv * yy[`${i}`]
                })
                newuu = {
                    ...newuu,
                    [`${i}`]: `$${vv}`
                }
            } else if (item.revenue_stream === 'Training Programs') {
                let vv = 1
                newdd.map(yy => {
                    if (typeof (yy[`${i}`]) === 'string') {
                        vv = vv * Number(yy[`${i}`].slice(1))
                    } else vv = vv * yy[`${i}`]
                })
                newuu = {
                    ...newuu,
                    [`${i}`]: `$${vv}`
                }
            } else if (item.revenue_stream === 'Subscription Fees') {
                let vv = 1
                newdd.map(yy => {
                    if (typeof (yy[`${i}`]) === 'string') {
                        vv = vv * Number(yy[`${i}`].slice(1))
                    } else vv = vv * yy[`${i}`]
                })
                newuu = {
                    ...newuu,
                    [`${i}`]: `$${vv}`
                }
            } else if (item.revenue_stream === 'Advisory Services') {
                let vv = 1
                newdd.map(yy => {
                    if (typeof (yy[`${i}`]) === 'string') {
                        vv = vv * Number(yy[`${i}`].slice(1))
                    } else vv = vv * yy[`${i}`]
                })
                newuu = {
                    ...newuu,
                    [`${i}`]: `$${vv}`
                }
            }
        }
        return newuu;
    }
    return item;
})


// for (let i = 1; i <= 39; i++){
//     let vv = 0;
//     newss.map(item => {
//         if(item.revenue_stream !== "Total Revenue"){
//             console.log(item[`${i}`])
//             vv = vv + Number(typeof(item[`${i}`]) === 'string' ? item[`${i}`].slice(1) : 0)
//         }
//     })
//     newss_last = {
//         ...newss_last,
//         [`${i}`]: vv
//     }
// }
// console.log(newss_last)

// newss = newss.map(item => {
//     if(item.revenue_stream !== "Total revenue") return item;
//     else{
//         return newss_last
//     }
// })

// console.log(newss.map(item => {
//     if(item.revenue_stream === "Total revenue"){
//         return item
//     }
//     return null;
// }))

export const monthlyRevenue = newss

export const expenseData = [
    {
        key: 1,
        revenue_stream: 'Cost of Goods Sold',
        four: '$63,413',
        five: '$71,455',
        six: '$80,517',
        seven: '$90,729',
        eight: '$102,235',
        children: [
            {
                key: 11,
                revenue_stream: 'Inventory Costs',
                four: '$63,413',
                five: '$71,455',
                six: '$80,517',
                seven: '$90,729',
                eight: '$102,235',
            },
        ]
    },
    {
        key: 2,
        revenue_stream: 'Operating Expenses',
        four: '$28,418',
        five: '$43,928',
        six: '$52,269',
        seven: '$59,680',
        eight: '$67,470',
        children: [
            {
                key: 21,
                revenue_stream: 'Marketing Expenses',
                four: '$28,418',
                five: '$43,928',
                six: '$52,269',
                seven: '$59,680',
                eight: '$67,470',
            },

        ]
    },
    {
        key: 3,
        revenue_stream: 'Capital Expenses',
        four: '$35,000',
        five: '',
        six: '',
        seven: '',
        eight: '',
        children: [
            {
                key: 31,
                revenue_stream: 'Office Furniture',
                four: '$10',
                five: '',
                six: '',
                seven: '',
                eight: '',
            },
            {
                key: 32,
                revenue_stream: 'Computer Equipment',
                four: '$15',
                five: '',
                six: '',
                seven: '',
                eight: '',
            },
            {
                key: 33,
                revenue_stream: 'Communication Systems',
                four: '$10',
                five: '',
                six: '',
                seven: '',
                eight: '',
            },
        ]
    },
    {
        key: 4,
        revenue_stream: 'Personnel Expenses',
        four: '$92,535',
        five: '$113,196',
        six: '$127,552',
        seven: '$143,729',
        eight: '$161,957',
        children: [
            {
                key: 41,
                revenue_stream: 'Salary Expenses',
                four: '$92,535',
                five: '$113,196',
                six: '$127,552',
                seven: '$143,729',
                eight: '$161,957',
            },
        ]
    },
    {
        key: 5,
        revenue_stream: 'Total Revenue',
        four: '$201,787',
        five: '$227,871',
        six: '$259,540',
        seven: '$293,239',
        eight: '$330,650',
    }
]

export const financingData = [
    {
        key: 1,
        financing: 'Amount received',
        four: '$150,000',
        five: '',
        six: '',
        seven: '',
        eight: '',
        children: [
            {
                key: 11,
                financing: 'Bank Loan',
                four: '$50',
                five: '',
                six: '',
                seven: '',
                eight: '',
            },
            {
                key: 12,
                financing: 'Investment',
                four: '$100',
                five: '',
                six: '',
                seven: '',
                eight: '',
            },
        ]
    },
    {
        key: 2,
        financing: 'Amount paid',
        four: '$14,985',
        five: '$17,983',
        six: '$17,983',
        seven: '$2,997',
        eight: '',
        children: [
            {
                key: 21,
                financing: 'Bank Loan',
                four: '$14,985',
                five: '$17,983',
                six: '$17,983',
                seven: '$2,997',
                eight: '',
            },

        ]
    },
    {
        key: 3,
        financing: 'Ending balance',
        four: '$36,853',
        five: '$20,338',
        six: '$2,978',
        seven: '',
        eight: '',
        children: [
            {
                key: 31,
                financing: 'Bank Loan',
                four: '$36,853',
                five: '$20,338',
                six: '$2,978',
                seven: '',
                eight: '',
            },
        ]
    },

]

export const taxesData = [
    {
        key: 1,
        tax: 'Tax accured',
        four: '$4,283',
        five: '$19,438',
        six: '$25,950',
        seven: '$30,448',
        eight: '$34,654',
        children: [
            {
                key: 11,
                tax: 'Income Tax',
                four: '($9,926)',
                five: '($2,526)',
                six: '($184)',
                seven: '$608',
                eight: '$920',
            },
            {
                key: 12,
                tax: 'Sales Tax',
                four: '$14,209',
                five: '$21,964',
                six: '$26,134',
                seven: '$29,840',
                eight: '$33,735',
            },
        ]
    },
    {
        key: 2,
        tax: 'Tax paid',
        four: '',
        five: '$4,283',
        six: '$19,438',
        seven: '$25,950',
        eight: '$30,448',
        children: [
            {
                key: 21,
                tax: 'Income Tax',
                four: '',
                five: '($9,926)',
                six: '($2,526)',
                seven: '($184)',
                eight: '$608',
            },
            {
                key: 22,
                tax: 'Sales Tax',
                four: '',
                five: '$14,209',
                six: '$21,964',
                seven: '$26,134',
                eight: '$29,840',
            }

        ]
    },

]

export const profitData = [
    {
        key: 1,
        profit: 'Revenue',
        four: '$142,089',
        five: '$219,642',
        six: '$261,343',
        seven: '$298,399',
        eight: '$337,348',
        children: [
            {
                key: 11,
                profit: 'Product Sales',
                four: '$57,834',
                five: '$70,747',
                six: '$79,720',
                seven: '$89,830',
                eight: '$101,223',
            },
            {
                key: 12,
                profit: 'Consulting Services',
                four: '$11,567',
                five: '$14,149',
                six: '$15,944',
                seven: '$17,966',
                eight: '$20,245',
            },
            {
                key: 13,
                profit: 'Subscription Fees',
                four: '$55,338',
                five: '$113,521',
                six: '$141,764',
                seven: '$163,653',
                eight: '$185,513',
            },
            {
                key: 14,
                profit: 'Training Programs',
                four: '$11,567',
                five: '$14,149',
                six: '$15,944',
                seven: '$17,966',
                eight: '$20,245',
            },
            {
                key: 15,
                profit: 'Advertising Revenue',
                four: '$5,783',
                five: '$7,075',
                six: '$7,972',
                seven: '$8,983',
                eight: '$10,122',
            },
        ]
    },
    {
        key: 2,
        profit: 'Cost of Goods Sold',
        four: '$57,834',
        five: '$70,747',
        six: '$79,720',
        seven: '$89,830',
        eight: '$101,223',
        children: [
            {
                key: 21,
                profit: 'Inventory Costs',
                four: '$57,834',
                five: '$70,747',
                six: '$79,720',
                seven: '$89,830',
                eight: '$101,223',
            },

        ]
    },
    {
        key: 3,
        profit: 'Gross Margin',
        four: '$84,255',
        five: '$148,894',
        six: '$181,624',
        seven: '$208,568',
        eight: '$236,125',
    },
    {
        key: 4,
        profit: 'Gross Margin %',
        four: '59%',
        five: '68%',
        six: '69%',
        seven: '70%',
        eight: '70%',
    },
    {
        key: 5,
        profit: 'Operating Expenses',
        four: '$120,952',
        five: '$157,124',
        six: '$179,820',
        seven: '$203,408',
        eight: '$229,426',
        children: [
            {
                key: 51,
                profit: 'Marketing Expenses',
                four: '$28,418',
                five: '$43,928',
                six: '$52,269',
                seven: '$59,680',
                eight: '$67,470',
            },
            {
                key: 52,
                profit: 'Salary Expenses',
                four: '$92,535',
                five: '$113,196',
                six: '$127,552',
                seven: '$143,729',
                eight: '$161,957',
            },
        ]
    },
    {
        key: 6,
        profit: 'EBITDA',
        four: '($36,698)',
        five: '($8,230)',
        six: '$1,803',
        seven: '$5,160',
        eight: '$6,698',
    },
    {
        key: 7,
        profit: 'Depreciation & Amortization',
        four: '$11,092',
        five: '$2,933',
        six: '$2,100',
        seven: '$2,100',
        eight: '$2,100',
        children: [
            {
                key: 51,
                profit: 'Computer Equirement (Amortized)',
                four: '$9,167',
                five: '$833',
                six: '',
                seven: '',
                eight: '',
            },
            {
                key: 52,
                profit: 'Office Furniture (Depreciated)',
                four: '$458',
                five: '$500',
                six: '$500',
                seven: '$500',
                eight: '$500',
            },
            {
                key: 53,
                profit: 'Communication Systems (Depreciated)',
                four: '$1,467',
                five: '$1,600',
                six: '$1,600',
                seven: '$1,600',
                eight: '$1,600',
            },
        ]
    },
    {
        key: 8,
        profit: 'EBIT',
        four: '($47,790)',
        five: '($11,163)',
        six: '($297)',
        seven: '$3,060',
        eight: '$4.598',
    },
    {
        key: 9,
        profit: 'Interest Payments',
        four: '$1,839',
        five: '$1,468',
        six: '$623',
        seven: '$18.63',
        eight: '',
        children: [
            {
                key: 91,
                profit: 'Bank Loan',
                four: '$1,839',
                five: '$1,468',
                six: '$623',
                seven: '$18.63',
                eight: '',
            }
        ]
    },
    {
        key: 10,
        profit: 'EBIT',
        four: '($49,628)',
        five: '($12,631)',
        six: '($920)',
        seven: '$3,041',
        eight: '$4,598',
    },
    {
        key: 11,
        profit: 'Income tax accrued',
        four: '($9,926)',
        five: '($2,526)',
        six: '($184)',
        seven: '$608',
        eight: '$920',
        children: [
            {
                key: 91,
                profit: 'Income Tax',
                four: '($9,926)',
                five: '($2,526)',
                six: '($184)',
                seven: '$608',
                eight: '$920',
            }
        ]
    },
    {
        key: 12,
        profit: 'Net Profit',
        four: '($39,703)',
        five: '($10,105)',
        six: '($736)',
        seven: '$2,433',
        eight: '$3,678',
    },

]

export const balanceData = [
    {
        key: 1,
        balance: 'Current Assets',
        four: '$90,359',
        five: '$80,994',
        six: '$71,511',
        seven: '$77,563',
        eight: '$87,548',
        children: [
            {
                key: 11,
                balance: 'Cash',
                four: '$89,526',
                five: '$80,994',
                six: '$71,511',
                seven: '$77,563',
                eight: '$87,548',
            },
            {
                key: 12,
                balance: 'Other Current Assets',
                four: '$833',
                five: '',
                six: '',
                seven: '',
                eight: '',
            },
        ]
    },
    {
        key: 2,
        balance: 'Long-Term Assets',
        four: '$11,275',
        five: '$8,975',
        six: '$6,875',
        seven: '$4,775',
        eight: '$2,675',
        children: [
            {
                key: 21,
                balance: 'Long Term Assets',
                four: '$13,000',
                five: '$13,000',
                six: '$13,000',
                seven: '$13,000',
                eight: '$13,000',
            },
            {
                key: 22,
                balance: 'Accumulated Depreciation',
                four: '($1,925)',
                five: '($4,025)',
                six: '($6,125)',
                seven: '($8,225)',
                eight: '($10,325)',
            },

        ]
    },
    {
        key: 3,
        balance: 'Assets',
        four: '$101,434',
        five: '$89,969',
        six: '$78,386',
        seven: '$82,338',
        eight: '$90,223',
    },
    {
        key: 5,
        balance: 'Current Liabilities',
        four: '$20,798',
        five: '$36,798',
        six: '$28,929',
        seven: '$30,448',
        eight: '$34,654',
        children: [
            {
                key: 51,
                balance: 'Income Tax Payable',
                four: '($9,926)',
                five: '($2,526)',
                six: '($184)',
                seven: '$608',
                eight: '$920',
            },
            {
                key: 52,
                balance: 'Sales Tax Payable',
                four: '$14,209',
                five: '$21,964',
                six: '$26,134',
                seven: '$29,840',
                eight: '$33,735',
            },
            {
                key: 53,
                balance: 'Short-Term Debt',
                four: '$16,515',
                five: '$17,360',
                six: '$2,978',
                seven: '',
                eight: '',
            },
        ]
    },
    {
        key: 7,
        balance: 'Long-Term Liabilities',
        four: '$20,338',
        five: '$2,978',
        six: '',
        seven: '',
        eight: '',
        children: [
            {
                key: 71,
                balance: 'Long-Term Debt',
                four: '$20,338',
                five: '$2,978',
                six: '',
                seven: '',
                eight: '',
            },

        ]
    },
    {
        key: 8,
        balance: 'Liabilities',
        four: '$41,136',
        five: '$39,776',
        six: '$28,929',
        seven: '$30,448',
        eight: '$34,654',
    },
    {
        key: 9,
        balance: 'Equlity',
        four: '$60,297',
        five: '$50,193',
        six: '$49,457',
        seven: '$51,890',
        eight: '$55,569',
        children: [
            {
                key: 91,
                balance: 'Paid-in Capital',
                four: '$100,000',
                five: '$100,000',
                six: '$100,000',
                seven: '$100,000',
                eight: '$100,000',
            },
            {
                key: 92,
                balance: 'Retained Earnings',
                four: '',
                five: '($39,703)',
                six: '($49,807)',
                seven: '($50,543)',
                eight: '($48,110)',
            },
            {
                key: 91,
                balance: 'Earnings',
                four: '($39,703)',
                five: '($10,105)',
                six: '($736)',
                seven: '$2,433',
                eight: '$3,678',
            },
        ]
    },
    {
        key: 10,
        balance: 'Liabilities & Equity',
        four: '$101,434',
        five: '$89,969',
        six: '$78,386',
        seven: '$82,338',
        eight: '$90,223',
    },
]

export const cashflowData = [
    {
        key: 1,
        cashflow: 'Operating Cash Flow',
        four: '($24,328)',
        five: '$7,983',
        six: '$7,877',
        seven: '$9,031',
        eight: '$9,985',
        children: [
            {
                key: 11,
                cashflow: 'Net Profit',
                four: '($39,703)',
                five: '($10,105)',
                six: '($736)',
                seven: '$2,433',
                eight: '$3,678',
            },
            {
                key: 12,
                cashflow: 'Depreciation and Amortization',
                four: '$11,092',
                five: '$2,933',
                six: '$2,100',
                seven: '$2,100',
                eight: '$2,100',
            },
            {
                key: 13,
                cashflow: 'Change in Income Tax Payable',
                four: '($9,926)',
                five: '$7,399',
                six: '$2,342',
                seven: '$792',
                eight: '$311',
            },
            {
                key: 14,
                cashflow: 'Change in Sales Tax Payable',
                four: '$14,209',
                five: '$7,755',
                six: '$4,170',
                seven: '$3,706',
                eight: '$3,895',
            },
        ]
    },
    {
        key: 2,
        cashflow: 'Investing Cash Flow',
        four: '($23,000)',
        five: '',
        six: '',
        seven: '',
        eight: '',
        children: [
            {
                key: 21,
                cashflow: 'Assets Purchased Sold',
                four: '($23,000)',
                five: '',
                six: '',
                seven: '',
                eight: '',
            },
        ]
    },
    {
        key: 3,
        cashflow: 'Financing Cash Flow',
        four: '$136,853',
        five: '($16,515)',
        six: '($17,360)',
        seven: '($2,978)',
        eight: '',
        children: [
            {
                key: 31,
                cashflow: 'Investments Received',
                four: '$100,000',
                five: '',
                six: '',
                seven: '',
                eight: '',
            },
            {
                key: 32,
                cashflow: 'Dividends Sent',
                four: '',
                five: '',
                six: '',
                seven: '',
                eight: '',
            },
            {
                key: 33,
                cashflow: 'Change in Short-Term Debt',
                four: '$16,515',
                five: '$845',
                six: '($14,381)',
                seven: '($2,978)',
                eight: '',
            },
            {
                key: 34,
                cashflow: 'Change in Long-Term Debt',
                four: '$20,338',
                five: '($17,360)',
                six: '($2,978)',
                seven: '',
                eight: '',
            },
        ]
    },

    {
        key: 4,
        cashflow: 'Cash at Beginning of Period',
        four: '',
        five: '$89,526',
        six: '$80,994',
        seven: '$71,511',
        eight: '$77,563',
    },
    {
        key: 5,
        cashflow: 'Net Change in Cash',
        four: '$89,526',
        five: '($8,531)',
        six: '($9,483)',
        seven: '$6,052',
        eight: '$9,985',
    }, {
        key: 6,
        cashflow: 'Cash at End of Period',
        four: '$89,526',
        five: '$80,994',
        six: '$71,511',
        seven: '$77,563',
        eight: '$87,548',
    },
]

