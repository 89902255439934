import React, { useEffect, useState } from "react";
import { Layout, Drawer, Affix } from "antd";
import { Outlet, useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setPlaceMent,
  handleSidebar,
} from "Redux/features/MainSlice";
import SideNav from "./SideNav";
import { PlusOutlined } from "@ant-design/icons"
import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button, notification, Modal, Form, Input, Spin } from "antd";
import axios from "axios";
import { URL} from "utils/const";
import { useNavigate } from "react-router-dom";
import { addUser } from "../../Redux/features/UserSlice";
import { addContent } from "../../Redux/features/pagesSlice";
import SubHeader from "./SubHeader";
import $ from 'jquery';
import lock from "../../assets/images/lock.svg";
import generate from "../../assets/images/generate.svg";
import { generateIdeas } from "../../utils/openai";

const { Sider, Header: AntHeader, Content } = Layout;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    textAlign: 'center',
    bottom: 'auto',
    marginRight: '-50%',
    borderRadius: '10px',
    overflowX: "scroll",
    overflowY: 'scroll',
    transform: 'translate(-50%, -50%)',
    // backgroundColor:' rgba(0, 0, 0, 0.1) !important'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 500
  }
};
const { TextArea } = Input;


export default function Main() {
  const [modalIsOpen, setIsOpen] = useState(false);

  const { email, role } = useSelector(state => state.userSlice)
  const auth = role;

  let { pathname } = useLocation();
  const { navFixed, sideNavType, sideNavColor, placement, openSidebar } =
    useSelector((state) => state.mainSlice);
  const dispatch = useDispatch();
  pathname = pathname.replace("/", "");
  useEffect(() => {
    if (pathname === "rtl") {
      dispatch(setPlaceMent("left"));
    } else {
      dispatch(setPlaceMent("right"));
    }
  }, [pathname]);


  let subtitle;

  const [companyName, setCompanyName] = useState('');
  const [companyDescription, setCompanyDescompanyDescription] = useState('');
  const [companyUnique, setCompanyUnique] = useState('');

  const [modalNext, setNext] = useState(false);
  const [newCompanyAdded, setNewCompanyAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [ideas, setIdeas] = useState([]);
  const [modalSignUp, setSignUp] = useState(false);
  const [drawerFlag, setDrawerFlag] = useState(false);
  const navigator = useNavigate()
  const [api, contextHolder] = notification.useNotification();
  const user = useSelector(state => state.userSlice)
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  const changeState = () => {
    setIsOpen(true);
  }

  const backCompany = () => {
    setNext(false);
  }

  const changeInputUnique = (e) => {

    const newUnique = e.target.value

    setCompanyUnique(newUnique)
  }

  async function generateIdea() {
    setIsLoading(false)
    let data = await generateIdeas({ companyName: companyName, companyDescription: companyDescription,modelName:user.gpt.modalName })
    const response = JSON.parse(data);
    setIdeas(response.ideas)
    setIsLoading(true)
  }

  const openNotificationWithIcon = (type, data) => {
    api[type]({
      message: "Notification",
      description: data,
    });
  };

  function closeModal() {
    setIsOpen(false);
  }

  const changeUnique = (item) => {
    let newUnique = ''


    if (companyUnique == '') {
      newUnique = item;
    } else {
      newUnique = companyUnique + ' , ' + item;
    }

    setCompanyUnique(newUnique)
    const updatedIdeas = ideas.filter((idea) => idea !== item);
    setIdeas(updatedIdeas);
  }

  function handleOk() {
    setIsOpen(true);
  }
  const changeNewCompanyState = () => {
    setNewCompanyAdded(false)
  }
  const handleOpen = () => {
    console.log("here")
    setIsOpen(true);
  }

  const changeDrawerFlag = () => {
    if (drawerFlag == false) {
      setDrawerFlag(true)
    } else {
      setDrawerFlag(false)
    }
  }

  const onFinishCompany = (values) => {
    setCompanyName(values.companyName);
    setCompanyDescompanyDescription(values.companyDescription)
    setNext(true);
    console.log("values : ", values);
  };
  const reset = () => {
    axios.post(URL+"/api/users/resetCompanies", { email: user.email })
      .then(response => {
        const data = response.data;
        if (data) {
          dispatch(addContent({ content: data.company[0].jsonContent }))
          dispatch(addUser({
            email: data.email,
            name: 'StartupSphere',
            companies: data.company,
            curID: data.curID,
            role: data.role,
            prompt: data.prompt,
            upgrade:data.upgrade,
            gpt:data.gpt
          }))
          setNewCompanyAdded(true)
        }
      })
      .catch(error => {
        console.log(error.response)
      })
  }
  const onFinishUnique = (values) => {
    setNext(false);
    setCompanyUnique(values.companyUnique)
    const data = { email: email, companyName: companyName, companyDescription: companyDescription, companyUnique: values.companyUnique }
    setIdeas([]);
    setCompanyUnique('')
    if (auth) {
      axios.post(URL+"/api/users/updateCompany", data)
        .then(response => {
          const data = response.data;
          if (data.email) {
            openNotificationWithIcon("success", "You added new company successfully");
            setTimeout(() => {
              reset()
            }, 1000)

          }
          else {
            openNotificationWithIcon("error", "New add failed");
          }
        })
        .catch(error => {
          console.error(error);
        })
    }
    !auth ?
      setSignUp(true)
      :
      setIsOpen(false)
    console.log("values : ", values);
  };

  const onFinishFailed = (errInfo) => {
    console.log("Error : ", errInfo);
  };

  const outClkEvent = () => {
    $('#recycle_com').css('display', 'none');
    $('#option_com').css('display', 'none');

  }

  return (
    <Layout
      className={`layout-dashboard ${pathname === "profile" ? "layout-profile" : ""
        } ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}
    >
      {contextHolder}

      <Drawer
        title={false}
        placement={placement === "right" ? "left" : "right"}
        closable={false}
        width={250}
        className={`drawer-sidebar ${pathname === "rtl" ? "drawer-sidebar-rtl" : ""
          } `}
        onClose={changeDrawerFlag}
        open={drawerFlag}
      >
        <Sider
         width={250}
         theme="light"
         className={`sider-primary ant-layout-sider-primary ${sideNavType === "#fff" ? "active-route" : ""
        }`}
        style={{ height: '100vh', padding: '0px' }}
        >
          <SideNav color={sideNavColor} onClickedChange={() => changeState()} newCompanyAdded={newCompanyAdded} changeNewCompanyState={changeNewCompanyState} />
        </Sider>
      </Drawer>
      <Sider
        breakpoint="lg"
        collapsedWidth={0}
        onCollapse={(collapsed, type) => {
        }}
        trigger={null}
        width={250}
        theme="light"
        className={`sider-primary ant-layout-sider-primary ${sideNavType === "#fff" ? "active-route" : ""
          }`}
        style={{ backgroundColor: '#FCFAF8', height: '100vh', padding: '0px' }}
      >
        <SideNav color={sideNavColor} onClickedChange={() => changeState()} newCompanyAdded={newCompanyAdded} changeNewCompanyState={changeNewCompanyState} />
      </Sider>
      <Layout>
        <Content className="content-ant" style={{ backgroundColor: 'white !important' }}>
          <div className="w-full fixed h-[45px] bg-white z-[350]">
            <SubHeader saveFlag={true} onChangeFlag={changeDrawerFlag} />
          </div>
          <div className="mt-[50px] pr-4 pl-4" onClick={outClkEvent}>
            <Outlet id="main_com" />
          </div>
        </Content>
        <Modal
          visible={modalIsOpen}
          // isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          onOk={handleOk}
          closeIcon={null}
          footer={null}
          onCancel={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          {
            !modalNext &&
            <div>
              {/* <div className="flex justify-end">
                <p><Link to="/sign-in" className="text-blue-500">Login</Link></p>
              </div> */}
              <p style={{ fontSize: '1.5rem', paddingTop: '0.5rem', textAlign: 'center', fontWeight: '600' }}>
                Get Started with StartupSphere
              </p>
              <h2 className="text-center">
                Tell us about your business and we'll tailor our AI report and content<br className="hidden sm:block" /> generator to fit your unique needs.
              </h2>
              <Form
                onFinish={onFinishCompany}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <p style={{ textAlign: 'left', paddingTop: '2.5rem' }}>Company Name</p>
                <Form.Item
                  className="username"
                  style={{ paddingTop: '0.5rem' }}
                  name="companyName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your company name!",
                    },
                  ]}
                >
                  <Input placeholder="E.g. Happy Hounds" />
                </Form.Item>

                <p style={{ textAlign: 'left' }}>Company Description</p>
                <Form.Item
                  className="username"
                  name="companyDescription"
                  style={{ marginBottom: 0, paddingTop: '0.5rem' }}
                  rules={[
                    {
                      required: true,
                      message: "Please input your company description!",
                    },
                  ]}
                >
                  <Input.TextArea autoSize={{ minRows: 1, maxRows: 6 }} placeholder="E.g. dog walking business in Toronto" />
                </Form.Item>

                <Form.Item>
                  <Button
                    className="mt-5"
                    type="default"
                    htmlType="submit"
                    // onClick={() => setNext(true)}
                    style={{ width: "100%", backgroundColor: '#3367D6' }}
                  >
                    <div className="text-lg text-white">Next</div>
                  </Button>
                </Form.Item>

              </Form>
              <div className="flex justify-center">
                <img width={15} className="mr-2" src={lock} alt="" />Private & secure.
              </div>
            </div>
          }
          {
            modalNext &&
            <div>
              {/* <div className="flex justify-between">
                <p onClick={backCompany} className="hover:cursor-pointer"> <ArrowLeftOutlined className="mr-1" />Back</p>
                <p><Link to="/sign-in" className="text-blue-500">Login</Link></p>
              </div> */}
              <p style={{ fontSize: '1.5rem', paddingTop: '0.5rem', fontWeight: '600', textAlign:'center' }}>
                Get Started with StartupSphere
              </p>
              <h2 className="mt-2">
                Tell us about your business and we'll tailor our AI report and content<br className="hidden sm:block" /> generator to fit your unique needs.
              </h2>
              <Form
                onFinish={onFinishUnique}
                onFinishFailed={onFinishFailed}
                layout="vertical"
                className="row-col"
              >
                <p style={{ textAlign: 'left', paddingTop: '2rem' }}>What makes <b>{companyName}</b> unique?</p>
                <Input.TextArea autoSize={{ minRows: 1, maxRows: 6 }} className="mt-2" value={companyUnique} onChange={(e) => changeInputUnique(e)} />
                <Form.Item>
                  <Button
                    className="mt-5"
                    onClick={() => generateIdea()}
                    style={{ width: "100%", backgroundColor: 'white' }}
                  >
                    {
                      isLoading ?
                        <div className="text-lg text-[#A660F6] flex justify-center"> <img width={15} className="mr-2" src={generate} alt="" />Generate ideas</div>
                        :
                        <Spin className="relative" size="small" />
                    }
                  </Button>
                </Form.Item>
                {
                  ideas && ideas.map((item, key) => (<div onClick={() => changeUnique(item)} className="idea-text" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}><p className="block text-left cursor-pointer hover:pointer " style={{ marginLeft: "10px" }} key={item}>{item}</p><PlusOutlined className="btn-plus"  style={{ marginRight: '10px' }} /></div>)
                  )
                }
                <Form.Item>
                  <Button
                    className="mt-3"
                    type="default"
                    htmlType="submit"
                    style={{ width: "100%", backgroundColor: '#3367D6' }}
                  >
                    <div className="text-lg text-white"> Continue </div>
                  </Button>
                </Form.Item>
              </Form>
              <div className="flex justify-center">
                <img width={15} className="mr-2" src={lock} alt="" />Private & Secure.
              </div>
            </div>
          }
        </Modal>
      </Layout>
    </Layout>
  );
}
