import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Row,
  Col,
  Switch
} from "antd";
import {
  TransactionOutlined,
  TrophyOutlined,
  CodepenOutlined
} from "@ant-design/icons";
import { connect, useSelector, useDispatch } from "react-redux";
import Admin from "utils/admin";
import Edit from "utils/edit";
import axios from "axios";
import { URL } from "utils/const";
import *as formats from '../../utils/configure'
import { genEachContent } from "../../utils/service";
import { updateHtmlContent } from '../../Redux/features/UserSlice';
import { addContent } from "../../Redux/features/pagesSlice";
import Piechart1 from "components/charts/Piechart1";
import { setRegenerate } from "../../Redux/features/UserSlice";
import Limit from "components/Limit";
import Generating from "components/Generating";
import { addUser } from "../../Redux/features/UserSlice";
import Upgrade from "components/Upgrade";
import FinancialPieChart1 from "components/charts/FinancialPieChart1";

export default function IndustryOverview() {
  const dispatch = useDispatch();

  const { email, curID, companies, role, prompt } = useSelector(state => state.userSlice)

  const user = useSelector(state => {
    return state.userSlice
  })

  const pageContents = useSelector(state => {
    return state.pagesSlice
  })

  const category = "Industry Overview"
  const page_title = user.companies[0].content.find((item) => item.title === "Market Research").list.find((item) => item.origin===category).shown

  const auth = role

  const [oldprompt, setOldPrompt] = useState([]);
  const [flag, setFlag] = useState(false);
  const [generateFlag, setGenerateFlag] = useState(false);
  const [renderData, setRenderData] = useState(null);
  const [contentOverview, setContentOverview] = useState('')
  const [content, setContent] = useState('')
  const [rate, setRate] = useState([])
  const [paid, setPaid] = useState();


  useEffect(() => {
    setFlag(false);
    console.log(user)
    dispatch(setRegenerate(false))
    if (email == '')
      window.location.href = '/sign-in';
  }, []);

  useEffect(() => {
    axios.post(URL + "/api/paid/get", { data: "Industry Overview" })
      .then(res => {
        console.log("res", res.data.prompt);
        if (res.data.prompt == 'paid')
          setPaid(true)
        else setPaid(false)
      })
      .catch(err => console.log("err", err))
  }, [])

  const handleChangeCheck = () => {
    if (paid == true)
      setPaid(false)
    else setPaid(true)

    axios.post(URL + "/api/paid/add", { data: "Industry Overview" })
      .then(res => {
        console.log("paid has changed", res)
      })
      .catch(err => console.log("failed to change the paid method", err))
  }

  useEffect(() => {
    const currentPrompt = prompt.filter((item) => item.title == category)
    console.log("currentPrompts", currentPrompt)
    setOldPrompt(currentPrompt.prompt)
  }, [])




  const savePrompt = (newPrompt) => {
    console.log("saveNewPrompt", newPrompt)
  }

  const generateContent = (newPrompt) => {
    setFlag(true);
    setGenerateFlag(true)
    console.log(formats)
    let formatData;
    Object.keys(formats).map(key => {
      if (category == formats[key].Title) {
        console.log(formats[key].Title, formats[key], formats[key])
        formatData = { title: formats[key].Title, data: formats[key] }
      }
    })
    // console.log(formatData.data)

    genEachContent(user.companies[0], newPrompt, formatData.data, user.gpt.modelName)
      .then((res) => {
        if (Array.isArray(pageContents.contents)) {
          const newContents = pageContents.contents.map((item, idx) => {
            if (item.Title === category) {
              return res
            }
            return item;
          });
          let temp = newContents;
          dispatch(addContent({ content: temp }));
          dispatch(updateHtmlContent({ itemTitle: category, newhtmlContent: {} }));
          dispatch(setRegenerate(false))
          setFlag(false);
          axios.post(URL + "/api/users/result/save", { email: user.email, companyName: user.companies[0].companyName, content: newContents })
            .then(res => {
              console.log("---------------", res)
              dispatch(addUser({
                email: user.email,
                name: 'StartupSphere',
                companies: res.data,
                curID: user.curID,
                role: user.role,
                prompt: user.prompt,
                upgrade: user.upgrade,
                gpt: user.gpt
              }))
            }).catch(err => console.log(err))
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleChange = (html, clippedTxt, id) => {

    if (id == "contentOverview")
      setContentOverview(html)
    if (id == "rate")
      setRate(html)
    if (id == "content")
      setContent(html)
  }

  useEffect(() => {
    if (contentOverview != "" || content != "") {
      const data = {
        contentOverview: contentOverview,
        content: content,
        rate: rate,
      }
      console.log("problem----", data)
      const sendData = { email: user.email, company: user.companies[0].companyName, title: category, sendData: data }
      axios.post(URL + "/api/users/changeHtml", {
        data: sendData
      }).then(res => {
        console.log('updated and saved')
        // console.log(sendData)
        dispatch(updateHtmlContent({ itemTitle: category, newhtmlContent: data }));
      }).catch(err => console.log('saving updated content'))
    }
  }, [contentOverview, content]);

  useEffect(() => {
    let middleData = [];

    if (Array.isArray(pageContents.contents)) {
      middleData = pageContents.contents.filter((item) => {
        return item.Title === category;
      });
    } else if (Array.isArray(pageContents.contents.contents)) {
      middleData = pageContents.contents.contents.filter((item) => {
        return item.Title === category;
      });
    }
    setRenderData(middleData);
    console.log("middledata", middleData)
  }, [pageContents]);

  const chageHtml = (html, id) => {
    console.log("content overview")
    if (id == "contentOverview")
      setContentOverview(html)
    if (id == "rate") {
      const parseContent = (content) => {
        const regex = /<h2>(.*?)<\/h2><h3>(.*?)<\/h3>/g;
        const matches = [];
        let match;
        while ((match = regex.exec(content)) !== null) {
          matches.push({ h2: match[1], h3: match[2] });
        }
        return matches;
      };

      const parsedContent = parseContent(html);
      setRate(parsedContent)
    }
    if (id == "content")
      setContent(html)
  }

  useEffect(() => {
    if (user.regenerateFlag == true) {
      let currentPrompt = ''
      console.log("regnerating")
      user.prompt.map(item => {
        if (item.title == category) {
          currentPrompt = item.prompt
        }
      })
      generateContent(currentPrompt)
    } else {
      setFlag(false)
    }
  }, [user.regenerateFlag]);

  useEffect(() => {

    let htmlContent = null;
    companies[0].htmlContent.forEach((item) => {
      if (item.Title === category) {
        htmlContent = item.htmlcontent;
      }
    });
    if (htmlContent != null && !generateFlag) {
      console.log("hereee", htmlContent)
      setContentOverview(htmlContent.contentOverview)
      setContent(htmlContent.content)
      // const parseContent = (content) => {
      //   const regex = /<h2>(.*?)<\/h2><h3>(.*?)<\/h3>/g;
      //   const matches = [];
      //   let match;
      //   while ((match = regex.exec(content)) !== null) {
      //     matches.push({ h2: match[1], h3: match[2] });
      //   }
      //   return matches;
      // };

      // const parsedContent = parseContent(htmlContent.rate);
      setRate(htmlContent.rate)
    } else if (renderData != null && renderData[0]) {

      setContentOverview('<h3>' + renderData[0]?.["Summary"] + '</h3>')

      const rlt = renderData[0]?.["Content"]?.["Description"]?.["Description"]
      if (rlt) {
        let length = rlt.length
        let result = `<h3>${renderData[0]?.["Content"]?.["Description"]?.["Overview"]}</h3>`
        for (let i = 0; i < length; i++) {
          result = result + `<ol><li>${rlt[i]?.["Description"]}</li></ol>`;
        }
        result = result + `<h3>${renderData[0]?.["Content"]?.["Description"]?.["Conclusion"]}</h3>`;
        setContent(result);
      }

      const rlt1 = renderData[0]?.["Rate"]
      if (rlt1) {
        let length1 = rlt1.length
        let result1 = '';
        for (let i = 0; i < length1; i++) {
          {
            result1 = result1 + `<h2>${rlt1[i]?.["SubTitle"]}</h2>` + `<h3>${rlt1[i]?.["Description"]}</h3>`
          }

        }
        const parseContent = (content) => {
          const regex = /<h2>(.*?)<\/h2><h3>(.*?)<\/h3>/g;
          const matches = [];
          let match;
          while ((match = regex.exec(content)) !== null) {
            matches.push({ h2: match[1], h3: match[2] });
          }
          return matches;
        };

        const parsedContent = parseContent(result1);

        setRate(parsedContent)
      }

    }

  }, [renderData])


  return (
    <div className="layout-content mx-auto max-w-4xl">
      {(user.upgrade == "unpaid" && auth != "admin") && <Col xs={24} md={24} lg={24}><Limit /></Col>}
      <Row className="p-6">
        <Col xs={24} md={24} lg={24}>
          <h6 class="text-md font-normal flex items-center leading-none capitalize w-full text-green-500">
            <div class="w-6 h-6 mr-2 justify-center flex rounded-sm text-green-500 bg-green-50"><div class="w-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-full"><path d="M3 3v18h18"></path><rect width="4" height="7" x="7" y="10" rx="1"></rect><rect width="4" height="12" x="15" y="5" rx="1"></rect></svg></div></div>
            <span class="text-base font-normal">Market Research</span>
          </h6>
        </Col>
        <Col
          xs={24}
          md={24}
          lg={24}
          className="flex items-center justify-between mt-4 mb-9"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div>
            <h3 class="text-2xl sm:text-3xl font-medium leading-none tracking-tight capitalize w-full">{page_title}</h3>
          </div>
          {auth=='admin'&&<Switch className="bg-[#3367D6]" checkedChildren="FREE" unCheckedChildren="PAID" checked={paid} onChange={handleChangeCheck} />}
        </Col>
        {
          auth == 'admin' &&
          <Admin currentPrompt={oldprompt} category={category} flag={flag} generateContent={generateContent} savePrompt={savePrompt}></Admin>
        }
        {((contentOverview != ''&&user.upgrade != "unpaid")||(contentOverview != ''&&auth == 'admin')||(contentOverview != ''&&paid)) &&
          <div>
            <div className='w-full mt- h-[250px] text-muted-foreground text-xs border flex items-center'>
              <FinancialPieChart1 year={2024} className="w-1/4" />
              <div className="py-4 sm:py-8 px-4 flex flex-col gap-8 false w-3/4">
                <div className="w-full">
                  {rate.map(({ h2, h3 }, index) => (
                    <div key={index} className="text-base w-full items-stretch flex">
                      <div className="w-1 mr-2 rounded-[1px] shrink-0" style={{ backgroundColor: "rgb(36, 99, 235)" }}></div>
                      <div className="flex flex-col w-full">
                        <span className="w-full" style={{ fontWeight: '500', color: 'black' }}>{index == 0 && "40% - "}{index == 1 && "30% - "}{index == 2 && "30% - "} {h2}</span>
                        <span className="text-sm text-muted-foreground w-full">{h3}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <Row>
              <Col xs={24} md={24} lg={24}>
                <div className="dashboard_title">
                  <div className="mt-4">
                    <Edit content={contentOverview} id={"contentOverview"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </div>
              </Col>
            </Row>

            {/* <Row className="mt-6 pb-6 p-2 bg-[#FFF7ED] border rounded-md w-full ml-1">
              <Col xs={24} md={12} lg={12} className="flex items-center invisible lg:visible">
                <div className="flex w-[400px] h-[300px] absolute left-[50%] ml-[-130px] lg:left-[20%] lg:ml-[-100px] mt-[-15px]">
                  <Piechart1 />
                </div>
              </Col>
              <Col xs={24} md={12} lg={12}>
                <Edit content={rate} id={"rate"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
              </Col>
            </Row> */}

            <Row className="mt-8 bg-[white] w-full">
              <div className="dashboard_title">
                <Edit content={content} id={"content1"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
              </div>
            </Row>
          </div>}

        {contentOverview == '' && <Generating></Generating>}
        {
        auth != "admin" && user.upgrade == "unpaid" &&!paid &&<Upgrade></Upgrade>
      }
      </Row>
    </div>
  );
}
