import React, { useEffect, useState } from "react";
import operations_plan from '../../assets/images/pdfs/operations_plan.png'
import business from '../../assets/images/pdfs/business.png'
import teams2 from '../../assets/images/pdfs/teams2.png'
import teams3 from '../../assets/images/pdfs/teams3.png'
import teams4 from '../../assets/images/pdfs/teams4.png'

export default function TeamRoles(props) {
    const [data, setData] = useState(null);

    useEffect(() => {
        if (props.user) {
            // console.log('business model -> user: ', props.user);
            // console.log('opration', props.user.companies[0].jsonContent.find(item => item.Title === "Team & Roles"))
            setData(props.user.companies[0].jsonContent.find(item => item.Title === "Team & Roles"));
        }
    })

    return (<div style={{fontFamily:'sans-serif',display:'none'}}><div style={{fontFamily:'sans-serif', width: 400, padding: '10px 20px', marginLeft: 20 }} id="role">
                <div style={{fontFamily:'sans-serif', display: 'flex', alignItems: 'center' }}>
                    <div style={{fontFamily:'sans-serif', width: 13, height: 13, marginRight: 8, marginTop: 17, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 3, color: '#26B35A' }}>
                        <div style={{fontFamily:'sans-serif', width: 13 }}>
                            <img src={operations_plan} />
                        </div>
                    </div>
                    <div style={{fontFamily:'sans-serif', fontSize: 14, color: '#26B35A' }}>Operations</div>
                </div>
                <div style={{fontFamily:'sans-serif', fontSize: 20 }}>
                Team & Roles
        </div>
        <div style={{fontFamily:'sans-serif',marginTop: '20px', fontSize: 12}}>
            <p>{data?.Summary}</p>
        </div>
        {data?.Content?.map(bigItem => (
                    <div>
        <div style={{fontFamily:'sans-serif',marginTop: '10px',padding: '10px', borderTopLeftRadius: '5px', borderTopRightRadius: '5px', borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px', border: 'solid 1px lightgray'}}>
            <div style={{fontFamily:'sans-serif', fontSize: 14, fontWeight: 'bold' }}>
                { data?.Content[0]?.SubTitle }
            </div>
            <p>{ data?.Content[0]?.Description.Overview}</p>
        </div>
                    <div style={{fontFamily:'sans-serif', display: 'grid', paddingLeft: '10px', paddingRight: '10px', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', gap: '0.5rem', borderBottomLeftRadius: '5px', borderTop: 'none', borderTopRightRadius: '0px', border: 'solid 1px lightgray'}}>
                    <div style={{fontFamily:'sans-serif', marginTop: '0px' }}>
                        <div style={{fontFamily:'sans-serif', display: 'flex', justifyContent: 'start', alignItems: 'center', }}>
                        <div style={{fontFamily:'sans-serif', width: 13, fontSize: 14 }}>
                                        <img src={business} />
                        </div>
                            <div style={{fontFamily:'sans-serif', fontSize: 14, fontWeight: 'bold', color: 'black', marginBottom: '10px', marginLeft: '10px' }}>Responsibilities</div>
                        </div>
                        <ol style={{fontFamily:'sans-serif',}}>
                            {data?.Content[0]?.Description.Responsibilities?.map((subitem, idx) => (
                                <li style={{fontFamily:'sans-serif',marginBottom: '20px', marginLeft: '10px', fontSize: 12}}>{(idx + 1) + " . " + subitem.Description }</li>
                            ))}
                        </ol>
                    </div>
                    <div>
                    <div style={{fontFamily:'sans-serif', display: 'flex', justifyContent: 'start', alignItems: 'center', }}>

                        <div style={{fontFamily:'sans-serif', width: 13, fontSize: 14 }}>
                                        <img src={business} />
                        </div>
                            <div style={{fontFamily:'sans-serif', fontSize: 14, fontWeight: 'bold', color: 'black', marginBottom: '10px', marginLeft: '10px' }}>Personal Attributes</div>
                    </div>
                        <ol style={{fontFamily:'sans-serif',}}>
                            {data?.Content[0]?.Description["Personal Attributes"]?.map((subitem, idx) => (
                                <li style={{fontFamily:'sans-serif',marginBottom: '20px', marginLeft: '10px', fontSize: 12}}>{(idx + 1) + " . " + subitem.Description }</li>
                            ))}
                        </ol>
                    </div>
                    <div>
                    <div style={{fontFamily:'sans-serif', display: 'flex', justifyContent: 'start', alignItems: 'center', }}>

                        <div style={{fontFamily:'sans-serif', width: 13, fontSize: 14 }}>
                                        <img src={business} />
                        </div>
                            <div style={{fontFamily:'sans-serif', fontSize: 14, fontWeight: 'bold', color: 'black', marginBottom: '10px', marginLeft: '10px' }}>Typical Cost To Hire</div>
                    </div>
                        <ol style={{fontFamily:'sans-serif',}}>
                            {data?.Content[0]?.Description["Typical Cost To Hire"]?.map((subitem, idx) => (
                                <li style={{fontFamily:'sans-serif',marginBottom: '20px', marginLeft: '10px', fontSize: 12}}>{(idx + 1) + " . " + subitem.Description }</li>
                            ))}
                        </ol>
                    </div>
                    <div>
                    <div style={{fontFamily:'sans-serif', display: 'flex', justifyContent: 'start', alignItems: 'center', }}>

                        <div style={{fontFamily:'sans-serif', width: 13, fontSize: 14 }}>
                                        <img src={business} />
                        </div>
                            <div style={{fontFamily:'sans-serif', fontSize: 14, fontWeight: 'bold', color: 'black', marginBottom: '10px', marginLeft: '10px' }}>Driving Motivators</div>
                    </div>
                        <ol style={{fontFamily:'sans-serif',}}>
                            {data?.Content[0]?.Description["Driving Motivators"]?.map((subitem, idx) => (
                                <li style={{fontFamily:'sans-serif',marginBottom: '20px', marginLeft: '10px', fontSize: 12}}>{(idx + 1) + " . " + subitem.Description }</li>
                            ))}
                        </ol>
                    </div>
                </div>
        </div>
        
        ))}
</div>
    </div>)
}