import { Col, Row } from 'antd'
import React from 'react'
import { ArrowRightOutlined, RightOutlined } from '@ant-design/icons'
import { Link } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux";

const TabContent = (props) => {
    const { email, companies, role, prompt } = useSelector(state => state.userSlice)
    return (
            <Row>
                <Col xs={24} sm={24} lg={12}>
                    <div className='text-left'>
                       
                        <p className='text-gray-600 sm:text-xl sm:mt-5 sm:mb-8 sm:mr-20'>
                            {props.content}
                        </p>

                        <button className="text-white gradient-button shadow-xl flex ml-8 sm:ml-0 sm:px-[12px] mt-2 sm:mt-0 sm:py-[10px] text-[16px]" style={{borderRadius: "30px", boxShadow: '-3px 4px #B7B7B7' }}>
                            <Link to={email ? props.path : "/sign-in"} className="text-white text-[12px] sm:text-[16px]">
                            <span><b>{props.btnName} <RightOutlined className='text-xs' style={{color:'white'}} /></b></span>
                            </Link>
                        </button>
                        
                        {/* <button style={{ backgroundColor: '#4382FE', color: 'white', boxShadow: '-3px 5px grey' }} className='shadow-xl mt-4 md:mt-0 inline-flex rounded-full items-center justify-center  text-sm sm:text-xl font-medium ring-offset-background px-4 relative overflow-hidden'>
                            {props.btnName}
                            <ArrowRightOutlined className='ml-2 ' />
                        </button> */}
                    </div>
                </Col>
                <Col xs={24} sm={24} lg={12}>
                    <div className='flex justify-end w-full h-full items-center mt-3'>
                        <img src={props.img} alt='' />
                    </div>
                </Col>
            </Row>
    )
}

export default TabContent