import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { Transform } from '@dnd-kit/utilities';
import { Modal } from 'antd';

import { Handle, Remove } from './components';
import { connect, useSelector, useDispatch } from "react-redux";
import { addUser } from 'Redux/features/UserSlice';
import axios from "axios";
import { URL } from 'utils/const';

import styles from './Item.module.css';

import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined
} from "@ant-design/icons";

// export interface Props {
//   dragOverlay?: boolean;
//   color?: string;
//   disabled?: boolean;
//   dragging?: boolean;
//   handle?: boolean;
//   handleProps?: any;
//   height?: number;
//   index?: number;
//   fadeIn?: boolean;
//   transform?: Transform | null;
//   listeners?: DraggableSyntheticListeners;
//   sorting?: boolean;
//   style?: React.CSSProperties;
//   transition?: string | null;
//   wrapperStyle?: React.CSSProperties;
//   value: React.ReactNode;
//   onRemove?(): void;
//   renderItem?(args: {
//     dragOverlay: boolean;
//     dragging: boolean;
//     sorting: boolean;
//     index: number | undefined;
//     fadeIn: boolean;
//     listeners: DraggableSyntheticListeners;
//     ref: React.Ref<HTMLElement>;
//     style: React.CSSProperties | undefined;
//     transform: Props['transform'];
//     transition: Props['transition'];
//     value: Props['value'];
//   }): React.ReactElement;
// }

export const Item = React.memo(
  React.forwardRef(
    (
      {
        color,
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        handle,
        handleProps,
        height,
        index,
        listeners,
        onRemove,
        changeItems,
        onChangeStatus,
        renderItem,
        sorting,
        style,
        transition,
        transform,
        value,
        wrapperStyle,
        ...props
      },
      ref
    ) => {

      const user = useSelector(state => {
        return state.userSlice
      })

      const company = user.companies[0].content;

      const [status, setStatus] = useState(false);

      useEffect(() => {
        if (!dragOverlay) {
          return;
        }

        document.body.style.cursor = 'grabbing';

        return () => {
          document.body.style.cursor = '';
        };
      }, [dragOverlay]);

      const onDelete = (e) => {
        const updatedCompany = company.map(item => ({
          ...item,
          list: item.list.filter(subItem => subItem.shown != e)
        }));
        changeItems(updatedCompany)
      }

      const onModify = () => {
        console.log("hereModify")
      }

      return renderItem ? (
        renderItem({
          dragOverlay: Boolean(dragOverlay),
          dragging: Boolean(dragging),
          sorting: Boolean(sorting),
          index,
          fadeIn: Boolean(fadeIn),
          listeners,
          ref,
          style,
          transform,
          transition,
          value,
        })
      ) : (
        <li
          className={classNames(
            styles.Wrapper,
            fadeIn && styles.fadeIn,
            sorting && styles.sorting,
            dragOverlay && styles.dragOverlay,
            "group"
          )}
          style={
            {
              ...wrapperStyle,
              transition: [transition, wrapperStyle?.transition]
                .filter(Boolean)
                .join(', '),
              '--translate-x': transform
                ? `${Math.round(transform.x)}px`
                : undefined,
              '--translate-y': transform
                ? `${Math.round(transform.y)}px`
                : undefined,
              '--scale-x': transform?.scaleX
                ? `${transform.scaleX}`
                : undefined,
              '--scale-y': transform?.scaleY
                ? `${transform.scaleY}`
                : undefined,
              '--index': index,
              // '--color': color,
            }
          }
          key={index}
          ref={ref}
        >
          <div
            className={classNames(
              styles.Item,
              dragging && styles.dragging,
              handle && styles.withHandle,
              dragOverlay && styles.dragOverlay,
              disabled && styles.disabled,
              color && styles.color,
              "pl-2"
            )}
            style={style}
            data-cypress="draggable-item"
            {...(!handle ? listeners : undefined)}
            {...props}
            tabIndex={!handle ? 0 : undefined}
          >
            <div className='mr-2'>
              <svg viewBox="0 0 20 20" width="12">
                <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
              </svg>
            </div>
            {/* <span className={styles.Actions}>
              {onRemove ? (
                <Remove className={styles.Remove} onClick={onRemove} />
              ) : null}
              {handle ? <Handle {...handleProps} {...listeners} /> : null}
            </span> */}

            {value}
            <div className="ml-auto flex items-center opacity-0 group-hover:opacity-100">
              <button onMouseUp={()=>onChangeStatus(value)} className='rounded-md hover:bg-gray-200 active:bg-gray-300'>
                <EditOutlined />
              </button>
              <button onMouseUp={() => changeItems(value)} className='rounded-md mx-2 hover:bg-gray-200 active:bg-gray-300'>
                <DeleteOutlined />
              </button>
              {/* <button className='rounded-md hover:bg-gray-200 active:bg-gray-300'>
                <EyeOutlined />
              </button> */}
            </div>
          </div>
        </li>
      );
    }
  )
);
