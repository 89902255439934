import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Switch
} from "antd";

import Edit from "utils/edit";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { URL } from "utils/const";
import Admin from "utils/admin";
import *as formats from '../../utils/configure'
import { genEachContent } from "../../utils/service";
import { updateHtmlContent } from '../../Redux/features/UserSlice';
import { addContent } from "../../Redux/features/pagesSlice";
import { setRegenerate } from "../../Redux/features/UserSlice";
import Limit from "components/Limit";
import Upgrade from "components/Upgrade";
import Generating from "components/Generating";
import { addUser } from "../../Redux/features/UserSlice";
export default function Team() {

  const dispatch = useDispatch();

  const { email, curID, companies, role, prompt } = useSelector(state => state.userSlice)

  const user = useSelector(state => {
    return state.userSlice
  })

  const pageContents = useSelector(state => {
    return state.pagesSlice
  })

  const category = "Team & Roles"
  const page_title = user.companies[0].content.find((item) => item.title === "Operations").list.find((item) => item.origin===category).shown

  const auth = role

  const [oldprompt, setOldPrompt] = useState([]);
  const [flag, setFlag] = useState(false);
  const [generateFlag, setGenerateFlag] = useState(false);
  const [description, setDescription] = useState('')
  const [contentOverview, setContentOverview] = useState('')
  const [subTitle1, setSubtitle1] = useState('')
  const [subOverview1, setSubOverview1] = useState('')
  const [advantages1, setAdvantages1] = useState('')
  const [challenges1, setChallenges1] = useState('')
  const [subTitle2, setSubtitle2] = useState('')
  const [subOverview2, setSubOverview2] = useState('')
  const [advantages2, setAdvantages2] = useState('')
  const [challenges2, setChallenges2] = useState('')
  const [subTitle11, setSubtitle11] = useState('')
  const [subOverview11, setSubOverview11] = useState('')
  const [advantages11, setAdvantages11] = useState('')
  const [challenges11, setChallenges11] = useState('')
  const [subTitle21, setSubtitle21] = useState('')
  const [subOverview21, setSubOverview21] = useState('')
  const [advantages21, setAdvantages21] = useState('')
  const [challenges21, setChallenges21] = useState('')
  const [renderData, setRenderData] = useState(null);
  const [paid, setPaid] = useState();


  useEffect(() => {
    setFlag(false);
    dispatch(setRegenerate(false))
    if (email == '')
      window.location.href = '/sign-in';
  }, []);

  useEffect(() => {
    axios.post(URL + "/api/paid/get", { data: "Team & Roles" })
        .then(res => {
            console.log("res", res.data.prompt);
            if (res.data.prompt == 'paid')
                setPaid(true)
            else setPaid(false)
        })
        .catch(err => console.log("err", err))
}, [])

const handleChangeCheck = () => {
    if (paid == true)
        setPaid(false)
    else setPaid(true)

    axios.post(URL + "/api/paid/add", { data: "Team & Roles" })
    .then(res=>{
        console.log("paid has changed", res)
    })
    .catch(err=>console.log("failed to change the paid method", err))
}

  /////////////////////////////////////////////////////////////////


  useEffect(() => {
    const currentPrompt = prompt.filter((item) => item.title == category)
    setOldPrompt(currentPrompt.prompt)
  }, [])

  const savePrompt = (newPrompt) => {
    console.log("saveNewPrompt", newPrompt)
  }

  const generateContent = (newPrompt) => {
    setFlag(true);
    setGenerateFlag(true)
    let formatData;
    Object.keys(formats).map(key => {
      if (category == formats[key].Title) {
        console.log(formats[key].Title, formats[key], formats[key])
        formatData = { title: formats[key].Title, data: formats[key] }
      }
    })

    genEachContent(user.companies[0], newPrompt, formatData.data, user.gpt.modelName)
      .then((res) => {
        if (Array.isArray(pageContents.contents)) {
          const newContents = pageContents.contents.map((item, idx) => {
            if (item.Title === category) {
              return res
            }
            return item;
          });
          let temp = newContents;
         
          dispatch(addContent({ content: temp }));
          dispatch(updateHtmlContent({ itemTitle: category, newhtmlContent: {} }));
          dispatch(setRegenerate(false))
          setFlag(false);
          axios.post(URL+"/api/users/result/save", { email: user.email, companyName: user.companies[0].companyName, content: newContents })
            .then(res => {
              console.log("---------------",res)
              dispatch(addUser({
                email: user.email,
                name: 'StartupSphere',
                companies: res.data,
                curID: user.curID,
                role: user.role,
                prompt: user.prompt,
                upgrade:user.upgrade,
                gpt:user.gpt
              }))
            }).catch(err => console.log(err))
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }


  //////////////////////////////////////////////////////////////////

  const handleChange = (html, clippedTxt, id) => {

    if (id == "contentOverview")
      setContentOverview(html)
    if (id == "subTitle1")
      setSubtitle1(html)
    if (id == "subOverview1")
      setSubOverview1(html)
    if (id == "advantages1")
      setAdvantages1(html)
    if (id == "challenges1")
      setChallenges1(html)
    if (id == "subTitle2")
      setSubtitle2(html)
    if (id == "subOverview2")
      setSubOverview2(html)
    if (id == "advantages2")
      setAdvantages2(html)
    if (id == "challenges2")
      setChallenges2(html)
    if (id == "subTitle11")
      setSubtitle11(html)
    if (id == "subOverview11")
      setSubOverview11(html)
    if (id == "advantages11")
      setAdvantages11(html)
    if (id == "challenges11")
      setChallenges11(html)
    if (id == "subTitle21")
      setSubtitle21(html)
    if (id == "subOverview21")
      setSubOverview21(html)
    if (id == "advantages21")
      setAdvantages21(html)
    if (id == "challenges21")
      setChallenges21(html)
    if (id == "description")
      setDescription(html)

  }

  useEffect(() => {
    if (contentOverview != "" || subTitle1 != "" || subOverview1 != "" || advantages1 != "" || challenges1 != "" || subTitle2 != "" || subOverview2 != "" || advantages2 != "" || challenges2 != "" || subTitle11 != "" ||
      subOverview11 != "" || advantages11 != "" || challenges11 != "" || subTitle21 != "" || subOverview21 != "" || advantages21 != "" || challenges21 != "" || description != "") {
      const data = {
        contentOverview: contentOverview,
        subTitle1: subTitle1,
        subOverview1: subOverview1,
        advantages1: advantages1,
        challenges1: challenges1,
        subTitle2: subTitle2,
        subOverview2: subOverview2,
        advantages2: advantages2,
        challenges2: challenges2,
        subTitle11: subTitle11,
        subOverview11: subOverview11,
        advantages11: advantages11,
        challenges11: challenges11,
        subTitle21: subTitle21,
        subOverview21: subOverview21,
        advantages21: advantages21,
        challenges21: challenges21,
        description: description
      }
      // console.log("problem----",problem)
      const sendData = { email: user.email, company: user.companies[0].companyName, title: category, sendData: data }
      axios.post(URL+"/api/users/changeHtml", {
        data: sendData
      }).then(res => {
        console.log('updated and saved')
        // console.log(sendData)
        dispatch(updateHtmlContent({ itemTitle: category, newhtmlContent: data }));
      }).catch(err => console.log('saving updated content'))
    }
  }, [contentOverview, subTitle1, subOverview1, advantages1, challenges1, subTitle2, subOverview2, advantages2, challenges2, subTitle11,
    subOverview11, advantages11, challenges11, subTitle21, subOverview21, advantages21, challenges21, description]);


  useEffect(() => {
    let middleData = [];

    if (Array.isArray(pageContents.contents)) {
      middleData = pageContents.contents.filter((item) => {
        return item.Title === category;
      });
    } else if (Array.isArray(pageContents.contents.contents)) {
      middleData = pageContents.contents.contents.filter((item) => {
        return item.Title === category;
      });
    }
    setRenderData(middleData);
    console.log("middledata", middleData)
  }, [pageContents]);



  const chageHtml = (html, id) => {
    console.log("content overview")
    if (id == "contentOverview")
      setContentOverview(html)
    if (id == "subTitle1")
      setSubtitle1(html)
    if (id == "subOverview1")
      setSubOverview1(html)
    if (id == "advantages1")
      setAdvantages1(html)
    if (id == "challenges1")
      setChallenges1(html)
    if (id == "subTitle2")
      setSubtitle2(html)
    if (id == "subOverview2")
      setSubOverview2(html)
    if (id == "advantages2")
      setAdvantages2(html)
    if (id == "challenges2")
      setChallenges2(html)
    if (id == "subTitle11")
      setSubtitle11(html)
    if (id == "subOverview11")
      setSubOverview11(html)
    if (id == "advantages11")
      setAdvantages11(html)
    if (id == "challenges11")
      setChallenges11(html)
    if (id == "subTitle21")
      setSubtitle21(html)
    if (id == "subOverview21")
      setSubOverview21(html)
    if (id == "advantages21")
      setAdvantages21(html)
    if (id == "challenges21")
      setChallenges21(html)
    if (id == "description")
      setDescription(html)
  }

  useEffect(() => {
    if (user.regenerateFlag == true) {
      let currentPrompt = ''
      console.log("regnerating")
      user.prompt.map(item => {
        if (item.title == category) {
          currentPrompt = item.prompt
        }
      })
      generateContent(currentPrompt)
    } else {
      setFlag(false)
    }
  }, [user.regenerateFlag]);

  useEffect(() => {

    let htmlContent = null;
    companies[0].htmlContent.forEach((item) => {
      if (item.Title === category) {
        htmlContent = item.htmlcontent;
      }
    });
    if (htmlContent != null && !generateFlag) {
      setContentOverview(htmlContent.contentOverview)
      setSubtitle1(htmlContent.subTitle1)
      setSubOverview1(htmlContent.subOverview1)
      setAdvantages1(htmlContent.advantages1)
      setChallenges1(htmlContent.challenges1)
      setSubtitle2(htmlContent.subTitle2)
      setSubOverview2(htmlContent.subOverview2)
      setAdvantages2(htmlContent.advantages2)
      setChallenges2(htmlContent.challenges2)
      setSubtitle11(htmlContent.subTitle11)
      setSubOverview11(htmlContent.subOverview11)
      setAdvantages11(htmlContent.advantages11)
      setChallenges11(htmlContent.challenges11)
      setSubtitle21(htmlContent.subTitle21)
      setSubOverview21(htmlContent.subOverview21)
      setAdvantages21(htmlContent.advantages21)
      setChallenges21(htmlContent.challenges21)
      setDescription(htmlContent.description)
    } else if (renderData != null && renderData[0]) {
      setContentOverview('<h3>' + renderData[0]?.["Summary"] + '</h3>')
      setDescription(`<h2>${renderData[0]?.["Conclusion"]?.["SubTitle"]}</h2><h3>${renderData[0]?.["Conclusion"]?.["Description"]}</h3>`)

      if (renderData[0]?.["Content"]?.[0]) {
        setSubtitle1('<h4>' + renderData[0]?.["Content"]?.[0]?.["SubTitle"] + '</h4>')
        setSubOverview1('<h3>' + renderData[0]?.["Content"]?.[0]?.["Description"]?.["Overview"] + '</h3>')
        const rlt = renderData[0]?.["Content"]?.[0]?.["Description"]?.["Responsibilities"]
        if (rlt) {
          let length = rlt.length
          let result = '';
          for (let i = 0; i < length; i++) {
            result = result + `<ol><li>${rlt[i]?.["Description"]}</li></ol>`;
          }
          setAdvantages1(result);
        }
        const rlt1 = renderData[0]?.["Content"]?.[0]?.["Description"]?.["Personal Attributes"]
        if (rlt1) {
          let length1 = rlt1.length
          let result1 = '';
          for (let i = 0; i < length1; i++) {
            result1 = result1 + `<ol><li>${rlt1[i]?.["Description"]}</li></ol>`;
          }
          setChallenges1(result1);
        }

        const rlt2 = renderData[0]?.["Content"]?.[0]?.["Description"]?.["Typical Cost To Hire"]
        if (rlt2) {
          const length2 = rlt2.length
          let result2 = '';
          for (let i = 0; i < length2; i++) {
            result2 = result2 + `<ol><li>${rlt2[i]?.["Description"]}</li></ol>`;
          }
          setAdvantages2(result2);
        }
        const rlt3 = renderData[0]?.["Content"]?.[0]?.["Description"]?.["Driving Motivators"]
        if (rlt3) {
          const length3 = rlt3.length
          let result3 = '';
          for (let i = 0; i < length3; i++) {
            result3 = result3 + `<ol><li>${rlt3[i]?.["Description"]}</li></ol>`;
          }
          setChallenges2(result3);
        }
      }

      if (renderData[0]?.["Content"]?.[1]) {
        setSubtitle11('<h4>' + renderData[0]?.["Content"]?.[1]?.["SubTitle"] + '</h4>')
        setSubOverview11('<h3>' + renderData[0]?.["Content"]?.[1]?.["Description"]?.["Overview"] + '</h3>')
        const rlt4 = renderData[0]?.["Content"]?.[1]?.["Description"]?.["Responsibilities"]
        if (rlt4) {
          let length4 = rlt4.length
          let result4 = '';
          for (let i = 0; i < length4; i++) {
            result4 = result4 + `<ol><li>${rlt4[i]?.["Description"]}</li></ol>`;
          }
          setAdvantages11(result4);
        }
        const rlt5 = renderData[0]?.["Content"]?.[1]?.["Description"]?.["Personal Attributes"]
        if (rlt5) {
          let length5 = rlt5.length
          let result5 = '';
          for (let i = 0; i < length5; i++) {
            result5 = result5 + `<ol><li>${rlt5[i]?.["Description"]}</li></ol>`;
          }
          setChallenges11(result5);
        }

        const rlt6 = renderData[0]?.["Content"]?.[1]?.["Description"]?.["Typical Cost To Hire"]
        if (rlt6) {
          const length6 = rlt6.length
          let result6 = '';
          for (let i = 0; i < length6; i++) {
            result6 = result6 + `<ol><li>${rlt6[i]?.["Description"]}</li></ol>`;
          }
          setAdvantages21(result6);
        }
        const rlt7 = renderData[0]?.["Content"]?.[1]?.["Description"]?.["Driving Motivators"]
        if (rlt7) {
          const length7 = rlt7.length
          let result7 = '';
          for (let i = 0; i < length7; i++) {
            result7 = result7 + `<ol><li>${rlt7[i]?.["Description"]}</li></ol>`;
          }
          setChallenges21(result7);
        }
      }
    }

  }, [renderData])

  return (
    <div className="layout-content mx-auto max-w-4xl">
      {(user.upgrade == "unpaid" && auth != "admin") && <Col xs={24} md={24} lg={24}><Limit /></Col>}
      <Row className="p-6">
        <Col
          xs={24}
          md={24}
          lg={24}
          style={{ display: "flex", alignItems: "center" }}
          className="flex items-center justify-between mt-4 mb-9"
        >
          <div><h6 class="text-md font-normal flex items-center leading-none capitalize w-full text-green-500"><div class="w-6 h-6 mr-2 justify-center flex rounded-sm text-green-500 bg-green-50"><div class="w-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-full"><rect width="18" height="18" x="3" y="3" rx="2"></rect><path d="M8 7v7"></path><path d="M12 7v4"></path><path d="M16 7v9"></path></svg></div></div><span class="text-base font-normal">Operations</span></h6><h3 class="text-2xl sm:text-3xl font-medium leading-none tracking-tight capitalize w-full mt-4 mb-3">{page_title}</h3></div>
          {auth=='admin'&&<Switch className="bg-[#3367D6]" checkedChildren="FREE" unCheckedChildren="PAID" checked={paid} onChange={handleChangeCheck} />}
        </Col>
        {
          auth == 'admin' &&
          <Admin currentPrompt={oldprompt} category={category} flag={flag} generateContent={generateContent} savePrompt={savePrompt}></Admin>
        }
        {((contentOverview != ''&&user.upgrade != "unpaid")||(contentOverview != ''&&auth == 'admin')||(contentOverview != ''&&paid)) &&
          <div>
            <Row>
              <Col xs={24} md={24} lg={24}>
                <div className="dashboard_title">
                  <div className="mt-4">
                    <Edit content={contentOverview} id={"contentOverview"} changeContent={handleChange} changeHtml={chageHtml} ></Edit>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-6 bg-[white] border rounded-md" style={{ width: "100%" }}>
              <div className="p-4 border-b" style={{ width: "100%" }}>
                <Edit content={subTitle1} id={"subTitle1"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                <Edit content={subOverview1} id={"subOverview1"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
              </div>
              <Row className="p-4" style={{ width: "100%" }}>
                <Col xs={24} md={12} lg={12} className="pr-2">
                  <div className="dashboard_title">
                    <p className="text-lg font-bold flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10"></path><path d="m9 12 2 2 4-4"></path></svg></div>
                      Responsibilities
                    </p>
                    <Edit content={challenges1} id={"challenges1"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12} className="pl-2">
                  <div className="dashboard_title">
                    <p className="text-lg font-bold flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><circle cx="12" cy="12" r="10"></circle><circle cx="12" cy="10" r="3"></circle><path d="M7 20.662V19a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v1.662"></path></svg></div>
                      Personal Attributes
                    </p>
                    <Edit content={advantages1} id={"advantages1"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
              </Row>
              <Row className="pl-4" style={{ width: "100%" }}>
                <Col span={4} xs={24} md={12} lg={12}>
                  <div className="dashboard_title">
                    <p className="text-lg font-bold flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><path d="M4 2v20l2-1 2 1 2-1 2 1 2-1 2 1 2-1 2 1V2l-2 1-2-1-2 1-2-1-2 1-2-1-2 1-2-1Z"></path><path d="M16 8h-6a2 2 0 1 0 0 4h4a2 2 0 1 1 0 4H8"></path><path d="M12 17V7"></path></svg></div>
                      Typical Cost To Hire
                    </p>
                    <Edit content={advantages2} id={"challenges2"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
                <Col span={4} xs={24} md={12} lg={12}>
                  <div className="dashboard_title">
                    <p className="text-lg font-bold flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><path d="M8.5 14.5A2.5 2.5 0 0 0 11 12c0-1.38-.5-2-1-3-1.072-2.143-.224-4.054 2-6 .5 2.5 2 4.9 4 6.5 2 1.6 3 3.5 3 5.5a7 7 0 1 1-14 0c0-1.153.433-2.294 1-3a2.5 2.5 0 0 0 2.5 2.5z"></path></svg></div>
                      Driving Motivators
                    </p>
                    <Edit content={challenges2} id={"advantages2"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
              </Row>
            </Row>


            <Row className="mt-6 bg-[white] border rounded-md" style={{ width: "100%" }}>
              <div className="p-4 border-b" style={{ width: "100%" }}>
                <Edit content={subTitle11} id={"subTitle11"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                <Edit content={subOverview11} id={"subOverview11"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
              </div>
              <Row className="p-4" style={{ width: "100%" }}>
                <Col xs={24} md={12} lg={12} className="pr-2">
                  <div className="dashboard_title">
                    <p className="text-lg font-bold flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10"></path><path d="m9 12 2 2 4-4"></path></svg></div>
                      Responsibilities
                    </p>
                    <Edit content={challenges11} id={"challenges11"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12} className="pl-2">
                  <div className="dashboard_title">
                    <p className="text-lg font-bold flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><circle cx="12" cy="12" r="10"></circle><circle cx="12" cy="10" r="3"></circle><path d="M7 20.662V19a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v1.662"></path></svg></div>
                      Personal Attributes
                    </p>
                    <Edit content={advantages11} id={"advantages11"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
              </Row>
              <Row className="pl-4" style={{ width: "100%" }}>
                <Col span={4} xs={24} md={12} lg={12}>
                  <div className="dashboard_title">
                    <p className="text-lg font-bold flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><path d="M4 2v20l2-1 2 1 2-1 2 1 2-1 2 1 2-1 2 1V2l-2 1-2-1-2 1-2-1-2 1-2-1-2 1-2-1Z"></path><path d="M16 8h-6a2 2 0 1 0 0 4h4a2 2 0 1 1 0 4H8"></path><path d="M12 17V7"></path></svg></div>
                      Typical Cost To Hire
                    </p>
                    <Edit content={advantages21} id={"challenges21"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
                <Col span={4} xs={24} md={12} lg={12}>
                  <div className="dashboard_title">
                    <p className="text-lg font-bold flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><path d="M8.5 14.5A2.5 2.5 0 0 0 11 12c0-1.38-.5-2-1-3-1.072-2.143-.224-4.054 2-6 .5 2.5 2 4.9 4 6.5 2 1.6 3 3.5 3 5.5a7 7 0 1 1-14 0c0-1.153.433-2.294 1-3a2.5 2.5 0 0 0 2.5 2.5z"></path></svg></div>
                      Driving Motivators
                    </p>
                    <Edit content={challenges21} id={"advantages21"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
              </Row>
            </Row>

            <Row>
              <Col xs={24} md={24} lg={24}>
                <div className="dashboard_title">
                  <div className="text-lg">
                    <Edit content={description} id={"description"} changeContent={handleChange} changeHtml={chageHtml} ></Edit>
                  </div>
                </div>
              </Col>
            </Row>
          </div>}
          {(contentOverview == '' && (user.upgrade !== "unpaid" || auth === 'admin')) &&<Generating></Generating>}

        {/* <Row className="mt-8 bg-[white] rounded-md" style={{ width: "100%" }}>
        <Row className="p-8 bg-[#FAFFFF]" style={{ width: "100%" }}>
          <span className="text-sm text-[#1D42D8] bg-[#D4E5F6] p-1 rounded-md">
            Marketing
          </span>
          <div className="mt-4">
            <h2 className="text-2xl">Marketing and Branding Specialist</h2>
            <p className="text-lg mt-4">
              As a Marketing and Branding Specialist, you will be responsible
              for enhancing the visibility and reputation of our walking
              company. Your role will involve developing brand strategies,
              coordinating marketing campaigns, and managing our online
              presence.
            </p>
          </div>
        </Row>
        <Row className="p-8" style={{ width: "100%" }}>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl">
                <SafetyOutlined className="pr-2" />
                Responsibilities
              </p>
              <ul className="pl-4" style={{ listStyle: "outside" }}>
                <li className="mt-3 text-md">
                  Developing and executing marketing campaigns to
                   attract new customers
                </li>
                <li className="mt-3 text-md">
                  Creating compelling content, including blog posts,
                  
                  social media updates, and email newsletters
                </li>
                <li className="mt-3 text-md">
                  Managing and monitoring our online presence,
                  
                  including website and social media platforms
                </li>
                <li className="mt-3 text-md">
                  Conducting market research to identify
                  
                  opportunities and target audience preferences
                </li>
                <li className="mt-3 text-md">
                  Collaborating with graphic designers and content
                  
                  creators to develop visually appealing and impactful
                  
                  marketing materials
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl">
                <UserOutlined className="pr-2" />
                Personal Attributes
              </p>
              <ul className="pl-4" style={{ listStyle: "outside" }}>
                <li className="mt-3 text-md">
                  Creative mindset and ability to think outside the box
                </li>
                <li className="mt-3 text-md">
                  Strong analytical and problem-solving skills
                </li>
                <li className="mt-3 text-md">
                  Detail-oriented with a keen eye for design
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="pl-8 pr-8 pb-8" style={{ width: "100%" }}>
          <Col span={4} xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl">
                <DollarOutlined className="pr-2" />
                Typical Cost To Hire
              </p>
              <p className="pt-2">Estimated yearly salary: $50,000 - $60,000</p>
            </div>
          </Col>
          <Col span={4} xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl">
                <FireOutlined className="pr-2" />
                Driving Motivators
              </p>
              <ul className="pl-4" style={{ listStyle: "outside" }}>
                <li className="mt-3 text-md">
                  Building a strong brand presence in the market{" "}
                </li>
                <li className="mt-3 text-md">
                  Engaging with a diverse audience through creative
                   marketing strategies
                </li>
                <li className="mt-3 text-md">
                  - Contributing to the growth and success of the
                   company
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Row>
      <Row
        className="mt-8 bg-[white] rounded-md flex "
        style={{
          width: "100%",
          height: "60vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <p className="text-center text-2xl mb-1">Upgrade to Pro to Unlock</p>
          <p className="mb-1 text-lg">
            This section is only included in the full report.
          </p>
          <Button
            style={{
              width: "50%",
              backgroundColor: "rgb(0, 184, 119)",
              color: "white",
              //   display: "flex-center",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1rem",
            }}
          >
            <TransactionOutlined style={{ margin: "0", padding: "0" }} />
            Upgrade
          </Button>
        </div>
      </Row>
      <Row
        className="mt-8 bg-[white] rounded-md flex "
        style={{
          width: "100%",
          height: "60vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <p className="text-center text-2xl mb-1">Upgrade to Pro to Unlock</p>
          <p className="mb-1 text-lg">
            This section is only included in the full report.
          </p>
          <Button
            style={{
              width: "50%",
              backgroundColor: "rgb(0, 184, 119)",
              color: "white",
              //   display: "flex-center",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1rem",
            }}
          >
            <TransactionOutlined style={{ margin: "0", padding: "0" }} />
            Upgrade
          </Button>
        </div>
      </Row>
      <Row
        className="mt-8 bg-[white] rounded-md flex "
        style={{
          width: "100%",
          height: "60vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <p className="text-center text-2xl mb-1">Upgrade to Pro to Unlock</p>
          <p className="mb-1 text-lg">
            This section is only included in the full report.
          </p>
          <Button
            style={{
              width: "50%",
              backgroundColor: "rgb(0, 184, 119)",
              color: "white",
              //   display: "flex-center",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1rem",
            }}
          >
            <TransactionOutlined style={{ margin: "0", padding: "0" }} />
            Upgrade
          </Button>
        </div>
      </Row>
      <Row
        className="mt-8 bg-[white] rounded-md flex "
        style={{
          width: "100%",
          height: "60vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <p className="text-center text-2xl mb-1">Upgrade to Pro to Unlock</p>
          <p className="mb-1 text-lg">
            This section is only included in the full report.
          </p>
          <Button
            style={{
              width: "50%",
              backgroundColor: "rgb(0, 184, 119)",
              color: "white",
              //   display: "flex-center",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1rem",
            }}
          >
            <TransactionOutlined style={{ margin: "0", padding: "0" }} />
            Upgrade
          </Button>
        </div>
      </Row>
      <Row
        className="mt-8 bg-[white] rounded-md flex "
        style={{
          width: "100%",
          height: "60vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <p className="text-center text-2xl mb-1">Upgrade to Pro to Unlock</p>
          <p className="mb-1 text-lg">
            This section is only included in the full report.
          </p>
          <Button
            style={{
              width: "50%",
              backgroundColor: "rgb(0, 184, 119)",
              color: "white",
              //   display: "flex-center",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1rem",
            }}
          >
            <TransactionOutlined style={{ margin: "0", padding: "0" }} />
            Upgrade
          </Button>
        </div>
      </Row>
      <Row
        className="mt-8 bg-[white] rounded-md flex "
        style={{
          width: "100%",
          height: "60vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <p className="text-center text-2xl mb-1">Upgrade to Pro to Unlock</p>
          <p className="mb-1 text-lg">
            This section is only included in the full report.
          </p>
          <Button
            style={{
              width: "50%",
              backgroundColor: "rgb(0, 184, 119)",
              color: "white",
              //   display: "flex-center",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1rem",
            }}
          >
            <TransactionOutlined style={{ margin: "0", padding: "0" }} />
            Upgrade
          </Button>
        </div>
      </Row>
      <Row>
        <Col xs={24} md={24} lg={24} className="mt-5">
          <div className="mt-5 text-lg">Team Development and Culture:</div>
          <div className="mt-5 text-lg">
            A strong and cohesive team is crucial for the success of any
            business, and Onq should be no exception. The company's unique
            walking experiences require the expertise and dedication of a
            diverse team with varying skill sets. This section will provide an
            overview of team development and culture within Onq.
          </div>
          <div className="mt-5 text-lg">
            Team development plays a vital role in fostering collaboration,
            innovation, and efficiency. As Onq aims to create customizable
            walking packages and tailored itineraries, the team should consist
            of individuals with a background in history, storytelling, tourism,
            and customer service. Each member should bring their unique skills
            and knowledge to enhance the overall experience for customers.
          </div>
          <div className="mt-5 text-lg">
            To build an effective team, Onq should focus on a few key areas:
          </div>
          <div className="mt-5 text-lg">
            1. Recruitment and Hiring: Onq should establish a thorough
            recruitment process to attract top talent. This may include creating
            detailed job descriptions, conducting interviews, and assessing
            candidates' skills and experience. Additionally, Onq should
            prioritize diversity and inclusivity in its hiring practices to
            ensure a broader perspective in delivering an inclusive experience
            for customers.
          </div>
          <div className="mt-5 text-lg">
            2. Training and Development: Once the team is assembled, Onq should
            invest in their professional growth and development. Ongoing
            training programs and educational opportunities can help employees
            improve their skills and stay updated with the latest historical
            research and storytelling techniques. This investment in the team's
            development will not only improve their performance but also their
            job satisfaction and commitment to the company.
          </div>
          <div className="mt-5 text-lg">
            3. Communication and Collaboration: Open communication channels are
            essential for effective teamwork. Onq should foster an environment
            where team members feel comfortable expressing their ideas,
            concerns, and feedback. Regular team meetings, brainstorming
            sessions, and collaborative projects can help build a sense of
            camaraderie among team members and encourage the sharing of insights
            and knowledge.
          </div>
          <div className="mt-5 text-lg">
            4. Recognition and Rewards: Recognizing and rewarding team members'
            contributions and achievements is important for maintaining
            motivation and job satisfaction. Onq should establish a system for
            acknowledging employees' efforts, such as performance-based bonuses,
            employee of the month recognition, or other incentives. This will
            not only boost team morale but also create a positive work culture
            where employees feel valued and appreciated.
          </div>
          <div className="mt-5 text-lg">
            In terms of culture, Onq should strive for a work environment that
            promotes teamwork, creativity, and a passion for history and
            storytelling. Emphasizing the importance of wellness and mental
            rejuvenation can also help establish a culture of work-life balance
            and employee well-being.
          </div>
          <div className="mt-5 mb-5 text-lg">
            It is important to note that this section provides a limited view of
            team roles and responsibilities within Onq. For a detailed analysis
            of specific team roles, organizational structure, and HR
            requirements, please refer to the full business analysis report.
          </div>
        </Col>
      </Row> */}
        {
        auth != "admin" && user.upgrade == "unpaid" &&!paid &&<Upgrade></Upgrade>
      }
      </Row>

    </div>
  );
}
