import React, { useState, useEffect } from "react";
import { Button, Form, Input, InputNumber, Spin, notification } from "antd";
import { OPENAI_API_KEY, URL } from "../../utils/const";
// import { Structure } from "utils/configure";
import axios from "axios";
import { Link } from "react-router-dom";

import './admin.css'


const { TextArea } = Input

export default function Overview() {
    const [system_prompts, setSystemPrompts] = useState(["", "", "", ""])
    const [user_prompts, setPrompts] = useState(["", "", "", ""])
    const [results, setResults] = useState(["", "", "", ""])
    const [histories, setHistories] = useState([[], [], [], []])
    const [loading, setLoading] = useState(false)

    const autoResizeTextarea = (element) => {
        element.style.height = 'auto';
        element.style.height = element.scrollHeight + 'px';
      };

    const handleChangePrompt = (e, index) => {
        let tmp = user_prompts;
        tmp[index] = e.target.value;
        setPrompts(tmp);
        // console.log(user_prompts);
    }

    const generateInfo = async (index) => {
        if (index != 0) return;
        let his = histories[index]
        if (user_prompts[index] == '' || user_prompts[index] == undefined){
            setHistories([his[0], [], [], []]
            )
        }
        else{
            setHistories([his.push(
                { role: 'user', content: user_prompts[index]}), [], [], []]
            )
        }
     
        let res = '';
        setResults(['', results[1], results[2], results[3]])

        const response = await fetch("https://api.openai.com/v1/chat/completions", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${OPENAI_API_KEY}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                // model: "gpt-3.5-turbo",
                model: "gpt-4-1106-preview",
                messages: histories[index],
                temperature: 0.7,
                stream: true
            }),
        });

        // Process the response in chunks
        const reader = response.body.getReader();
        let result = '';
    
        while (true) {
            const { done, value } = await reader.read();
    
            if (done) {
                break;
            }
    
            // Process the chunk as needed
            let chunk = new TextDecoder('utf-8').decode(value);
            const lines = chunk.split("\n\n");
            const parsedLines = lines
              .map((line) => line.replace(/data: /, "").trim()) // Remove the "data: " prefix
              .filter((line) => line !== "" && line !== "[DONE]") // Remove empty lines and "[DONE]"
              .map((line) => JSON.parse(line)); // Parse the JSON string
      
            for (const parsedLine of parsedLines) {
                const { choices } = parsedLine;
                const { delta } = choices[0];
                const { content } = delta;
                // Update the UI with the new content
                if (content) {
                    res += content;
                    setResults([res, results[1], results[2], results[3]])
                }
            }
        }
        setLoading(false)
        setHistories([histories[index].push(
            { role: 'assistant', content: res}
        ), [], [], []])
        // return data.choices[0].message.content.trim();
        // console.log(input)
        // input.innerHTML += 'werwerwerwer';
        return
    }
 
    async function fetchOpenAIResponse(info) {
        const companyName = info.companyName
        const companyDescription = info.companyDescription
        const category = info.category
        const title = info.title
        const prompt = info.prompt

        const userMessage = `Your company name is ${companyName}, and here is the description about the company - '${companyDescription}'
        This is for ${category}. You should generate content with some information.
        Title covered is ${title}.
        For this title, here is the detailed prompt: ${prompt}.
        You should response with following format if title is not empty:
        your title
        your content for this title

        otherwise, you should following format:
        your content

        If Title is empty, you should only generate content with title based on company name, company description, category and prompt.
        Remember, you should think as an owner of the company and don't need to mark anything, response naturly.
        `
        setLoading(true)
        const response = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
            Authorization: `Bearer ${OPENAI_API_KEY}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            model: "gpt-4-1106-preview",
            messages: [{ role: "user", content: userMessage }],
            temperature: 0.7,
        }),
        });

        if (!response.ok) {
            setLoading(false)
            throw new Error(
                `OpenAI API request failed with status ${response.status}`
            );
        }
        const data = await response.json();
        console.log(data.choices[0].message.content.trim())
        setLoading(false)
        return data.choices[0].message.content.trim();
    }

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.post("/api/prompt/get", {email: 'm@test.com', category: 'overview'});
            console.log("response", response.data.prompt);
            setSystemPrompts([response.data.prompt, "", "", ""])
            setHistories(
                [
                    [
                        { role: 'user', content : response.data.prompt}, 
                        { role : 'assistant', content : response.data.result}, 
                    ],
                    [], [], []
                ]
            )
            setResults([response.data.result, "", "", ""])

          } catch (error) { }
        };
    
        fetchData();
      }, []);


  return (
    <div class="main-admin">
        {
            // Structure[0].subtitle.map((item, index) => (
            //     <div className="item-board">
            //         <section class="title">
            //             <h2>{item.name}</h2>
            //         </section>
            //         <div class="tweakable">
            //             <div class="info" slot="output" id={`info-${index}`} dangerouslySetInnerHTML={{ __html: results[index] }}>
            //             </div>
            //             <Button class="btn-secondary">Save Results</Button>
            //         </div>
            //         <section class="title">
            //             <h1>Tweaks</h1>
            //             <textarea onChange = {e => handleChangePrompt(e, index)} onInput={(e) => autoResizeTextarea(e.target)} class="prompt">
            //             </textarea>
            //         </section>
            //         <section class="buttons">
            //             {/* <Button class="btn-secondary">Apply</Button> */}
            //             <Button onClick={e => generateInfo(index)} class="btn-secondary">Apply</Button>
            //         </section>
            //     </div>
            //   ))
        }
        
    </div>
    );
}
