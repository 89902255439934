import React, { useEffect, useState } from "react";
import business_title_img from '../../assets/images/pdfs/business_title.png';

export default function Customer(props) {
    const [data, setData] = useState(null);

    useEffect(() => {
        if (props.user) {
            // console.log('mission and vision -> user: ', props.user);
            setData(props.user.companies[0].jsonContent.find(item => item.Title === "Customer Service"));
        }
    })

    return (
    <div style={{fontFamily:'sans-serif',display:'none'}}><div style={{fontFamily:'sans-serif', width: `400px`, padding: '10px 20px', marginLeft: 20 }} id="customer">
        <div style={{fontFamily:'sans-serif', display: 'flex', alignItems: 'center' }}>
            <div style={{fontFamily:'sans-serif', width: 8, height: 8, marginRight: 8, marginTop: 17, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 3, color: '#26B35A' }}>
                <div style={{fontFamily:'sans-serif', width: 12 }}>
                    <img src={business_title_img} />
                </div>
            </div>
            <div style={{fontFamily:'sans-serif', fontSize: 12, color: '#26B35A' }}>Products &amp; Services</div>
        </div>
        <div style={{fontFamily:'sans-serif',textWrap: 'wrap'}}>
          <div style={{fontFamily:'sans-serif', fontSize: 16, margin: '5px 0', fontWeight: 'bold' }}>
              Customer Service
            </div>
          {/* <div style={{fontFamily:'sans-serif', marginTop: 10 }}>
                {
                    data?.Content[0]?.Description.map((item, index) => (<div>
                        <div style={{fontFamily:'sans-serif', fontSize: 12, fontWeight: 'bold' }}>{item.sub}</div>
                        <div>
                            <p>{item.Description}</p>
                        </div>
                    </div>))
                }
            </div> */}
            <div style={{fontFamily:'sans-serif', fontSize: 8, margin: '5px 0', }}>
                {data?.Content[0]?.Description.Overview}
            </div>
               <div style={{fontFamily:'sans-serif', marginTop: 5 }}>
                {
                    data?.Content[0]?.Description.SubContent.map((item, index) => (<div>
              
                        <div style={{fontFamily:'sans-serif',marginTop:"5px", fontSize:8}}>
                            <p style={{fontFamily:'sans-serif',fontSize:10}}>{item.Item}</p>
                            <p style={{fontFamily:'sans-serif',fontSize:8}}>{item.Description}</p>
                        </div>
                    </div>))
                }
            </div>
            <div style={{fontFamily:'sans-serif', fontSize: 8, margin: '5px 0', }}>
                {data?.Content[0]?.Description.Conclusion}
            </div>
            {/* <div style={{fontFamily:'sans-serif', fontSize: 12, margin: '10px 0', }}>
                {data?.Content[0]?.Description.Conclusion}
            </div> */}
               {/*  <div style={{fontFamily:'sans-serif', marginTop: 10 }}>
                {
                    data?.Content[1]?.Description
                }
            </div>
            <div style={{fontFamily:'sans-serif', fontSize: 20, margin: '10px 0', fontWeight: 'bold' }}>
                {data?.Content[2]?.SubTitle}
            </div>
            <div style={{fontFamily:'sans-serif', marginTop: 10 }}>
                {
                    data?.Content[2]?.Description
                }
            </div>
            <div style={{fontFamily:'sans-serif', fontSize: 20, margin: '10px 0', fontWeight: 'bold' }}>
                {data?.Content[3]?.SubTitle}
            </div>
            <div style={{fontFamily:'sans-serif', marginTop: 10 }}>
                    <p>{data?.Content[3]?.Description?.Overview}</p>
                    <p>{data?.Content[3]?.Description?.Conclusion}</p>
            </div> */}
        </div>
        </div>
    </div>)
}