import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: '',
  name: '',
  companies: [],   //only company names
  role: '',
  curID: -1,
  prompt: null,
  regenerateFlag: false,
  regenerateFlag1: false,
  regenerateFlag2: false,
  regenerateFlag3: false,
  upgrade: "",
  gpt: ""
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUser: (state, { payload }) => {
      state.email = payload.email;
      state.name = payload?.name;
      state.companies = payload.companies;
      state.role = payload.role;
      state.curID = payload.curID;
      state.prompt = payload.prompt;
      state.upgrade = payload.upgrade;
      state.gpt = payload.gpt;
    },
    deleteUser: (state, { payload }) => {

      state.email = "";
      state.name = "";
      state.companies = [];
      state.role = "";
      state.curID = 0;
      state.prompt = [];
      state.upgrade = "";
      state.gpt = "";
    },
    addCompanies: (state, { payload }) => {
      state.companies = state.companies.concat(payload)
      // console.log(state.companies)
    },
    setRegenerate: (state, { payload }) => {
      state.regenerateFlag = payload
      console.log("redux.regenerate", state.regenerateFlag)
    },
    setRegenerate1: (state, { payload }) => {
      state.regenerateFlag1 = payload
      console.log("redux.regenerate", state.regenerateFlag1)
    },
    setRegenerate2: (state, { payload }) => {
      state.regenerateFlag2 = payload
      console.log("redux.regenerate", state.regenerateFlag2)
    },
    setRegenerate3: (state, { payload }) => {
      state.regenerateFlag3 = payload
      console.log("redux.regenerate", state.regenerateFlag3)
    },
    updateHtmlContent: (state, { payload: { itemTitle, newhtmlContent } }) => {
      // console.log("noldstae", state.companies)
      // console.log("here",itemTitle, newhtmlContent)
      state.companies = state.companies.map((company, idx) => {
        if (idx == 0) {
          const htmlContent = company.htmlContent.map((item) => {
            if (item.Title === itemTitle) {
              return {
                ...item,
                htmlcontent: newhtmlContent,
              };
            }
            return item;
          });
          return {
            ...company,
            htmlContent,
          };
        } else {
          return company
        }

      });
      let flag = -1;
      state.companies[0].htmlContent.map((item, idx) => {
        if (item.Title == itemTitle) {
          flag = idx;
        }
      })
      if (flag = -1) {
        // const temp = state.companies[0].htmlContent
        // const newhtml = [...temp, { itemTitle, newhtmlContent }]
        // const newcomp = [...state.companies[0], { htmlContent: newhtml }]
        // state.companies[0] = newcomp
        state.companies[0].htmlContent.push({ Title:itemTitle, htmlcontent:newhtmlContent });

      }

      // console.log("newstae", state.companies)
    },
    removeHtmlContent: (state, { payload: { } }) => {
      state.companies = state.companies.map((company) => {
        const htmlContent = company.htmlContent.map((item) => {
          return [];
        });
        return {
          ...company,
          htmlContent,
        };
      });
    },



  }
})

export const {
  addUser,
  deleteUser,
  addCompanies,
  updateHtmlContent,
  removeHtmlContent,
  setRegenerate,
  setRegenerate1,
  setRegenerate2,
  setRegenerate3
} = userSlice.actions

export default userSlice.reducer