import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { store } from "Redux/app/store";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from '@react-oauth/google'
import 'react-multi-carousel/lib/styles.css';
import './index.css'
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import './assets/styles/custom.css';

// import { AntUpload } from "./Practics";
// import 'antd/dist/antd.css';
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId='207098240626-akktl86mo6fa7b3i6hk2f2pnrlp3orgt.apps.googleusercontent.com'>
    <BrowserRouter>
      <Provider store={store}>
        <App/>
        {/* <AntUpload /> */}
      </Provider>
    </BrowserRouter>
  </GoogleOAuthProvider>
);
