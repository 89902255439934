import React from 'react'
import { Button, Popover, Space } from 'antd';
import {
    CopyOutlined,
    DeleteOutlined
} from "@ant-design/icons";

const Pop = () => {
    return (
        <div className='flex flex-col'>
            <div className='flex cursor-pointer'>
                <DeleteOutlined />
                <div className='ml-2'>Delete</div>
            </div>
            <div className='flex cursor-pointer'>
                <CopyOutlined />
                <div className='ml-2'>Duplicate</div>
            </div>
        </div>
    )
}

export default Pop