import React, { useEffect, useState } from "react";
import operations_plan from '../../assets/images/pdfs/operations_plan.png'

export default function RiskAnalysis(props) {
    const [data, setData] = useState(null);

    useEffect(() => {
        if (props.user) {
            // console.log('business model -> user: ', props.user);
            // console.log('opration', props.user.companies[0])
            setData(props.user.companies[0].jsonContent.find(item => item.Title === "Risk Analysis"));
        }
    })

    return (<div style={{fontFamily:'sans-serif',display:'none'}}><div style={{fontFamily:'sans-serif', width: 400, padding: '10px 20px', marginLeft: 20 }} id="risk">
                <div style={{fontFamily:'sans-serif', display: 'flex', alignItems: 'center' }}>
                    <div style={{fontFamily:'sans-serif', width: 13, height: 13, marginRight: 8, marginTop: 17, display: 'flex', justifycontent: 'center', alignItems: 'center', borderRadius: 3, color: '#26B35A' }}>
                        <div style={{fontFamily:'sans-serif', width: 12 }}>
                            <img src={operations_plan} />
                        </div>
                    </div>
                    <div style={{fontFamily:'sans-serif', fontSize: 12, color: '#26B35A' }}>Operations</div>
                </div>
                <div style={{fontFamily:'sans-serif', fontSize: 16, fontWeight:'bold' }}>
                Risk Analysis
        </div>
        <div style={{fontFamily:'sans-serif',marginTop: '20px', fontSize: 12}}>
            <p style={{fontFamily:'sans-serif',fontSize:8}}>{ data?.content[1]?.SubTitle + ": " + data?.content[1]?.Description }</p>
        </div>
        <div>
        <ol style={{fontFamily:'sans-serif',fontSize: 12}}>
            {data?.content?.map((items, idx) => (
                <li key={idx} style={{fontFamily:'sans-serif', marginTop: '10px' }}>
                    <p style={{fontFamily:'sans-serif',marginLeft: '5px', fontSize:8}}>{ (idx + 1) + " : " + items?.Description }</p>
                </li>
            ))}
        </ol>
        </div>
        </div>
    </div>)
}