import React, { useState, useEffect } from "react";
import {
    Row, Col, Switch
} from "antd";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import axios from "axios";
import { URL } from '../../utils/const'
import Edit from "utils/edit";
import Admin from "utils/admin";
import *as formats from '../../utils/configure'
import { genEachContent } from "../../utils/service";
import { useSelector, useDispatch } from "react-redux";
import { updateHtmlContent } from '../../Redux/features/UserSlice';
import { addContent } from "../../Redux/features/pagesSlice";
import { setRegenerate } from "../../Redux/features/UserSlice";
import Limit from "components/Limit";
import Generating from "components/Generating";
import { addUser } from "../../Redux/features/UserSlice";
import Upgrade from "components/Upgrade";

export default function Mission() {

    const dispatch = useDispatch();
    const { email, companies, role, prompt } = useSelector(state => state.userSlice)
    const user = useSelector(state => {
        return state.userSlice
    })
    const pageContents = useSelector(state => {
        return state.pagesSlice
    })

    const category = 'Mission and Vision'
    const page_title = user.companies[0].content.find((item) => item.title === "Overview").list.find((item) => item.origin === category).shown
    const auth = role
    const [oldprompt, setOldPrompt] = useState([]);
    const [flag, setFlag] = useState(false);
    const [generateFlag, setGenerateFlag] = useState(false);
    const [mission, setMission] = useState('')
    const [problem, setProblem] = useState('')
    const [solution, setSolution] = useState('')
    const [unique, setUnique] = useState('')
    const [renderData, setRenderData] = useState(null);
    const [paid, setPaid] = useState();


    useEffect(() => {
        console.log("---flag")
        setFlag(false);
        dispatch(setRegenerate(false))

        if (email == '')
            window.location.href = '/sign-in';
    }, []);

    useEffect(() => {
        axios.post(URL + "/api/paid/get", { data: "Mission and Vision" })
            .then(res => {
                console.log("res", res.data.prompt);
                if (res.data.prompt == 'paid')
                    setPaid(true)
                else setPaid(false)
            })
            .catch(err => console.log("err", err))
    }, [])

    const handleChangeCheck = () => {
        if (paid == true)
            setPaid(false)
        else setPaid(true)

        axios.post(URL + "/api/paid/add", { data: "Mission and Vision" })
            .then(res => {
                console.log("paid has changed", res)
            })
            .catch(err => console.log("failed to change the paid method", err))
    }

    useEffect(() => {
        if (problem != "" || mission != "" || solution != "" || unique != "") {
            const data = {
                mission: mission,
                problem: problem,
                solution: solution,
                unique: unique
            }
            // console.log("problem----",problem)
            const sendData = { email: user.email, company: user.companies[0].companyName, title: category, sendData: data }
            axios.post(URL + "/api/users/changeHtml", {
                data: sendData
            }).then(res => {
                console.log('updated and saved')
                dispatch(updateHtmlContent({ itemTitle: category, newhtmlContent: data }));
            }).catch(err => console.log('saving updated content'))
        }
    }, [problem, mission, solution, unique]);



    useEffect(() => {
        // console.log("htmlconetnt:",user.companies[0].htmlContent)        
    }, [user.companies[0].htmlContent])

    useEffect(() => {
        if (user.regenerateFlag == true) {
            let currentPrompt = ''
            console.log("regnerating")
            user.prompt.map(item => {
                if (item.title == category) {
                    currentPrompt = item.prompt
                }
            })
            generateContent(currentPrompt)
        } else {
            setFlag(false)
        }
    }, [user.regenerateFlag]);

    useEffect(() => {
        const currentPrompt = prompt.filter((item) => item.title == category)
        setOldPrompt(currentPrompt.prompt)
    }, [])

    const savePrompt = (newPrompt) => {
        console.log("saveNewPrompt", newPrompt)
    }

    const generateContent = (newPrompt) => {
        setFlag(true);
        setGenerateFlag(true)
        let formatData;
        Object.keys(formats).map(key => {
            if (category == formats[key].Title) {
                console.log(formats[key].Title, formats[key], formats[key])
                formatData = { title: formats[key].Title, data: formats[key] }
            }
        })

        genEachContent(user.companies[0], newPrompt, formatData.data, user.gpt.modelName, user.gpt.modelName)
            .then((res) => {
                if (Array.isArray(pageContents.contents)) {
                    const newContents = pageContents.contents.map((item, idx) => {
                        if (item.Title === category) {
                            return res;
                        }
                        return item;
                    });
                    let temp = newContents;
                    console.log(".........generated..........")
                    dispatch(addContent({ content: temp }));
                    dispatch(updateHtmlContent({ itemTitle: category, newhtmlContent: {} }));
                    dispatch(setRegenerate(false))
                    setFlag(false);
                    axios.post("/api/users/result/save", { email: user.email, companyName: user.companies[0].companyName, content: newContents })
                        .then(res => {
                            console.log("---------------", res)
                            dispatch(addUser({
                                email: user.email,
                                name: 'StartupSphere',
                                companies: res.data,
                                curID: user.curID,
                                role: user.role,
                                prompt: user.prompt,
                                upgrade: user.upgrade,
                                gpt: user.gpt
                            }))
                        }).catch(err => console.log(err))
                }
            })
            .catch((error) => {
                console.error(error);
            });

    }

    const handleChange = (html, clippedTxt, id) => {
        if (id == "mission")
            setMission(html)
        if (id == "problem")
            setProblem(html)
        if (id == "solution")
            setSolution(html)
        if (id == "unique")
            setUnique(html)
    }

    const chageHtml = (html, id) => {
        if (id == "mission")
            setMission(html)
        if (id == "problem")
            setProblem(html)
        if (id == "solution")
            setSolution(html)
        if (id == "unique")
            setUnique(html)
    }

    useEffect(() => {
        let middleData = [];
        console.log("pageContents", pageContents)
        if (Array.isArray(pageContents.contents)) {
            middleData = pageContents.contents.filter((item) => {
                return item.Title === category;
            });
        } else if (Array.isArray(pageContents.contents.contents)) {
            middleData = pageContents.contents.contents.filter((item) => {
                return item.Title === category;
            });
        }
        setRenderData(middleData);
    }, [pageContents]);

    useEffect(() => {

        let htmlContent = null;
        companies[0].htmlContent.forEach((item) => {
            if (item.Title === category) {
                htmlContent = item.htmlcontent;
                console.log("htmlcontent", htmlContent)
            }
        });
        if (htmlContent != null && !generateFlag) {
            setMission(htmlContent.mission);
            setProblem(htmlContent.problem);
            setSolution(htmlContent.solution);
            setUnique(htmlContent.unique);
        } else if (renderData != null && renderData[0]) {
            console.log("renderData", renderData[0])
            const rlt = renderData[0]?.["Content"]?.[0]?.["Description"]
            let length = rlt.length
            let result = ``;
            for (let i = 0; i < length; i++) {
                result = result + `<h2>${rlt[i]?.["sub"]}:</h2> <h3>${rlt[i]?.["Description"]}</h3>`;
            }
            setMission(result);
            const result1 = `<h2>${renderData[0]?.["Content"]?.[1]?.["SubTitle"]}</h2>` + `<h3>${renderData[0]?.["Content"]?.[1]?.["Description"]}</h3>`
            setProblem(result1);
            const result2 = `<h2>${renderData[0]?.["Content"]?.[2]?.["SubTitle"]}</h2>` + `<h3>${renderData[0]?.["Content"]?.[2]?.["Description"]}</h3>`
            setSolution(result2);
            let result3 = `<h2>${renderData[0]?.["Content"]?.[3]?.["SubTitle"]}</h2>` + `<h3>${renderData[0]?.["Content"]?.[3]?.["Description"]?.["Overview"]}</h3>`
            result3 = result3 + `<h3>${renderData[0]?.["Content"]?.[3]?.["Description"]?.["Conclusion"]}</h3>`
            setUnique(result3);
        }

    }, [renderData])

    return (
        <div className="layout-content mx-auto max-w-4xl" id="testing_html">
            {(user.upgrade == "unpaid" && auth != "admin") &&
                <Col xs={24} md={24} lg={24}>
                    <Limit></Limit>
                </Col>}
            <Row className="p-6">
                <Col xs={24} md={24} lg={24} className="flex items-center">
                    <div class="w-6 h-6 mr-2 justify-center flex rounded-sm text-green-500 bg-green-50"><div class="w-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-full"><path d="m2 7 4.41-4.41A2 2 0 0 1 7.83 2h8.34a2 2 0 0 1 1.42.59L22 7"></path><path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path><path d="M15 22v-4a2 2 0 0 0-2-2h-2a2 2 0 0 0-2 2v4"></path><path d="M2 7h20"></path><path d="M22 7v3a2 2 0 0 1-2 2v0a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 16 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 12 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 8 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 4 12v0a2 2 0 0 1-2-2V7"></path></svg></div></div>
                    <div className="text-[#26B35A] text-lg">Overview</div>
                </Col>
                <Col xs={24} md={24} lg={24} className="flex items-center justify-between mt-4 mb-9">
                    <div className="dashboard_title">
                        <h1 className="text-3xl text-[#141414] font-[500]">{page_title}</h1>
                    </div>
                    {auth == 'admin' && <Switch className="bg-[#3367D6]" checkedChildren="FREE" unCheckedChildren="PAID" checked={paid} onChange={handleChangeCheck} />}
                </Col>

                {
                    auth == 'admin' &&
                    <Admin currentPrompt={oldprompt} category={category} flag={flag} generateContent={generateContent} savePrompt={savePrompt}></Admin>
                }

                {((mission != '' && user.upgrade != "unpaid") || (mission != '' && auth == 'admin') || (mission != '' && paid)) && <Col xs={24} md={24} lg={24}>
                    <Edit content={mission} id={"mission"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                    <Edit content={problem} id={"problem"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                    <Edit content={solution} id={"solution"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                    <Edit content={unique} id={"unique"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                </Col>}

                {mission == '' && <Generating></Generating>}

                {
                    auth != "admin" && user.upgrade == "unpaid" && !paid && <Upgrade></Upgrade>
                }
            </Row>
        </div>
    )
}