

export const MissionType = {
    "Title": "Mission and Vision",
    "Summary": "",
    "Content":
        [
            {
                "SubTitle": "Mission and Vision",
                "Description": [
                    {
                        "sub": "Mission Statement",
                        "Description": ""
                    },
                    {
                        "sub": "Vision Statement",
                        "Description": ""
                    }
                ]
            },
            {
                "SubTitle": "Problem",
                "Description": ""
            },
            {
                "SubTitle": "Solution",
                "Description": ""
            },
            {
                "SubTitle": "Unique Selling Proposition",
                "Description": {
                    "Overview": "",
                    "USP": [
                        {
                            "id": "1",
                            "Item": "",
                            "Description": ""
                        }
                    ],
                    "Conclusion": ""
                }
            }
        ]
}

export const ViabilityType = {
    "Title": "Viability analysis",
    "Summary": "",
    "Value1": "",
    "Content":
        [
            {
                "SubTitle": "overview",
                "Description": ""
            },
            {
                "SubTitle": "description",
                "Description": [{
                    "id": "1",
                    "item": "",
                    "Description": ""
                }],
            },
            {
                "SubTitle": "conclusion",
                "Description": ""
            },
            {
                "SubTitle": "Sources",
                "Description": [{
                    "id": "1",
                    "item": "",
                    "Description": ""
                }],
            },

        ]
}

export const BusinessType = {
    "Title": "Business Models",
    "Summary": "",
    "Content":
        [
           
            {
                "SubTitle": "Subscription Model",
                "Description": {
                    "Overview": "",
                    "Advantages": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Challenges": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                }
            },
            {
                "SubTitle": "Freemium Model",
                "Description": {
                    "Overview": "",
                    "Advantages": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Challenges": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                }
            },
            {
                "SubTitle": "Pay-per-Mentor Model",
                "Description": {
                    "Overview": "",
                    "Advantages": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Challenges": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                }
            },

        ]
}

export const SwotType = {
    "Title": "Swot Analysis",
    "Summary": "",
    "Content":
        [
            {
                "SubTitle": "Strengths",
                "Description": [{
                    "id": "1",
                    "item": "",
                    "Description": ""
                }],
            },
            {
                "SubTitle": "Weaknesses",
                "Description": [{
                    "id": "1",
                    "item": "",
                    "Description": ""
                }],
            },
            {
                "SubTitle": "Opportunities",
                "Description": [{
                    "id": "1",
                    "item": "",
                    "Description": ""
                }],
            },
            {
                "SubTitle": "Threats",
                "Description": [{
                    "id": "1",
                    "item": "",
                    "Description": ""
                }],
            },

        ]
}

export const CoreOfferingsType = {
    "Title": "Core Offerings",
    "Summary": "",
    "Content":
        [
            {
                "SubTitle": "Interactive Learning Modules",
                "Description": {
                    "Overview": "",
                    "Advantages": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Challenges": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Target Audience": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Pricing Strategy": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Marketing & Promotion": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Execution Strategy": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],

                }
            },
            {
                "SubTitle": "Virtual Cultural Exchange Program",
                "Description": {
                    "Overview": "",
                    "Advantages": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Challenges": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Target Audience": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Pricing Strategy": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Marketing & Promotion": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Execution Strategy": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],

                }
            },
        ]
}

export const SecondaryOfferingsType = {
    "Title": "Secondary Offering",
    "Summary": "",
    "Content":
        [
            {
                "SubTitle": "Virtual Cultural Exchange Program",
                "Description": {
                    "Overview": "",
                    "Advantages": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Challenges": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Target Audience": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Pricing Strategy": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Marketing & Promotion": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Execution Strategy": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],

                }
            },
            {
                "SubTitle": "Interactive Quizzes and Assessments",
                "Description": {
                    "Overview": "",
                    "Advantages": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Challenges": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Target Audience": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Pricing Strategy": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Marketing & Promotion": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Execution Strategy": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],

                }
            },
            {
                "SubTitle": "Public",
                "Description": {
                    "Overview": "",
                    "Advantages": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Challenges": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Target Audience": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Pricing Strategy": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Marketing & Promotion": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Execution Strategy": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],

                }
            },
        ]
}

export const ExpansionOpportunitiesType = {
    "Title": "Expansion Opportunities",
    "Summary": "",
    "Content":
        [
            {
                "SubTitle": "Virtual Culure Tours",
                "Description": {
                    "Overview": "",
                    "Advantages": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Challenges": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Target Audience": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Pricing Strategy": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Marketing & Promotion": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Execution Strategy": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],

                }
            },
            {
                "SubTitle": "Cultural Cooking Classes",
                "Description": {
                    "Overview": "",
                    "Advantages": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Challenges": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Target Audience": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Pricing Strategy": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Marketing & Promotion": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Execution Strategy": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],

                }
            },
        ]
}

export const CustomerServiceType = {
    "Title": "Customer Service",
    "Summary": "",
    "Content":
        [
            {
                "SubTitle": "Customer Service",
                "Description": {
                    "Overview": "",
                    "SubContent": [
                        {
                            "id": "1",
                            "Item": "",
                            "Description": ""
                        }
                    ],
                    "Conclusion": ""
                }
            }
        ]
}

export const IndustryOverviewType = {
    "Title": "Industry Overview",
    "Summary": "",
    "Rate":
        [
            {
                "SubTitle": "",
                "Description": ""
            },
            {
                "SubTitle": "",
                "Description": ""
            },
            {
                "SubTitle": "",
                "Description": ""
            },
        ],
    "Content":
    {
        "SubTitle": "Industry Overview",
        "Description": {
            "Overview": "",
            "Description": [
                {
                    "id": "1",
                    "Item": "",
                    "Description": ""
                }
            ],
            "Conclusion": ""
        }
    },
}

export const TargetAudienceType = {
    "Title": "Target Audience",
    "Summary": "",
    "Content":
        [
            {
                "SubTitle": "Students",
                "Description": {
                    "Overview": "",
                    "Profile": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Frustrations": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Goals": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Preferences": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Behavioural Considerations": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Where To Find": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],

                }
            },
            {
                "SubTitle": "Teachers",
                "Description": {
                    "Overview": "",
                    "Profile": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Frustrations": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Goals": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Preferences": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Behavioural Considerations": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Where To Find": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],

                }
            },
        ]
}

export const MargetSizeTrendsType = {
    "Title": "Market size and Trends",
    "Summary": "",
    "Content":
        [
            {
                "SubTitle": "Growing demand for cultural education",
                "Description": {
                    "Overview": "",
                    "Key Points": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "How You Can Leverage": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],

                }
            },
            {
                "SubTitle": "Integration of AI in education",
                "Description": {
                    "Overview": "",
                    "Key Points": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "How You Can Leverage": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],

                }
            },
        ],
    "Description":
    {
        "SubTitle": "Market Size and Trends",
        "Description": ""
    },
}

export const CompetitorAnalysisType = {
    "Title": "Competitor Analysis",
    "Summary": "",
    "Content":
        [
            {
                "SubTitle": "GlobalEdu",
                "Description": {
                    "Overview": "",
                    "Key Points": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "How You Can Leverage": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],

                }
            },
            {
                "SubTitle": "CultivateNow",
                "Description": {
                    "Overview": "",
                    "Key Points": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "How You Can Leverage": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],

                }
            },

        ],
    "Description":
    {
        "SubTitle": "Competitor Analysis",
        "Description": ""
    },
}


export const BrandingIdentityType = {
    "Title": "Branding & Identity",
    "Summary": "",
    "Color":[],
    "Content":
        [
            {
                "SubTitle": "Logo Idea",
                "Description": ""
            },
            {
                "SubTitle": "Typography",
                "Description": ""
            },
            {
                "SubTitle": "Imagery & Photography",
                "Description": ""
            },
            {
                "SubTitle": "Brand Voice",
                "Description": ""
            },
            {
                "SubTitle": "Brand Values",
                "Description": ""
            },
            {
                "SubTitle": "Brand Personality Traits",
                "Description": ""
            },
            {
                "SubTitle": "Customer Promise",
                "Description": ""
            },
        ]
}

export const OnlinePresenceType = {
    "Title": "Online Presence",
    "Summary": "",
    "Content":
        [
            {
                "SubTitle": "",
                "Description": {
                    "Overview": "",
                    "Description": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                }
            },
        ]
}

export const CustomerRetentionType = {
    "Title": "Customer Retention",
    "Summary": "",
    "Content":
        [
         
            {
                "SubTitle": "Gamification Rewards System",
                "Description": {
                    "Overview": "",
                    "Key Points": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Challenges": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Opportunity": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Execution Strategy": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],

                }
            },
            {
                "SubTitle": "Personalized Learning Recommendations",
                "Description": {
                    "Overview": "",
                    "Key Points": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Challenges": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Opportunity": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Execution Strategy": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],

                }
            },
        ]
}

export const OpertionsPlanType = {
    "Title": "Operations Plan",
    "Conclusion": "",
    "content":
        [
            {
                "SubTitle": "Operations Plan",
                "Description": ""
            },
            {
                "SubTitle": "Technology Infrastructure",
                "Description": ""
            },
            {
                "SubTitle": "Content Creation and Management",
                "Description": ""
            },
            {
                "SubTitle": "Customer Support",
                "Description": ""
            },
            {
                "SubTitle": "Marketing and Revenue Generation",
                "Description": ""
            },
        ]
}

export const RiskAnalysisType = {
    "Title": "Risk Analysis",
    "Conclusion": "",
    "content":
        [
            {
                "SubTitle": "Risk Analysis",
                "Description": ""
            },
            {
                "SubTitle": "Technological Risks",
                "Description": ""
            },
            {
                "SubTitle": "Competition",
                "Description": ""
            },
            {
                "SubTitle": "User Adoption and Engagement",
                "Description": ""
            },
            {
                "SubTitle": "Legal and Regulatory",
                "Description": ""
            },
            {
                "SubTitle": "Financial",
                "Description": ""
            },
        ]
}

export const RegulatoryComplianceType = {
    "Title": "Regulatory Compliance",
    "Summary": "",
    "Conclusion": "",
    "content":
    [
        {
            "SubTitle": "Intellectual Properly Rights",
            "Description": ""
        },
        {
            "SubTitle": "Data Privacy and Security",
            "Description": ""
        },
        {
            "SubTitle": "Accessibility Compliance",
            "Description": ""
        },
        {
            "SubTitle": "Audio Content Regulations",
            "Description": ""
        },
        {
            "SubTitle": "Advertising and Endorsements",
            "Description": ""
        },
        {
            "SubTitle": "Terms of Service and User Agreements",
            "Description": ""
        },
        {
            "SubTitle": "International Compliance",
            "Description": ""
        },
    ]
}

export const TeamRolesType = {
    "Title": "Team & Roles",
    "Summary": "",
    "Conclusion": {
        "SubTitle": "",
        "Description": ""
    },
    "Content":
        [
            {
                "SubTitle": "Chief Executive Officer(CEO)",
                "Description": {
                    "Overview": "",
                    "Responsibilities": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Personal Attributes": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Typical Cost To Hire": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Driving Motivators": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                }
            },
            {
                "SubTitle": "Marketing Manager",
                "Description": {
                    "Overview": "",
                    "Responsibilities": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Personal Attributes": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Typical Cost To Hire": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Driving Motivators": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                }
            },
            {
                "SubTitle": "Content Manager",
                "Description": {
                    "Overview": "",
                    "Responsibilities": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Personal Attributes": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Typical Cost To Hire": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Driving Motivators": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                }
            },
            {
                "SubTitle": "User Experience(UX) Desinger",
                "Description": {
                    "Overview": "",
                    "Responsibilities": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Personal Attributes": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Typical Cost To Hire": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    "Driving Motivators": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                }
            },
        ]
}

export const PreLaunchType = {
    "Title": "Pre-Launch",
    "Summary": "",
    "Conclusion": "",
    "Content":
        [
            {
                "SubTitle": "Finalize Platform Devepopment",
                "Description": [{
                    "id": "1",
                    "item": "",
                    "Description": ""
                }],
            },
            {
                "SubTitle": "Create and Customize Podcast Templates",
                "Description": [{
                    "id": "1",
                    "item": "",
                    "Description": ""
                }],
            },
            {
                "SubTitle": "Develop Interactive User Interface",
                "Description": [{
                    "id": "1",
                    "item": "",
                    "Description": ""
                }],
            },
            {
                "SubTitle": "Integrate with Popular Podcast Platforms",
                "Description": [{
                    "id": "1",
                    "item": "",
                    "Description": ""
                }],
            },
            {
                "SubTitle": "Enable Easy Audio Editing",
                "Description": [{
                    "id": "1",
                    "item": "",
                    "Description": ""
                }],
            },
            {
                "SubTitle": "Implement Transcript Generation Feature",
                "Description": [{
                    "id": "1",
                    "item": "",
                    "Description": ""
                }],
            },
            {
                "SubTitle": "Quality Assurance and Beta Testing",
                "Description": [{
                    "id": "1",
                    "item": "",
                    "Description": ""
                }],
            },
            {
                "SubTitle": "Marketing and Promotion",
                "Description": [{
                    "id": "1",
                    "item": "",
                    "Description": ""
                }],
            },
            {
                "SubTitle": "Lanuch and Post-Launch Support",
                "Description": [{
                    "id": "1",
                    "item": "",
                    "Description": ""
                }],
            },

        ]
}

export const PostLaunchType = {
    "Title": "Post-Launch",
    "Summary": "",
    "Conclusion": "",
    "Content":
        [
            {
                "SubTitle": "Marketing and Promotion",
                "Description": [{
                    "id": "1",
                    "item": "",
                    "Description": ""
                }],
            },
            {
                "SubTitle": "User Support and Feedback",
                "Description": [{
                    "id": "1",
                    "item": "",
                    "Description": ""
                }],
            },
            {
                "SubTitle": "Technology and Infrastructure",
                "Description": [{
                    "id": "1",
                    "item": "",
                    "Description": ""
                }],
            },
            {
                "SubTitle": "Continuous Improvement",
                "Description": [{
                    "id": "1",
                    "item": "",
                    "Description": ""
                }],
            },
            {
                "SubTitle": "Strategic Partnerships",
                "Description": [{
                    "id": "1",
                    "item": "",
                    "Description": ""
                }],
            },

        ]
}

export const CostsBreakdown = {
    "Title": "Costs Breakdown",
    "Summary": "",
    "Conclusion": "",

    "Content":[{
        "SubTitle": "Research and Development",
        "Description": "",
    },
    {
        "SubTitle": "Technology Infrastructure",
        "Description": "",
    },
    {
        "SubTitle": "Multilingual Language Support",
        "Description": "",
    },
    {
        "SubTitle": "Content Development",
        "Description": "",
    },
    {
        "SubTitle": "Marketing and Customer Acquisition",
        "Description": "",
    },
    {
        "SubTitle": "Operational Costs",
        "Description": "",
    },
    {
        "SubTitle": "Partnerships and Collaboration",
        "Description": "",
    },
    {
        "SubTitle": "Legal and Regulatory Compliance",
        "Description": "",
    },
    {
        "SubTitle": "Miscellaneous Expenses",
        "Description": "",
    }
    
] 
}

export const FundingPlan = {
    "Title": "Funding Plan",
    "Summary": "",
    "Conclusion": "",
    "Content":
    [
        {
            "Title": "Initial Startup Costs",
            "Summary": "",
            "Conclusion": "",
            "Content":[{
                "SubTitle": "Tow Trucks",
                "Description": "",
            },
            {
                "SubTitle": "Equipment",
                "Description": "",
            },
            {
                "SubTitle": "Office Space",
                "Description": "",
            },
            {
                "SubTitle": "Insurance and Licensing",
                "Description": "",
            },
            {
                "SubTitle": "Marketing Expenses",
                "Description": "",
            }       
            ]
            },
            {
                "Title": "Operational Expenses",
                "Summary": "",
            "Conclusion": "",
            "Content":[{
                    "SubTitle": "Salaries and Wages",
                    "Description": "",
                },
                {
                    "SubTitle": "Fuel and Maintenance",
                    "Description": "",
                },
                {
                    "SubTitle": "Office Expenses",
                    "Description": "",
                },
                {
                    "SubTitle": "Marketing and Advertising",
                    "Description": "",
                },
                {
                    "SubTitle": "Miscellaneous Expenses",
                    "Description": "",
                }       
                ]
            },
            {
                "Title": "Funding Sources",
                "Summary": "",
            "Conclusion": "",
            "Content":[{
                    "SubTitle": "Personal Savings",
                    "Description": "",
                },
                {
                    "SubTitle": "Friends and Family",
                    "Description": "",
                },
                {
                    "SubTitle": "Business Loans",
                    "Description": "",
                },
                {
                    "SubTitle": "Investors",
                    "Description": "",
                },
                {
                    "SubTitle": "Crowdfunding",
                    "Description": "",
                }       
                ]
            },
            {
                "Title": "Financial Projections",
                "Summary": "",
            "Conclusion": "",
            "Content":[{
                    "SubTitle": "Income Statement",
                    "Description": "",
                },
                {
                    "SubTitle": "Cash Flow Statement",
                    "Description": "",
                },
                {
                    "SubTitle": "Balance Sheet",
                    "Description": "",
                }
                ]
            }
    ]
}

export const FIveYearPlan = {
    "Title": "5 Year Plan",
    "Conclusion": "",
    "Summary": "",
    "Content":
        [
            {
            "Title": "Year 2",
            "Content":[ {
                "SubTitle": "Expand Product/Service Offering",
                "Description": [{
                    "id": "1",
                    "item": "",
                    "Description": ""
                }],
            },
            {
                "SubTitle": "Increase Market Penetration",
                "Description": [{
                    "id": "1",
                    "item": "",
                    "Description": ""
                }],
            },
            {
                "SubTitle": "Enhance Customer Support",
                "Description": [{
                    "id": "1",
                    "item": "",
                    "Description": ""
                }],
                },
            {
                "SubTitle": "Strengthen Corporate Culture",
                "Description": [{
                    "id": "1",
                    "item": "",
                    "Description": ""
                }],
            },
            ]
            },
            {
                "Title": "Year 3",
                "Content":[ {
                    "SubTitle": "Geographic Expansion",
                    "Description": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                },
                {
                    "SubTitle": "Innovation and R&D",
                    "Description": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                },
                {
                    "SubTitle": "Supply Chain Optimization",
                    "Description": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                    },
                {
                    "SubTitle": "Corporate Social Responsibility",
                    "Description": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                },
                ]
            },
            {
                "Title": "Year 4",
                "Content":[ {
                    "SubTitle": "Mergers and Acquisitions",
                    "Description": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                },
                {
                    "SubTitle": "Technology Upgrades and Automation",
                    "Description": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                },
                {
                    "SubTitle": "Diversification",
                    "Description": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                },
                ]
            },
            {
                "Title": "Year 5",
                "Content":[ {
                    "SubTitle": "Global Expansion",
                    "Description": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                },
                {
                    "SubTitle": "Brand Equity Enhancement",
                    "Description": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                },
                {
                    "SubTitle": "Continuous Improvement",
                    "Description": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                },
                {
                    "SubTitle": "Long-Term Strategic Planning",
                    "Description": [{
                        "id": "1",
                        "item": "",
                        "Description": ""
                    }],
                },
                ]
            }
        
    ]
}

export const MarketingIdeas = {
    "Title": "Marketing Ideas",
    "Summary": "",
    "Content": [
        {
            "Title": "Offer a limited-time discount or promotion for new customers to incentivize their initial purchase.",
            "Description":""
        },
        {
            "Title": "Host a webinar series offering valuable insights about this company and how it can benefit businesses",
            "Description":""
        },
        {
            "Title": "Partnering with Influencers in this company Industry",
            "Description":""
        },
        {
            "Title": "Launching a Creative Social Media Campaign",
            "Description":""
        },
        {
            "Title": "Sponsoring Industry-specific webinars and Workshops",
            "Description":""
        }
    ]

}