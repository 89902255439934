import { Row, Col, Select, Space, Switch, Table, Drawer, Popover } from 'antd'
import React, { useState, useEffect } from 'react'
import {
  DollarOutlined,
  EditOutlined,
  MoreOutlined,
  QuestionCircleOutlined
} from "@ant-design/icons";
import * as Data from '../../utils/FinancialData'
import AreaChart from 'components/charts/AreaChart';
import BarChart from 'components/charts/BarChart';
import AddRevenue from 'components/Financial/addRevenue';
import Title from 'components/Financial/title';
import Pop from 'components/Popover';
import PopContent from 'components/Financial/popContent';
import Limit from 'components/Limit';
import { useSelector, useDispatch } from "react-redux";
import Upgrade from "components/Upgrade";
import axios from 'axios';
import { URL } from '../../utils/const'

const ProfitLoss = () => {

  const { email, curID, companies, role, prompt } = useSelector(state => state.userSlice)
  const auth = role
  const [drawer, setDrawer] = useState(false);
  const [areaYear, setAreaYear] = useState(2023);
  const [pieYear, setPieYear] = useState(2024);
  const [tableData, setTableData] = useState(false)
  const [paid, setPaid] = useState();
  const user = useSelector((state) => {
    return state.userSlice;
  });

  useEffect(() => {
    axios.post(URL + "/api/paid/get", { data: "Profit & Loss" })
      .then(res => {
        console.log("res", res.data.prompt);
        if (res.data.prompt == 'paid')
          setPaid(true)
        else setPaid(false)
      })
      .catch(err => console.log("err", err))
  }, [])

  const handleChangeCheck = () => {
    if (paid == true)
      setPaid(false)
    else setPaid(true)

    axios.post(URL + "/api/paid/add", { data: "Profit & Loss" })
      .then(res => {
        console.log("paid has changed", res)
      })
      .catch(err => console.log("failed to change the paid method", err))
  }

  const handleAreaChart = (value) => {
    if (value === '1') {
      setAreaYear(2023);
      return;
    }
    let year = Data.option1.filter(opt => opt.value == value)[0].label;
    setAreaYear(year);
    console.log(`selected ${value}`);
  };

  const handlePieChart = (value) => {
    if (value === '1') {
      setPieYear(2024);
      return;
    }
    let year = Data.option2.filter(opt => opt.value == value)[0].label;
    setPieYear(year);
    console.log(`selected ${value}`);
  }

  const onChange = (value) => {
    console.log(`selected ${value}`);
    setTableData(value)
  }

  const openDrawer = () => {
    setDrawer(true)
  }

  const closeDrawer = () => {
    setDrawer(false)
  }

  const columns = [
    {
      title: 'Profit/Loss',
      dataIndex: 'profit',
      key: 'profit',
      width: '400px',
      render: (text) =>
      (
        <div className='flex'>
          {text}
          <div className='ml-2'>
            <Popover content={<PopContent />} trigger="hover">
              <QuestionCircleOutlined />
            </Popover>
          </div>
        </div>
      )
    },
    {
      title: '2024',
      dataIndex: 'four',
      key: '2024',
    },
    {
      title: '2025',
      dataIndex: 'five',
      key: '2025',
    },
    {
      title: '2026',
      dataIndex: 'six',
      key: '2026',
    },
    {
      title: '2027',
      dataIndex: 'seven',
      key: '2027',
    },
    {
      title: '2028',
      dataIndex: 'eight',
      key: '2028',
    },
  ]
  const firstData = {
    title: 'Profit/Loss',
    dataIndex: 'revenue_stream',
    key: 'revenue_stream',
    width: '400px',
    fixed: 'left',
    render: (text) =>
    (<div className='revenue flex justify-between'>
      <button className="px-2 text-emerald-400 rounded-lg text-green-500 transition duration-300 ease-in-out hover:text-black hover:bg-gray-200 active:bg-gray-300" onClick={openDrawer}>
        {text}
        <span>
          <EditOutlined />
        </span>
      </button>
      <div className='flex items-center justify-center px-2'>
        <Popover content={<Pop />} trigger="click">
          <button className="hover:bg-gray-100 revenue-action"><MoreOutlined /></button>
        </Popover>
      </div>
    </div>
    )
  }
  const monthlyColumn = []
  monthlyColumn.push(firstData)
  let t = 0;
  for (let i = 0; i < 41; i++) {
    monthlyColumn.push({
      title: (i + 1) % 13 === 0 ? `202${4 + t}` : `202${4 + t}-${(i % 13) + 1}`,
      dataIndex: `${i + 1}`,
      key: `${i + 1}`,
    })
    if ((i + 1) % 13 === 0) t++;
  }

  return (
    <div className="layout-content mx-auto max-w-4xl">
      {(user.upgrade == "unpaid" && auth != "admin") &&<Col xs={24} md={24} lg={24}><Limit /></Col>}
      <div className='flex w-full items-center justify-between'>
      <Title title={user.companies[0].content.find((item) => item.title === "Financials").list.find((item) => item.origin==="Profit & Loss").shown} />
        {auth=='admin'&&<Switch className="bg-[#3367D6]" checkedChildren="FREE" unCheckedChildren="PAID" checked={paid} onChange={handleChangeCheck} />}
      </div>
      {(user.upgrade !="unpaid" || auth == 'admin'||paid) && <>
      <div className='p-4 pb-28 max-w-7xl mx-auto'>
        <div className='grid grid-cols-3 gap-4'>
          <div className='col-span-4 xl:col-span-2'>
            <div className='w-full p-0 h-full flex flex-col justify-end border border-neutral-200 shadow-sm rounded-lg '>
              <div className='w-full flex items-center mb-auto p-4'>
                <h3 className='text-xl font-semibold'>
                  Monthly Net Profit{' '}
                  <span className='font-normal text-muted-foreground'>$2,021,112</span>
                </h3>
                <div className='ml-auto'>
                  <Select
                    defaultValue="First 12 months"
                    style={{
                      width: 160,
                    }}
                    onChange={handleAreaChart}
                    options={Data.option1}
                  />
                </div>

              </div>
              <div className='w-full h-[200px] text-muted-foreground text-xs'>
                <AreaChart year={areaYear} />
              </div>
            </div>
          </div>
          <div className='col-span-2 xl:col-span-1'>
            <div className='w-full p-0 h-full flex flex-col justify-end border border-neutral-200 shadow-sm rounded-lg '>
              <div className='w-full flex items-center mb-auto p-4 pb-0'>
                <h3 className='text-xl font-semibold'>Yearly Net Profit</h3>
              </div>
              <div className='w-full h-[200px] text-muted-foreground text-xs'>
                <BarChart />
              </div>
            </div>
          </div>

        </div>
        <div className='flex items-center pt-4 justify-end'>
          <div >
            <span className='mr-2'>Monthly Data</span>
            <Switch unCheckedChildren onChange={onChange} />
          </div>
        </div>
        <div className='border border-neutral-200 rounded overflow-hidden mt-4'>
          <div className='relative w-full overflow-auto'>
            {
              tableData == false
                ?
                <Table
                  columns={columns}
                  dataSource={Data.profitData}
                  pagination={false}
                />
                :
                <Table
                  columns={monthlyColumn}
                  dataSource={Data.monthlyRevenue}
                  pagination={false}
                  scroll={{ x: 4000 }}
                />
            }
          </div>
        </div>
        <div className='flex justify-center text-lg my-8'>AI can make mistakes. You should always review your financials.</div>
      </div>
      <Drawer title="Add Revenue Stream" onClose={closeDrawer} open={drawer}>
        <AddRevenue />
      </Drawer>
      </>}
      {
        auth!="admin"&&user.upgrade == "unpaid" &&!paid&& <Upgrade></Upgrade>
      }
    </div>
  )
}
export default ProfitLoss