import React, { useEffect, useState } from "react";
import business_title_img from '../../assets/images/pdfs/business_title.png';

export default function MissionVision(props) {
    const [data, setData] = useState(null);

    useEffect(() => {
        if (props.user) {
            // console.log('mission and vision -> user: ', props.user);
            setData(props.user.companies[0].jsonContent.find(item => item.Title === "Mission and Vision"));
        }
    })

    return (<div style={{display:'none', fontFamily:'sans-serif' }} ><div style={{ width: `400px`, padding: '10px 20px', marginLeft: 20}}  id="missions">
        <div style={{ display: 'flex', alignItems: 'center', margin:'0', padding:'0' }}>
            <div style={{ width: 8, height: 8, marginRight: 8, marginTop: 17, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 3, color: '#26B35A' }}>
                <div style={{ width: 8 }}>
                    <img src={business_title_img} />
                </div>
            </div>
            <div style={{ fontSize: 12, color: '#26B35A', fontFamily:'sans-serif' }}>Overview</div>
        </div>
        <div>
            <div style={{ fontSize: 16, margin: '10px 0', fontWeight: 'bold', fontFamily:'sans-serif' }}>
            Executive Summary
            </div>
            <div style={{ fontSize: 10, margin: '10px 0', fontWeight: 'bold', fontFamily:'sans-serif' }}>
                Mission and Vision
            </div>
            <div>
                {
                    data?.Content[0]?.Description.map((item, index) => (<div>
                        <div style={{ fontSize: 9, fontFamily:'sans-serif' }}>{item.sub}</div>
                        <div>
                            <p style={{fontSize: 8, fontFamily:'sans-serif'}}>{item.Description}</p>
                        </div>
                    </div>))
                }
            </div>
            <div style={{ fontSize: 10, margin: '10px 0', fontWeight: 'bold', fontFamily:'sans-serif' }}>
                {data?.Content[1]?.SubTitle}
            </div>
            <div style={{fontSize:8, fontFamily:'sans-serif'}}>
                {
                    data?.Content[1]?.Description
                }
            </div>
            <div style={{ fontSize: 10, margin: '10px 0', fontWeight: 'bold', fontFamily:'sans-serif' }}>
                {data?.Content[2]?.SubTitle}
            </div>
            <div style={{fontSize:8, fontFamily:'sans-serif' }}>
                {
                    data?.Content[2]?.Description
                }
            </div>
            <div style={{ fontSize: 10, margin: '10px 0', fontWeight: 'bold', fontFamily:'sans-serif' }}>
                {data?.Content[3]?.SubTitle}
            </div>
            <div style={{fontSize:8, fontFamily:'sans-serif' }}>
                    <p style={{fontSize:8, fontFamily:'sans-serif' }}>{data?.Content[3]?.Description?.Overview}</p>
                    <p style={{fontSize:8, fontFamily:'sans-serif' }}>{data?.Content[3]?.Description?.Conclusion}</p>
            </div>
        </div>
    </div></div>)
}