import React, { useState, useRef } from "react";
import {
    Space, Button, Dropdown, Select, Menu, Input, Table, Row, Col
} from "antd";

import {PlusCircleOutlined} from "@ant-design/icons"
import { BarChart, Bar, XAxis, YAxis, Legend, Tooltip } from 'recharts';

export default function Content() {

   const menu = (
        <Menu>
          <Menu.Item>
            <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
              1st menu item
            </a>
          </Menu.Item>
          <Menu.Item disabled>
            <a target="_blank" rel="noopener noreferrer" href="https://www.aliyun.com">
              2nd menu item (disabled)
            </a>
          </Menu.Item>
          <Menu.Item disabled>
            <a target="_blank" rel="noopener noreferrer" href="https://www.luohanacademy.com">
              3rd menu item (disabled)
            </a>
          </Menu.Item>
          <Menu.Item danger onClick={(e) => {
            e.preventDefault()
          }}>a danger item</Menu.Item>
        </Menu>
      );
      const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          sorter: (a, b) => a.age - b.age,
          width: '30%',
        },
        {
          title: 'Age',
          dataIndex: 'age',
          sorter: (a, b) => a.age - b.age,
        },
        {
          title: 'Address',
          dataIndex: 'address',
          sorter: (a, b) => a.age - b.age,
          width: '40%',
        },
      ];

    return (
        <div className="layout-content">
            <Row>
                <Col xs={24} md={24} lg={24}>
                    <div className="dashboard_title">
                    <h1 className="text-4xl mt-5">Blue Business Plan</h1>
                    </div>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col xs={24} md={24} lg={6}>
                    <Input placeholder="Filter content..." />
                </Col>
                <Col xs={24} md={24} lg={18}>
                      <Dropdown overlay={menu} trigger={['click']} placement="top" destroyPopupOnHide={true}>
                        <Button type="dashed" className="ml-2">
                          <Space align="center">
                            <PlusCircleOutlined className="flex items-center" />
                            <span className="flex items-center text-md">Type</span>
                          </Space>
                        </Button>
                      </Dropdown>
                      <Dropdown overlay={menu} trigger={['click']} placement="top" destroyPopupOnHide={true}>
                        <Button type="dashed" className="ml-2">
                          <Space align="center">
                            <PlusCircleOutlined className="flex items-center" />
                            <span className="flex items-center text-md">Category</span>
                          </Space>
                        </Button>
                      </Dropdown>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col span={24}>
                    <Table columns={columns} dataSource={[]} bordered/>
                </Col>
            </Row>
        </div>
    )
}