import { Input, Select } from 'antd'
import React, { useState } from 'react'
import * as Data from '../../utils/FinancialData'
import InitialType from './Type'
import UnitSales from './Type/unitSales';
import BillableHours from './Type/billableHours';
import RecurringCharges from './Type/recurringCharges';
import RevenueOnly from './Type/revenueOnly';

const { TextArea } = Input;

const AddRevenue = () => {

    const [type, setType] = useState('')
    const handleType = (value) => {
        // console.log(`selected ${value}`);
        setType(value)
    };

    return (
        <div className=''>
            <label className='text-black text-md font-medium'>Name</label>
            <div className='my-2'>
                <Input size='large' />
            </div>
            <div className='my-2'>
                <button className='inline-flex items-center text-[15px] font-medium justify-center text-purple-500 w-full p-2 h-10 border border-input rounded-md hover:bg-gray-100 active:bg-gray-300'>
                    Generate ideas
                </button>
            </div>

            <div className='mb-1'>
                <label className='text-black text-md font-medium'>Description Optional</label>
            </div>
            <TextArea rows={4} />
            <div className='mt-2 mb-1'>
                <label className='text-black text-md font-medium'>Type</label>
            </div>
            <div className='w-full mb-1'>
                <Select
                    options={Data.revenueType}
                    onChange={handleType}
                    placeholder="Select Type"
                    size='large'
                    style={{
                        width: '100%',
                    }}
                />
            </div>
            <div className='mt-2'>
                {

                    type == 'unit_sales'
                        ?
                        <UnitSales />
                        :
                        type == 'billable_hours'
                            ?
                            <BillableHours />
                            :
                            type == 'recurring_charges'
                                ?
                                <RecurringCharges />
                                :
                                type =='revenue_only'
                                ?
                                <RevenueOnly />
                                :<></>
                }

            </div>
            <div className='mt-2 mb-1'>
                <label className='text-black text-md font-medium'>When will you start offering this?</label>
            </div>
            <div>
                <InitialType />
            </div>

            <div className='mt-2'>
                <button className='items-center justify-center rounded-md text-[15px] text-white font-medium py-2 h-10 w-full bg-black hover:bg-neutral-600 active:bg-black'>
                    Add
                </button>
            </div>
        </div>
    )
}

export default AddRevenue