import React, { useState, useEffect } from "react";
import { Button, Form, Input, InputNumber, Spin, notification } from "antd";
import { OPENAI_API_KEY, URL } from "../../utils/const";
// import { Structure } from "utils/configure";
import axios from "axios";
import { Link } from "react-router-dom";
import './admin.css'
import { useSelector } from "react-redux";

const { TextArea } = Input

export default function Admin() {
  const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
  };
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false)

  const [prompt, setPrompt] = useState();
  const user = useSelector(state => {
    return state.userSlice
  })
  const [initialValues, setInitialValues] = useState([
    {
      name: ["companyName"],
      value: "Laboratory Corporation of America Holdings",
    },
    {
      name: ["companyDescription"],
      value: "This is a IT company",
    },
    {
      name: ["unique"],
      value: "Innovative IT solutions",
    },
    {
      name: ["prompt"],
      value: "",
    },
  ]);

  const {email} = useSelector(state => state.userSlice)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/prompt/get");
        console.log("response", response.data);
        // setPrompt(response.data[0].prompt);
        setInitialValues([
          {
            name: ["companyName"],
            value: "Laboratory Corporation of America Holdings",
          },
          {
            name: ["companyDescription"],
            value: "This is a IT company",
          },
          {
            name: ["unique"],
            value: "Innovative IT solutions",
          },
          {
            name: ["prompt"],
            value: response.data[0].prompt,
          },
        ]);
        setDescription(response.data[0].content);
        console.log("data", data);
      } catch (error) { }
    };

    fetchData();
  }, []);

  async function fetchOpenAIResponse(info) {
    const companyName = info.companyName
    const companyDescription = info.companyDescription
    const category = info.category
    const title = info.title
    const prompt = info.prompt

    const userMessage = `Your company name is ${companyName}, and here is the description about the company - '${companyDescription}'
      This is for ${category}. You should generate content with some information.
      Title covered is ${title}.
      For this title, here is the detailed prompt: ${prompt}.
      You should response with following format if title is not empty:
      your title
      your content for this title

      otherwise, you should following format:
      your content

      If Title is empty, you should only generate content with title based on company name, company description, category and prompt.
      Remember, you should think as an owner of the company and don't need to mark anything, response naturly.
    `
    setLoading(true)
    alert(user.gpt)
    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${OPENAI_API_KEY}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        model: user.gpt.modelName,
        messages: [{ role: "user", content: userMessage }],
        temperature: 0.7,
        // max_tokens: 25,
      }),
    });

    if (!response.ok) {
      setLoading(false)
      throw new Error(
        `OpenAI API request failed with status ${response.status}`
      );
    }
    const data = await response.json();
    console.log(data.choices[0].message.content.trim())
    setLoading(false)
    return data.choices[0].message.content.trim();
  }

  const [description, setDescription] = useState("");

  const onChangePrompt = (e) => {
    setPrompt(e.target.value);
  };

  /* eslint-disable no-template-curly-in-string */
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };
  /* eslint-enable no-template-curly-in-string */

  async function onFinish(values) {
    console.log("hi")
    await handleSavePrompt();
    console.log(values);
    setDescription("")
    const response = await axios.post(URL+"/api/prompt/get", { email, category: data.category, title: data.title });
    console.log(response.data)
    const responseFromOpenAI = await fetchOpenAIResponse(response.data);
    setLoading(false)
    setDescription(responseFromOpenAI);
    // const data = {
    //   id: 'test@example.com',
    //   category: values.category,
    //   title: values.title,
    //   category: values.category,
    //   description: values.description,
    //   subjects: values.subjects,
    //   detail: values.detail,
    // };
    // const response = await axios.post(URL+"/api/prompt/add", data);
    // // if(response.data=="succ")
    // console.log("res", response.data);
  }

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, data) => {
    api[type]({
      message: "Notification",
      description: data,
    });
  };

  const beautifulOutput = (text) => {
    const result = text.replace(/\n/g, '<br />');
    return result;
  }

  const handleSavePrompt = async () => {
    console.log('payload: ', email)
    try{
      const response = await axios.post(URL+"/api/prompt/add", {
        ...data,
        email
      });
      console.log(response.data)
      openNotificationWithIcon("success", response.data);
    }catch(err){
      openNotificationWithIcon("error", "Failed to upload your prompt material");
    }
  }

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  }

  return (
    <Form
      {...layout}
      // fields={initialValues}
      name="nest-messages"
      onFinish={onFinish}
      style={{ maxWidth: "100%", paddingTop: "20vh" }}
      validateMessages={validateMessages}
    >
      {email}
      <Form.Item
        name="companyName"
        label="Company Name"
        rules={[{ type: "text" }]}
      >
        <Input name="companyName" onChange={handleChange} />
      </Form.Item>
      <Form.Item
        name="companyDescription"
        label="Company Description"
        rules={[{ type: "text" }]}
      >
        <Input name="companyDescription" onChange={handleChange} />
      </Form.Item>
      <Form.Item
        name="category"
        label="Category"
        rules={[{ type: "text" }]}
      >
        <Input name="category" onChange={handleChange} />
      </Form.Item>
      <Form.Item
        name="title"
        label="Title"
        rules={[{ type: "text" }]}
      >
        <Input name="title" onChange={handleChange} placeholder="Please write a title of content" />
      </Form.Item>
      <Form.Item name="prompt" label="Prompt" rules={[{ type: "text" }]}>
        <TextArea name="prompt" onChange={handleChange} placeholder="Please write details for each subject." />
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 5 }}>
        <span className="mr-3"></span>
        <button
          htmlType="submit"
          className="bg-[#00b877] text-white inline-flex items-center justify-center rounded-md text-[15px] font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 active:bg-primary-darker h-10 px-4 mx-auto sm:m-0 sm:ml-auto"
        >
          Generate
        </button>
      </Form.Item>
      {description != "" ? (
        <div className="flex justify-center">
          <div style={{ backgroundColor: "white" }} className="w-[800px] min-w-[550px] p-2 rounded-md text-lg font-bold" dangerouslySetInnerHTML={{ __html: beautifulOutput(description) }} />
        </div>
      ) : null}
      {contextHolder}
      {
        loading && <div className="absolute z-[999] top-0 bottom-0 left-0 right-0 bg-black bg-opacity-50 flex justify-center items-center">
          <Spin className="relative" size="large" />
        </div>
      }
    </Form>
    // <div className="main-admin">
    //   {
    //     Structure.map((item, index) => (
    //       <Link to = {item.url}>
    //         <div class="item-board">
    //           <h3>{item.title}</h3>
    //           <p class="info">
    //             loading from DB...
    //           </p>
    //         </div>
    //       </Link>
    //     ))
    //   }
    // </div>
  );
}
