import React, { useState } from "react";
import {
  Layout,
  Button,
  Form,
  Input,
  Row,
  Col,
  notification
} from "antd";
import { URL } from "../utils/const";
import { useNavigate, Link, useParams } from "react-router-dom";
import { GoogleLogin } from '@react-oauth/google'
import logo from '../assets/images/StartUp Sphere Logo-02.png'
import { MenuFoldOutlined } from '@ant-design/icons'
import { jwtDecode } from 'jwt-decode';
import axios from "axios";
import { addUser } from "Redux/features/UserSlice";
import { connect, useSelector, useDispatch } from "react-redux";
import { addContent } from "Redux/features/pagesSlice";






const { Content } = Layout;

const { Header } = Layout;

const contentStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  color: 'black',
  marginTop: '60px'
};

const headerStyle = {
  textAlign: 'center',
  color: 'black',
  height: 100,
  // paddingInline: 48,
  padding: 0,
  lineHeight: '64px',
  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
};

export default function SignIn() {

  const dispatch = useDispatch()
  const navigator = useNavigate()

  const [api, contextHolder] = notification.useNotification();

  const [flag, setflag] = useState(false);

  const onChangeFlag = () => {
    if (flag == true) {
      setflag(false)
    }
    else {
      setflag(true)
    }
  }


  const openNotificationWithIcon = (type, data) => {

    api[type]({
      message: "Notification",
      description: data,
    });
  };
  const pageContents = useSelector(state => {
    return state.pagesSlice
  })
  const onFinish = (values) => {

    axios.post(URL + "/api/users/login", values)
      .then(response => {

        const data = response.data;
        console.log(data)
        if (data) {

          openNotificationWithIcon("success", "You logined successfully");
          setTimeout(() => {
            navigator('/dashboard')
          }, 1000)

          axios.post(URL + "/api/users/result/getall", { email: data.email }).then(response => {
            console.log("all contents", response.data)
          }).catch((err) => {
            console.log(err)
          })


          dispatch(addContent({ content: data.company[0].jsonContent }))
          const contentData = JSON.stringify({ content: data.company[0].jsonContent })
          localStorage.setItem('StartupSphere_content', contentData)

          dispatch(addUser({
            email: data.email,
            name: 'StartupSphere',
            companies: data.company,
            curID: data.curID,
            role: data.role,
            prompt: data.prompt,
            upgrade: data.upgrade,
            gpt: data.gpt
          }))
          const userData = JSON.stringify({
            email: data.email,
            name: 'StartupSphere',
            companies: data.company,
            curID: data.curID,
            role: data.role,
            prompt: data.prompt,
            password: data.password
          })
          localStorage.setItem('StartupSphere_user', userData)
        }
        else {
          openNotificationWithIcon("error", "Please input correct email and password");
        }
      })
      .catch(error => {
        console.log(error.response)
        openNotificationWithIcon("error", error.response.data);
      })
  };

  const onFinishFailed = (errInfo) => {
    console.log("Error : ", errInfo);
  };

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  }

  const responseMessage = (response) => {


    axios.post(URL + "/api/users/loginWithGoogle", { email: jwtDecode(response.credential).email })
      .then(response => {

        const data = response.data;
        console.log(data)
        if (data) {

          openNotificationWithIcon("success", "You logined successfully");
          setTimeout(() => {
            navigator('/dashboard')
          }, 1000)
          axios.post(URL + "/api/users/result/getall", { email: data.email }).then(response => {
            console.log("all contents", response.data)
          }).catch((err) => {
            console.log(err)
          })

          dispatch(addContent({ content: data.company[0].jsonContent }))
          const contentData = JSON.stringify({ content: data.company[0].jsonContent })
          localStorage.setItem('StartupSphere_content', contentData)

          dispatch(addUser({
            email: data.email,
            name: 'StartupSphere',
            companies: data.company,
            curID: data.curID,
            role: data.role,
            prompt: data.prompt,
            upgrade: data.upgrade,
            gpt: data.gpt,
          }))
          const userData = JSON.stringify({
            email: data.email,
            name: 'StartupSphere',
            companies: data.company,
            curID: data.curID,
            role: data.role,
            prompt: data.prompt,
            password: data.password
          })
          localStorage.setItem('StartupSphere_user', userData)
        }
        else {
          openNotificationWithIcon("error", "Please input correct email and password");
        }
      })
      .catch(error => {
        navigator('/dashboard', { state: jwtDecode(response.credential).email })

        // console.log(error.response)
        // openNotificationWithIcon("error", error.response.data);
      })

  };
  const errorMessage = (error) => {
    console.log(error);
  };

  const validateEmail = (_, value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!value || emailRegex.test(value)) {
      return Promise.resolve();
    }

    return Promise.reject('Please enter a valid email address.');
  };


  return (
    <>
      {contextHolder}
      <div>
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Col sm={20} xs={20} lg={19} style={{}}>
            <Header style={headerStyle}>
              <Link to="/" >
                <img src={logo} alt='logo' className='h-7 w-auto my-auto sm:h-10' />
              </Link>
              <div className='hidden sm:block'>
                <Link to="/" className='text-lg mr-8' type='text' style={{ color: 'black' }}>Home</Link>
                {/* <Link to="/blog" className='text-lg mr-8' type='text' style={{ color: 'black' }}>Blog</Link> */}
                <Link to="/about" className='text-lg' type='text' style={{ color: 'black' }}>About</Link>
              </div>
              <div className='flex items-center'>
                <Link cla to="/sign-in" className="mr-8 text-lg text-black hidden lg:block">
                  Login
                </Link>
                {/* <Button type='second' className='mr-4' style={{ backgroundColor: '#3367D6', color: 'white' }}>
                  <Link to="/dashboard" className="text-white text-lg">
                    Get started
                  </Link>
                </Button> */}
                <Link to="/dashboard" className="text-white text-lg">
                  <button className="gradient-button shadow-xl flex" style={{ fontFamily: 'cursive', paddingRight: 20, paddingLeft: 20, fontSize: 16, borderRadius: "20px", paddingTop: "5px", paddingBottom: "5px", boxShadow: '-3px 4px #B7B7B7' }}>
                    <span className='text-[12px] sm:text-[16px]'><b>Get started</b></span>
                  </button>
                </Link>
                <MenuFoldOutlined className='sm:hidden' onClick={onChangeFlag} />
              </div>
            </Header>
          </Col>

          {
            !flag && <>
              <Content style={contentStyle}>
                <Col sm={22} xs={22} md={12} lg={8}>
                  <sapn className="text-2xl w-full pb-8 text-center font-medium inline-block tracking-tight">
                    Login
                  </sapn>
                  <Form
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    layout="vertical"
                    className="row-col"
                  >
                    <Form.Item
                      className="username"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: 'Please enter your email address.',
                        },
                        {
                          validator: validateEmail,
                        },
                      ]}
                    >
                      <Input placeholder="Email" />
                    </Form.Item>

                    <Form.Item
                      className="username"
                      name="password"
                      style={{ marginBottom: 0 }}
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input placeholder="Password" type="password" />
                    </Form.Item>

                    <div className="flex">
                      <a className="text-lg ml-auto text-muted-foreground hover:underline hover:text-foreground" href="/forgot-password">
                        Forgot password
                      </a>
                    </div>

                    <Form.Item>
                      <Button
                        type="default"
                        htmlType="submit"
                        style={{ width: "100%", backgroundColor: '#3367D6' }}
                      >
                        <div className="text-lg text-white">Login</div>
                      </Button>
                    </Form.Item>

                  </Form>
                  <div>
                    <div className="relative mt-3">
                      <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className="w-full border-t border-neutral-200"></div>
                      </div>
                      <div className="relative flex justify-center text-sm font-normal leading-6">
                        <span className="bg-white px-6 text-muted-foreground">
                          Or continue with
                        </span>
                      </div>
                    </div>
                    <div className="mt-3 flex gap-4 justify-center w-full">
                      {/* <button className="inline-flex items-center justify-center rounded-md text-[15px] font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground active:bg-black/10 h-10 px-4 w-full">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" className="w-4 mr-2">
                      <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"></path>
                      <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"></path>
                      <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"></path>
                      <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"></path>
                      <path d="M1 1h22v22H1z" fill="none"></path>
                    </svg>
                    Google
                  </button> */}
                      <GoogleLogin className="w-full" text="Google" onSuccess={responseMessage} onError={errorMessage} />
                    </div>
                  </div>
                </Col>
              </Content>
            </>}
        </Row>
        {
          flag &&
          <div className='mt-20 text-center'>
            <div className='block'>
              <Link to="/" className='text-2xl ' type='text' style={{ color: 'black' }}>Home</Link>
            </div>
            {/* <div className='block mt-4'>
              <Link to="/blog" className='text-2xl ' type='text' style={{ color: 'black' }}>Blog</Link>
            </div> */}
            <div className='block mt-4'>
              <Link to="/about" className='text-2xl' type='text' style={{ color: 'black' }}>About</Link>
            </div>
            <div className='block mt-4'>
              <Link cla to="/sign-in" className=" text-2xl text-black">
                Login
              </Link>
            </div>
          </div>
        }
      </div>
    </>
  );
  // }
}
