import React from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import * as Data from '../../utils/FinancialData'

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className='bg-white shadow-md rounded p-2 text-sm font-medium min-w-[200px] text-neutral-800'>
                <span className='text-muted-foreground w-full border-b border-neutral-200 pb-2 mb-1 flex'>Revenue</span>
                <div className="flex items-center">
                    <div className="flex items-center pt-1">
                        <div className="w-2 h-2 mr-2 rounded-full" style={{ background: 'rgb(36, 99, 235)' }}></div>
                        <span>{`${label}`}</span>
                    </div>
                    <span className=" ml-auto">${`${payload[0].value}`}K</span>
                </div>
            </div>
        );
    }

    return null;
};

const Chart = () => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart data={Data.barChart}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip content={<CustomTooltip />}/>
                <Bar dataKey="uv" fill="#2463EB" barSize={30}/>
            </BarChart>
        </ResponsiveContainer>
    )
}

export default Chart