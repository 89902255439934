import React, { useState, useEffect } from "react";
import {
    Row, Col, Switch
} from "antd";
import axios from "axios";
import { URL } from '../../utils/const'
import Edit from "utils/edit";
import Admin from "utils/admin";
import *as formats from '../../utils/configure'
import { genEachContent } from "../../utils/service";
import { useSelector, useDispatch } from "react-redux";
import { updateHtmlContent } from '../../Redux/features/UserSlice';
import { addContent } from "../../Redux/features/pagesSlice";
import { setRegenerate } from "../../Redux/features/UserSlice";
import Limit from "components/Limit";
import Generating from "components/Generating";
import Upgrade from "components/Upgrade";
import { addUser } from "../../Redux/features/UserSlice";

export default function CustomerService() {

    const dispatch = useDispatch();
    const { email, companies, role, prompt } = useSelector(state => state.userSlice)
    const user = useSelector(state => {
        return state.userSlice
    })
    const pageContents = useSelector(state => {
        return state.pagesSlice
    })

    const category = 'Customer Service'
    const page_title = user.companies[0].content.find((item) => item.title === "Products & Services").list.find((item) => item.origin===category).shown
    const auth = role

    const [oldprompt, setOldPrompt] = useState([]);
    const [flag, setFlag] = useState(false);
    const [generateFlag, setGenerateFlag] = useState(false);
    const [unique, setUnique] = useState('')
    const [renderData, setRenderData] = useState(null);
    const [paid, setPaid] = useState();

    useEffect(() => {
        setFlag(false);
        dispatch(setRegenerate(false))
        if (email == '')
            window.location.href = '/sign-in';
    }, []);

    useEffect(() => {
        axios.post(URL + "/api/paid/get", { data: "Customer Service" })
            .then(res => {
                console.log("res", res.data.prompt);
                if (res.data.prompt == 'paid')
                    setPaid(true)
                else setPaid(false)
            })
            .catch(err => console.log("err", err))
    }, [])

    const handleChangeCheck = () => {
        if (paid == true)
            setPaid(false)
        else setPaid(true)

        axios.post(URL + "/api/paid/add", { data: "Customer Service" })
        .then(res=>{
            console.log("paid has changed", res)
        })
        .catch(err=>console.log("failed to change the paid method", err))
    }

    useEffect(() => {
        const currentPrompt = prompt.filter((item) => item.title == category)
        setOldPrompt(currentPrompt.prompt)
    }, [])

    const savePrompt = (newPrompt) => {
        console.log("saveNewPrompt", newPrompt)
    }

    const generateContent = (newPrompt) => {
        setFlag(true);
        setGenerateFlag(true)
        let formatData;
        Object.keys(formats).map(key => {
            if (category == formats[key].Title) {
                console.log(formats[key].Title, formats[key], formats[key])
                formatData = { title: formats[key].Title, data: formats[key] }
            }
        })


        genEachContent(user.companies[0], newPrompt, formatData.data, user.gpt.modelName)
            .then((res) => {
                if (Array.isArray(pageContents.contents)) {
                    const newContents = pageContents.contents.map((item, idx) => {
                        if (item.Title === category) {
                            return res
                        }
                        return item;
                    });
                    let temp = newContents;
                    dispatch(addContent({ content: temp }));
                    dispatch(updateHtmlContent({ itemTitle: category, newhtmlContent: {} }));
                    dispatch(setRegenerate(false))
                    setFlag(false);
                    axios.post(URL + "/api/users/result/save", { email: user.email, companyName: user.companies[0].companyName, content: newContents })
                        .then(res => {
                            console.log("---------------", res)
                            dispatch(addUser({
                                email: user.email,
                                name: 'StartupSphere',
                                companies: res.data,
                                curID: user.curID,
                                role: user.role,
                                prompt: user.prompt,
                                upgrade: user.upgrade,
                                gpt: user.gpt
                            }))
                        }).catch(err => console.log(err))
                }
            })
            .catch((error) => {
                console.error(error);
            });

    }

    const handleChange = (html, clippedTxt, id) => {

        if (id == "unique")
            setUnique(html)

    }

    const chageHtml = (html, id) => {
        if (id == "unique")
            setUnique(html)
    }
    useEffect(() => {
        if (unique != '') {
            const data = {
                unique: unique
            }
            // console.log("problem----",problem)
            const sendData = { email: user.email, company: user.companies[0].companyName, title: category, sendData: data }
            axios.post(URL + "/api/users/changeHtml", {
                data: sendData
            }).then(res => {
                console.log('updated and saved')
                // console.log(sendData)
                dispatch(updateHtmlContent({ itemTitle: category, newhtmlContent: data }));
            }).catch(err => console.log('saving updated content'))
        }
    }, [unique]);

    useEffect(() => {
        if (user.regenerateFlag == true) {
            let currentPrompt = ''
            console.log("regnerating")
            user.prompt.map(item => {
                if (item.title == category) {
                    currentPrompt = item.prompt
                }
            })
            generateContent(currentPrompt)
        } else {
            setFlag(false)
        }
    }, [user.regenerateFlag]);

    useEffect(() => {
        let middleData = [];

        if (Array.isArray(pageContents.contents)) {
            middleData = pageContents.contents.filter((item) => {
                return item.Title === category;
            });
        } else if (Array.isArray(pageContents.contents.contents)) {
            middleData = pageContents.contents.contents.filter((item) => {
                return item.Title === category;
            });
        }
        setRenderData(middleData);
        console.log("middledata", middleData)
    }, [pageContents]);

    useEffect(() => {

        let htmlContent = null;
        companies[0].htmlContent.forEach((item) => {
            if (item.Title === category) {
                htmlContent = item.htmlcontent;
            }
        });
        if (htmlContent != null && !generateFlag) {
            setUnique(htmlContent.unique);
        } else if (renderData != null && renderData[0]) {
            let result3 = `<p>${renderData[0]?.["Content"]?.[0]?.["Description"]?.["Overview"]}</p>`
            const rlt1 = renderData[0]?.["Content"]?.[0]?.["Description"]?.["SubContent"]
            const length1 = rlt1.length
            for (let i = 0; i < length1; i++) {
                result3 = result3 + `<p>${rlt1[i]?.["Item"]}: ${rlt1[i]?.["Description"]}</p>`;
            }
            result3 = result3 + `<h3>${renderData[0]?.["Content"]?.[0]?.["Description"]?.["Conclusion"]}</h3>`
            setUnique(result3);
        }

    }, [renderData])

    return (
        <div className="layout-content mx-auto max-w-4xl">
            {(user.upgrade == "unpaid" && auth != "admin") &&
                <Col xs={24} md={24} lg={24}>
                    <Limit></Limit>
                </Col>}
            <Row className="p-6">
                <Col xs={24} md={24} lg={24}>
                    <h6 class="text-md font-normal flex items-center leading-none capitalize w-full text-green-500">
                        <div class="w-6 h-6 mr-2 justify-center flex rounded-sm text-green-500 bg-green-50">
                            <div class="w-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-full"><path d="m7.5 4.27 9 5.15"></path><path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z"></path><path d="m3.3 7 8.7 5 8.7-5"></path><path d="M12 22V12"></path></svg></div></div>
                        <span class="text-base font-normal">Products &amp; Services</span>
                    </h6>
                </Col>
                <Col xs={24} md={24} lg={24} className="flex items-center justify-between mt-4 mb-9">
                    <div>

                        <h3 class="text-2xl sm:text-3xl font-medium leading-none tracking-tight capitalize w-full">Customer Service</h3>
                    </div>
                    {auth=='admin'&&<Switch className="bg-[#3367D6]" checkedChildren="FREE" unCheckedChildren="PAID" checked={paid} onChange={handleChangeCheck} />}
 
                </Col>

                {
                    auth == 'admin' &&
                    <Admin currentPrompt={oldprompt} category={category} flag={flag} generateContent={generateContent} savePrompt={savePrompt}></Admin>
                }

                {((unique != '' && user.upgrade != "unpaid") || (unique != '' && auth == 'admin') || (unique != '' && paid)) &&
                    <Col xs={24} md={24} lg={24}>
                        <Edit content={unique} id={"unique"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                    </Col>
                }
                {unique == '' &&<Generating></Generating>
                }
                {
        auth != "admin" && user.upgrade == "unpaid" &&!paid &&<Upgrade></Upgrade>
      }
            </Row>
        </div>
    )
}