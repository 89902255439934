import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Row,
  Col,
  Switch
} from "antd";
import {
  TransactionOutlined,
  TrophyOutlined,
  CodepenOutlined
} from "@ant-design/icons";
import { connect, useSelector, useDispatch } from "react-redux";
import Admin from "utils/admin";
import Edit from "utils/edit";
import axios from "axios";
import { URL } from "utils/const";
import *as formats from '../../utils/configure'
import { genEachContent } from "../../utils/service";
import { updateHtmlContent } from '../../Redux/features/UserSlice';
import { addContent } from "../../Redux/features/pagesSlice";
import { setRegenerate } from "../../Redux/features/UserSlice";
import Limit from "components/Limit";
import Generating from "components/Generating";
import { addUser } from "../../Redux/features/UserSlice";
import Upgrade from "components/Upgrade";

export default function CoreOfferings() {


  const dispatch = useDispatch();

  const { email, curID, companies, role, prompt } = useSelector(state => state.userSlice)

  const user = useSelector(state => {
    return state.userSlice
  })

  const pageContents = useSelector(state => {
    return state.pagesSlice
  })

  const category = "Core Offerings"
  const page_title = user.companies[0].content.find((item) => item.title === "Products & Services").list.find((item) => item.origin === category).shown

  const auth = role

  const [oldprompt, setOldPrompt] = useState([]);
  const [flag, setFlag] = useState(false);
  const [generateFlag, setGenerateFlag] = useState(false);
  const [renderData, setRenderData] = useState(null);
  const [contentOverview, setContentOverview] = useState('')
  const [subTitle1, setSubtitle1] = useState('')
  const [subOverview1, setSubOverview1] = useState('')
  const [execution1, setExecution1] = useState('')
  const [challenges1, setChallenges1] = useState('')
  const [key1, setKey1] = useState('')
  const [promotion1, setPromotion1] = useState('')
  const [pricing1, setPricing1] = useState('')
  const [target1, setTarget1] = useState('')
  const [subTitle2, setSubtitle2] = useState('')
  const [subOverview2, setSubOverview2] = useState('')
  const [execution2, setExecution2] = useState('')
  const [challenges2, setChallenges2] = useState('')
  const [key2, setKey2] = useState('')
  const [promotion2, setPromotion2] = useState('')
  const [pricing2, setPricing2] = useState('')
  const [target2, setTarget2] = useState('')
  const [paid, setPaid] = useState();

  useEffect(() => {
    setFlag(false);
    dispatch(setRegenerate(false))
    if (email == '')
      window.location.href = '/sign-in';
  }, []);

  useEffect(() => {
    const currentPrompt = prompt.filter((item) => item.title == category)
    console.log("currentPrompts", currentPrompt)
    setOldPrompt(currentPrompt.prompt)
  }, [])

  useEffect(() => {
    axios.post(URL + "/api/paid/get", { data: "Core Offerings" })
      .then(res => {
        console.log("res", res.data.prompt);
        if (res.data.prompt == 'paid')
          setPaid(true)
        else setPaid(false)
      })
      .catch(err => console.log("err", err))
  }, [])

  const handleChangeCheck = () => {
    if (paid == true)
      setPaid(false)
    else setPaid(true)

    axios.post(URL + "/api/paid/add", { data: "Core Offerings" })
      .then(res => {
        console.log("paid has changed", res)
      })
      .catch(err => console.log("failed to change the paid method", err))
  }

  useEffect(() => {
    let middleData = [];

    if (Array.isArray(pageContents.contents)) {
      middleData = pageContents.contents.filter((item) => {
        return item.Title === category;
      });
    } else if (Array.isArray(pageContents.contents.contents)) {
      middleData = pageContents.contents.contents.filter((item) => {
        return item.Title === category;
      });
    }
    setRenderData(middleData);
    console.log("middledata", middleData)
  }, [pageContents]);


  const savePrompt = (newPrompt) => {
    console.log("saveNewPrompt", newPrompt)
  }

  const generateContent = (newPrompt) => {
    setFlag(true);
    setGenerateFlag(true)
    let formatData;
    Object.keys(formats).map(key => {
      if (category == formats[key].Title) {
        console.log(formats[key].Title, formats[key], formats[key])
        formatData = { title: formats[key].Title, data: formats[key] }
      }
    })

    genEachContent(user.companies[0], newPrompt, formatData.data, user.gpt.modelName)
      .then((res) => {
        if (Array.isArray(pageContents.contents)) {
          const newContents = pageContents.contents.map((item, idx) => {
            if (item.Title === category) {
              return res
            }
            return item;
          });
          let temp = newContents;
          dispatch(addContent({ content: temp }));
          dispatch(updateHtmlContent({ itemTitle: category, newhtmlContent: {} }));
          dispatch(setRegenerate(false))
          setFlag(false);
          axios.post(URL + "/api/users/result/save", { email: user.email, companyName: user.companies[0].companyName, content: newContents })
            .then(res => {
              console.log("---------------", res)
              dispatch(addUser({
                email: user.email,
                name: 'StartupSphere',
                companies: res.data,
                curID: user.curID,
                role: user.role,
                prompt: user.prompt,
                upgrade: user.upgrade,
                gpt: user.gpt
              }))
            }).catch(err => console.log(err))
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleChange = (html, clippedTxt, id) => {

    if (id == "contentOverview")
      setContentOverview(html)
    if (id == "subTitle1")
      setSubtitle1(html)
    if (id == "subOverview1")
      setSubOverview1(html)
    if (id == "execution1")
      setExecution1(html)
    if (id == "challenges1")
      setChallenges1(html)
    if (id == "key1")
      setKey1(html)
    if (id == "promotion1")
      setPromotion1(html)
    if (id == "pricing1")
      setPricing1(html)
    if (id == "target1")
      setTarget1(html)
    if (id == "subTitle2")
      setSubtitle2(html)
    if (id == "subOverview2")
      setSubOverview2(html)
    if (id == "execution2")
      setExecution2(html)
    if (id == "challenges2")
      setChallenges2(html)
    if (id == "key2")
      setKey2(html)
    if (id == "promotion2")
      setPromotion2(html)
    if (id == "pricing2")
      setPricing2(html)
    if (id == "target2")
      setTarget2(html)
  }

  useEffect(() => {
    if (contentOverview != "" || subTitle1 != "" || subOverview1 != "" || execution1 != "" || challenges1 != "" || key1 != "" || promotion1 != "" || pricing1 != "" || target1 != "" || subTitle2 != "" || subOverview2 != "" || execution2 != "" || challenges2 != "" || key2 != "" || promotion2 != "" || pricing2 != "" || target2 != "") {
      const data = {
        contentOverview: contentOverview,
        subTitle1: subTitle1,
        subOverview1: subOverview1,
        execution1: execution1,
        challenges1: challenges1,
        key1: key1,
        promotion1: promotion1,
        pricing1: pricing1,
        target1: target1,
        subTitle2: subTitle2,
        subOverview2: subOverview2,
        execution2: execution2,
        challenges2: challenges2,
        key2: key2,
        promotion2: promotion2,
        pricing2: pricing2,
        target2: target2,
      }
      // console.log("problem----",problem)
      const sendData = { email: user.email, company: user.companies[0].companyName, title: category, sendData: data }
      axios.post(URL + "/api/users/changeHtml", {
        data: sendData
      }).then(res => {
        console.log('updated and saved')
        dispatch(updateHtmlContent({ itemTitle: category, newhtmlContent: data }));
      }).catch(err => console.log('saving updated content'))
    }
  }, [contentOverview, subTitle1, subOverview1, execution1, challenges1, key1, promotion1, pricing1, target1, subTitle2, subOverview2, execution2, challenges2, key2, promotion2, pricing2, target2]);

  const chageHtml = (html, id) => {
    console.log("content overview")
    if (id == "contentOverview")
      setContentOverview(html)
    if (id == "subTitle1")
      setSubtitle1(html)
    if (id == "subOverview1")
      setSubOverview1(html)
    if (id == "execution1")
      setExecution1(html)
    if (id == "challenges1")
      setChallenges1(html)
    if (id == "key1")
      setKey1(html)
    if (id == "promotion1")
      setPromotion1(html)
    if (id == "pricing1")
      setPricing1(html)
    if (id == "target1")
      setTarget1(html)
    if (id == "subTitle2")
      setSubtitle2(html)
    if (id == "subOverview2")
      setSubOverview2(html)
    if (id == "execution2")
      setExecution2(html)
    if (id == "challenges2")
      setChallenges2(html)
    if (id == "key2")
      setKey2(html)
    if (id == "promotion2")
      setPromotion2(html)
    if (id == "pricing2")
      setPricing2(html)
    if (id == "target2")
      setTarget2(html)
  }
  useEffect(() => {
    if (user.regenerateFlag == true) {
      let currentPrompt = ''
      console.log("regnerating")
      user.prompt.map(item => {
        if (item.title == category) {
          currentPrompt = item.prompt
        }
      })
      generateContent(currentPrompt)
    } else {
      setFlag(false)
    }
  }, [user.regenerateFlag]);

  useEffect(() => {

    let htmlContent = null;
    companies[0].htmlContent.forEach((item) => {
      if (item.Title === category) {
        htmlContent = item.htmlcontent;
      }
    });
    if (htmlContent != null && !generateFlag) {
      setContentOverview(htmlContent.contentOverview)
      setSubtitle1(htmlContent.subTitle1)
      setSubOverview1(htmlContent.subOverview1)
      setExecution1(htmlContent.execution1)
      setChallenges1(htmlContent.challenges1)
      setKey1(htmlContent.key1)
      setPromotion1(htmlContent.promotion1)
      setPricing1(htmlContent.pricing1)
      setTarget1(htmlContent.target1)
      setSubtitle2(htmlContent.subTitle2)
      setSubOverview2(htmlContent.subOverview2)
      setExecution2(htmlContent.execution2)
      setChallenges2(htmlContent.challenges2)
      setKey2(htmlContent.key2)
      setPromotion2(htmlContent.promotion2)
      setPricing2(htmlContent.pricing2)
      setTarget2(htmlContent.target2)
    } else if (renderData != null && renderData[0]) {
      setContentOverview('<h3>' + renderData[0]?.["Summary"] + '</h3>')
      if (renderData[0]?.["Content"]?.[1]) {
        setSubtitle1('<h4>' + renderData[0]?.["Content"]?.[1]?.["SubTitle"] + '</h4>')
        setSubOverview1('<h3>' + renderData[0]?.["Content"]?.[1]?.["Description"]?.["Overview"] + '</h3>')

        const rlt = renderData[0]?.["Content"]?.[1]?.["Description"]?.["Execution Strategy"]
        if (rlt) {
          let length = rlt.length
          let result = '';
          for (let i = 0; i < length; i++) {
            result = result + `<ol><li>${rlt[i]?.["Description"]}</li></ol>`;
          }
          setExecution1(result);
        }

        const rlt1 = renderData[0]?.["Content"]?.[1]?.["Description"]?.["Challenges"]
        if (rlt1) {
          let length1 = rlt1.length
          let result1 = '';
          for (let i = 0; i < length1; i++) {
            result1 = result1 + `<ol><li>${rlt1[i]?.["Description"]}</li></ol>`;
          }
          setChallenges1(result1);
        }

        const rlt2 = renderData[0]?.["Content"]?.[1]?.["Description"]?.["Advantages"]
        if (rlt2) {
          let length2 = rlt2.length
          let result2 = '';
          for (let i = 0; i < length2; i++) {
            result2 = result2 + `<ol><li>${rlt2[i]?.["Description"]}</li></ol>`;
          }
          setKey1(result2);
        }

        const rlt3 = renderData[0]?.["Content"]?.[1]?.["Description"]?.["Marketing & Promotion"]
        if (rlt3) {
          let length3 = rlt3.length
          let result3 = '';
          for (let i = 0; i < length3; i++) {
            result3 = result3 + `<ol><li>${rlt3[i]?.["Description"]}</li></ol>`;
          }
          setPromotion1(result3);
        }

        const rlt4 = renderData[0]?.["Content"]?.[1]?.["Description"]?.["Pricing Strategy"]
        if (rlt4) {
          let length4 = rlt4.length
          let result4 = '';
          for (let i = 0; i < length4; i++) {
            result4 = result4 + `<ol><li>${rlt4[i]?.["Description"]}</li></ol>`;
          }
          setPricing1(result4);
        }

        const rlt5 = renderData[0]?.["Content"]?.[1]?.["Description"]?.["Target Audience"]
        if (rlt5) {
          let length5 = rlt5.length
          let result5 = '';
          for (let i = 0; i < length5; i++) {
            result5 = result5 + `<ol><li>${rlt5[i]?.["Description"]}</li></ol>`;
          }
          setTarget1(result5);
        }
      }
      if (renderData[0]?.["Content"]?.[0]) {

        setSubtitle2('<h4>' + renderData[0]?.["Content"]?.[0]?.["SubTitle"] + '</h4>')
        setSubOverview2('<h3>' + renderData[0]?.["Content"]?.[0]?.["Description"]?.["Overview"] + '</h3>')

        const rlt6 = renderData[0]?.["Content"]?.[0]?.["Description"]?.["Execution Strategy"]
        if (rlt6) {
          let length6 = rlt6.length
          let result6 = '';
          for (let i = 0; i < length6; i++) {
            result6 = result6 + `<ol><li>${rlt6[i]?.["Description"]}</li></ol>`;
          }
          setExecution2(result6);
        }

        const rlt7 = renderData[0]?.["Content"]?.[0]?.["Description"]?.["Challenges"]
        if (rlt7) {
          let length7 = rlt7.length
          let result7 = '';
          for (let i = 0; i < length7; i++) {
            result7 = result7 + `<ol><li>${rlt7[i]?.["Description"]}</li></ol>`;
          }
          setChallenges2(result7);
        }

        const rlt8 = renderData[0]?.["Content"]?.[0]?.["Description"]?.["Advantages"]
        if (rlt8) {
          let length8 = rlt8.length
          let result8 = '';
          for (let i = 0; i < length8; i++) {
            result8 = result8 + `<ol><li>${rlt8[i]?.["Description"]}</li></ol>`;
          }
          setKey2(result8);
        }

        const rlt9 = renderData[0]?.["Content"]?.[0]?.["Description"]?.["Marketing & Promotion"]
        if (rlt9) {
          let length9 = rlt9.length
          let result9 = '';
          for (let i = 0; i < length9; i++) {
            result9 = result9 + `<ol><li>${rlt9[i]?.["Description"]}</li></ol>`;
          }
          setPromotion2(result9);
        }

        const rlt10 = renderData[0]?.["Content"]?.[0]?.["Description"]?.["Pricing Strategy"]
        if (rlt10) {
          let length10 = rlt10.length
          let result10 = '';
          for (let i = 0; i < length10; i++) {
            result10 = result10 + `<ol><li>${rlt10[i]?.["Description"]}</li></ol>`;
          }
          setPricing2(result10);
        }

        const rlt11 = renderData[0]?.["Content"]?.[0]?.["Description"]?.["Target Audience"]
        if (rlt11) {
          let length11 = rlt11.length
          let result11 = '';
          for (let i = 0; i < length11; i++) {
            result11 = result11 + `<ol><li>${rlt11[i]?.["Description"]}</li></ol>`;
          }
          setTarget2(result11);
        }
      }

    }

  }, [renderData])




  return (
    <div className="layout-content mx-auto max-w-4xl">
      {(user.upgrade == "unpaid" && auth != "admin") && <Col xs={24} md={24} lg={24}><Limit /></Col>}
      <Row className="p-6">
        <Col xs={24} md={24} lg={24}>
          <h6 class="text-md font-normal flex items-center leading-none capitalize w-full text-green-500">
            <div class="w-6 h-6 mr-2 justify-center flex rounded-sm text-green-500 bg-green-50">
              <div class="w-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-full"><path d="m7.5 4.27 9 5.15"></path><path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z"></path><path d="m3.3 7 8.7 5 8.7-5"></path><path d="M12 22V12"></path></svg></div></div>
            <span class="text-base font-normal">Products &amp; Services</span>
          </h6>
        </Col>
        <Col
          xs={24}
          md={24}
          lg={24}
          style={{ display: "flex", alignItems: "center" }}
          className="flex items-center justify-between mt-4 mb-9"
        >
          <div>
            <h3 class="text-2xl sm:text-3xl font-medium leading-none tracking-tight capitalize w-full">{page_title}</h3>
          </div>
          {auth == 'admin' && <Switch className="bg-[#3367D6]" checkedChildren="FREE" unCheckedChildren="PAID" checked={paid} onChange={handleChangeCheck} />}
        </Col>
        {
          auth == 'admin' &&
          <Admin currentPrompt={oldprompt} category={category} flag={flag} generateContent={generateContent} savePrompt={savePrompt}></Admin>
        }
        {((contentOverview != '' && user.upgrade != "unpaid") || (contentOverview != '' && auth == 'admin') || (contentOverview != '' && paid)) &&
          <div>
            <Row>
              <Col xs={24} md={24} lg={24}>
                <div className="dashboard_title">
                  <div className="mt-4">
                    <Edit content={contentOverview} id={"contentOverview"} changeContent={handleChange} changeHtml={chageHtml} ></Edit>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-6 bg-[white] border rounded-md" style={{ width: "100%" }}>
              <Row className="p-4 border-b" style={{ width: "100%" }}>
                <div>
                  <h2 className="text-xl font-semibold"><Edit content={subTitle1} id={"subTitle1"} changeContent={handleChange} changeHtml={chageHtml}></Edit></h2>
                  <p className="text-lg mt-4">
                    <Edit content={subOverview1} id={"subOverview1"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </p>
                </div>
              </Row>
              <Row className="p-4" style={{ width: "100%" }}>
                <Col xs={24} md={12} lg={12} className="pr-2">
                  <div className="dashboard_title">
                    <p className="text-lg font-bold flex mb-1 items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><path d="M6 9H4.5a2.5 2.5 0 0 1 0-5H6"></path><path d="M18 9h1.5a2.5 2.5 0 0 0 0-5H18"></path><path d="M4 22h16"></path><path d="M10 14.66V17c0 .55-.47.98-.97 1.21C7.85 18.75 7 20.24 7 22"></path><path d="M14 14.66V17c0 .55.47.98.97 1.21C16.15 18.75 17 20.24 17 22"></path><path d="M18 2H6v7a6 6 0 0 0 12 0V2Z"></path></svg></div>
                      Advantages
                    </p>
                    <Edit content={key1} id={"key1"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12} className="pl-2">
                  <div className="dashboard_title">
                    <p className="text-lg font-bold flex mb-1 items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><polyline points="14.5 17.5 3 6 3 3 6 3 17.5 14.5"></polyline><line x1="13" x2="19" y1="19" y2="13"></line><line x1="16" x2="20" y1="16" y2="20"></line><line x1="19" x2="21" y1="21" y2="19"></line><polyline points="14.5 6.5 18 3 21 3 21 6 17.5 9.5"></polyline><line x1="5" x2="9" y1="14" y2="18"></line><line x1="7" x2="4" y1="17" y2="20"></line><line x1="3" x2="5" y1="19" y2="21"></line></svg></div>
                      Challenges
                    </p>
                    <Edit content={challenges1} id={"challenges1"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
              </Row>
              <Row className="p-4" style={{ width: "100%" }}>
                <Col xs={24} md={12} lg={12} className="pr-2">
                  <div className="dashboard_title">
                    <p className="text-lg font-bold flex mb-1 items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><circle cx="12" cy="12" r="10"></circle><circle cx="12" cy="10" r="3"></circle><path d="M7 20.662V19a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v1.662"></path></svg></div>
                      Target Audience
                    </p>
                    <Edit content={target1} id={"target1"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12} className="pl-2">
                  <div className="dashboard_title">
                    <p className="text-lg font-bold mb-1 flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><circle cx="12" cy="12" r="10"></circle><path d="M16 8h-6a2 2 0 1 0 0 4h4a2 2 0 1 1 0 4H8"></path><path d="M12 18V6"></path></svg></div>
                      Pricing Strategy
                    </p>
                    <Edit content={pricing1} id={"pricing1"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
              </Row>
              <Row className="p-4" style={{ width: "100%" }}>
                <Col xs={24} md={12} lg={12} className="pr-2">
                  <div className="dashboard_title">
                    <p className="text-lg font-bold mb-1 flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><path d="m3 11 18-5v12L3 14v-3z"></path><path d="M11.6 16.8a3 3 0 1 1-5.8-1.6"></path></svg></div>
                      Marketing & Promotion
                    </p>
                    <Edit content={promotion1} id={"promotion1"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12} className="pl-2">
                  <div className="dashboard_title">
                    <p className="text-lg font-bold mb-1 flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><path d="m3 17 2 2 4-4"></path><path d="m3 7 2 2 4-4"></path><path d="M13 6h8"></path><path d="M13 12h8"></path><path d="M13 18h8"></path></svg></div>
                      Execution Strategy
                    </p>
                    <Edit content={execution1} id={"execution1"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
              </Row>
            </Row>

            <Row className="mt-6 bg-[white] border rounded-md" style={{ width: "100%" }}>
              <Row className="p-4 border-b" style={{ width: "100%" }}>
                <div>
                  <h2 className="text-xl font-semibold"><Edit content={subTitle2} id={"subTitle2"} changeContent={handleChange} changeHtml={chageHtml}></Edit></h2>
                  <p className="text-lg mt-4">
                    <Edit content={subOverview2} id={"subOverview2"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </p>
                </div>
              </Row>
              <Row className="p-4" style={{ width: "100%" }}>
                <Col xs={24} md={12} lg={12} className="pr-2">
                  <div className="dashboard_title">
                    <p className="text-lg font-bold flex mb-1 items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><path d="M6 9H4.5a2.5 2.5 0 0 1 0-5H6"></path><path d="M18 9h1.5a2.5 2.5 0 0 0 0-5H18"></path><path d="M4 22h16"></path><path d="M10 14.66V17c0 .55-.47.98-.97 1.21C7.85 18.75 7 20.24 7 22"></path><path d="M14 14.66V17c0 .55.47.98.97 1.21C16.15 18.75 17 20.24 17 22"></path><path d="M18 2H6v7a6 6 0 0 0 12 0V2Z"></path></svg></div>
                      Advantages
                    </p>
                    <Edit content={key2} id={"key2"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12} className="pl-2">
                  <div className="dashboard_title">
                    <p className="text-lg font-bold flex mb-1 items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><polyline points="14.5 17.5 3 6 3 3 6 3 17.5 14.5"></polyline><line x1="13" x2="19" y1="19" y2="13"></line><line x1="16" x2="20" y1="16" y2="20"></line><line x1="19" x2="21" y1="21" y2="19"></line><polyline points="14.5 6.5 18 3 21 3 21 6 17.5 9.5"></polyline><line x1="5" x2="9" y1="14" y2="18"></line><line x1="7" x2="4" y1="17" y2="20"></line><line x1="3" x2="5" y1="19" y2="21"></line></svg></div>
                      Challenges
                    </p>
                    <Edit content={challenges2} id={"challenges2"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
              </Row>
              <Row className="p-4" style={{ width: "100%" }}>
                <Col xs={24} md={12} lg={12} className="pr-2">
                  <div className="dashboard_title">
                    <p className="text-lg font-bold flex mb-1 items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><circle cx="12" cy="12" r="10"></circle><circle cx="12" cy="10" r="3"></circle><path d="M7 20.662V19a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v1.662"></path></svg></div>
                      Target Audience
                    </p>
                    <Edit content={target2} id={"target2"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12} className="pl-2">
                  <div className="dashboard_title">
                    <p className="text-lg font-bold mb-1 flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><circle cx="12" cy="12" r="10"></circle><path d="M16 8h-6a2 2 0 1 0 0 4h4a2 2 0 1 1 0 4H8"></path><path d="M12 18V6"></path></svg></div>
                      Pricing Strategy
                    </p>
                    <Edit content={pricing2} id={"pricing2"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
              </Row>
              <Row className="p-4" style={{ width: "100%" }}>
                <Col xs={24} md={12} lg={12} className="pr-2">
                  <div className="dashboard_title">
                    <p className="text-lg font-bold mb-1 flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><path d="m3 11 18-5v12L3 14v-3z"></path><path d="M11.6 16.8a3 3 0 1 1-5.8-1.6"></path></svg></div>
                      Marketing & Promotion
                    </p>
                    <Edit content={promotion2} id={"promotion2"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12} className="pl-2">
                  <div className="dashboard_title">
                    <p className="text-lg font-bold mb-1 flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><path d="m3 17 2 2 4-4"></path><path d="m3 7 2 2 4-4"></path><path d="M13 6h8"></path><path d="M13 12h8"></path><path d="M13 18h8"></path></svg></div>
                      Execution Strategy
                    </p>
                    <Edit content={execution2} id={"execution2"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
              </Row>
            </Row>
          </div>}

        {contentOverview == '' && <Generating></Generating>}

        {
          auth != "admin" && user.upgrade == "unpaid" && !paid && <Upgrade></Upgrade>
        }


        {/* <Row className="mt-8 bg-[white] border rounded-md" style={{ width: "100%" }}>
        <Row className="p-8 bg-[#FAFAFA]" style={{ width: "100%" }}>
          <div className="text-sm flex text-[#9F68F4] p-[1px] pl-[4px] pr-[4px] bg-[#EBE1FA] rounded-md">
            Web Hosting
          </div>
          <div className="mt-4">
            <h2 className="text-lg font-semibold">Energy-Efficient Web Hosting</h2>
            <p className="text-lg mt-4">
              Our energy-efficient web hosting services are designed to minimize our carbon footprint and provide reliable and efficient hosting solutions. We utilize sustainable methods to power our servers, ensuring that our clients' websites are hosted in an environmentally friendly manner.              </p>
          </div>
        </Row>
        <Row className="p-8" style={{ width: "100%" }}>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-lg font-semibold flex items-center">
                <TrophyOutlined className="pr-2" />
                Advantages
              </p>
              <ul className="pl-4" style={{ listStyle: "number" }}>
                <li className="mt-3 text-md">
                  Recurring revenue stream from monthly or annual subscriptions
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                   ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                   equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-lg font-semibold flex items-center">
                <CodepenOutlined className="pr-2" />
                Challenges
              </p>
              <ul className="pl-4" style={{ listStyle: "number" }}>
                <li className="mt-3 text-md">
                  Designing and curating walking routes that
                  showcase the historical and cultural highlights of
                  the city
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                  ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                  equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="p-8" style={{ width: "100%" }}>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-lg font-semibold flex items-center">
                <TrophyOutlined className="pr-2" />
                Target Audience
              </p>
              <ul className="pl-4" style={{ listStyle: "number" }}>
                <li className="mt-3 text-md">
                  Recurring revenue stream from monthly or annual subscriptions
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                   ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                   equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-lg font-semibold flex items-center">
                <CodepenOutlined className="pr-2" />
                Pricing Strategy
              </p>
              <ul className="pl-4" style={{ listStyle: "number" }}>
                <li className="mt-3 text-md">
                  Designing and curating walking routes that
                  showcase the historical and cultural highlights of
                  the city
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                  ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                  equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row className="p-8" style={{ width: "100%" }}>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-lg font-semibold flex items-center">
                <TrophyOutlined className="pr-2" />
                Marketing & Promotion
              </p>
              <ul className="pl-4" style={{ listStyle: "number" }}>
                <li className="mt-3 text-md">
                  Recurring revenue stream from monthly or annual subscriptions
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                   ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                   equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-lg font-semibold flex items-center">
                <CodepenOutlined className="pr-2" />
                Execution Strategy
              </p>
              <ul className="pl-4" style={{ listStyle: "number" }}>
                <li className="mt-3 text-md">
                  Designing and curating walking routes that
                  showcase the historical and cultural highlights of
                  the city
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                  ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                  equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Row> */}
        {/* <Row
        className="mt-8 bg-[#FAFAFA] rounded-md flex "
        style={{
          width: "100%",
          height: "60vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <p className="text-center text-2xl mb-1 font-semibold">Upgrade to Pro to Unlock</p>
          <p className="mb-1 text-lg">
            This section is only included in the full report.
          </p>
          <Button
            style={{
              width: "50%",
              backgroundColor: "#3367D6",
              color: "white",
              //   display: "flex-center",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1rem",
            }}
          >
            <TransactionOutlined style={{ margin: "0", padding: "0" }} />
            Upgrade
          </Button>
        </div>
      </Row>
      <Row
        className="mt-8 bg-[#FAFAFA] rounded-md flex "
        style={{
          width: "100%",
          height: "60vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <p className="text-center text-2xl mb-1 font-semibold">Upgrade to Pro to Unlock</p>
          <p className="mb-1 text-lg">
            This section is only included in the full report.
          </p>
          <Button
            style={{
              width: "50%",
              backgroundColor: "#3367D6",
              color: "white",
              //   display: "flex-center",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1rem",
            }}
          >
            <TransactionOutlined style={{ margin: "0", padding: "0" }} />
            Upgrade
          </Button>
        </div>
      </Row>
      <Row
        className="mt-8 bg-[#FAFAFA] rounded-md flex "
        style={{
          width: "100%",
          height: "60vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <p className="text-center text-2xl mb-1 font-semibold">Upgrade to Pro to Unlock</p>
          <p className="mb-1 text-lg">
            This section is only included in the full report.
          </p>
          <Button
            style={{
              width: "50%",
              backgroundColor: "#3367D6",
              color: "white",
              //   display: "flex-center",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1rem",
            }}
          >
            <TransactionOutlined style={{ margin: "0", padding: "0" }} />
            Upgrade
          </Button>
        </div>
      </Row>
      <Row
        className="mt-8 bg-[#FAFAFA] rounded-md flex "
        style={{
          width: "100%",
          height: "60vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <p className="text-center text-2xl mb-1 font-semibold">Upgrade to Pro to Unlock</p>
          <p className="mb-1 text-lg">
            This section is only included in the full report.
          </p>
          <Button
            style={{
              width: "50%",
              backgroundColor: "#3367D6",
              color: "white",
              //   display: "flex-center",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1rem",
            }}
          >
            <TransactionOutlined style={{ margin: "0", padding: "0" }} />
            Upgrade
          </Button>
        </div>
      </Row>
      <Row
        className="mt-8 mb-8 bg-[#FAFAFA] rounded-md flex "
        style={{
          width: "100%",
          height: "60vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <p className="text-center text-2xl mb-1 font-semibold">Upgrade to Pro to Unlock</p>
          <p className="mb-1 text-lg">
            This section is only included in the full report.
          </p>
          <Button
            style={{
              width: "50%",
              backgroundColor: "#3367D6",
              color: "white",
              //   display: "flex-center",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1rem",
            }}
          >
            <TransactionOutlined style={{ margin: "0", padding: "0" }} />
            Upgrade
          </Button>
        </div>
      </Row> */}
      </Row>
    </div>
  );
}
