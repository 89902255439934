import { Col, Switch, Table, Drawer, Popover } from 'antd'
import React, { useState, useEffect } from 'react'
import {
  DollarOutlined,
  EditOutlined,
  MoreOutlined
} from "@ant-design/icons";
import * as Data from '../../utils/FinancialData'

import AddRevenue from 'components/Financial/addRevenue';
import Title from 'components/Financial/title';
import Pop from 'components/Popover';
import Limit from 'components/Limit';
import { useSelector, useDispatch } from "react-redux";
import Upgrade from "components/Upgrade";
import axios from 'axios';
import { URL } from '../../utils/const'

const Dividends = () => {

  const { email, curID, companies, role, prompt } = useSelector(state => state.userSlice)
  const auth = role
  const [drawer, setDrawer] = useState(false);
  const [tableData, setTableData] = useState(false)
  const [paid, setPaid] = useState();
  const user = useSelector((state) => {
    return state.userSlice;
  });

  useEffect(() => {
    axios.post(URL + "/api/paid/get", { data: "Dividends" })
      .then(res => {
        console.log("res", res.data.prompt);
        if (res.data.prompt == 'paid')
          setPaid(true)
        else setPaid(false)
      })
      .catch(err => console.log("err", err))
  }, [])

  const handleChangeCheck = () => {
    if (paid == true)
      setPaid(false)
    else setPaid(true)

    axios.post(URL + "/api/paid/add", { data: "Dividends" })
      .then(res => {
        console.log("paid has changed", res)
      })
      .catch(err => console.log("failed to change the paid method", err))
  }

  const onChange = (value) => {
    console.log(`selected ${value}`);
    setTableData(value)
  }

  const openDrawer = () => {
    setDrawer(true)
  }

  const closeDrawer = () => {
    setDrawer(false)
  }

  const renderFooter = () => {
    return (
      <div>
        Total Dividends
      </div>
    );
  };

  const columns = [
    {
      title: 'Dividends',
      dataIndex: 'dividends',
      key: 'dividends',
      width: '300px',
      render: (text) =>
      (<div className='revenue flex justify-between'>
        <button className="px-2 text-emerald-400 rounded-lg text-green-500 transition duration-300 ease-in-out hover:text-black hover:bg-gray-200 active:bg-gray-300" onClick={openDrawer}>
          {text}
          <span>
            <EditOutlined />
          </span>
        </button>
        <div className='flex items-center justify-center px-2'>
          <Popover content={<Pop />} trigger="click">
            <button className="hover:bg-gray-100 revenue-action"><MoreOutlined /></button>
          </Popover>
        </div>
      </div>
      )
    },
    {
      title: '2024',
      dataIndex: 'four',
      key: '2024',
    },
    {
      title: '2025',
      dataIndex: 'five',
      key: '2025',
    },
    {
      title: '2026',
      dataIndex: 'six',
      key: '2026',
    },
    {
      title: '2027',
      dataIndex: 'seven',
      key: '2027',
    },
    {
      title: '2028',
      dataIndex: 'eight',
      key: '2028',
    },
  ]
  const firstData = {
    title: 'Revenue Stream',
    dataIndex: 'revenue_stream',
    key: 'revenue_stream',
    width: '300px',
    fixed: 'left',
    render: (text) =>
    (<div className='revenue flex justify-between'>
      <button className="px-2 text-emerald-400 rounded-lg text-green-500 transition duration-300 ease-in-out hover:text-black hover:bg-gray-200 active:bg-gray-300" onClick={openDrawer}>
        {text}
        <span>
          <EditOutlined />
        </span>
      </button>
      <div className='flex items-center justify-center px-2'>
        <Popover content={<Pop />} trigger="click">
            <button className="hover:bg-gray-100 revenue-action"><MoreOutlined /></button>
          </Popover>
      </div>
    </div>
    )
  }
  const monthlyColumn = []
  monthlyColumn.push(firstData)
  let t = 0;
  for (let i = 0; i < 39; i++) {
    monthlyColumn.push({
      title: (i + 1) % 13 === 0 ? `202${4 + t}` : `202${4 + t}-${(i % 13) + 1}`,
      dataIndex: `${i + 1}`,
      key: `${i + 1}`,
    })
    if ((i + 1) % 13 === 0) t++;
  }

  return (
    <div className="layout-content mx-auto max-w-4xl">
    {(user.upgrade == "unpaid" && auth != "admin") &&<Col xs={24} md={24} lg={24}><Limit /></Col>}
      
      <div className='flex w-full items-center justify-between'>
      <Title title={user.companies[0].content.find((item) => item.title === "Financials").list.find((item) => item.origin==="Dividends").shown} />
        {auth=='admin'&&<Switch className="bg-[#3367D6]" checkedChildren="FREE" unCheckedChildren="PAID" checked={paid} onChange={handleChangeCheck} />}
      </div>
      {(user.upgrade !="unpaid" || auth == 'admin'||paid) && <>
      <div className='p-4 pb-28 max-w-7xl mx-auto'>
        <div className='flex items-center pt-4 justify-between'>
          <button className='flex justify-center font-medium border rounded-lg text-green-500 px-4 py-2 transition duration-300 ease-in-out hover:text-black hover:bg-gray-100 active:bg-gray-300' onClick={openDrawer}>
            <div className='mr-2'>+</div>
            <span>Add Dividend</span>
          </button>
          <div >
            <span className='mr-2'>Monthly Data</span>
            <Switch unCheckedChildren onChange={onChange} />
          </div>
        </div>
        <div className='border border-neutral-200 rounded overflow-hidden mt-4'>
          <div className='relative w-full overflow-auto'>
            {
              tableData == false
                ?
                <Table
                  columns={columns}
                  dataSource={[]}
                  pagination={false}
                  footer={renderFooter}
                />
                :
                <Table
                  columns={monthlyColumn}
                  dataSource={[]}
                  pagination={false}
                  scroll={{ x: 4000 }}
                  footer={renderFooter}
                />
            }
          </div>
        </div>
        <div className='flex justify-center text-lg my-8'>AI can make mistakes. You should always review your financials.</div>
      </div>
      <Drawer title="Add Revenue Stream" onClose={closeDrawer} open={drawer}>
        <AddRevenue />
      </Drawer>
      </>}
      {
        auth!="admin"&&user.upgrade == "unpaid" &&!paid&& <Upgrade></Upgrade>
      }
    </div>
  )
}
export default Dividends