import React from 'react'
import { InputNumber } from 'antd'

const onRevenue = (value) => {
    console.log('changed', value);
};
const onGrowth = (value) => {
    console.log('changed', value);
};


const RevenueOnly = () => {
    return (
        <div className='grid grid-cols-2 gap-2'>
            <div className='space-y-1'>
                <label className='text-black text-md font-medium'>Revenue</label>
                <div className='relative w-full'>
                    <InputNumber
                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        onChange={onRevenue}
                        style={{
                            width: '100%',
                        }}
                    />
                </div>
                <p className='text-md text-neutral-500'>Per month</p>
            </div>
            <div className='space-y-1'>
                <label className='text-black text-md font-medium'>Growth rate</label>
                <div className='relative w-full'>
                    <InputNumber
                        formatter={(value) => `${value}%`}
                        parser={(value) => value.replace('%', '')}
                        max={100}
                        onChange={onGrowth}
                        style={{
                            width: '100%',
                        }}
                    />
                </div>
                <p className='text-md text-neutral-500'>Per month</p>
            </div>

        </div>
    )
}

export default RevenueOnly