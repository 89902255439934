import { fetchOpenAIResponse,continueWriting} from "./openai"
import * as formats from './configure'
import { connect, useDispatch, useSelector } from "react-redux";
import { URL } from "../utils/const";
import axios from "axios";
import { dataproject } from "./TablesData";

export function generateAll(email, info, prompt) {
    console.log("email, info, prompt", email, info, prompt)
    return new Promise(async (resolve, reject) => {
        let pageContents = [];
        let subtitles = []
        try {
            info.content.map(item => {
                item.list.map(subitem => {
                    Object.keys(formats).map(key => {
                        if (subitem.origin === formats[key].Title) {
                            subtitles = subtitles.concat({ title: subitem.origin, data: formats[key] })
                        }
                    })
                })
            })
            for (const item of prompt) {
                const rlt = subtitles.filter((row) => row.title === item.title)
                if (rlt.length > 0) {
                    const promp = item.prompt
                    let content = await genEachContent(info, promp, rlt[0].data)
                    let data = { email: email, companyName: info.companyName, pageName: item.title, content: content, isHtmlGenerated: 0 }

                    pageContents.push(JSON.parse(content))

                }
            }

            resolve(pageContents)
        } catch (err) {
            reject(err)
        }
    })

};

// export async function genEachContent(info, prompt, format,modelName) {
//     console.log("info",info, prompt, format)

//     let dataString=""
    

//     return new Promise(async (resolve, reject) => {
//         try {
       
//             let content = await fetchOpenAIResponse({ companyName: info.companyName, companyDescription: info.companyDescription, title: format.Title, prompt: prompt, output_format: format,summaries:dataString,modelName:modelName});
//             resolve(content)
//         } catch (err) {
//             reject(err)
//         }

//     })
// } 

export function genEachContent(info, prompt, format, modelName) {
    // Logging the values of info, prompt, and format to the console
    console.log("info", info, prompt, format);

    // Initializing an empty string variable named dataString
    let dataString = "";

    try {
        // Calling the fetchOpenAIResponse function synchronously
        let content = fetchOpenAIResponse({
            companyName: info.companyName,
            companyDescription: info.companyDescription,
            title: format.Title,
            prompt: prompt,
            output_format: format,
            summaries: dataString,
            modelName: modelName
        });
        
        // Returning the obtained content
        return content;
    } catch (err) {
        // Catching any errors that occur during the execution of the synchronous operation
        // Rethrowing the error
        throw err;
    }
}


export async function moreWriting(info, text, title,modelName) {
    // console.log(info,text,title)
    return new Promise(async (resolve, reject) => {
        try {
            
            let content = await continueWriting(info, text, title,modelName);
            resolve(content)
        } catch (err) {
            reject(err)
        }

    })
} 





export async function writeContent(title) {
    // console.log(info,text,title)
    return new Promise(async (resolve, reject) => {
        try {
            
            let content = await continueWriting(title);
            resolve(content)
        } catch (err) {
            reject(err)
        }

    })
} 


export  async function updatePrompt(title, prompt) {
    let data = {title:title,prompt:prompt}
    return new Promise(async (resolve, reject) => {
    axios.post(URL+"/api/users/updatePrompt", data).then(res=>{
    resolve(res)
    })
    .catch(error => {
        console.error(error);
        reject(error)
    })
})
} 