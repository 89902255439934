import React from "react";
import { Layout, Col } from "antd";
const { Content } = Layout;

const contentStyle = {
  textAlign: 'center',

  height: 'auto',
  color: 'black',
  backgroundColor: 'white',
  paddingTop: '70px'
};

const AntdFooter = () => {
  return (
      <Col sm={22} xs={22} lg={12}>
        <Content style={contentStyle}>
          <div className='px-6 py-20 sm:py-24 lg:px-8'>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
              <a href='/#' className='items-center justify-center rounded-md text-[15px] font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground active:bg-black/10 h-10 px-4 hidden sm:flex'>
                Home
              </a>
              <a href='/#' className='items-center justify-center rounded-md text-[15px] font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground active:bg-black/10 h-10 px-4 hidden sm:flex'>
                Pricing
              </a>
              <a href='/#' className='items-center justify-center rounded-md text-[15px] font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground active:bg-black/10 h-10 px-4 hidden sm:flex'>
                About
              </a>
              {/* <a href='/#' className='items-center justify-center rounded-md text-[15px] font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground active:bg-black/10 h-10 px-4 hidden sm:flex'>
                Support
              </a>
              <a href='/#' className='items-center justify-center rounded-md text-[15px] font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground active:bg-black/10 h-10 px-4 hidden sm:flex'>
                Teams
              </a>
              <a href='/#' className='items-center justify-center rounded-md text-[15px] font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground active:bg-black/10 h-10 px-4 hidden sm:flex'>
                Privacy
              </a> */}
            </div>
            <p className='mt-10 text-center text-xs leading-5 text-gray-500'>
              © Venturekit. All rights reserved.
            </p>
          </div>
        </Content>
      </Col>

  );
};
export default AntdFooter;
