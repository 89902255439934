import { setDefaultData } from 'Redux/features/MainSlice'
import { addUser } from 'Redux/features/UserSlice'
import { addContent } from 'Redux/features/pagesSlice'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { URL } from 'utils/const'
import axios from 'axios'
import { notification} from "antd";
export default ({ children }) => {

  const dispatch = useDispatch()
  const navigator = useNavigate()

  function getLocationTitle(pathname) {
    switch (pathname) {
      case '/dashboard':
        return 'Home'
      default:
        return 'Business Plan';
    }
  }

  const location = useLocation();
  const [api, contextHolder] = notification.useNotification();
  useEffect(() => {
    // Update the document title whenever the route changes
    document.title = `${getLocationTitle(location.pathname)} | StartupSphere`;
  }, [location]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const status = params.get('status');
  


    console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%324242424234%%%%%%%%%%')
    const user = localStorage.getItem('StartupSphere_user')
    const content = localStorage.getItem('StartupSphere_content')

    try {
      if (user && content) {
        const userData = JSON.parse(user)
        axios.post(URL+"/api/users/login", { email: userData.email, password: userData.password })
          .then(response => {
            console.log("response",response)
            const data = response.data;
            if (data) {
              dispatch(addContent({ content: data.company[0].jsonContent }))
              dispatch(addUser({
                email: data.email,
                name: 'StartupSphere',
                companies: data.company,
                curID: data.curID,
                role: data.role,
                prompt: data.prompt,
                upgrade:data.upgrade,
                gpt:data.gpt
              }))
              if(status=="success"){

                navigator('/account/success')
                return
              }else if(status=="failure"){
                navigator('/account/failed')
                // alert("error")
                return
              }
              navigator('/dashboard')
            }
            else {
              console.log("Please input correct email and password");
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    } catch (error) {
      navigator('/')
      console.log(error)
    }

  }, [children])
  return <div>
    {children}
  </div>
}