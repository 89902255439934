import React, { useState, useEffect } from 'react'
import Marketing from '../../../assets/images/pdfs/marketing_ideas.png'

const MarketingIdeas = (props) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        if (props.user) {
            // console.log('Marketing Ideas -> user: ', props.user);
            setData(props.user.companies[0].jsonContent.find(item => item.Title === "Marketing Ideas"));
        }
    })

    return (
        <div style={{fontFamily:'sans-serif',display:'none'}}>
        <div style={{fontFamily:'sans-serif', width: `400px`, padding: '10px 20px', marginLeft: 20 }} id='ideas'>
            <div style={{fontFamily:'sans-serif', display: 'flex', alignItems: 'center' }}>
                <div style={{fontFamily:'sans-serif', width: 13, height: 13, marginRight: 8, marginTop: 17, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 3, color: '#26B35A' }}>
                    <div style={{fontFamily:'sans-serif', width: 12 }}>
                        <img src={Marketing} />
                    </div>
                </div>
                <div style={{fontFamily:'sans-serif', fontSize: 12, color: '#26B35A' }}>Marketing Ideas</div>
            </div>
            <div style={{fontFamily:'sans-serif', fontSize: 16, fontWeight: 'bold' }}>
                Marketing Ideas
            </div>
            <div style={{fontFamily:'sans-serif',width:'100%'}}>
                <div style={{fontFamily:'sans-serif', paddingBottom: 10, paddingTop:20,fontSize: 8 }}>
                    {data?.Summary}
                </div>
                <div style={{fontFamily:'sans-serif', border: '1px solid #666', borderRadius: 5, padding: '0px 5px 5px', fontWeight: 'bold', fontSize: 14, marginBottom: 10 }}>
                    <div style={{fontFamily:'sans-serif',fontSize:10}}>{data?.Content[0]?.Title}</div>
                    <div style={{fontFamily:'sans-serif', fontSize: 8, marginTop: 5, fontWeight: 'normal' }}>
                        {data?.Content[0]?.Description}
                    </div>
                </div>
                <div style={{fontFamily:'sans-serif', border: '1px solid #666', borderRadius: 5, padding: '0px 5px 5px', fontWeight: 'bold', fontSize: 14, marginBottom: 10 }}>
                <div style={{fontFamily:'sans-serif',fontSize:10}}>{data?.Content[1]?.Title}</div>
                    <div style={{fontFamily:'sans-serif', fontSize: 8, marginTop: 5, fontWeight: 'normal' }}>
                        {data?.Content[1]?.Description}
                    </div>
                </div>
                <div style={{fontFamily:'sans-serif', border: '1px solid #666', borderRadius: 5, padding: '0px 5px 5px', fontWeight: 'bold', fontSize: 14, marginBottom: 10 }}>
                <div style={{fontFamily:'sans-serif',fontSize:10}}>{data?.Content[2]?.Title}</div>
                    <div style={{fontFamily:'sans-serif', fontSize: 8, marginTop: 5, fontWeight: 'normal' }}>
                        {data?.Content[2]?.Description}
                    </div>
                </div>
                <div style={{fontFamily:'sans-serif', border: '1px solid #666', borderRadius: 5, padding: '0px 5px 5px', fontWeight: 'bold', fontSize: 14, marginBottom: 10 }}>
                <div style={{fontFamily:'sans-serif',fontSize:10}}>{data?.Content[3]?.Title}</div>
                    <div style={{fontFamily:'sans-serif', fontSize: 8, marginTop: 5, fontWeight: 'normal' }}>
                        {data?.Content[3]?.Description}
                    </div>
                </div>
                {/* <div style={{fontFamily:'sans-serif', border: '1px solid #666', borderRadius: 5, padding: '0px 10px 15px', fontWeight: 'bold', fontSize: 14, marginBottom: 10 }}>
                    {data?.Content[4]?.Title}
                    <div style={{fontFamily:'sans-serif', fontSize: 12, marginTop: 10, fontWeight: 'normal' }}>
                        {data?.Content[4]?.Description}
                    </div>
                </div> */}
            </div>

        </div>
        </div>
    )
}

export default MarketingIdeas