import React, { PureComponent, useEffect } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import * as Data from '../../utils/FinancialData'

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className='bg-white shadow-md rounded p-2 text-sm font-medium min-w-[200px] text-neutral-800'>
                <span className='text-muted-foreground w-full border-b border-neutral-200 pb-2 mb-1 flex'>Revenue</span>
                <div className="flex items-center">
                    <div className="flex items-center pt-1">
                        <div className="w-2 h-2 mr-2 rounded-full" style={{ background: 'rgb(36, 99, 235)' }}></div>
                        <span>{`${label}`}</span>
                    </div>
                    <span className=" ml-auto">${`${payload[0].value}`}K</span>
                </div>
            </div>
        );
    }

    return null;
};

const Chart = ({year}) => {
    useEffect(() => {
        // console.log(year && Data.areaData.filter(data => data.year == year)[0].data);
    },[])
    return (
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={year && Data.areaData.filter(data => data.year == year)[0].data}
                margin={{ top: 10, right: 30, left: 20, bottom: 10 }}>
                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#2463EB" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="#2463EB" stopOpacity={0} />
                    </linearGradient>
                </defs>
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Area type="monotone" dataKey="uv" stroke="#2463EB" fillOpacity={1} fill="url(#colorUv)"/>
            </AreaChart>
        </ResponsiveContainer>
    )
}

export default Chart