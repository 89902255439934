import React, { useState, useEffect } from 'react'
import { Col, Row, Button } from 'antd'
import { useSelector, useDispatch } from "react-redux";
import { notification } from 'antd';
import { Modal, Segmented, Tabs, Rate } from 'antd';
import axios from 'axios'
import { URL } from 'utils/const'
import { useParams } from 'react-router-dom'
import { CheckOutlined, ThunderboltOutlined, CloseOutlined, ControlOutlined, DollarOutlined, CheckCircleOutlined, CrownOutlined, InboxOutlined, BarChartOutlined, RiseOutlined } from '@ant-design/icons'
const Upgrade = (props) => {
    const [paymentloadings, setPaymentLoadings] = useState(false);
    const [isProPlan, setIsProPlan] = useState(true);
    const [isMonthlyPlan, setIsMonthlyPlan] = useState(false);
    const [pricePerMonth, setPricePerMonth] = useState(20);
    const { status } = useParams()
    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = (type, data) => {
        api[type]({
            message: "Notification",
            description: data,
        });
    };
    useEffect(() => {
        if (isMonthlyPlan && isProPlan) {
            setPricePerMonth(19)
        }
        if (!isMonthlyPlan && isProPlan) {
            setPricePerMonth(11)
        }
        if (isMonthlyPlan && !isProPlan) {
            setPricePerMonth(39)
        }
        if (!isMonthlyPlan && !isProPlan) {
            setPricePerMonth(23)
        }
    }, [isMonthlyPlan, isProPlan])
    useEffect(() => {
        if (status == "success") {
            openNotificationWithIcon("success", "Upgraded Successfully");
        }

        if (status == "cancel") {
            openNotificationWithIcon("error", "Failed, Try again later");
        }
    }, [status])

    const user = useSelector(state => {
        return state.userSlice
    })
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const connectToStripe = async () => {
        // setPaymentLoadings(!paymentloadings)
        try {
            const response = await axios.post(`/api/payment/process`, { price: pricePerMonth * 100, userId: user.email, isMonthly: isMonthlyPlan });
            console.log(response.data)
            window.location.href = response.data.url;
        } catch (err) {
            console.log('error!')
        }
    }
    return (
        <div className='w-full'>
            <div>
                {contextHolder}
                <Modal className='p-0' width={700} open={isModalOpen} closable={false} footer={false} onCancel={handleCancel} >
                    <div className='w-full p-[20px] h-[75vh] overflow-scroll'>
                        <div className='text-right'>
                            <CloseOutlined onClick={handleCancel} />
                        </div>
                        <h3 className="text-2xl text-center font-normal mb-4">Upgrade to Pro</h3>
                        <div style={{ backgroundColor: "rgb(237 233 254)" }} className='rounded-[10px] bg-grey-500 p-[15px] flex items-center justify-center'>
                            <span className='font-bold'>
                                <ThunderboltOutlined className='font-bold' />
                            </span>
                            <p>&nbsp;Flash Sale Save 40% on a yearly subscription</p>
                        </div>
                        <div className='w-full flex pt-4'>
                            <div className='w-[43%] rounded-[10px] border p-[20px]' >
                                <Segmented
                                    centered='true'
                                    defaultValue="year"
                                    style={{
                                        marginBottom: 8,
                                        padding: '5px'
                                    }}
                                    onChange={(value) => {
                                        if (value == 'month') { setIsMonthlyPlan(true) } else {
                                            setIsMonthlyPlan(false)
                                        }
                                    }}
                                    options={[
                                        {
                                            label: (<h3 className='font-normal'>
                                                Yearly <span className='rounded-full p-1 bg-[#e7e1ff] text-[#5330db] text-[12px]'>Saved 40%</span>
                                            </h3>),
                                            value: 'year'
                                        },
                                        {
                                            label: (<h3 className='font-normal'>
                                                Monthly
                                            </h3>),
                                            value: 'month'
                                        }
                                    ]}
                                />
                                {
                                    !isMonthlyPlan ?
                                        <div>
                                            <div className='flex relative'>
                                                <p className='font-normal text-[30px]'>${pricePerMonth}</p><span className='relative ml-2 flex items-end pb-[10px] text-[14px]'>/mo</span>
                                            </div>
                                            <p className='text-[#5330db]'>Flash sale. Saving 40%</p>
                                        </div>
                                        :
                                        <div>
                                            <div className='flex'>
                                                <p className='font-normal text-[30px]'>${pricePerMonth}</p><span className='relative ml-2 flex items-end pb-[10px] text-[14px]'>/mo</span>
                                            </div>
                                            <p className=''>Billed monthly</p>
                                        </div>
                                }

                                {
                                    isProPlan ?
                                        <div>
                                            <Button className='upgradedEffect' type="primary" loading={paymentloadings} onClick={connectToStripe} style={{ backgroundColor: "black", width: "100%", marginTop: "15px", padding: 10, height: 'auto', fontSize: 16, fontWeight: 'normal' }}>
                                                {paymentloadings ? "" : "Upgrade to Pro"}
                                            </Button>
                                            <div className='flex mt-3'>
                                                <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                                                <p>Unlock full business plan</p>
                                            </div>
                                            <div className='flex mt-3'>
                                                <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                                                <p>Financials & budgeting</p>
                                            </div>
                                            <div className='flex mt-3'>
                                                <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                                                <p>PDF export</p>
                                            </div>
                                            <div className='flex mt-3'>
                                                <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                                                <p>AI business plan editor</p>
                                            </div>
                                            <div className='flex mt-3'>
                                                <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                                                <p>3 full business plans a month</p>
                                            </div>
                                            <div className='flex mt-3'>
                                                <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                                                <p>Chat AI consultant</p>
                                            </div>
                                            <div className='flex mt-3'>
                                                <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                                                <p>Priority email support</p>
                                            </div>
                                            <div className='flex mt-3'>
                                                <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                                                <p>Cancel anytime</p>
                                            </div></div>
                                        : <div>
                                            <Button type="primary" loading={paymentloadings} onClick={connectToStripe} style={{ backgroundColor: "black", width: "100%", marginTop: "15px" }}>
                                                {paymentloadings ? "" : "Upgrade to Agency"}
                                            </Button>
                                            <div className='flex mt-2'>
                                                <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                                                <p>20 full business plans a month</p>
                                            </div>
                                            <div className='flex mt-2'>
                                                <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                                                <p>Unlock full business plan</p>
                                            </div>
                                            <div className='flex mt-2'>
                                                <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                                                <p>Financials & budgeting</p>
                                            </div>
                                            <div className='flex mt-2'>
                                                <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                                                <p>PDF export</p>
                                            </div>
                                            <div className='flex mt-2'>
                                                <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                                                <p>AI business plan editor</p>
                                            </div>
                                            <div className='flex mt-2'>
                                                <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                                                <p>Chat AI consultant</p>
                                            </div>
                                            <div className='flex mt-2'>
                                                <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                                                <p>Priority email support</p>
                                            </div>
                                            <div className='flex mt-2'>
                                                <CheckOutlined style={{ color: "rgb(22 163 74)" }} /> &nbsp;
                                                <p>Cancel anytime</p>
                                            </div>
                                        </div>
                                }
                            </div>
                            <div className='w-[60%]'>
                                <div className='flex justify-center text-sm mt-5'>
                                    <div className='border-r-[1px] border-gray-300 pr-[20px]'>
                                        <Rate className='text-[14px] text-[#ffb204]' disabled={true} value={5} />
                                        <div className='text-center text-normal text-md'>Rated 4.9/5</div>
                                    </div>
                                    <div className='flex flex-col justify-center pl-4'>
                                        <div className='flex justify-center w-full'>
                                            <div className="inline-block w-2 h-2 mt-1 mr-2">
                                                <div className="w-2 h-2 bg-green-500 rounded-full mr-1 z-10"></div>
                                                {/* <div className="w-2 h-2 bg-green-400 rounded-full mr-1 animate-ping-slow"></div> */}
                                            </div>
                                            <div className='text-center text-md text-black font-light'>203 people</div>
                                        </div>
                                        <div className='w-full text-center text-md'>upgraded last week</div>
                                    </div>
                                </div>
                                <p className='p-[20px]'>
                                    "Embarking on the entrepreneurial journey with StartupSphere felt like I had an expert by my side. Their intuitive platform transformed my initial idea into a structured and actionable business plan in no time.<br /> I was able to hit the ground running, launching my business with confidence and clarity months ahead of schedule.<br /> The insights I gained have become the compass that guides my business decisions every step of the way."
                                </p>
                                <div className='p-[20px]'>
                                    <div className='flex items-center'>
                                        <div className='w-[32px] h-[32px] rounded-full'>
                                            <img src='https://www.venturekit.ai/jason.webp' className='w-full h-full rounded-full' />
                                        </div>
                                        <div className='ml-3'>
                                            <div className='font-normal'>Sophia Rodriguez</div>
                                            <div>Bloom & Grow Gardening Services</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* {
                        isProPlan ?
                            <div className='mt-2 mb-4 pl-8'>
                                Get more with the&nbsp;&nbsp;<a style={{ color: "rgb(22 163 74)" }} className='hover:underline' onClick={() => { setIsProPlan(!isProPlan) }}>Agency plan</a>
                            </div>
                            :
                            <div className='mt-2 mb-4 pl-8'>
                                Back to the&nbsp;&nbsp;<a style={{ color: "rgb(22 163 74)" }} className='hover:underline' onClick={() => { setIsProPlan(!isProPlan) }}>Pro plan</a>
                            </div>
                    } */}

                    </div>
                </Modal>
                <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Col xs={24} md={24} lg={24}>
                        <div style={{ width: '100%', height: '500px', backgroundColor: 'rgb(250 250 250)', borderRadius: '10px', border: '1px dashed rgb(229 229 229)', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ letterSpacing: '-.025em', fontWeight: 500, fontSize: '1.5rem', lineHeight: '2rem', color: 'black' }}>
                                Upgrade to Pro to Unlock
                            </div>
                            <div style={{ margin: '15px 0', fontSize: '16px' }}>
                                This section is only included in the full report.
                            </div>

                            <button onClick={showModal} className="bg-[#3367D6] text-white inline-flex items-center justify-center rounded-md text-[15px] font-medium h-10 px-10 ">
                                <CrownOutlined />
                                <div style={{ marginLeft: '10px' }}>Upgrade</div>
                            </button>
                        </div>
                    </Col>

                </Row>
            </div>
        </div>
    )
}

export default Upgrade