import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Col,
  Row,
  Switch
} from "antd";
import {
  TrophyOutlined,
  CodepenOutlined
} from "@ant-design/icons";

import Edit from "utils/edit";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { URL } from "utils/const";
import Admin from "utils/admin";
import *as formats from '../../utils/configure'
import { genEachContent } from "../../utils/service";
import { updateHtmlContent } from '../../Redux/features/UserSlice';
import { addContent } from "../../Redux/features/pagesSlice";
import { setRegenerate } from "../../Redux/features/UserSlice";
import Limit from "components/Limit";
import Generating from "components/Generating";
import Upgrade from "components/Upgrade";
import { addUser } from "../../Redux/features/UserSlice";
const CompetitorAnalysis = () => {

  const dispatch = useDispatch();

  const { email, curID, companies, role, prompt } = useSelector(state => state.userSlice)

  const user = useSelector(state => {
    return state.userSlice
  })

  const pageContents = useSelector(state => {
    return state.pagesSlice
  })

  const category = "Competitor Analysis"
  const page_title = user.companies[0].content.find((item) => item.title === "Market Research").list.find((item) => item.origin===category).shown

  const auth = role

  const [oldprompt, setOldPrompt] = useState([]);
  const [flag, setFlag] = useState(false);
  const [generateFlag, setGenerateFlag] = useState(false);
  const [description, setDescription] = useState('')
  const [contentOverview, setContentOverview] = useState('')
  const [subTitle1, setSubtitle1] = useState('')
  const [subOverview1, setSubOverview1] = useState('')
  const [advantages1, setAdvantages1] = useState('')
  const [challenges1, setChallenges1] = useState('')
  const [subTitle2, setSubtitle2] = useState('')
  const [subOverview2, setSubOverview2] = useState('')
  const [advantages2, setAdvantages2] = useState('')
  const [challenges2, setChallenges2] = useState('')
  const [renderData, setRenderData] = useState(null);
  const [paid, setPaid] = useState();


  useEffect(() => {
    setFlag(false);
    dispatch(setRegenerate(false))
    if (email == '')
      window.location.href = '/sign-in';
  }, []);

  /////////////////////////////////////////////////////////////////

  useEffect(() => {
    axios.post(URL + "/api/paid/get", { data: "Competitor Analysis" })
        .then(res => {
            console.log("res", res.data.prompt);
            if (res.data.prompt == 'paid')
                setPaid(true)
            else setPaid(false)
        })
        .catch(err => console.log("err", err))
}, [])

const handleChangeCheck = () => {
    if (paid == true)
        setPaid(false)
    else setPaid(true)

    axios.post(URL + "/api/paid/add", { data: "Competitor Analysis" })
    .then(res=>{
        console.log("paid has changed", res)
    })
    .catch(err=>console.log("failed to change the paid method", err))
}

  useEffect(() => {
    const currentPrompt = prompt.filter((item) => item.title == category)
    setOldPrompt(currentPrompt.prompt)
  }, [])

  const savePrompt = (newPrompt) => {
    console.log("saveNewPrompt", newPrompt)
  }

  const generateContent = (newPrompt) => {
    setFlag(true);
    setGenerateFlag(true)
    let formatData;
    Object.keys(formats).map(key => {
      if (category == formats[key].Title) {
        console.log(formats[key].Title, formats[key], formats[key])
        formatData = { title: formats[key].Title, data: formats[key] }
      }
    })

    genEachContent(user.companies[0], newPrompt, formatData.data, user.gpt.modelName)
      .then((res) => {
        if (Array.isArray(pageContents.contents)) {
          const newContents = pageContents.contents.map((item, idx) => {
            if (item.Title === category) {
              return res
            }
            return item;
          });
          let temp = newContents;
          dispatch(addContent({ content: temp }));
          dispatch(updateHtmlContent({ itemTitle: category, newhtmlContent: {} }));
          dispatch(setRegenerate(false))
          setFlag(false);
          axios.post(URL+"/api/users/result/save", { email: user.email, companyName: user.companies[0].companyName, content: newContents })
            .then(res => {
              console.log("---------------",res)
              dispatch(addUser({
                email: user.email,
                name: 'StartupSphere',
                companies: res.data,
                curID: user.curID,
                role: user.role,
                prompt: user.prompt,
                upgrade:user.upgrade,
                gpt:user.gpt
              }))
            }).catch(err => console.log(err))
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }


  //////////////////////////////////////////////////////////////////

  const handleChange = (html, clippedTxt, id) => {

    if (id == "contentOverview")
      setContentOverview(html)
    if (id == "subTitle1")
      setSubtitle1(html)
    if (id == "subOverview1")
      setSubOverview1(html)
    if (id == "advantages1")
      setAdvantages1(html)
    if (id == "challenges1")
      setChallenges1(html)
    if (id == "subTitle2")
      setSubtitle2(html)
    if (id == "subOverview2")
      setSubOverview2(html)
    if (id == "advantages2")
      setAdvantages2(html)
    if (id == "challenges2")
      setChallenges2(html)
    if (id == "description")
      setDescription(html)

  }

  useEffect(() => {
    if (contentOverview != "" || subTitle1 != "" || subOverview1 != "" || advantages1 != "" || challenges1 != "" || description != "" || subTitle2 != "" || subOverview2 != "" || advantages2 != "" || challenges2 != "") {
      const data = {
        contentOverview: contentOverview,
        subTitle1: subTitle1,
        subOverview1: subOverview1,
        advantages1: advantages1,
        challenges1: challenges1,
        subTitle2: subTitle2,
        subOverview2: subOverview2,
        advantages2: advantages2,
        challenges2: challenges2,
        description: description
      }
      // console.log("problem----",problem)
      const sendData = { email: user.email, company: user.companies[0].companyName, title: category, sendData: data }
      axios.post(URL+"/api/users/changeHtml", {
        data: sendData
      }).then(res => {
        console.log('updated and saved')
        // console.log(sendData)
        dispatch(updateHtmlContent({ itemTitle: category, newhtmlContent: data }));
      }).catch(err => console.log('saving updated content'))
    }
  }, [contentOverview, subTitle1, subOverview1, advantages1, challenges1, description, subTitle2, subOverview2, advantages2, challenges2]);


  useEffect(() => {
    let middleData = [];

    if (Array.isArray(pageContents.contents)) {
      middleData = pageContents.contents.filter((item) => {
        return item.Title === category;
      });
    } else if (Array.isArray(pageContents.contents.contents)) {
      middleData = pageContents.contents.contents.filter((item) => {
        return item.Title === category;
      });
    }
    setRenderData(middleData);
    console.log("middledata", middleData)
  }, [pageContents]);



  const chageHtml = (html, id) => {
    console.log("content overview")
    if (id == "contentOverview")
      setContentOverview(html)
    if (id == "subTitle1")
      setSubtitle1(html)
    if (id == "subOverview1")
      setSubOverview1(html)
    if (id == "advantages1")
      setAdvantages1(html)
    if (id == "challenges1")
      setChallenges1(html)
    if (id == "subTitle2")
      setSubtitle2(html)
    if (id == "subOverview2")
      setSubOverview2(html)
    if (id == "advantages2")
      setAdvantages2(html)
    if (id == "challenges2")
      setChallenges2(html)
    if (id == "description")
      setDescription(html)
  }

  useEffect(() => {
    if (user.regenerateFlag == true) {
      let currentPrompt = ''
      console.log("regnerating")
      user.prompt.map(item => {
        if (item.title == category) {
          currentPrompt = item.prompt
        }
      })
      generateContent(currentPrompt)
    } else {
      setFlag(false)
    }
  }, [user.regenerateFlag]);

  useEffect(() => {

    let htmlContent = null;
    companies[0].htmlContent.forEach((item) => {
      if (item.Title === category) {
        htmlContent = item.htmlcontent;
      }
    });
    if (htmlContent != null && !generateFlag) {
      setContentOverview(htmlContent.contentOverview)
      setSubtitle1(htmlContent.subTitle1)
      setSubOverview1(htmlContent.subOverview1)
      setAdvantages1(htmlContent.advantages1)
      setChallenges1(htmlContent.challenges1)
      setSubtitle2(htmlContent.subTitle2)
      setSubOverview2(htmlContent.subOverview2)
      setAdvantages2(htmlContent.advantages2)
      setChallenges2(htmlContent.challenges2)
      setDescription(htmlContent.description)
    } else if (renderData != null && renderData[0]) {
      setContentOverview('<h3>' + renderData[0]?.["Summary"] + '</h3>')
      setDescription(`<h2>${renderData[0]?.["Description"]?.["SubTitle"]}</h2><h3>${renderData[0]?.["Description"]?.["Description"]}</h3>`)

      if (renderData[0]?.["Content"]?.[0]) {
        setSubtitle1('<h4>' + renderData[0]?.["Content"]?.[0]?.["SubTitle"] + '</h4>')
        setSubOverview1('<h3>' + renderData[0]?.["Content"]?.[0]?.["Description"]?.["Overview"] + '</h3>')
        const rlt = renderData[0]?.["Content"]?.[0]?.["Description"]?.["How You Can Leverage"]
        if (rlt) {
          let length = rlt.length
          let result = '';
          for (let i = 0; i < length; i++) {
            result = result + `<ol><li>${rlt[i]?.["Description"]}</li></ol>`;
          }
          setAdvantages1(result);
        }
        const rlt1 = renderData[0]?.["Content"]?.[0]?.["Description"]?.["Key Points"]
        if (rlt1) {
          let length1 = rlt1.length
          let result1 = '';
          for (let i = 0; i < length1; i++) {
            result1 = result1 + `<ol><li>${rlt1[i]?.["Description"]}</li></ol>`;
          }
          setChallenges1(result1);
        }
      }
      if (renderData[0]?.["Content"]?.[1]) {

        setSubtitle2('<h4>' + renderData[0]?.["Content"]?.[1]?.["SubTitle"] + '</h4>')
        setSubOverview2('<h3>' + renderData[0]?.["Content"]?.[1]?.["Description"]?.["Overview"] + '</h3>')
        const rlt2 = renderData[0]?.["Content"]?.[1]?.["Description"]?.["How You Can Leverage"]
        if (rlt2) {
          const length2 = rlt2.length
          let result2 = '';
          for (let i = 0; i < length2; i++) {
            result2 = result2 + `<ol><li>${rlt2[i]?.["Description"]}</ol></li>`;
          }
          setAdvantages2(result2);
        }
        const rlt3 = renderData[0]?.["Content"]?.[1]?.["Description"]?.["Key Points"]
        if (rlt3) {
          const length3 = rlt3.length
          let result3 = '';
          for (let i = 0; i < length3; i++) {
            result3 = result3 + `<ol><li>${rlt3[i]?.["Description"]}</ol></li>`;
          }
          setChallenges2(result3);
        }
      }
    }

  }, [renderData])

  return (
    <div className="layout-content mx-auto max-w-4xl">
      {(user.upgrade == "unpaid" && auth != "admin") && <Col xs={24} md={24} lg={24}><Limit /></Col>}
      <Row className="p-6">
        <Col xs={24} md={24} lg={24}>
        <h6 class="text-md font-normal flex items-center leading-none capitalize w-full text-green-500">
              <div class="w-6 h-6 mr-2 justify-center flex rounded-sm text-green-500 bg-green-50"><div class="w-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-full"><path d="M3 3v18h18"></path><rect width="4" height="7" x="7" y="10" rx="1"></rect><rect width="4" height="12" x="15" y="5" rx="1"></rect></svg></div></div>
              <span class="text-base font-normal">Market Research</span>
            </h6>
        </Col>
        <Col
          xs={24}
          md={24}
          lg={24}
          className="flex items-center justify-between mt-4 mb-9"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div>
            {/* <h6 class="text-md font-normal flex items-center leading-none capitalize w-full text-green-500">
              <div class="w-6 h-6 mr-2 justify-center flex rounded-sm text-green-500 bg-green-50"><div class="w-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-full"><path d="M3 3v18h18"></path><rect width="4" height="7" x="7" y="10" rx="1"></rect><rect width="4" height="12" x="15" y="5" rx="1"></rect></svg></div></div>
              <span class="text-base font-normal">Market Research</span>
            </h6> */}
            <h3 class="text-2xl sm:text-3xl font-medium leading-none tracking-tight capitalize w-full">{page_title}</h3>
          </div>
          {auth=='admin'&&<Switch className="bg-[#3367D6]" checkedChildren="FREE" unCheckedChildren="PAID" checked={paid} onChange={handleChangeCheck} />}
        </Col>
        {
          auth == 'admin' &&
          <Admin currentPrompt={oldprompt} category={category} flag={flag} generateContent={generateContent} savePrompt={savePrompt}></Admin>
        }
        {((contentOverview != ''&&user.upgrade != "unpaid")||(contentOverview != ''&&auth == 'admin')||(contentOverview != ''&&paid)) &&
          <div>
            <Row>
              <Col xs={24} md={24} lg={24}>
                <div className="dashboard_title">
                  <div className="mt-4">
                    <Edit content={contentOverview} id={"contentOverview"} changeContent={handleChange} changeHtml={chageHtml} ></Edit>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-6 bg-[white] border rounded-md" style={{ width: "100%" }}>
              <div className="p-4 bg-[#FAFAFA]" style={{ width: "100%" }}>
                <Edit content={subTitle1} id={"subTitle1"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                <Edit content={subOverview1} id={"subOverview1"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
              </div>
              <Row className="p-4" style={{ width: "100%" }}>
                <Col xs={24} md={12} lg={12} className="pr-2">
                  <div className="dashboard_title">
                    <p className="text-xl font-semibold flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" x2="8" y1="13" y2="13"></line><line x1="16" x2="8" y1="17" y2="17"></line><line x1="10" x2="8" y1="9" y2="9"></line></svg></div>
                      Key Points
                    </p>
                    <Edit content={challenges1} id={"challenges1"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12} className="pl-2">
                  <div className="dashboard_title">
                    <p className="text-xl font-semibold flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><circle cx="12" cy="12" r="10"></circle><path d="M8 12h8"></path><path d="m12 16 4-4-4-4"></path></svg></div>
                      How You Can Leverage
                    </p>
                    <Edit content={advantages1} id={"advantages1"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
              </Row>
            </Row>

            <Row className="mt-6 bg-[white] border rounded-md" style={{ width: "100%" }}>
              <div className="p-4 bg-[#FAFAFA]" style={{ width: "100%" }}>
                <Edit content={subTitle2} id={"subTitle2"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                <Edit content={subOverview2} id={"subOverview2"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
              </div>
              <Row className="p-4" style={{ width: "100%" }}>
                <Col xs={24} md={12} lg={12} className="pr-2">
                  <div className="dashboard_title">
                    <p className="text-xl font-semibold flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" x2="8" y1="13" y2="13"></line><line x1="16" x2="8" y1="17" y2="17"></line><line x1="10" x2="8" y1="9" y2="9"></line></svg></div>
                      Key Points
                    </p>
                    <Edit content={challenges2} id={"challenges2"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12} className="pl-2">
                  <div className="dashboard_title">
                    <p className="text-xl font-semibold flex items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><circle cx="12" cy="12" r="10"></circle><path d="M8 12h8"></path><path d="m12 16 4-4-4-4"></path></svg></div>
                      How You Can Leverage
                    </p>
                    <Edit content={advantages2} id={"advantages2"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
              </Row>
            </Row>
            <Row className="bg-[white] w-full">
              <div className="dashboard_title">
                <Edit content={description} id={"description"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
              </div>
            </Row>
          </div>}
          {contentOverview == ''&&<Generating></Generating>}

        {/* <Row className="mt-8 bg-[white] border rounded-md" style={{ width: "100%" }}>
        <Row className="p-8 bg-[#FAFAFA]" style={{ width: "100%" }}>
          <div className="text-sm flex text-[#26B35A] p-[1px] pl-[4px] pr-[4px] bg-[#FFF7ED] rounded-md">
            Competitor
          </div>
          <div className="mt-4">
            <h2 className="text-xl font-semibold">VoiceCast</h2>
            <p className="text-lg mt-4">
              VoiceCast is an AI-powered podcast creation platform that enables users to create and share realistic conversations. They offer customizable voice profiles and generate AI-led dialogues for various topics.
            </p>
          </div>
        </Row>
        <Row className="p-8" style={{ width: "100%" }}>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl font-semibold flex items-center">
                <TrophyOutlined className="pr-2" />
                Key Points
              </p>
              <ul className="pl-4" style={{ listStyle: "number" }}>
                <li className="mt-3 text-md">
                  Increasing number of podcast listeners
                </li>
                <li className="mt-3 text-md">
                  Higher demand for niche content
                </li>
                <li className="mt-3 text-md">
                  Rising popularity of audio-based learning
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl font-semibold flex items-center">
                <CodepenOutlined className="pr-2" />
                How You Can Leverage
              </p>
              <ul className="pl-4" style={{ listStyle: "number" }}>
                <li className="mt-3 text-md">
                  Designing and curating walking routes that
                  showcase the historical and cultural highlights of
                  the city
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                  ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                  equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Row>
      <Row className="mt-8 bg-[white] border rounded-md" style={{ width: "100%" }}>
        <Row className="p-8 bg-[#FAFAFA]" style={{ width: "100%" }}>
          <div className="text-sm flex text-[#26B35A] p-[1px] pl-[4px] pr-[4px] bg-[#FFF7ED] rounded-md">
            Competitor
          </div>
          <div className="mt-4">
            <h2 className="text-xl font-semibold">Podify</h2>
            <p className="text-lg mt-4">
              Podify is a podcast creation platform that aims to simplify the process of creating and publishing podcasts. They offer an intuitive user interface, transcript generation, and integration with popular podcast platforms.
            </p>
          </div>
        </Row>
        <Row className="p-8" style={{ width: "100%" }}>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl font-semibold flex items-center">
                <TrophyOutlined className="pr-2" />
                Key Points
              </p>
              <ul className="pl-4" style={{ listStyle: "number" }}>
                <li className="mt-3 text-md">
                  Increasing number of podcast listeners
                </li>
                <li className="mt-3 text-md">
                  Higher demand for niche content
                </li>
                <li className="mt-3 text-md">
                  Rising popularity of audio-based learning
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={24} md={12} lg={12}>
            <div className="dashboard_title">
              <p className="text-xl font-semibold flex items-center">
                <CodepenOutlined className="pr-2" />
                How You Can Leverage
              </p>
              <ul className="pl-4" style={{ listStyle: "number" }}>
                <li className="mt-3 text-md">
                  Designing and curating walking routes that
                  showcase the historical and cultural highlights of
                  the city
                </li>
                <li className="mt-3 text-md">
                  Coordinating with local guides and experts to
                  ensure accurate and interesting storytelling
                </li>
                <li className="mt-3 text-md">
                  Organizing logistics, including transportation,
                  equipment, and permits
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Row> */}

      </Row>
      {
        auth != "admin" && user.upgrade == "unpaid" &&!paid &&<Upgrade></Upgrade>
      }

    </div>
  )
}

export default CompetitorAnalysis