// const defaultInitializer = (index) => index;

// export function createRange(
//   length: number,
//   initializer: (index: number) => any = defaultInitializer
// ): T[] {
//   return [...new Array(length)].map((_, index) => initializer(index));
// }
const defaultInitializer = (index) => index;

export function createRange(length, initializer = defaultInitializer) {
  return [...new Array(length)].map((_, index) => initializer(index));
}