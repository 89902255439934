import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Switch
} from "antd";

import Edit from "utils/edit";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { URL } from "utils/const";
import Admin from "utils/admin";
import *as formats from '../../utils/configure'
import { genEachContent } from "../../utils/service";
import { updateHtmlContent } from '../../Redux/features/UserSlice';
import { addContent } from "../../Redux/features/pagesSlice";
import { setRegenerate } from "../../Redux/features/UserSlice";
import Limit from "components/Limit";
import Generating from "components/Generating";
import { addUser } from "../../Redux/features/UserSlice";
import Upgrade from "components/Upgrade";


export default function Business() {

  const dispatch = useDispatch();

  const { email, companies, role, prompt } = useSelector(state => state.userSlice)

  const user = useSelector(state => {
    return state.userSlice
  })

  const pageContents = useSelector(state => {
    return state.pagesSlice
  })

  const category = "Business Models"
  const page_title = user.companies[0].content.find((item) => item.title === "Overview").list.find((item) => item.origin === category).shown
  const auth = role

  const [oldprompt, setOldPrompt] = useState([]);
  const [flag, setFlag] = useState(false);
  const [generateFlag, setGenerateFlag] = useState(false);
  const [contentOverview, setContentOverview] = useState('')
  const [subTitle1, setSubtitle1] = useState('')
  const [subOverview1, setSubOverview1] = useState('')
  const [advantages1, setAdvantages1] = useState('')
  const [challenges1, setChallenges1] = useState('')
  const [subTitle2, setSubtitle2] = useState('')
  const [subOverview2, setSubOverview2] = useState('')
  const [advantages2, setAdvantages2] = useState('')
  const [challenges2, setChallenges2] = useState('')
  const [renderData, setRenderData] = useState(null);
  const [paid, setPaid] = useState();


  useEffect(() => {
    setFlag(false);
    dispatch(setRegenerate(false))
    if (email == '')
      window.location.href = '/sign-in';
  }, []);

  useEffect(() => {
    axios.post(URL + "/api/paid/get", { data: "Business Models" })
      .then(res => {
        console.log("res", res.data.prompt);
        if (res.data.prompt == 'paid')
          setPaid(true)
        else setPaid(false)
      })
      .catch(err => console.log("err", err))
  }, [])

  const handleChangeCheck = () => {
    if (paid == true)
      setPaid(false)
    else setPaid(true)

    axios.post(URL + "/api/paid/add", { data: "Business Models" })
      .then(res => {
        console.log("paid has changed", res)
      })
      .catch(err => console.log("failed to change the paid method", err))
  }


  useEffect(() => {
    const currentPrompt = prompt.filter((item) => item.title == category)
    setOldPrompt(currentPrompt.prompt)
  }, [])

  const savePrompt = (newPrompt) => {
    console.log("saveNewPrompt", newPrompt)
  }

  const generateContent = (newPrompt) => {
    setFlag(true);
    setGenerateFlag(true)
    let formatData;
    Object.keys(formats).map(key => {
      if (category == formats[key].Title) {
        console.log(formats[key].Title, formats[key], formats[key])
        formatData = { title: formats[key].Title, data: formats[key] }
      }
    })


    genEachContent(user.companies[0], newPrompt, formatData.data, user.gpt.modelName)
      .then((res) => {
        console.log(res)
        if (Array.isArray(pageContents.contents)) {
          const newContents = pageContents.contents.map((item, idx) => {
            if (item.Title === category) {
              return res
            }
            return item;
          });
          let temp = newContents;
          dispatch(addContent({ content: temp }));
          dispatch(updateHtmlContent({ itemTitle: category, newhtmlContent: {} }));
          dispatch(setRegenerate(false))
          setFlag(false);
          axios.post(URL + "/api/users/result/save", { email: user.email, companyName: user.companies[0].companyName, content: newContents })
            .then(res => {
              console.log("---------------", res)
              dispatch(addUser({
                email: user.email,
                name: 'StartupSphere',
                companies: res.data,
                curID: user.curID,
                role: user.role,
                prompt: user.prompt,
                upgrade: user.upgrade,
                gpt: user.gpt
              }))
            }).catch(err => console.log(err))
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }


  const handleChange = (html, clippedTxt, id) => {
    console.log(html, id)
    if (id == "contentOverview")
      setContentOverview(html)
    if (id == "subTitle1")
      setSubtitle1(html)
    if (id == "subOverview1")
      setSubOverview1(html)
    if (id == "advantages1")
      setAdvantages1(html)
    if (id == "challenges1")
      setChallenges1(html)
    if (id == "subTitle2")
      setSubtitle2(html)
    if (id == "subOverview2")
      setSubOverview2(html)
    if (id == "advantages2")
      setAdvantages2(html)
    if (id == "challenges2")
      setChallenges2(html)

    // const data = {
    //   contentOverview: contentOverview,
    //   subTitle1: subTitle1,
    //   subOverview1: subOverview1,
    //   advantages1: advantages1,
    //   challenges1: challenges1,
    //   subTitle2: subTitle2,
    //   subOverview2: subOverview2,
    //   advantages2: advantages2,
    //   challenges2: challenges2
    // }

    // dispatch(updateHtmlContent({ itemTitle: category, newhtmlContent: data }));
    // const sendData = { email: user.email, company: user.companies[0].companyName, title: category, sendData: data }
    // axios.post("//users/changeHtml", {
    //   data: sendData
    // }).then(res => {
    //   console.log('updated and saved')
    // }).catch(err => console.log('saving updated content'))
  }


  useEffect(() => {
    let middleData = [];

    if (Array.isArray(pageContents.contents)) {
      middleData = pageContents.contents.filter((item) => {
        return item.Title === category;
      });
    } else if (Array.isArray(pageContents.contents.contents)) {
      middleData = pageContents.contents.contents.filter((item) => {
        return item.Title === category;
      });
    }
    setRenderData(middleData);
    console.log("middledata", middleData)
  }, [pageContents]);



  const chageHtml = (html, id) => {
    console.log("content overview")
    if (id == "contentOverview")
      setContentOverview(html)
    if (id == "subTitle1")
      setSubtitle1(html)
    if (id == "subOverview1")
      setSubOverview1(html)
    if (id == "advantages1")
      setAdvantages1(html)
    if (id == "challenges1")
      setChallenges1(html)
    if (id == "subTitle2")
      setSubtitle2(html)
    if (id == "subOverview2")
      setSubOverview2(html)
    if (id == "advantages2")
      setAdvantages2(html)
    if (id == "challenges2")
      setChallenges2(html)
  }


  useEffect(() => {
    if (contentOverview != '' || subTitle1 != '' || subOverview1 != "" || advantages1 != "" || challenges1 != "" || subTitle2 != "" || subOverview2 != "" || advantages2 != "" || challenges2 != "") {
      const data = {
        contentOverview: contentOverview,
        subTitle1: subTitle1,
        subOverview1: subOverview1,
        advantages1: advantages1,
        challenges1: challenges1,
        subTitle2: subTitle2,
        subOverview2: subOverview2,
        advantages2: advantages2,
        challenges2: challenges2
      }
      // console.log("problem----",problem)
      const sendData = { email: user.email, company: user.companies[0].companyName, title: category, sendData: data }
      axios.post("/users/changeHtml", {
        data: sendData
      }).then(res => {
        console.log('updated and saved')
        dispatch(updateHtmlContent({ itemTitle: category, newhtmlContent: data }));
      }).catch(err => console.log('saving updated content'))
    }
  }, [contentOverview, subTitle1, subOverview1, advantages1, challenges1, subTitle2, subOverview2, advantages2, challenges2]);


  useEffect(() => {
    if (user.regenerateFlag == true) {
      let currentPrompt = ''
      console.log("regnerating")
      user.prompt.map(item => {
        if (item.title == category) {
          currentPrompt = item.prompt
        }
      })
      generateContent(currentPrompt)
    } else {
      setFlag(false)
    }
  }, [user.regenerateFlag]);

  useEffect(() => {

    let htmlContent = null;
    companies[0].htmlContent.forEach((item) => {
      if (item.Title === category) {
        htmlContent = item.htmlcontent;
      }
    });
    if (htmlContent != null && !generateFlag) {
      setContentOverview(htmlContent.contentOverview)
      setSubtitle1(htmlContent.subTitle1)
      setSubOverview1(htmlContent.subOverview1)
      setAdvantages1(htmlContent.advantages1)
      setChallenges1(htmlContent.challenges1)
      setSubtitle2(htmlContent.subTitle2)
      setSubOverview2(htmlContent.subOverview2)
      setAdvantages2(htmlContent.advantages2)
      setChallenges2(htmlContent.challenges2)
    } else if (renderData != null && renderData[0]) {
      setContentOverview('<h3>' + renderData[0]?.["Summary"] + '</h3>')
      setSubtitle1('<h3 style="color:"red"">' + renderData[0]?.["Content"]?.[0]?.["SubTitle"] + '</h3>')
      setSubOverview1('<h2>' + renderData[0]?.["Content"]?.[0]?.["Description"]?.["Overview"] + '</h2>')
      setSubtitle1('<h4>' + renderData[0]?.["Content"]?.[0]?.["SubTitle"] + '</h4>')
      setSubOverview1('<h3>' + renderData[0]?.["Content"]?.[0]?.["Description"]?.["Overview"] + '</h3>')

      const rlt = renderData[0]?.["Content"]?.[0]?.["Description"]?.["Advantages"]
      if (rlt) {
        let length = rlt.length
        let result = '';
        for (let i = 0; i < length; i++) {
          result = result + `<ol><li>${rlt[i]?.["Description"]}</li></ol>`;
        }
        setAdvantages1(result);
      }

      const rlt1 = renderData[0]?.["Content"]?.[0]?.["Description"]?.["Challenges"]
      if (rlt1) {
        let length1 = rlt1.length
        let result1 = '';
        for (let i = 0; i < length1; i++) {
          result1 = result1 + `<ol><li>${rlt1[i]?.["Description"]}</li></ol>`;
        }
        setChallenges1(result1);
      }

      if (renderData[0]?.["Content"]?.[1]) {
        setSubtitle2('<h3>' + renderData[0]?.["Content"]?.[1]?.["SubTitle"] + '</h3>')
        setSubOverview2('<h2>' + renderData[0]?.["Content"]?.[1]?.["Description"]?.["Overview"] + '</h2>')
        setSubtitle2('<h4>' + renderData[0]?.["Content"]?.[1]?.["SubTitle"] + '</h4>')
        setSubOverview2('<h3>' + renderData[0]?.["Content"]?.[1]?.["Description"]?.["Overview"] + '</h3>')

        const rlt2 = renderData[0]?.["Content"]?.[1]?.["Description"]?.["Advantages"]
        if (rlt2) {
          let length2 = rlt2.length
          let result2 = '';
          for (let i = 0; i < length2; i++) {
            result2 = result2 + `<ol><li>${rlt2[i]?.["Description"]}</li></ol>`;
          }
          setAdvantages2(result2);
        }

        const rlt3 = renderData[0]?.["Content"]?.[1]?.["Description"]?.["Challenges"]
        if (rlt3) {
          let length3 = rlt3.length
          let result3 = '';
          for (let i = 0; i < length3; i++) {
            result3 = result3 + `<ol><li>${rlt3[i]?.["Description"]}</li></ol>`;
          }
          setChallenges2(result3);
        }
      }
    }

  }, [renderData])


  return (
    <div className="layout-content mx-auto max-w-4xl">
      {(user.upgrade == "unpaid" && auth != "admin") && <Col xs={24} md={24} lg={24}><Limit /></Col>}
      <Row className="p-6">
        <Col
          xs={24}
          md={24}
          lg={24}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Col xs={24} md={24} lg={24} className="flex items-center">
            <div class="w-6 h-6 mr-2 justify-center flex rounded-sm text-green-500 bg-green-50"><div class="w-4"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-full"><path d="m2 7 4.41-4.41A2 2 0 0 1 7.83 2h8.34a2 2 0 0 1 1.42.59L22 7"></path><path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path><path d="M15 22v-4a2 2 0 0 0-2-2h-2a2 2 0 0 0-2 2v4"></path><path d="M2 7h20"></path><path d="M22 7v3a2 2 0 0 1-2 2v0a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 16 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 12 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 8 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 4 12v0a2 2 0 0 1-2-2V7"></path></svg></div></div>
            <div className="text-[#26B35A] text-lg">Overview</div>
          </Col>
        </Col>
        <Col xs={24} md={24} lg={24} className="flex items-center justify-between mt-4 mb-9">
          <div className="dashboard_title">
            <h1 className="text-3xl text-[#141414] font-[500]">{page_title}</h1>
          </div>
          {auth == 'admin' && <Switch className="bg-[#3367D6]" checkedChildren="FREE" unCheckedChildren="PAID" checked={paid} onChange={handleChangeCheck} />}
        </Col>

        {
          auth == 'admin' &&
          <Admin currentPrompt={oldprompt} category={category} flag={flag} generateContent={generateContent} savePrompt={savePrompt}></Admin>
        }
        {((contentOverview != '' && user.upgrade != "unpaid") || (contentOverview != '' && auth == 'admin') || (contentOverview != '' && paid)) &&
          <div>
            <Row>
              <Col xs={24} md={24} lg={24} >
                <div className="dashboard_title mt-4">
                  {/* <h1 className="text-3xl text-[#141414] font-[500]">Business Models</h1> */}
                  <div className="mt-8">
                    <Edit content={contentOverview} id={"contentOverview"} changeContent={handleChange} changeHtml={chageHtml} ></Edit>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-6 bg-[white] border rounded-md" style={{ width: "100%" }}>
              <Row className="p-4 border-b" style={{ width: "100%" }}>
                <div>
                  <h2 className="text-xl font-semibold">
                    <Edit content={subTitle1} id={"subTitle1"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </h2>
                  <p className="text-lg">
                    <Edit content={subOverview1} id={"subOverview1"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </p>
                </div>
              </Row>
              <Row className="p-4" style={{ width: "100%" }}>
                <Col xs={24} md={12} lg={12} className="pr-2">
                  <div className="dashboard_title">
                    <p className="text-lg font-bold flex mb-1 items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><path d="M6 9H4.5a2.5 2.5 0 0 1 0-5H6"></path><path d="M18 9h1.5a2.5 2.5 0 0 0 0-5H18"></path><path d="M4 22h16"></path><path d="M10 14.66V17c0 .55-.47.98-.97 1.21C7.85 18.75 7 20.24 7 22"></path><path d="M14 14.66V17c0 .55.47.98.97 1.21C16.15 18.75 17 20.24 17 22"></path><path d="M18 2H6v7a6 6 0 0 0 12 0V2Z"></path></svg></div>
                      Advantages
                    </p>
                    <Edit content={advantages1} id={"advantages1"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12} className="pr-2">
                  <div className="dashboard_title">
                    <p className="text-lg font-bold flex mb-1 items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><polyline points="14.5 17.5 3 6 3 3 6 3 17.5 14.5"></polyline><line x1="13" x2="19" y1="19" y2="13"></line><line x1="16" x2="20" y1="16" y2="20"></line><line x1="19" x2="21" y1="21" y2="19"></line><polyline points="14.5 6.5 18 3 21 3 21 6 17.5 9.5"></polyline><line x1="5" x2="9" y1="14" y2="18"></line><line x1="7" x2="4" y1="17" y2="20"></line><line x1="3" x2="5" y1="19" y2="21"></line></svg></div>
                      Challenges
                    </p>
                    <Edit content={challenges1} id={"challenges1"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
              </Row>
            </Row>

            <Row className="mt-6 bg-[white] border rounded-md" style={{ width: "100%" }}>
              <Row className="p-4 border-b" style={{ width: "100%" }}>
                <div>
                  <h2 className="text-xl font-semibold">
                    <Edit content={subTitle2} id={"subTitle2"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </h2>
                  <p className="text-lg">
                    <Edit content={subOverview2} id={"subOverview2"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </p>
                </div>
              </Row>
              <Row className="p-4" style={{ width: "100%" }}>
                <Col xs={24} md={12} lg={12} className="pr-2">
                  <div className="dashboard_title">
                    <p className="text-lg font-bold flex mb-1 items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><path d="M6 9H4.5a2.5 2.5 0 0 1 0-5H6"></path><path d="M18 9h1.5a2.5 2.5 0 0 0 0-5H18"></path><path d="M4 22h16"></path><path d="M10 14.66V17c0 .55-.47.98-.97 1.21C7.85 18.75 7 20.24 7 22"></path><path d="M14 14.66V17c0 .55.47.98.97 1.21C16.15 18.75 17 20.24 17 22"></path><path d="M18 2H6v7a6 6 0 0 0 12 0V2Z"></path></svg></div>
                      Advantages
                    </p>
                    <Edit content={advantages2} id={"advantages2"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={12} className="pr-2">
                  <div className="dashboard_title">
                    <p className="text-lg font-bold flex mb-1 items-center">
                      <div class="bg-blue-50 text-blue-500 w-6 h-6 flex items-center justify-center rounded mr-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 shrink-0"><polyline points="14.5 17.5 3 6 3 3 6 3 17.5 14.5"></polyline><line x1="13" x2="19" y1="19" y2="13"></line><line x1="16" x2="20" y1="16" y2="20"></line><line x1="19" x2="21" y1="21" y2="19"></line><polyline points="14.5 6.5 18 3 21 3 21 6 17.5 9.5"></polyline><line x1="5" x2="9" y1="14" y2="18"></line><line x1="7" x2="4" y1="17" y2="20"></line><line x1="3" x2="5" y1="19" y2="21"></line></svg></div>
                      Challenges
                    </p>
                    <Edit content={challenges2} id={"challenges2"} changeContent={handleChange} changeHtml={chageHtml}></Edit>
                  </div>
                </Col>
              </Row>
            </Row>
          </div>}
        {contentOverview == '' && <Generating></Generating>}
        {/* <Row
          className="mt-8 bg-[#FAFAFA] rounded-md flex "
          style={{
            width: "100%",
            height: "60vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <p className="text-center text-2xl mb-1 font-semibold">Upgrade to Pro to Unlock</p>
            <p className="mb-1 text-lg">
              This section is only included in the full report.
            </p>
            <Button
              style={{
                width: "50%",
                backgroundColor: "#3367D6",
                color: "white",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1rem",
              }}
            >
              <TransactionOutlined style={{ margin: "0", padding: "0" }} />
              Upgrade
            </Button>
          </div>
        </Row> */}
        {
          auth != "admin" && user.upgrade == "unpaid" && !paid && <Upgrade></Upgrade>
        }
      </Row>


    </div>
  );
}
