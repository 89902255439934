import { NavLink } from "react-router-dom";
import { useState,useEffect} from "react";
import { Menu } from "antd";
import {
  EditOutlined,
  MessageOutlined,
  ContainerOutlined,
  HomeOutlined,
  ShopOutlined,
  CodepenOutlined,
  BarChartOutlined,
  RiseOutlined,
  ControlOutlined,
  DollarOutlined,
  CheckCircleOutlined,
  EllipsisOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";

import { profileSVg, signInSvg, signUpSvg } from "./SignInUpData";
import DragDrop from "components/DragDrop";
import DrawerSidebar from "./DrawerSidebar";
import axios from "axios";
import { URL } from "./const";

const menu = {
  "Home": {
    "url": "/dashboard",
    "icon": <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-home"><path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
  },
  // "Chat": {
  //   "url": "/chat",
  //   "icon": <MessageOutlined />
  // },
  // "Create Content": {
  //   "url": "/createContent",
  //   "icon": <EditOutlined />
  // },
  "Your Content": {
    "url": "/content",
    "icon": <ContainerOutlined />
  },
  "Overview": {
    "url": "/dashboard",
    "icon": <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-full"><path d="m2 7 4.41-4.41A2 2 0 0 1 7.83 2h8.34a2 2 0 0 1 1.42.59L22 7"></path><path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path><path d="M15 22v-4a2 2 0 0 0-2-2h-2a2 2 0 0 0-2 2v4"></path><path d="M2 7h20"></path><path d="M22 7v3a2 2 0 0 1-2 2v0a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 16 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 12 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 8 12a2.7 2.7 0 0 1-1.59-.63.7.7 0 0 0-.82 0A2.7 2.7 0 0 1 4 12v0a2 2 0 0 1-2-2V7"></path></svg>
  },
  "Mission and Vision": {
    "url": "/overview/mission-vision",
  },
  "Viability analysis": {
    "url": "/overview/viability-analysis",
  },
  "Business Models": {
    "url": "/overview/business-models",
  },
  "Swot Analysis": {
    "url": "/overview/swot-analysis",
  },
  "Products & Services": {
    "url": "/dashboard",
    "icon": <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-full"><path d="m7.5 4.27 9 5.15"></path><path d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z"></path><path d="m3.3 7 8.7 5 8.7-5"></path><path d="M12 22V12"></path></svg>
  },
  "Core Offerings": {
    "url": "/products-services/core-offering",

  },
  "Secondary Offering": {
    "url": "/products-services/secondary-offering",
  },
  "Expansion Opportunities": {
    "url": "/products-services/expansion-opportunities",
    "icon": <HomeOutlined />
  },
  "Customer Service": {
    "url": "/products-services/customer-service",
    "icon": <HomeOutlined />
  },
  "Market Research": {
    "url": "/products-services/customer-service",
    "icon": <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-full"><path d="M3 3v18h18"></path><rect width="4" height="7" x="7" y="10" rx="1"></rect><rect width="4" height="12" x="15" y="5" rx="1"></rect></svg>
  },
  "Industry Overview": {
    "url": "/market-research/industry-overview",
    "icon": <BarChartOutlined />
  },
  "Target Audience": {
    "url": "/market-research/target-audience",
    "icon": <BarChartOutlined />
  },
  "Market size and Trends": {
    "url": "/market-research/market-size",
    "icon": <BarChartOutlined />
  },
  "Competitor Analysis": {
    "url": "/market-research/competitor-analysis",
    "icon": <BarChartOutlined />
  },
  "Sales and Marketing": {
    "url": "/products-services/customer-service",
    "icon": <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-full"><polyline points="22 7 13.5 15.5 8.5 10.5 2 17"></polyline><polyline points="16 7 22 7 22 13"></polyline></svg>
  },
  "Branding & Identity": {
    "url": "/sales-marketing/branding-identity",
    "icon": <BarChartOutlined />
  },
  "Online Presence": {
    "url": "/sales-marketing/online-presence",
    "icon": <BarChartOutlined />
  },
  "Marketing Ideas": {
    "url": "/sales-marketing/marketing-ideas",
    "icon": <BarChartOutlined />
  },
  "Customer Retention": {
    "url": "/sales-marketing/customer-retention",
    "icon": <BarChartOutlined />
  },
  "Operations": {
    "url": "/sales-marketing/branding-identity",
    "icon": <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-full"><rect width="18" height="18" x="3" y="3" rx="2"></rect><path d="M8 7v7"></path><path d="M12 7v4"></path><path d="M16 7v9"></path></svg>
  },
  "Operations Plan": {
    "url": "/operations/plan",
    "icon": <BarChartOutlined />
  },
  "Team & Roles": {
    "url": "/operations/team-roles",
    "icon": <BarChartOutlined />
  },
  "Risk Analysis": {
    "url": "/operations/risk-analysis",
    "icon": <BarChartOutlined />
  },
  "Regulatory Compliance": {
    "url": "/operations/regulatory-compliance",
    "icon": <BarChartOutlined />
  },
  "Financials": {
    "url": "/operations/plan",
    "icon": <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-full"><line x1="12" x2="12" y1="2" y2="22"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
  },
  "Revenue": {
    "url": "/financials/revenue",
  },
  "Expenses": {
    "url": "/financials/expenses"
  },
  "Financing": {
    "url": "/financials/financing",
  },
  "Dividends": {
    "url": "/financials/dividends",
  },
  "Taxes": {
    "url": "/financials/taxes",
  },
  "Profit & Loss": {
    "url": "/financials/profit-loss",
  },
  "Balance Sheet": {
    "url": "/financials/balance-sheet",
  },
  "Implementations Plan": {
    "url": "/financials/revenue",
    "icon": <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-full"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
  },

  "Pre-Launch": {
    "url": "/implementation-plan/pre-launch",
    "icon": <CheckCircleOutlined />
  },
  "Post-Launch": {
    "url": "/implementation-plan/post-launch",
    "icon": <DollarOutlined />
  },
  "5 Year Plan": {
    "url": "/implementation-plan/five-year-plan",
    "icon": <CheckCircleOutlined />
  },
  
  "Costs Breakdown": {
    "url": "/financials/costs-breakdown",
    "icon": <CheckCircleOutlined />
  },
  "Funding Plan": {
    "url": "/financials/funding-plan",
    "icon": <CheckCircleOutlined />
  },

}


export const dashboard = (color) => [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
      fill={color}
    ></path>
    <path
      d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
      fill={color}
    ></path>
    <path
      d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
      fill={color}
    ></path>
  </svg>,
];

const getItem = (label, path, icon, pathName, color) => {

  return (
    <NavLink to={path}>
      <div className="flex items-center">
      <span
        className="icon"
      >
        {/* <EditOutlined /> */}
        {icon}
        {/* {icon!=null?icon: <EditOutlined />} */}
      </span>
      <span className="label">{label}</span>
      </div>
    </NavLink>
  );
};
const getParentItem = (label, icon) => {
  return (
    <div className="flex items-center">
      <span className="icon ml-6 sm:ml-0">{icon!=null?icon: <EditOutlined />}</span>
      <span className="label">{label}</span>
    </div>
  );
};
const getTitle = (label) => {
  return (
    <div className="mt-4">
      <span className="label ml-6 text-xl font-semibold">{label}</span>
    </div>
  );
};

const estimateFlag = (label, items) => {
  console.log(label, "     ^^^^^^^     ", items)
  for( const item of items){
    if(item.title === label && item.prompt ==="unpaid") return true;
  }

  return false;
}

const getChildItem = (label, path, user, role, flag) => {
  // console.log("label path user role flag", label, path, user, role, flag)

  return (
    <NavLink to={path}>
      <span className="label ml-2">{label}</span>
      {
        role!="admin"&&user=="unpaid"&&flag&&(
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 h-4 text-[#FFAE00] ml-auto mr-1"><path d="m2 4 3 12h14l3-12-6 7-4-7-4 7-6-7zm3 16h14"></path></svg>
  )
      }
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 h-4 text-[#FFAE00] ml-auto mr-1"><path d="m2 4 3 12h14l3-12-6 7-4-7-4 7-6-7zm3 16h14"></path></svg> */}
    </NavLink>
  );
}

export const items = (pathname, color, showNavbar) => [
  {
    key: "1",
    label: getItem("Home", "/dashboard", <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-home"><path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>, pathname, color),
  },
  {
    key: "2",
    label: getItem("Chat", "/chat", <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>, pathname, color),
  },
  {
    key: "3",
    label: getItem(
      "Create Content",
      "/createContent",
      <EditOutlined />,
      pathname,
      color
    ),
  },
  {
    key: "4",
    label: getItem(
      "Your Content",
      "/content",
      <ContainerOutlined />,
      pathname,
      color
    ),
  },
  {
    // key:'6',
    label: <DragDrop title="Business Plan" />
  },

  {
    key: "5",
    label: getParentItem("Overview", <ShopOutlined />),
    children: [
      {
        key: "5-1",
        label: getChildItem("Mission & Vision", "/overview/mission-vision"),
      },
      {
        key: "5-2",
        label: getChildItem("Viability Analysis", "/overview/viability-analysis"),
      },
      {
        key: "5-3",
        label: getChildItem("Business Models", "/overview/business-models"),
      },
      {
        key: "5-4",
        label: getChildItem("Swot Analysis", "/overview/swot-analysis"),
      },
    ],
  },
  {
    key: "6",
    label: getParentItem("Products & Services", <CodepenOutlined />),
    children: [
      {
        key: "6-1",
        label: getChildItem("Core Offerings", "products-services/core-offering"),
      },
      {
        key: "6-2",
        label: getChildItem("Secondary Offering", "products-services/secondary-offering"),
      },
      {
        key: "6-3",
        label: getChildItem(
          "Expansion Opportunities",
          "products-services/expansion-opportunities"
        ),
      },
      {
        key: "6-4",
        label: getChildItem("Customer Service", "products-services/customer-service"),
      },
    ],
  },
  {
    key: "7",
    label: getParentItem("Market Research", <BarChartOutlined />),
    children: [
      {
        key: "7-1",
        label: getChildItem("Industry Overview", "market-research/industry-overview"),
      },
      {
        key: "7-2",
        label: getChildItem("Target Audience", "market-research/target-audience"),
      },
      {
        key: "7-3",
        label: getChildItem("Market Size & Trends", "market-research/market-size"),
      },
      {
        key: "7-4",
        label: getChildItem("Competitor Analysis", "market-research/competitor-analysis"),
      },
    ],
  },
  {
    key: "8",
    label: getParentItem("Sales & Marketing", <RiseOutlined />),
    children: [
      {
        key: "8-1",
        label: getChildItem("Brading & Identity", "sales-marketing/branding-identity"),
      },
      {
        key: "8-2",
        label: getChildItem("Online Presence", "sales-marketing/online-presence"),
      },
      {
        key: "8-3",
        label: getChildItem("Marketing Ideas", "sales-marketing/marketing-ideas"),
      },
      {
        key: "8-4",
        label: getChildItem("Customer Retention", "sales-marketing/customer-retention"),
      },
    ],
  },
  {
    key: "9",
    label: getParentItem("Operations", <ControlOutlined />),
    children: [
      {
        key: "9-1",
        label: getChildItem("Operations Plan", "/operations/plan"),
      },
      {
        key: "9-2",
        label: getChildItem("Team & Roles", "/operations/team-roles"),
      },
      {
        key: "9-3",
        label: getChildItem("Risk Analysis", "/operations/risk-analysis"),
      },
      {
        key: "9-4",
        label: getChildItem(
          "Regulatory Compliance",
          "/operations/regulatory-compliance"
        ),
      },
    ],
  },
  {
    key: "10",
    label: getParentItem("Financials", <DollarOutlined />),
    children: [
      {
        key: "10-1",
        label: getChildItem("Revenue", "/financials/revenue"),
      },
      {
        key: "10-2",
        label: getChildItem("Expenses", "/financials/expenses"),
      },
      {
        key: "10-3",
        label: getChildItem("Financing", "/financials/financing"),
      },
      {
        key: "10-4",
        label: getChildItem("Balance Sheet", "/financials/balance-sheet"),
      },
      {
        key: "10-5",
        label: getChildItem("Taxes", "/financials/taxes"),
      },
      {
        key: "10-6",
        label: getChildItem("Profit & Loss", "/financials/profit-loss"),
      },
      {
        key: "10-7",
        label: getChildItem("Balance Sheet", "/financials/balance-sheet"),
      },
    ],
  },
  {
    key: "11",
    label: getParentItem("Implementation Plan", <CheckCircleOutlined />),
    children: [
      {
        key: "11-1",
        label: getChildItem("Pre-Launch", "/implementation-plan/pre-launch"),
      },
      {
        key: "11-2",
        label: getChildItem("Post-Launch", "/implementation-plan/post-launch"),
      },
      {
        key: "11-3",
        label: getChildItem(
          "5 Year Plan",
          "/implementation-plan/five-year-plan"
        ),
      },
    ],
  },

  // {
  //   key: "12",
  //   label: getItem("Admin", "/admin", <ProjectOutlined />, pathname, color),
  // },
  // {
  //   key: "8",
  //   label: getParentItem("Operations", <ProjectOutlined />),
  //   children: [
  //     {
  //       key: "8-1",
  //       label: getChildItem("Operations Plan", "/operations/plan"),
  //     },
  //     {
  //       key: "8-2",
  //       label: getChildItem("Team & Roles", "/operations/team-"),
  //     },
  //     {
  //       key: "8-3",
  //       label: getChildItem("Risk Analysis", "/operations/risk"),
  //     },
  //     {
  //       key: "8-4",
  //       label: getChildItem("Regulatory Compliance", "/operations/regulatory"),
  //     },
  //   ],
  // },
];

const MenuItem = ({ pathname, color}) => {

  const [url, setUrl] = useState(pathname)

  useEffect(() => {
    setUrl(pathname)
  }, [pathname])

  const user = useSelector(state => {
    return state.userSlice
  })

  const { role } = useSelector(
    (state) => state.userSlice
  );

  const auth = role;

 const [menuItems,setItems] = useState([])
 const [paidItems, setPaidItems] = useState([])

//  useEffect(() => {
//   axios.get(URL + "/api/paid/getAll")
//     .then(res => {
//       console.log("res", res.data);
//       setPaidItems(res.data);
//       // if (res.data.prompt == 'paid')
//       //   setPaid(true)
//       // else setPaid(false)
//     })
//     .catch(err => console.log("err", err))
// }, [])

  useEffect(()=>{
    axios.get(URL + "/api/paid/getAll")
    .then(res => {
      console.log("res", res.data);
      setPaidItems(res.data);
      // if (res.data.prompt == 'paid')
      //   setPaid(true)
      // else setPaid(false)
      const temp =  items(pathname, color, showNavbar)
      setItems(generate_menu_data(user.companies[0].content, res.data))
      console.log("user.upgrade", user.upgrade)
    })
    .catch(err => console.log("err", err))
    
  },[user])
  const [drawerOpen, setDrawerOpen] = useState(false);
  const showNavbar = () => {
    setDrawerOpen(true);
  }
  const generate_menu_data = (content, items) => {
    if(auth=="admin")
    {
    var intial_data = [
      {
        key: "1",
        label: getItem("Home", "/dashboard", <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-home"><path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>, url, color),
      },
      {
        key: "4",
        label: getItem(
          "AI Consultant",
          "/chat",
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>,
          pathname,
          color
        ),
      },
      {
        key: "5",
        label: <DragDrop title="Business Plan" />
      }
    ]
  }else{
    var intial_data = [
      {
        key: "1",
        label: getItem("Home", "/dashboard", <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-home"><path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>, url, color),
      },
      {
        key: "4",
        label: getItem(
          "AI Consultant",
          "/chat",
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-message-square"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>,
          pathname,
          color
        ),
      },
      {
        key: "5",
        label: <span className="pl-2" style={{fontWeight:'600'}}>Business Plan</span>
      }
    ]
  }
    var menu_data = content.map( (item, index) => {
      if(item.title != "Financials"){
      var new_item = {
        key: `menu-${index+6}`,
        label: item.list.length === 0 ? getItem(item.title, menu[item.title]?menu[item.title]['url']:"/new/"+item.title, pathname, color) : getParentItem(item.title, menu[item.title]?menu[item.title]['icon']:null),
        children: item.list.map((child, child_index) => {
          console.log("category name: ", child.origin)
          let flag = estimateFlag(child.origin, items)
          return {
            key: `submenu-${index}-${child_index}`,
            label: getChildItem(child.shown, menu[child.origin] ? menu[child.origin]['url'] : "/new/"+child.shown, user.upgrade, user.role, flag)
          }
        })
      }
      return new_item;
    }
    })
    return [...intial_data, ...menu_data]
  }
  
  const closeDrawer = () => {
    setDrawerOpen(false);
  }

  return (
    <>
      <DrawerSidebar open={drawerOpen} close={closeDrawer}/>
      <Menu mode="inline" theme="light" items={menuItems} />
    </> 
  )
};
export default MenuItem;
