import React from 'react'

const title = (props) => {
    return (
        <>
            <article class="rose max-w-4xl p-3 sm:p-6 pt-0 sm:pt-0 flex items-center mt-10 ">
                <div>
                    <h6 class="text-md font-normal flex items-center leading-none capitalize w-full text-green-500">
                        <div class="w-6 h-6 mr-2 justify-center flex rounded-sm text-green-500 bg-green-50">
                            <div class="w-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-full">
                                    <line x1="12" x2="12" y1="2" y2="22"></line>
                                    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                                </svg>
                            </div>
                        </div>
                        <span class="text-base font-normal">Financials</span>
                    </h6>
                    <h3 class="text-2xl sm:text-3xl font-medium leading-none tracking-tight capitalize w-full mt-4 mb-3">{props.title}</h3>
                </div>
            </article>
        </>
    )
}

export default title