import React, { useEffect, useState } from "react";
import operations_plan from '../../assets/images/pdfs/operations_plan.png'

export default function RegulatoryCompliance(props) {
    const [data, setData] = useState(null);

    useEffect(() => {
        if (props.user) {
            // console.log('business model -> user: ', props.user);
            // console.log('opration', props.user.companies[0])
            setData(props.user.companies[0].jsonContent.find(item => item.Title === "Regulatory Compliance"));
        }
    })

    return (<div style={{fontFamily:'sans-serif', width: 400, padding: '10px 20px', marginLeft: 20 }}>
                <div style={{fontFamily:'sans-serif', display: 'flex', alignItems: 'center' }}>
                    <div style={{fontFamily:'sans-serif', width: 13, height: 13, marginRight: 8, marginTop: 17, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 3, color: '#26B35A' }}>
                        <div style={{fontFamily:'sans-serif', width: 13 }}>
                            <img src={operations_plan} />
                        </div>
                    </div>
                    <div style={{fontFamily:'sans-serif', fontSize: 14, color: '#26B35A' }}>Operations</div>
                </div>
                <div style={{fontFamily:'sans-serif', fontSize: 20 }}>
                Regulatory Compliance
        </div>
        <div style={{fontFamily:'sans-serif',marginTop: '20px', fontSize: 12}}>
            <p>{ data?.Title + ": " + data?.Summary }</p>
        </div>
        <div>
        <ol style={{fontFamily:'sans-serif',fontSize: 12}}>
            {data?.content?.map((items, idx) => (
                <li key={idx} style={{fontFamily:'sans-serif', marginTop: '10px' }}>
                    <p style={{fontFamily:'sans-serif',marginLeft: '5px'}}>{ (idx + 1) + " : " + items?.Description }</p>
                </li>
            ))}
        </ol>
        </div>

    </div>)
}