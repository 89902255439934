import React, { useEffect, useState } from "react";
import business_title_img from "../../assets/images/pdfs/business_title.png";
import business_advantages from "../../assets/images/pdfs/business.png";

export default function BusinessModel(props) {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (props.user) {
      // console.log('business model -> user: ', props.user);
      setData(
        props.user?.companies[0]?.jsonContent.find(
          (item) => item.Title === "Business Models"
        )
      );
    }
  });

  return (
    <div style={{display:"none", fontFamily:'sans-serif'}}>
    <div style={{ width: `400px`, padding: "10px 20px", marginLeft: 20, fontFamily:'sans-serif' }} id="business">
      <div style={{ display: "flex", alignItems: "center", fontFamily:'sans-serif' }}>
        <div
          style={{
            width: 8,
            height: 8,
            marginRight: 8,
            marginTop: 17,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 3,
            color: "#26B35A", fontFamily:'sans-serif'
            
          }}
        >
          <div style={{ width: 8 }}>
            <img src={business_title_img} />
          </div>
        </div>
        <div style={{ fontSize: 12, color: "#26B35A", fontFamily:'sans-serif' }}>Overview</div>
      </div>
      <div style={{ fontSize: 16, fontWeight: 'bold', fontFamily:'sans-serif' }}>Business Models</div>
      <div style={{ paddingTop: 20, paddingBottom: 20, textWrap: "wrap", fontFamily:'sans-serif' }}>
        <p style={{ fontSize: 8, fontFamily:'sans-serif' }}>{data?.Summary}</p>
      </div>
      <div style={{ textWrap: "wrap", fontFamily:'sans-serif' }}>
        <div
          style={{
            border: "1px solid #666",
            borderRadius: 5,
            padding: "5px 0px", fontFamily:'sans-serif'
          }}
        >
          <div style={{ padding: 5, marginBottom: 5, fontFamily:'sans-serif' }}>
            <div style={{ fontSize: 10, fontWeight: "bold", marginTop: -5, fontFamily:'sans-serif' }}>
              {data?.Content[0]?.SubTitle}
            </div>
            <div>
              <p style={{ fontSize: 8, fontFamily:'sans-serif' }}>
                {data?.Content[0]?.Description?.Overview}
              </p>
            </div>
          </div>
          <div
            style={{ display: "flex", height: 1, backgroundColor: "#666", fontFamily:'sans-serif' }}
          ></div>
          <div style={{ display: "flex", width: "100%", fontFamily:'sans-serif' }}>
            <div style={{ width: "50%", padding: 5, fontFamily:'sans-serif' }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: 5, fontFamily:'sans-serif'
                }}
              >
                <img
                  width={8}
                  height={8}
                  src={business_advantages}
                  style={{ marginTop: 5 }}
                />
                <span
                  style={{
                    marginLeft: 5,
                    marginBottom: 5,
                    fontWeight: "bold",
                    fontSize: 10, fontFamily:'sans-serif'
                  }}
                >
                  Advantages
                </span>
              </div>
              <div style={{ padding: 5, fontFamily:'sans-serif' }}>
                {data?.Content[0]?.Description?.Advantages?.map(
                  (item, index) => (
                    <div style={{fontSize:8, fontFamily:'sans-serif'}}>{`${index + 1}. ${item.Description}`}</div>
                  )
                )}
              </div>
            </div>
            <div style={{ width: "50%", padding: 5, fontFamily:'sans-serif' }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: 5, fontFamily:'sans-serif'
                }}
              >
                <img
                  width={8}
                  height={8}
                  src={business_advantages}
                  style={{ marginTop: 5 }}
                />
                <span
                  style={{
                    marginLeft: 5,
                    marginBottom: 5,
                    fontWeight: "bold",
                    fontSize: 10, fontFamily:'sans-serif'
                  }}
                >
                  Challenges
                </span>
              </div>
              <div style={{ padding: 5, fontFamily:'sans-serif' }}>
                {data?.Content[0]?.Description?.Challenges?.map(
                  (item, index) => (
                    <div style={{fontSize:8, fontFamily:'sans-serif'}}>{`${index + 1}. ${item.Description}`}</div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            border: "1px solid #666",
            borderRadius: 5,
            padding: "5px 0px",
            marginTop: 5, fontFamily:'sans-serif'
          }}
        >
          <div style={{ padding: 5, marginBottom: 5, fontFamily:'sans-serif' }}>
            <div style={{ fontSize: 10, fontWeight: "bold", marginTop: -5, fontFamily:'sans-serif' }}>
              {data?.Content[1]?.SubTitle}
            </div>
            <div>
              <p style={{fontSize:8, fontFamily:'sans-serif'}}>{data?.Content[1]?.Description?.Overview}</p>
            </div>
          </div>
          <div
            style={{ display: "flex", height: 1, backgroundColor: "#666", fontFamily:'sans-serif' }}
          ></div>
          <div style={{ display: "flex", width: "100%", fontFamily:'sans-serif' }}>
            <div style={{ width: "50%", padding: 5, fontFamily:'sans-serif' }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: 5, fontFamily:'sans-serif'
                }}
              >
                <img
                  width={8}
                  height={8}
                  src={business_advantages}
                  style={{ marginTop: 5, fontFamily:'sans-serif' }}
                />
                <span
                  style={{
                    marginLeft: 5,
                    marginBottom: 5,
                    fontWeight: "bold",
                    fontSize: 10, fontFamily:'sans-serif'
                  }}
                >
                  Advantages
                </span>
              </div>
              <div style={{ padding: 5, fontFamily:'sans-serif' }}>
                {data?.Content[1]?.Description?.Advantages?.map(
                  (item, index) => (
                    <>
                      {typeof item === "object" && (
                        <div style={{ fontSize: 8, fontFamily:'sans-serif' }}>
                          {`${index + 1}. ${item.Description}`}
                        </div>
                      )}
                    </>
                  )
                )}
              </div>
            </div>
            <div style={{ width: "50%", padding: 5, fontFamily:'sans-serif' }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: 5, fontFamily:'sans-serif'
                }}
              >
                <img
                  width={8}
                  height={8}
                  src={business_advantages}
                  style={{ marginTop: 5, fontFamily:'sans-serif' }}
                />
                <span
                  style={{
                    marginLeft: 5,
                    marginBottom: 5,
                    fontWeight: "bold",
                    fontSize: 10, fontFamily:'sans-serif'
                  }}
                >
                  Challenges
                </span>
              </div>
              <div style={{ padding: 5, fontFamily:'sans-serif' }}>
                {data?.Content[1]?.Description?.Challenges?.map(
                  (item, index) => (
                    <div style={{fontSize:8, fontFamily:'sans-serif'}}>{`${index + 1}. ${item.Description}`}</div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            border: "1px solid #666",
            borderRadius: 5,
            padding: "5px 0px",
            marginTop: 5, fontFamily:'sans-serif'
          }}
        >
          <div style={{ padding: 5, marginBottom: 5, fontFamily:'sans-serif' }}>
            <div style={{ fontSize: 10, fontWeight: "bold", marginTop: -5, fontFamily:'sans-serif' }}>
              {data?.Content[2]?.SubTitle}
            </div>
            <div>
              <p style={{fontSize:8, fontFamily:'sans-serif'}}>{data?.Content[2]?.Description?.Overview}</p>
            </div>
          </div>
          <div
            style={{ display: "flex", height: 1, backgroundColor: "#666", fontFamily:'sans-serif' }}
          ></div>
          <div style={{ display: "flex", width: "100%", fontFamily:'sans-serif' }}>
            <div style={{ width: "50%", padding: 5, fontFamily:'sans-serif' }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: 5, fontFamily:'sans-serif'
                }}
              >
                <img
                  width={8}
                  height={8}
                  src={business_advantages}
                  style={{ marginTop: 5 , fontFamily:'sans-serif'}}
                />
                <span style={{ marginLeft: 5, marginBottom: 5, fontSize: 10, fontFamily:'sans-serif' }}>
                  Advantages
                </span>
              </div>
              <div style={{ padding: 5, fontFamily:'sans-serif' }}>
                {data?.Content[2]?.Description?.Advantages?.map(
                  (item, index) => (
                    <>
                      {typeof item === "object" && (
                        <div style={{ fontSize: 8, fontFamily:'sans-serif' }}>
                          {`${index + 1}. ${item.Description}`}
                        </div>
                      )}
                    </>
                  )
                )}
              </div>
            </div>
            <div style={{ width: "50%", padding: 5, fontFamily:'sans-serif' }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: 5, fontFamily:'sans-serif'
                }}
              >
                <img
                  width={8}
                  height={88888}
                  src={business_advantages}
                  style={{ marginTop: 5 }}
                />
                <span style={{ marginLeft: 5, marginBottom: 5, fontSize: 10, fontFamily:'sans-serif' }}>
                  Challenges
                </span>
              </div>
              <div style={{ padding: 5, fontFamily:'sans-serif' }}>
                {data?.Content[2]?.Description?.Challenges?.map(
                  (item, index) => (
                    <div>
                      {typeof item === "object" && (
                        <div style={{ fontSize: 8, fontFamily:'sans-serif' }}>
                          {`${index + 1}. ${item.Description}`}
                        </div>
                      )}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}
