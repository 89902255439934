import React, { useEffect, useState } from "react";
import {
  Layout,
  notification,
  Switch,
  Input,
  Modal,
  Button,
  Drawer,
  Row,
  Col,
  Radio,
  Space,
} from "antd";
import { Link } from "react-router-dom";
import { URL } from "../../utils/const";
import logo from "../../assets/images/StartUp Sphere Logo-02.png";
import BusinessPlan from "../BusinessPlan";
import { SketchPicker } from "react-color";
import $ from "jquery";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { addContent } from "../../Redux/features/pagesSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  addUser,
  setRegenerate2,
  setRegenerate,
  setRegenerate1,
} from "../../Redux/features/UserSlice";
import {
  MenuFoldOutlined,
  SyncOutlined,
  ApartmentOutlined,
} from "@ant-design/icons";
import { dashboard } from "utils/SideNavData";
import PdfView from "../../pages/pdfView";
import MissionVision from "utils/export/MissionVision";
import SWOT from "utils/export/SWOT";
import Business from "utils/export/businessModel";
// import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
const { Header } = Layout;

const headerStyle = {
  textAlign: "center",
  color: "black",
  height: 80,
  padding: 0,
  lineHeight: "64px",
  backgroundColor: "white",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const SubHeader = ({ saveFlag, onChangeFlag }) => {
  const [api, contextHolder] = notification.useNotification();
  const [isRecycleModalOpen, setIsRecycleModalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isSwitch, setIsSwitch] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [visible, setVisible] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const [newDescription, setNewDescription] = useState("");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userSlice);
  const [textValue, setTextValue] = useState("");
  const [isOpenAIModel, setOpenAIModel] = useState(false);
  const [companyName, setcompanyName] = useState("");
  const [ishome, setishome] = useState(false);
  const [flag, setFlag] = useState(0);
  const handleTextareaChange = (event) => {
    setTextValue(event.target.value);
    // console.log("event.target", event.target.value)
  };
  const openNotificationWithIcon = (type, data) => {
    api[type]({
      message: "Notification",
      description: data,
    });
  };
  useEffect(() => {
    const url = window.location.href;

    if (url.indexOf("dashboard") >= 0) {
      setishome(true);
    } else {
      setishome(false);
    }
  }, [window.location.href]);

  useEffect(() => {
    // console.log(user);
    try {
      if (user.gpt.modelName == "gpt-4-1106-preview") {
        setValue(1);
      }
      if (user.gpt.modelName == "gpt-3.5-turbo-1106") {
        setValue(2);
      }
    } catch (err) {
      console.log(err);
    }

    // console.log("subheader description", user.companies[0])
    try {
      if (user.companies[0].companyDescription != undefined) {
        setcompanyName(user.companies[0].companyName);
        setTextValue(user.companies[0].companyDescription);
        setcompanyName(user.companies[0].companyName);
      }
    } catch (err) {
      console.log(err);
    }
  }, [user]);

  const toggleRecycleModal = () => {
    $("#recycle_com").css("display", "");
    $("#option_com").css("display", "");
    setIsRecycleModalOpen(!isRecycleModalOpen);
    setIsUploadModalOpen(false);
  };

  const toggleUploadModal = () => {
    $("#recycle_com").css("display", "");
    $("#option_com").css("display", "");
    setIsUploadModalOpen(!isUploadModalOpen);
    setIsRecycleModalOpen(false);
  };
  const navigator = useNavigate();
  const onChange = (checked) => {
    // console.log(`switch to ${checked}`);
    setIsSwitch(!isSwitch);
  };

  const handleRegenerate = () => {
    dispatch(setRegenerate1(false));
    dispatch(setRegenerate(false));
    dispatch(setRegenerate2(true));
  };

  const handleOk = () => {
    setModal2Open(false);
    // resetDescription()
    axios
      .post(URL + "/api/users/rename", {
        email: user.email,
        oldComp: user.companies[0].companyName,
        newComp: companyName,
        newDescription: textValue,
      })
      .then((res) => {
        // console.log(res.data)
        const updatedTemp = user.companies.map((item, index) => {
          if (index == 0) {
            return {
              ...item,
              companyName: companyName,
              companyDescription: textValue,
            };
          }
          return item;
        });

        dispatch(
          addUser({
            email: user.email,
            name: "StartupSphere",
            companies: updatedTemp,
            curID: user.curID,
            role: user.role,
            prompt: user.prompt,
            gpt: user.gpt,
          })
        );

        handleRegenerate();
      });
  };
  const chooseOpenAImodel = () => {
    setOpenAIModel(true);
  };
  const handleCancel = () => {
    setModal2Open(false);
    setVisible(false);
  };

  const handleFlag = () => {
    onChangeFlag();
  };

  const openDrawer = () => {
    // try{
    //       axios.post(URL+"/api/users/loginWithGoogle", { email: user.email })
    //   .then(response => {

    //     const data = response.data;
    //     console.log(data)
    //     if (data) {
    //       console.log("`````````````````````````````````````````",data)
    //       const updatedCompany = data.company.find(item=>item.companyName == user.companies[0].companyName)

    //       dispatch(addUser({
    //         email: data.email,
    //         name: 'StartupSphere',
    //         companies:  user.companies.map((comp,idx)=>{
    //           if(idx ==0){
    //             return updatedCompany
    //           }
    //           return comp
    //         }),
    //         curID: data.curID,
    //         role: data.role,
    //         prompt: data.prompt,
    //         upgrade: data.upgrade,
    //         gpt: data.gpt,
    //       }))
    //     }
    //     else {
    //       openNotificationWithIcon("error", "Try again!");
    //       return
    //     }
    //   })
    //   .catch(error => {
    //     openNotificationWithIcon("error", "Try again!");
    //     return
    //   })

    setTimeout(() => {
      const html = document.getElementById("pdf_export");
      const report = new jsPDF("portrait", "px", "a4");
      // report.setPageMargins(0,0,0, 50);

      // report.html(document.querySelector('#pdf_export')).then(() => {
      //   report.save('report.pdf');
      // })
      setFlag(0);
      // navigator("/pdfs")
      report.addFont("Poppins.ttf", "Poppins", "sans-serif");
      report.setFont("Poppins");
      report.html(document.getElementById("bg"), {
        margin: [0, 0, 5, 0],

        callback: function () {
          report.addPage("a4", "1");
          report.html(document.getElementById("missions"), {
            x: 0,
            y: 627,
            margin: [0, 0, 5, 0],
            callback: function () {
              report.addPage("a4", "1");
              report.html(document.getElementById("swot"), {
                margin: [0, 0, 5, 0],
                x: 0,
                y: 627 * 2,
                callback: function () {
                  report.addPage("a4", "1");
                  report.html(document.getElementById("business"), {
                    margin: [0, 0, 5, 0],
                    x: 0,
                    y: 627 * 3,
                    callback: function () {
                      report.addPage("a4", "1");
                      report.html(document.getElementById("visibility"), {
                        margin: [0, 0, 5, 0],
                        x: 0,
                        y: 627 * 5,
                        callback: function () {
                          report.addPage("a4", "1");
                          report.html(document.getElementById("core"), {
                            margin: [0, 0, 5, 0],
                            x: 0,
                            y: 627 * 6,
                            callback: function () {
                              report.addPage("a4", "1");
                              report.html(
                                document.getElementById("secondary"),
                                {
                                  margin: [0, 0, 5, 0],
                                  x: 0,
                                  y: 627 * 8,
                                  callback: function () {
                                    report.addPage("a4", "1");
                                    report.html(
                                      document.getElementById("expansion"),
                                      {
                                        margin: [0, 0, 5, 0],
                                        x: 0,
                                        y: 627 * 10,
                                        callback: function () {
                                          report.addPage("a4", "1");
                                          report.html(
                                            document.getElementById("customer"),
                                            {
                                              margin: [0, 0, 5, 0],
                                              x: 0,
                                              y: 627 * 12,
                                              callback: function () {
                                                report.addPage("a4", "1");
                                                report.html(
                                                  document.getElementById(
                                                    "industryoverview"
                                                  ),
                                                  {
                                                    margin: [0, 0, 5, 0],
                                                    x: 0,
                                                    y: 627 * 13,
                                                    callback: function () {
                                                      report.addPage("a4", "1");
                                                      report.html(
                                                        document.getElementById(
                                                          "market"
                                                        ),
                                                        {
                                                          margin: [0, 0, 5, 0],
                                                          x: 0,
                                                          y: 627 * 14,
                                                          callback:
                                                            function () {
                                                              report.addPage("a4", "1");
                                                              report.html(
                                                                document.getElementById(
                                                                  "retention"
                                                                ),
                                                                {
                                                                  margin: [0, 0, 5, 0],
                                                                  x: 0,
                                                                  y: 627 * 16,
                                                                  callback:
                                                                    function () {
                                                                      report.addPage("a4", "1");
                                                                      report.html(
                                                                        document.getElementById(
                                                                          "ideas"
                                                                        ),
                                                                        {
                                                                          margin: [0, 0, 5, 0],
                                                                          x: 0,
                                                                          y: 627 * 18,
                                                                          callback:
                                                                            function () {
                                                                              report.addPage("a4", "1");
                                                                              report.html(
                                                                                document.getElementById(
                                                                                  "branding"
                                                                                ),
                                                                                {
                                                                                  margin: [0, 0, 5, 0],
                                                                                  x: 0,
                                                                                  y: 627 * 19,
                                                                                  callback:
                                                                                    function () {
                                                                                      report.addPage("a4", "1");
                                                                                      report.html(
                                                                                        document.getElementById(
                                                                                          "operation"
                                                                                        ),
                                                                                        {
                                                                                          margin: [0, 0, 5, 0],
                                                                                          x: 0,
                                                                                          y: 627 * 21,
                                                                                          callback:
                                                                                            function () {
                                                                                              report.addPage("a4", "1");
                                                                                              report.html(
                                                                                                document.getElementById(
                                                                                                  "risk"
                                                                                                ),
                                                                                                {
                                                                                                  margin: [0, 0, 5, 0],
                                                                                                  x: 0,
                                                                                                  y: 627 * 22,
                                                                                                  callback:
                                                                                                    function () {
                                                                                                      report.save(
                                                                                                        "report.pdf"
                                                                                                      );
                                                                                                    },
                                                                                                }
                                                                                              );
                                                                                            },
                                                                                        }
                                                                                      );
                                                                                    },
                                                                                }
                                                                              );
                                                                            },
                                                                        }
                                                                      );
                                                                    },
                                                                }
                                                              );
                                                            },
                                                        }
                                                      );
                                                    },
                                                  }
                                                );
                                              },
                                            }
                                          );
                                        },
                                      }
                                    );
                                  },
                                }
                              );
                            },
                          });
                        },
                      });
                    },
                  });
                },
              });
            },
          });
        },
      });
    }, 4000);

    // html2canvas(document.getElementById("swot"), {
    //   logging: false,
    //   scale: 2
    // }).then(canvas => {
    //   console.log("canvas", canvas, document.getElementById("food"))
    //   var contentWidth = canvas.width;
    //   var contentHeight = canvas.height;
    //   //One page pdf shows the canvas height generated by html pages.
    //   var pageHeight = (contentWidth / 592.28) * 841.89;
    //   //html page height without pdf generation
    //   var leftHeight = contentHeight;
    //   //Page offset
    //   var position = 0;
    //   //a4 paper size [595.28, 841.89], html page generated canvas in pdf picture width

    //   var imgWidth = 555.28;
    //   var imgHeight = (555.28 / contentWidth) * contentHeight;
    //   var pageData = canvas.toDataURL("image/jpeg", 1.0);
    //   var pdf = new jsPDF("", "pt", "a4");
    //   //There are two heights to distinguish, one is the actual height of the html page, and the page height of the generated pdf (841.89)
    //   //When the content does not exceed the range of pdf page display, there is no need to paginate
    //   if (leftHeight < pageHeight) {
    //     pdf.addImage(pageData, "JPEG", 20, 0, imgWidth, imgHeight);
    //   } else {
    //     while (leftHeight > 0) {
    //       pdf.addImage(pageData, "JPEG", 20, position, imgWidth, imgHeight);
    //       leftHeight -= pageHeight;
    //       position -= 841.89;
    //       //Avoid adding blank pages
    //       if (leftHeight > 0) {
    //         pdf.addPage();
    //       }
    //     }
    //   }
    //   let date = new Date().toISOString();
    //   pdf.save(`Report_${date}.pdf`);
    // });

    openNotificationWithIcon(
      "success",
      "Please wait for generating a PDF file"
    );

    // }catch(error){
    //   openNotificationWithIcon("error", "Failed, try again");
    // }
    // setDrawer(true)
    // alert("sdf")
  };

  const closeDrawer = () => {
    setDrawer(false);
  };
  const openAIModalOK = () => {};
  const openAIModalCancel = () => {
    setOpenAIModel(false);
  };

  const [value, setValue] = useState(1);
  const onRadioChange = (e) => {
    // console.log('radio checked', e.target);
    setValue(e.target.value);
  };

  const saveGPTmodel = () => {
    let model = "";
    if (value == 1) {
      model = "gpt-4-1106-preview";
    } else {
      model = "gpt-3.5-turbo-1106";
    }
    axios
      .post(URL + "/api/users/saveGPTModel", { modelName: model })
      .then((res) => {
        console.log(user.gpt);
        // console.log(user)
        dispatch(
          addUser({
            email: user.email,
            name: "StartupSphere",
            companies: user.companies,
            curID: user.curID,
            role: user.role,
            prompt: user.prompt,
            upgrade: user.upgrade,
            gpt: { modelName: model },
          })
        );
        setOpenAIModel(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const resetDescription = (e) => {
  //   axios.post(URL+"/api/users/resetdescription", { email: user.email, companyName: user.companies[0].companyName, newDescription: textValue }).then(res => {
  //     dispatch(addUser({
  //       email: user.email,
  //       name: 'StartupSphere',
  //       companies: res.data,
  //       curID: user.curID,
  //       role: user.role,
  //       prompt: user.prompt,
  //       upgrade: user.upgrade,
  //       gpt: user.gpt,
  //     }))
  //   }).catch(err => {
  //     console.log(err);
  //   })

  // }

  return (
    <div>
      {contextHolder}
      {user.email != "" ? <PdfView></PdfView> : ""}
      <MissionVision></MissionVision>
      <SWOT></SWOT>

      <div
        id="food"
        className="fixed top-[0px] left-[0px] z-[400] pt-[8px] px-[10px] pl-[320px] sm:pl-[400px] flex items-center justify-end w-full h-[42px]"
      >
        <MenuFoldOutlined className="sm:hidden mr-3" onClick={handleFlag} />

        <Modal
          title="OpenAI Model"
          open={isOpenAIModel}
          onCancel={openAIModalCancel}
          style={{ color: "black" }}
          footer={false}
        >
          <p>
            {" "}
            All the contents will be generated by this model. It is saved for
            users
          </p>
          <div>
            <Radio.Group
              onChange={onRadioChange}
              value={value}
              style={{ marginTop: "20px" }}
            >
              <Space direction="vertical">
                <Radio value={1}>gpt-4-1106-preview</Radio>
                <Radio value={2}>gpt-3.5-turbo-1106</Radio>
              </Space>
            </Radio.Group>
          </div>
          <div className="w-full flex items-end justify-end">
            <Button
              style={{ marginLeft: "30px", marginTop: "20px" }}
              onClick={saveGPTmodel}
            >
              Save
            </Button>
          </div>
        </Modal>

        {!ishome && (
          <>
            {user.regenerateFlag ? (
              <p className={`text-gray-400 mr-2 ${saveFlag ? "" : "hidden"}`}>
                Saving...
              </p>
            ) : (
              <p
                className={`text-gray-400 mr-2 $     {saveFlag ? '' : 'hidden'}`}
              >
                Saved
              </p>
            )}
          </>
        )}
        <div className={`relative ${saveFlag ? "" : "hidden"}`}>
          {!ishome && (
            <>
              {user.regenerateFlag ? (
                <button className="p-2">
                  <SyncOutlined spin />
                </button>
              ) : (
                <button className="p-2" onClick={toggleRecycleModal}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="w-4 false"
                  >
                    <path d="M21 12a9 9 0 0 0-9-9 9.75 9.75 0 0 0-6.74 2.74L3 8"></path>
                    <path d="M3 3v5h5"></path>
                    <path d="M3 12a9 9 0 0 0 9 9 9.75 9.75 0 0 0 6.74-2.74L21 16"></path>
                    <path d="M16 16h5v5"></path>
                  </svg>
                </button>
              )}
            </>
          )}

          {isRecycleModalOpen && (
            <div
              id="recycle_com"
              className="z-[100] absolute top-[38px] left-[-150px] bg-white pt-[20px] px-[20px] shadow-md flex flex-col space-y-1 w-[360px] h-[140px]"
            >
              <h3 className="font-semibold text-[16px] leading-none tracking-tight">
                Regenerate this section
              </h3>
              <span className="font-normal text-[14px] leading-snug text-muted-foreground">
                This will replace the content in this section with new content.
                It cannot be undone.
              </span>
              <div class="flex flex-row">
                <button
                  onClick={() => {
                    toggleRecycleModal();
                    dispatch(setRegenerate2(true));
                  }}
                  className="inline-flex items-center justify-center text-[14px] font-normal bg-black text-white rounded-md px-3 mt-2 mr-2"
                >
                  Regenerate
                </button>
                <button
                  onClick={toggleRecycleModal}
                  className="inline-flex items-center justify-center text-[14px] font-normal ground hover:bg-accent hover:text-accent-foreground  border border-input h-9 rounded-md px-3 mt-2"
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
        {/* <div className="relative">

          <button className="p-3" onClick={toggleUploadModal}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4"><path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path><polyline points="16 6 12 2 8 6"></polyline><line x1="12" x2="12" y1="2" y2="15"></line></svg>
          </button>
          {isUploadModalOpen && (
            <div id="option_com" className="z-[200] absolute top-[38px] left-[-200px] sm:left-[-220px] bg-white pt-[20px] px-[20px] shadow-md flex flex-col space-y-1 w-[400px] h-[300px] sm:h-[230px]">
              <div className="sm:flex items-center justify-between pb-[12px]">
                <div>
                  <h4 style={{ fontWeight: "500" }}>Share to the web</h4>
                  <span>Publish and share link with anymore</span>
                </div>
                <div>
                  <Switch defaultChecked onChange={onChange} />
                </div>
              </div>
              <hr />
              <div>
                <h4 style={{ fontWeight: "500" }}>Share Report</h4>
                <span>Allow access to those with the link.</span>
              </div>
              <hr />
              <div className="sm:flex items-center justify-between ">
                <Input value="https://www.venturekit.ai/report/clsbqerb5001llzakzfvzpjpc/home" disabled={!isSwitch} size="small" placeholder="Basic usage" />
                <button className="text-[14px] px-3 py-2 bg-gray-100 rounded-lg">Copy</button>
              </div>
              <button className="flex items-center pt-2" style={{ color: "#7fdbbb" }}>Open in new tab
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 h-4 ml-1"><path d="M7 7h10v10"></path><path d="M7 17 17 7"></path></svg>
              </button>
            </div>
          )}
        </div> */}
        {user.role == "admin" ? (
          <ApartmentOutlined onClick={chooseOpenAImodel} />
        ) : (
          ""
        )}
        <button className="p-2" onClick={() => setModal2Open(true)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="w-4"
          >
            <path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z"></path>
            <circle cx="12" cy="12" r="3"></circle>
          </svg>
        </button>
        {/* {flag?
        <Button style={{ backgroundColor: 'black',color:"white" }} onClick={openDrawer} className="mr-4" type="primary" loading>Export</Button>
        :
        <Button style={{ backgroundColor: 'black',color:"white" }} onClick={openDrawer} className="mr-4" type="primary">Export</Button>
        } */}
        <button
          className={`flex h-full mx-2 items-center bg-black text-white px-[12px] border rounded-lg `}
          onClick={openDrawer}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="w-4 mr-2"
          >
            <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z"></path>
            <polyline points="14 2 14 8 20 8"></polyline>
            <line x1="16" x2="8" y1="13" y2="13"></line>
            <line x1="16" x2="8" y1="17" y2="17"></line>
            <line x1="10" x2="8" y1="9" y2="9"></line>
          </svg>
          <span>Export</span>
        </button>
      </div>
      <Modal
        title="Edit Company"
        centered
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
        footer={[
          <Button
            className="border-none hover:bg-gray-100 text-black"
            key="cancel"
            onClick={handleCancel}
          >
            Cancel
          </Button>,
          <Button
            style={{}}
            className="bg-black hover:bg-black"
            key="submit"
            type="primary"
            onClick={handleOk}
          >
            Save
          </Button>,
        ]}
      >
        <p className="mt-4 mb-[5px]">Comapnay Name:</p>
        <textarea
          value={companyName}
          onChange={(e) => {
            setcompanyName(e.target.value);
          }}
          className="w-full h-[40px] p-2 mt-0 border border-solid rounded-lg text-[#8c8c8c]"
        ></textarea>
        <p className="mt-[15px] mb-[5px]">Description:</p>
        <textarea
          value={textValue}
          onChange={handleTextareaChange}
          className="w-full h-[80px] p-2 mt-0 border border-solid rounded-lg text-[#8c8c8c] "
        ></textarea>
      </Modal>
      <Drawer
        width={450}
        title="Export Business Plan"
        onClose={closeDrawer}
        open={drawer}
      >
        <BusinessPlan />
      </Drawer>
    </div>
  );
};
export default SubHeader;
