import { Col, Row, Typography, Input, Form, Button } from 'antd'
import React from 'react'

const { Text } = Typography

const ForgotPassword = () => {
    return (
        <Row style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', marginTop: '100px' }}>
            <Col sm={22} xs={22} lg={7}>
                <Typography.Title level={2}>
                    Forgot Password
                </Typography.Title>
                <Form
                    layout="vertical"
                    className="row-col"
                >
                    <Form.Item
                        className="username"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: "Please input your email!",
                            },
                        ]}
                    >
                        <Input placeholder="Email" />
                    </Form.Item>
                    <Button
                      type="default"
                      htmlType="submit"
                      style={{ width: "100%", backgroundColor: 'rgb(0, 184, 119)' }}
                    >
                      <div className="text-lg text-white">Send reset instructions</div>
                    </Button>
                </Form>
            </Col>
        </Row>
    )
}

export default ForgotPassword