import React from "react";
import { Layout, Button, Row, Col } from "antd";
import { Link } from "react-router-dom";

import logo from '../../assets/images/StartUp Sphere Logo-02.png'

const { Header } = Layout;

const headerStyle = {
  textAlign: 'center',
  color: 'black',
  height: 80,
  padding: 0,
  lineHeight: '64px',
  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
};

const AntdHeader = () => {
  return (
    // <Row style={{ display: 'flex', justifyContent: 'center' }}>
      <Col sm={22} xs={22} lg={18} style={{}}>
        <Header style={headerStyle}>
          <Link to='/'>
            <img src={logo} alt='logo' className='h-10 w-auto my-auto' />
          </Link>
          <div>
            <Button type='text' style={{ marginRight: '30px', backgroundColor:'white', color:'black' }}>
              <Link to='/'>
                Home
              </Link>
            </Button>
            <Button type='text' style={{backgroundColor:'white', color:'black'}}>
              <Link to='/about'>
                About
              </Link>
            </Button>
          </div>
          <div>
            <Button type='text' style={{ marginRight: '30px', backgroundColor:'white', color:'black' }}>
              <Link to="/sign-in">Log in</Link>
            </Button>
            <Button type='second' style={{ backgroundColor: '#3367D6', color:'white' }}>
              <Link to="/dashboard" className="text-white font-bold">
                Get started
              </Link>
            </Button>
          </div>
        </Header>
      </Col>
    // </Row>
  );
};
export default AntdHeader;
