import React, { useState, useEffect } from 'react'
import market from '../../assets/images/pdfs/Market.png';
import industryImg from '../../assets/images/pdfs/industryOverview.png';

export default function IndustryOverview(props) {
    const [data, setData] = useState(null);

    useEffect(() => {
        if (props.user) {
            // console.log('Industry Overview -> user: ', props.user);
            setData(props.user.companies[0].jsonContent.find(item => item.Title === "Industry Overview"));
        }
    })

    return (
    <div style={{fontFamily:'sans-serif',display:'none'}}>
    <div style={{fontFamily:'sans-serif', width: `400px`, padding: '10px 20px', marginLeft: 20 }} id='industryoverview'>
        <div style={{fontFamily:'sans-serif', display: 'flex', alignItems: 'center' }}>
            <div style={{fontFamily:'sans-serif', width: 8, height: 8, marginRight: 8, marginTop: 17, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: 3, color: '#26B35A' }}>
                <div style={{fontFamily:'sans-serif', width: 8 }}>
                    <img src={market} />
                </div>
            </div>
            <div style={{fontFamily:'sans-serif', fontSize: 12, color: '#26B35A' }}>Market Research</div>
        </div>
        <div style={{fontFamily:'sans-serif', fontSize: 16, fontWeight:"bold" }}>
            Industry Overview
        </div>
        <div style={{fontFamily:'sans-serif', paddingTop: 20, paddingBottom: 20, textWrap: 'wrap' }}>
            <p style={{fontFamily:'sans-serif', fontSize: 8 }}>{data?.Summary}</p>
        </div>
        <div>
            {/* <div style={{fontFamily:'sans-serif', textWrap: 'wrap', backgroundColor: '#FFF7ED', borderRadius: 10, border: '1px solid #999' }}> */}
                {/* <div style={{fontFamily:'sans-serif', width: '100%', padding: 10, display: 'flex', justifyContent: 'center' }}>
                    <img style={{fontFamily:'sans-serif',width: '70%', height: '70%'}} src={industryImg} />
                </div> */}
                {/* <div style={{fontFamily:'sans-serif',display: 'flex', flexWrap: 'wrap', width: '100%', padding: 5}}>
                    {
                        data?.Rate.map(item => (<div style={{fontFamily:'sans-serif',margin: '5px 0'}}>
                            <div style={{fontFamily:'sans-serif', fontSize: 10, fontWeight: 'bold' }}>{ item.SubTitle }</div>
                            <div>
                                <ol>
                                    { item.Description.map(subcontent => (<li style={{fontFamily:'sans-serif',fontSize:8}}>
                                        { subcontent.Description }
                                    </li>)) }
                                </ol>
                            </div>
                        </div>))
                    }
                </div> */}
            {/* </div> */}
            <div style={{fontFamily:'sans-serif', marginTop: 5 }}>
                <div style={{fontFamily:'sans-serif', fontSize: 10, fontWeight: 'bold', margin: '5px 0' }}>
                    {data?.Content?.SubTitle}
                </div>
                <div>
                    <div><p style={{fontFamily:'sans-serif',fontSize:8}}>{data?.Content.Description?.Overview}</p></div>
                    <div style={{fontFamily:'sans-serif',margin: '10px 0'}}>
                        <ol className='numbered-list'>
                            {
                                data?.Content?.Description.Description.map(desc => (<li style={{fontFamily:'sans-serif',fontSize:8}}>
                                    { desc.Description }
                                </li>))
                            }
                        </ol>
                    </div>
                    <div>
                        <p style={{fontFamily:'sans-serif',fontSize:8}}>
                            { data?.Content?.Description.Conclusion }
                        </p>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>)
}