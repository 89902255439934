import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    contents: [],
}   

const pagesSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        addContent: (state, {payload}) => {
            state.contents = payload.content
        },
        updateContent: (state, {payload}) => {
            state.menu = payload.menu
        }
    }
})

export const {
    addContent,
    updateContent
} = pagesSlice.actions

export default pagesSlice.reducer