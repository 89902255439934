import React from 'react';
import { PieChart, Pie, Tooltip, ResponsiveContainer } from 'recharts';
import * as Data from '../../utils/FinancialData'

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className='bg-white shadow-md rounded p-2 text-sm font-medium min-w-[200px] text-neutral-800'>
                <span className='text-muted-foreground w-full border-b border-neutral-200 pb-2 mb-1 flex'>Revenue</span>
                <div className="flex items-center">
                    <div className="flex items-center pt-1">
                        <div className="w-2 h-2 mr-2 rounded-full" style={{ background: 'rgb(36, 99, 235)' }}></div>
                        <span>{`${payload[0].name}`}</span>
                    </div>
                    <span className=" ml-auto">${`${payload[0].value}`}K</span>
                </div>
            </div>
        );
    }

    return null;
};

const FinancialPieChart = ({year}) => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart >
                <Pie data={year && Data.pieChart.filter(data => data.year == year)[0].data} dataKey="value" cx="50%" cy="50%" innerRadius={50} outerRadius={80} fill="#2463EB" />
                <Tooltip content={<CustomTooltip />} />
            </PieChart>
        </ResponsiveContainer>
    )
}

export default FinancialPieChart