import React,{useEffect} from 'react';
import { useSelector } from "react-redux";
import BusinessModel from 'utils/export/businessModel';
import MissionVision from 'utils/export/MissionVision';
import SWOT from 'utils/export/SWOT';
import Visibility from 'utils/export/visibility';
import TargetAudience from 'utils/export/targetAudience';
import MarketRsearch from 'utils/export/marketSizeResearch';
import Competitor from 'utils/export/competitor';
import IndustryOverview from 'utils/export/industryOverview';

import BrandingIdentity from 'utils/export/sales/BrandingIdentity';
import MarketingIdeas from 'utils/export/sales/MarketingIdeas';
import CustomerRetention from 'utils/export/sales/CustomerRetention';
import OnlinePresence from 'utils/export/sales/OnlinePresence';
// import Revenue from 'utils/export/financials/Revenue';
// import Expenses from 'utils/export/financials/Expenses';
// import Financing from 'utils/export/financials/Financing';
// import Dividends from 'utils/export/financials/Dividends';
// import Taxes from 'utils/export/financials/Taxes';
// import ProfitLoss from 'utils/export/financials/ProfitLoss';
// import BalanceSheet from 'utils/export/financials/BalanceSheet';
// import CostsBreakdown from 'utils/export/financials/CostsBreakdown';
// import FundingPlan from 'utils/export/financials/FundingPlan';
import Expansion from 'utils/export/expansion';

import OperationPlan from 'utils/export/operationPlan';
import RiskAnalysis from 'utils/export/riskAnalysis';
import RegulatoryCompliance from 'utils/export/regulatoryCompliance';
import TeamRoles from 'utils/export/teamRoles';
import Offerings from 'utils/export/offerings';
import Secondary from 'utils/export/secondary';
import Customer from 'utils/export/customer';
const PdfView = () => {

    const user = useSelector(state => {
        return state.userSlice
    })
    
    useEffect(()=>{
        // alert(user.companies[0].jsonContent.length)
    
    })
//     Prepared by Dawson Whitfield
// dawson@venturekit.ai
// 416 887 1226
// venturekit.ai
// 123 Fake street, Toronto, ON, M5V 1A1

    return (

        <div style={{ marginTop: "0px",display:"none" }} >
            <div id="pdf_export" style={{ width: "400px", marginTop:"0px", paddingTop:"0px",display:"block", fontFamily:"Poppins, sans-serif"}}>
                <div style={{backgroundColor:"#5793EB", width:"500px", height:"100%" }} id='bg'>
                <div style={{margin:"50px",paddingTop:"200px",marginTop:"0px"}} >
                    <p style={{fontWeight:"bold", fontSize:"30px",color:"white",fontFamily:'sans-serif'}}>{user.companies[0].companyName}</p>
                    <p style={{ fontSize:"20px",color:"white",fontFamily:'sans-serif'}}>Business Plan</p>
                    <div style={{color:"white" ,width:"100vw", height:"345px",paddingTop:"150px",fontFamily:'sans-serif'}}>
                        <p style={{fontFamily:'sans-serif'}}>{user.email}</p>
                        <p style={{fontFamily:'sans-serif'}}>StartupSphere.ai</p>
                    
                    </div>

                </div>
                </div>
                <div id = "content1">
                <MissionVision  user={user} />
                </div>
                <div id = "content2">
                <Visibility user={user} />
                </div>
                <div id = "content3">
                <BusinessModel user={user} />
                </div>
                <div>
                <SWOT user={user} />
                
                <Offerings user = {user}></Offerings>
                <Expansion user={user}></Expansion>
                <Secondary user={user}></Secondary>
                <Customer user={user}></Customer>

                <IndustryOverview user={user} />
                {/* <TargetAudience user={user} /> */}
                <MarketRsearch user={user} />
                {/* <Competitor user={user} /> */}

                <BrandingIdentity user={user} />
                <MarketingIdeas user={user} />
                <CustomerRetention user={user} />
                <OnlinePresence user={user} />

                <OperationPlan user={user} />
                <TeamRoles user={user} />
                <RiskAnalysis user={user} />
                <RegulatoryCompliance user={user} />

                {/* <Revenue />
                <Expenses />
                <Financing />
                <Dividends />
                <Taxes />
                <ProfitLoss />
                <BalanceSheet />
                <CostsBreakdown user={user} />
                <FundingPlan user={user} /> */}
                </div>
            </div>
        </div>
    );

};

export default PdfView
